import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const DIGEST_LANG: {
  DIGEST_BREADCRUMB: MvnCodeFE;
} = {
  DIGEST_BREADCRUMB: {
    ko: "Audit Trail에 대한 Digest를 확인하고 데이터 위변조 여부를 모니터링 할 수 있습니다.",
    en: "Validate data integrity in audit trail.",
  },
};

MvnCodeInitiate(DIGEST_LANG);
