import React, { useEffect, useState } from "react";
import { FxApiRequest, useRequest, UseRequestProps } from "@coralblack/flax";
import { CrDialog } from "./CrDialog";
import { CrButton, CrButtonProps } from "../base/CrButton";
import { nl2br } from "../helper/nl2br";

export interface CrRequestButtonProps<TR, TE, TRR, TRE> extends Omit<CrButtonProps, "onClick"> {
  elementRef?: React.RefObject<HTMLElement>;
  api: FxApiRequest<TR, TE, TRR, TRE> | (() => FxApiRequest<TR, TE, TRR, TRE>);
  delegate?: UseRequestProps<TR, TE, TRR, TRE>;
  confirm?: {
    confirmHeader?: string;
    confirmMessage: string;
    confirmText: string;
    cancelText: string;
  };
  dialogTextSize?: "SMALL" | "MEDIUM" | "LARGE";
  onRequested?: () => void;
  onLoadingStatusChanged?: (busy: boolean) => void;
}

export function CrRequestButton<TR = any, TE = any, TRR = TR, TER = TE>(props: CrRequestButtonProps<TR, TE, TRR, TER>) {
  const { api, delegate, disabled, elementRef, confirm, onRequested, dialogTextSize, onLoadingStatusChanged, ...extras } = props;
  const [visibility, setVisibility] = useState(false);

  const { request, response, cancel } = useRequest<TR, TE, TRR, TER>(typeof api === "function" ? api() : api, {
    done: delegate?.done,
    success: delegate?.success,
    error: delegate?.error,
  });

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onLoadingStatusChanged && onLoadingStatusChanged(response.busy);
  }, [onLoadingStatusChanged, response]);

  if (confirm) {
    const { confirmMessage, confirmText, cancelText } = confirm;

    return (
      <>
        <CrButton elementRef={elementRef} disabled={disabled} loading={response.busy} onClick={() => setVisibility(true)} {...extras} />
        <CrDialog
          header={confirm.confirmHeader}
          visibility={visibility}
          confirmDisabled={disabled || response.busy}
          size="WIDE"
          textSize={dialogTextSize}
          onConfirm={() => {
            ((resp) => {
              if (resp) {
                request({ data: resp.data, query: resp.query, headers: resp.headers });
              } else {
                request();
              }
            })(typeof api === "function" ? api() : null);
            setVisibility(false);
          }}
          onCancel={() => {
            setVisibility(false);
            cancel();
          }}
          confirm={confirmText}
          cancel={cancelText}
        >
          {nl2br(confirmMessage)}
        </CrDialog>
      </>
    );
  }

  return (
    <>
      <CrButton
        elementRef={elementRef}
        disabled={disabled}
        loading={response.busy}
        busy={response.busy}
        onClick={() => {
          request();
          onRequested && onRequested();
        }}
        {...extras}
      />
    </>
  );
}
