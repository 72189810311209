/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_RANDOMIZATION: {
  RANDOMIZATION_INVALID: MvnCodeBE;
  RANDOMIZATION_NOTFOUND: MvnCodeBE;
  RANDOMIZATION_NOT_ENTERED: MvnCodeBE;
  RANDOMIZATION_PAUSE: MvnCodeBE;
  RANDOMIZATION_STRATIFICATION_NOT_CONFIGURED: MvnCodeBE;
  RANDOMIZATION_STRATIFICATION_NOT_ENTERED: MvnCodeBE;
  RANDOMIZATION_STRATIFICATION_INVALID: MvnCodeBE;
  RANDOMIZATION_RANDOM_GROUP_NOT_ENTERED: MvnCodeBE;
  RANDOMIZATION_NOT_ALLOWED: MvnCodeBE;
  NO_REMAIN_RANDOM_CODE: MvnCodeBE;
  RANDOM_CODE_INVALID: MvnCodeBE;
  RANDOM_CODE_NOTFOUND: MvnCodeBE;
  RANDOM_CODE_ALREADY_ASSIGNED: MvnCodeBE;
  RANDOM_ALLOCATION_EXCEED_MAXLEN: MvnCodeBE;
  RANDOMIZATION_ALREADY_GENERATED: MvnCodeBE;
  STRATIFICATION_NOTFOUND: MvnCodeBE;
  STRATIFICATION_DUPLICATED: MvnCodeBE;
  STRATIFICATION_LABEL_MISSING: MvnCodeBE;
  STRATIFICATION_CODE_MISSING: MvnCodeBE;
  STRATIFICATION_LABEL_INVALID: MvnCodeBE;
  STRATIFICATION_LABEL_EXCEED_MAXLEN: MvnCodeBE;
  STRATIFICATION_CODE_INVALID: MvnCodeBE;
  STRATIFICATION_CODE_EXCEED_MAXLEN: MvnCodeBE;
  STRATIFICATION_CODE_EXIST: MvnCodeBE;
  RANDOM_GROUP_NOTFOUND: MvnCodeBE;
  RANDOM_GROUP_DUPLICATED: MvnCodeBE;
  RANDOM_GROUP_LABEL_MISSING: MvnCodeBE;
  RANDOM_GROUP_CODE_MISSING: MvnCodeBE;
  RANDOM_GROUP_LABEL_INVALID: MvnCodeBE;
  RANDOM_GROUP_LABEL_EXCEED_MAXLEN: MvnCodeBE;
  RANDOM_GROUP_CODE_INVALID: MvnCodeBE;
  RANDOM_GROUP_CODE_EXCEED_MAXLEN: MvnCodeBE;
  RANDOM_GROUP_CODE_EXIST: MvnCodeBE;
  RANDOM_INITIALIZE_ALREADY_SUBJECT_ASSIGNED: MvnCodeBE;
  RANDOM_CODE_CANNOT_UPDATE_AFTER_RANDOM_CODE_ASSIGNED: MvnCodeBE;

  UPLOAD_STUDY_DISABLED: MvnCodeBE;
  UPLOAD_STUDY_LOCKED: MvnCodeBE;
  UPLOAD_RANDOMIZATION_NOT_SETUP: MvnCodeBE;
  UPLOAD_SITE_NOT_EXIST: MvnCodeBE;
  UPLOAD_FILE_NOT_EXIST: MvnCodeBE;
  UPLOAD_SITE_CODE_MISSING: MvnCodeBE;
  UPLOAD_SITE_CODE_INVALID: MvnCodeBE;
  UPLOAD_SITE_CODE_ONLY_POSSIBLE_ALL: MvnCodeBE;
  UPLOAD_SITE_CODE_ONLY_POSSIBLE_SITE: MvnCodeBE;
  UPLOAD_STRATIFICATION_MISSING: MvnCodeBE;
  UPLOAD_SEQ_MISSING: MvnCodeBE;
  UPLOAD_SITE_STRATIFICATION_SEQ_DUPLICATED: MvnCodeBE;
  UPLOAD_SEQ_INVALID: MvnCodeBE;
  UPLOAD_RANDOM_GROUP_MISSING: MvnCodeBE;
  UPLOAD_RANDOM_GROUP_TOO_LONG: MvnCodeBE;
  UPLOAD_RANDOM_CODE_MISSING: MvnCodeBE;
  UPLOAD_RANDOM_CODE_TOO_LONG: MvnCodeBE;
  UPLOAD_RANDOM_CODE_DUPLICATED: MvnCodeBE;
  UPLOAD_DESC_TOO_LONG: MvnCodeBE;
  UPLOAD_STRATIFICATION_INVALID: MvnCodeBE;
  UPLOAD_RANDOM_GROUP_INALID: MvnCodeBE;
  UPLOAD_UNEXPECTED_ERROR: MvnCodeBE;
} = {
  RANDOMIZATION_INVALID: {
    status: 400,
    ko: "요청하신 Study Randomization 정보가 올바르지 않습니다.",
    en: "Requested study Randomization is invalid.",
  },
  RANDOMIZATION_NOTFOUND: {
    status: 404,
    ko: "요청하신 Study Randomization가 존재하지 않습니다.",
    en: "Requested study Randomization does not exist.",
  },
  RANDOMIZATION_NOT_ENTERED: {
    status: 400,
    ko: "Study Randomization 설정이 입력되지 않았습니다.",
    en: "Study randomization setting is not entered.",
  },
  RANDOMIZATION_PAUSE: {
    status: 400,
    ko: "대상자 Random Code 할당이 일시정지된 상태입니다.",
    en: "Subject random code assignment has been paused.",
  },
  RANDOMIZATION_STRATIFICATION_NOT_CONFIGURED: {
    status: 400,
    ko: "무작위배정 번호 부여를 위한 Stratification이 설정되지 않았습니다.",
    en: "No stratification is set for randomization.",
  },
  RANDOMIZATION_STRATIFICATION_NOT_ENTERED: {
    status: 400,
    ko: "무작위배정 번호 부여를 위한 Stratification이 입력되지 않았습니다.",
    en: "No stratification is entered for randomization.",
  },
  RANDOMIZATION_STRATIFICATION_INVALID: {
    status: 400,
    ko: "무작위배정 번호 부여를 위한 Stratification이 올바르지 않습니다.",
    en: "Stratification is invalid for randomization.",
  },
  RANDOMIZATION_RANDOM_GROUP_NOT_ENTERED: {
    status: 400,
    ko: "무작위배정 번호 부여를 위한 Random Group이 입력되지 않았습니다.",
    en: "No random group is entered for randomization.",
  },
  RANDOMIZATION_NOT_ALLOWED: {
    status: 400,
    ko: "Randomization 설정을 허용하지 않는 스터디입니다.",
    en: "This study does not use randomization.",
  },
  NO_REMAIN_RANDOM_CODE: {
    status: 400,
    ko: "할당할 수 있는 무작위배정 번호가 없습니다.",
    en: "There is no random code left to assign.",
  },
  RANDOM_CODE_NOTFOUND: {
    status: 400,
    ko: "무작위 배정 번호가 존재하지 않습니다.",
    en: "Random code could not be found.",
  },
  RANDOM_CODE_ALREADY_ASSIGNED: {
    status: 400,
    ko: "무작위 배정 번호가 이미 부여된 대상자입니다.",
    en: "The subject have already been assigned a random code.",
  },
  RANDOM_CODE_INVALID: {
    status: 400,
    ko: "무작위 배정 번호가 잘못되었습니다.",
    en: "Random code is invalid.",
  },
  RANDOMIZATION_ALREADY_GENERATED: {
    status: 409,
    ko: "무작위배정 번호가 생성된 뒤에는 수정할 수 없습니다. 무작위 배정 초기화 후에 시도해 주십시오.",
    en: "Random code cannot be modified. Please initilize randomization and try again.",
  },
  RANDOM_ALLOCATION_EXCEED_MAXLEN: {
    status: 400,
    ko: "입력된 무작위 배정 대상자수보다 초과 되었습니다.",
    en: "Number of randomization exceeded number of subjects.",
  },
  STRATIFICATION_NOTFOUND: {
    status: 400,
    ko: "Stratification 정보가 존재하지 않습니다.",
    en: "Stratification does not exists.",
  },
  STRATIFICATION_DUPLICATED: {
    status: 400,
    ko: "Stratification가 중복되었습니다.",
    en: "Stratification is duplicated.",
  },
  STRATIFICATION_LABEL_MISSING: {
    status: 400,
    ko: "Stratification이 누락되었습니다.",
    en: "Stratification is missing.",
  },
  STRATIFICATION_CODE_MISSING: {
    status: 400,
    ko: "Stratification Code가 누락되었습니다.",
    en: "Stratification code is missing.",
  },
  STRATIFICATION_LABEL_INVALID: {
    status: 400,
    ko: "Stratification이 잘못되었습니다.",
    en: "Stratification is invalid.",
  },
  STRATIFICATION_LABEL_EXCEED_MAXLEN: {
    status: 400,
    ko: "Stratification의 길이가 255자를 초과했습니다.",
    en: "Stratification has exceeded 255 characters in length.",
  },
  STRATIFICATION_CODE_INVALID: {
    status: 400,
    ko: "Stratification Code가 올바르지 않습니다.",
    en: "Stratification code is invalid.",
  },
  STRATIFICATION_CODE_EXCEED_MAXLEN: {
    status: 400,
    ko: "Stratification Code가 255자를 초과했습니다.",
    en: "Stratification code has exceeded 255 characters in length.",
  },
  STRATIFICATION_CODE_EXIST: {
    status: 409,
    ko: "Stratification Code가 이미 존재합니다.",
    en: "Stratification code is already exist.",
  },
  RANDOM_GROUP_NOTFOUND: {
    status: 400,
    ko: "Random Group 정보가 존재하지 않습니다.",
    en: "Random group does not exist.",
  },
  RANDOM_GROUP_DUPLICATED: {
    status: 400,
    ko: "Random Group이 중복되었습니다.",
    en: "Random group is duplicated.",
  },
  RANDOM_GROUP_LABEL_MISSING: {
    status: 400,
    ko: "Random Group이 누락되었습니다.",
    en: "Random group is missing.",
  },
  RANDOM_GROUP_CODE_MISSING: {
    status: 400,
    ko: "Random Group Code가 누락되었습니다.",
    en: "Random group code is missing.",
  },
  RANDOM_GROUP_LABEL_INVALID: {
    status: 400,
    ko: "Random Group이 잘못되었습니다.",
    en: "Random group is invalid.",
  },
  RANDOM_GROUP_LABEL_EXCEED_MAXLEN: {
    status: 400,
    ko: "Random Group의 길이가 255자를 초과했습니다.",
    en: "Random group has exceeded 255 characters in length.",
  },
  RANDOM_GROUP_CODE_INVALID: {
    status: 400,
    ko: "Random Group Code가 올바르지 않습니다.",
    en: "Random group code is invalid.",
  },
  RANDOM_GROUP_CODE_EXCEED_MAXLEN: {
    status: 400,
    ko: "Random Group Code가 255자를 초과했습니다.",
    en: "Random group code has exceeded 255 characters in length.",
  },
  RANDOM_GROUP_CODE_EXIST: {
    status: 404,
    ko: "Random Group Code가 이미 존재합니다.",
    en: "Random group code is already exists.",
  },
  RANDOM_INITIALIZE_ALREADY_SUBJECT_ASSIGNED: {
    status: 409,
    ko: "이미 대상자에 할당된 Random Code가 있어서 초기화 기능을 수행할 수 없습니다.",
    en: "Random Code cannot be initialized since Random Code is alreay assigned to subject.",
  },
  RANDOM_CODE_CANNOT_UPDATE_AFTER_RANDOM_CODE_ASSIGNED: {
    status: 400,
    ko: "Random Code 할당이 시작된 후에는 Random Code 추가만 가능합니다. .",
    en: "You may only add Random Code once assigned.",
  },
  UPLOAD_STUDY_DISABLED: {
    status: 400,
    ko: "Study 정보가 잘못되었습니다.",
    en: "Study information is not valid.",
  },
  UPLOAD_STUDY_LOCKED: {
    status: 400,
    ko: "Study가 Lock 상태입니다.",
    en: "Study is locked.",
  },
  UPLOAD_RANDOMIZATION_NOT_SETUP: {
    status: 400,
    ko: "무작위 배정 정보가 세팅되지 않았습니다.",
    en: "Randomization is not set.",
  },
  UPLOAD_SITE_NOT_EXIST: {
    status: 400,
    ko: "등록된 Site가 없습니다.",
    en: "No registered sites.",
  },
  UPLOAD_FILE_NOT_EXIST: {
    status: 400,
    ko: "Randomization Upload File이 존재하지 않습니다.",
    en: "Randomization upload File dose not exist.",
  },
  UPLOAD_SITE_CODE_MISSING: {
    status: 400,
    ko: "`Site code`가 입력되지 않은 항목이 있습니다.",
    en: "Please check items with no `Site Code`.",
  },
  UPLOAD_SITE_CODE_INVALID: {
    status: 400,
    ko: "`Site code`가 잘못된 항목이 있습니다.",
    en: "Please check items with incorrect `Site Code`.",
  },
  UPLOAD_SITE_CODE_ONLY_POSSIBLE_ALL: {
    status: 400,
    ko: "Randomization이 Study 단위로 적용된 경우 `Site Code`는 [ALL] 만 입력할 수 있습니다.",
    en: "You may only enter [ALL] to `Site Code` when randomization is applied in Study level.",
  },
  UPLOAD_SITE_CODE_ONLY_POSSIBLE_SITE: {
    status: 400,
    ko: "Randomization이 Site 단위로 적용된 경우 `Site Code`에는 Random Code를 할당할 Site Code를 입력해야 합니다.",
    en: "Please enter appropriate site codes to `Site Code` when randomization is applied in Site level.",
  },
  UPLOAD_STRATIFICATION_MISSING: {
    status: 400,
    ko: "`Stratification`이 입력되지 않은 항목이 있습니다.",
    en: "Please check items with no `Stratification`.",
  },
  UPLOAD_SEQ_MISSING: {
    status: 400,
    ko: "`Seq`가 입력되지 않은 항목이 있습니다.",
    en: "Please check items with no `Seq`.",
  },
  UPLOAD_SITE_STRATIFICATION_SEQ_DUPLICATED: {
    status: 400,
    ko: "`Site`, `Stratification`, `Seq`의 집합은 중복될 수 없습니다.",
    en: "The sets of 'Site', 'Stratification', and 'Seq' cannot be duplicated.",
  },
  UPLOAD_SEQ_INVALID: {
    status: 400,
    ko: "`Seq`가 잘못된 항목이 있습니다.",
    en: "Please check items with incorrect `Seq`.",
  },
  UPLOAD_RANDOM_GROUP_MISSING: {
    status: 400,
    ko: "`Random Group`이 입력되지 않은 항목이 있습니다.",
    en: "Please check items with no `Random Group`.",
  },
  UPLOAD_RANDOM_GROUP_TOO_LONG: {
    status: 400,
    ko: "`Random Group`이 255자를 초과한 항목이 있습니다.",
    en: "`Random Group` contains more than 255 characters.",
  },
  UPLOAD_RANDOM_CODE_MISSING: {
    status: 400,
    ko: "`Random Code`가 입력되지 않은 항목이 있습니다.",
    en: "Please check items with no `Random Code`.",
  },
  UPLOAD_RANDOM_CODE_TOO_LONG: {
    status: 400,
    ko: "`Random Code`가 255자를 초과한 항목이 있습니다.",
    en: "`Random Group` contains more than 255 characters.",
  },
  UPLOAD_RANDOM_CODE_DUPLICATED: {
    status: 400,
    ko: "`Random Code`가 중복된 항목이 있습니다.",
    en: "Please check duplicated `Random Code`.",
  },
  UPLOAD_DESC_TOO_LONG: {
    status: 400,
    ko: "`Description`이 255자를 초과한 항목이 있습니다.",
    en: "`Description` contains more than 255 characters.",
  },
  UPLOAD_STRATIFICATION_INVALID: {
    status: 400,
    ko: "`Stratification`이 잘못된 항목이 있습니다.",
    en: "Please check items with duplicated `Random Code`.",
  },
  UPLOAD_RANDOM_GROUP_INALID: {
    status: 400,
    ko: "`Random Group`이 잘못된 항목이 있습니다.",
    en: "Please check items with incorrect `Random Group`.",
  },
  UPLOAD_UNEXPECTED_ERROR: {
    status: 400,
    ko: "Randomization Upload에 실패하였습니다.",
    en: "Failed to upload Randomization.",
  },
};

//

MvnCodeInitiate(STUDY_RANDOMIZATION);
