import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CASE_REPORT_REQUEST_CODE: {
  SAFETY_CASE_REPORTS_REQUEST_NOT_AVAILABLE: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUEST_INVALID_IS_NOT_BLINDED: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUEST_VALIDATE_ERROR_APPROVER_DUPLICATED: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUEST_INVALID_EXIST_PRIVILEGE: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUEST_INVALID_MSG_MAX_LENGTH: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUEST_INVALID_MSG_MIN_LENGTH: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUEST_INVALID_APPROVER: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUEST_INVALID_APPROVER_APPROVE_ORDER: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUEST_INVALID_APPROVER_REJECT_ORDER: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUEST_PAGINATE_INVALID_ID: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUEST_INVALID_BATCH_COUNT_EXCEED: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUESTS_INVALID_CASE_MISSING: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUESTS_INVALID_UNBLIND_REPORT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUESTS_INVALID_CASE_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUESTS_INVALID_APPROVER_MISSING: MvnCodeBE;
  SAFETY_CASE_REPORT_REQUESTS_INVALID_MAX_APPROVER_EXCEED: MvnCodeBE;
} = {
  SAFETY_CASE_REPORTS_REQUEST_NOT_AVAILABLE: {
    status: 400,
    ko: "승인 요청 중이거나 unblind 요청 중인 리포트는 요청할 수 없습니다.",
    en: "Report(s) that are unblind requested or approved for request cannot be requested.",
  },
  SAFETY_CASE_REPORT_REQUEST_INVALID_IS_NOT_BLINDED: {
    status: 400,
    ko: "Blind 되지 않은 Report는 unblind 요청을 보낼 수 없습니다.",
    en: "Unblinded reports can not send unblind request.",
  },
  SAFETY_CASE_REPORT_REQUEST_VALIDATE_ERROR_APPROVER_DUPLICATED: {
    status: 400,
    ko: "승인자가 중복되었습니다.",
    en: "Approver is duplicated.",
  },
  SAFETY_CASE_REPORT_REQUEST_INVALID_EXIST_PRIVILEGE: {
    status: 400,
    ko: "이미 부여받은 권한입니다.",
    en: "Privilege is duplicated",
  },
  SAFETY_CASE_REPORT_REQUEST_INVALID_MSG_MAX_LENGTH: {
    status: 400,
    ko: "메세지의 길이는 최대 {length}자 입니다.",
    en: "Messages can be up to {length} characters long.",
  },
  SAFETY_CASE_REPORT_REQUEST_INVALID_MSG_MIN_LENGTH: {
    status: 400,
    ko: "메세지의 길이는 최소 {length}자 이상입니다.",
    en: "Messages must be at least {length} characters long.",
  },
  SAFETY_CASE_REPORT_REQUEST_INVALID_APPROVER: {
    status: 400,
    ko: "'{privilege}' 권한만 승인자로 지정할 수 있습니다.",
    en: "Only members with the '{privilege}' permission can be designated as approvers.",
  },
  SAFETY_CASE_REPORT_REQUEST_INVALID_APPROVER_APPROVE_ORDER: {
    status: 409,
    ko: "본인 차례에만 승인 가능합니다.",
    en: "You can only approve on your turn.",
  },
  SAFETY_CASE_REPORT_REQUEST_INVALID_APPROVER_REJECT_ORDER: {
    status: 409,
    ko: "본인 차례에만 반려 가능합니다.",
    en: "You can only reject on your turn.",
  },
  SAFETY_CASE_REPORT_REQUEST_PAGINATE_INVALID_ID: {
    status: 400,
    ko: "requestId, approverId 중 하나의 값이 포함되어야 합니다.",
    en: "Contain one of requestId and approverId values.",
  },
  SAFETY_CASE_REPORT_REQUEST_INVALID_BATCH_COUNT_EXCEED: {
    status: 400,
    ko: "하나의 요청에 {limit}건을 초과할 수 없습니다.",
    en: "A request cannot exceed {limit}.",
  },
  SAFETY_CASE_REPORT_REQUESTS_INVALID_CASE_MISSING: {
    status: 400,
    ko: "최소 1개 이상의 case를 선택해야합니다.",
    en: "You must select at least 1 case.",
  },
  SAFETY_CASE_REPORT_REQUESTS_INVALID_UNBLIND_REPORT_EXIST: {
    status: 400,
    ko: "Blind 되지 않은 report가 포함되어있습니다.",
    en: "Includes unblinded reports.",
  },
  SAFETY_CASE_REPORT_REQUESTS_INVALID_CASE_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 case가 포함되어있습니다.",
    en: "Contains a case that doesn't exist.",
  },
  SAFETY_CASE_REPORT_REQUESTS_INVALID_APPROVER_MISSING: {
    status: 400,
    ko: "최소 1명 이상의 승인자를 선택해야합니다.",
    en: "You must select at least 1 approver.",
  },
  SAFETY_CASE_REPORT_REQUESTS_INVALID_MAX_APPROVER_EXCEED: {
    status: 400,
    ko: "승인자는 최대 {limit}명까지 지정 가능합니다.",
    en: "You can have up to {limit} approver.",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_REQUEST_CODE);
