import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const ECOA_CAPTURE_CODE: {
  ECOA_CAPTURE_DATA_MISSING: MvnCodeBE;
  ECOA_CAPTURE_VISIT_NOT_FOUND: MvnCodeBE;
  ECOA_CAPTURE_VISIT_NOT_AVAILABLE: MvnCodeBE;
  ECOA_CAPTURE_QUESTIONNAIRE_NOT_AVAILABLE: MvnCodeBE;
  ECOA_CAPTURE_SUBMIT_NO_CHANGES: MvnCodeBE;
} = {
  ECOA_CAPTURE_DATA_MISSING: {
    status: 400,
    ko: "답변을 입력해 주세요.",
    en: "Please enter an answer.",
  },
  ECOA_CAPTURE_VISIT_NOT_FOUND: {
    status: 400,
    ko: "요청하신 eCOA Visit을 찾을 수 없습니다.",
    en: "The requested eCOA visit cannot be found.",
  },
  ECOA_CAPTURE_VISIT_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 eCOA Visit에 Data를 Capture 할 수 없습니다.",
    en: "The requested data cannot be captured to the specified eCOA visit.",
  },
  ECOA_CAPTURE_QUESTIONNAIRE_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 eCOA Questionnaire에 Data를 Capture 할 수 없습니다.",
    en: "The requested data cannot be captured to the specified eCOA questionnaire.",
  },
  ECOA_CAPTURE_SUBMIT_NO_CHANGES: {
    status: 400,
    ko: "제출할 답변이 없습니다.",
    en: "There are no answers to submit.",
  },
};

MvnCodeInitiate(ECOA_CAPTURE_CODE);
