import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_NOTIFICATION_MAIL_LANG: {
  SAFETY_NOTIFICATION_MAIL_BODY_QUESTIONS: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_BODY_CONTACT: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_INVITE_WORKSPACE_MEMBER_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_INVITE_WORKSPACE_MEMBER_CONTENT_001: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_INVITE_WORKSPACE_MEMBER_CONTENT_002: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_DELETE_WORKSPACE_MEMBER_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_DELETE_WORKSPACE_MEMBER_CONTENT_001: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_CHANGED_WORKSPACE_ROLE_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_CHANGED_WORKSPACE_ROLE_PRIVILEGE_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_CASE_REPORT_REQUESTED_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_CASE_REPORT_REQUEST_APPROVED_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_CASE_REPORT_REQUEST_REJECTED_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_CASE_REPORT_REQUEST_CANCELED_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_WORKSPACE_DESTROY_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_WORKSPACE_DESTROY_CONTENT: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_WORKFLOW_REQUESTED_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_WORKFLOW_CANCELED_TITLE: MvnCodeFE;
  SAFETY_NOTIFICATION_MAIL_WORKFLOW_SEND_BACK_TITLE: MvnCodeFE;
} = {
  SAFETY_NOTIFICATION_MAIL_BODY_QUESTIONS: {
    ko: "문의 사항은 아래 연락처를 통해 문의 부탁드립니다.",
    en: "If you have any questions, please contact us.",
  },
  SAFETY_NOTIFICATION_MAIL_BODY_CONTACT: {
    ko: "cs@jnpmedi.com\n070-4128-2040",
    en: "cs@jnpmedi.com\n070-4128-2040",
  },
  SAFETY_NOTIFICATION_MAIL_INVITE_WORKSPACE_MEMBER_TITLE: {
    ko: "[Maven Safety] 초대가 도착하였습니다",
    en: "[Maven Safety] You have an invitation",
  },
  SAFETY_NOTIFICATION_MAIL_INVITE_WORKSPACE_MEMBER_CONTENT_001: {
    ko: "{name} ({email})님의 초대가 도착했습니다.",
    en: "{name} ({email}) has invited you.",
  },
  SAFETY_NOTIFICATION_MAIL_INVITE_WORKSPACE_MEMBER_CONTENT_002: {
    ko: "Join 버튼을 클릭하여 새로운 Workspace 에 참여할 수 있습니다.",
    en: "Please join the workspace by clicking the Join button.",
  },
  SAFETY_NOTIFICATION_MAIL_DELETE_WORKSPACE_MEMBER_TITLE: {
    ko: "[Maven Safety] Workspace에서 제외되었습니다",
    en: "[Maven Safety] You have been eliminated from Workspace",
  },
  SAFETY_NOTIFICATION_MAIL_DELETE_WORKSPACE_MEMBER_CONTENT_001: {
    ko: "{workspace}에서 제외되었습니다.",
    en: "You have been eliminated from {workspace}.",
  },
  SAFETY_NOTIFICATION_MAIL_CHANGED_WORKSPACE_ROLE_TITLE: {
    ko: "[Maven Safety] Workspace의 Role이 변경되었습니다",
    en: "[Maven Safety] Role in Workspace has been changed",
  },
  SAFETY_NOTIFICATION_MAIL_CHANGED_WORKSPACE_ROLE_PRIVILEGE_TITLE: {
    ko: "[Maven Safety] 권한이 변경되었습니다",
    en: "[Maven Safety] Your privilege have been changed",
  },
  SAFETY_NOTIFICATION_MAIL_CASE_REPORT_REQUESTED_TITLE: {
    ko: "[Maven Safety] 새로운 요청이 도착했습니다",
    en: "[Maven Safety] Request has been arrived",
  },
  SAFETY_NOTIFICATION_MAIL_CASE_REPORT_REQUEST_APPROVED_TITLE: {
    ko: "[Maven Safety] 요청에 대한 승인이 완료되었습니다",
    en: "[Maven Safety] Request has been approved",
  },
  SAFETY_NOTIFICATION_MAIL_CASE_REPORT_REQUEST_REJECTED_TITLE: {
    ko: "[Maven Safety] 요청에 대한 승인이 반려되었습니다",
    en: "[Maven Safety] Request has been rejected",
  },
  SAFETY_NOTIFICATION_MAIL_CASE_REPORT_REQUEST_CANCELED_TITLE: {
    ko: "[Maven Safety] 요청이 취소되었습니다",
    en: "[Maven Safety] Request has been canceled",
  },
  SAFETY_NOTIFICATION_MAIL_WORKSPACE_DESTROY_TITLE: {
    ko: "[Maven Safety] Database가 곧 삭제됩니다",
    en: "[Maven Safety] Your database will be deleted soon",
  },
  SAFETY_NOTIFICATION_MAIL_WORKSPACE_DESTROY_CONTENT: {
    ko: "Maven-Safety Subscription Plan을 구독해 Database를 유지하세요.",
    en: "Subscribe any plans to maintain your database.",
  },
  SAFETY_NOTIFICATION_MAIL_WORKFLOW_REQUESTED_TITLE: {
    ko: "[Maven Safety] {status} 요청이 있습니다",
    en: "[Maven Safety] {status} request has been arrived",
  },
  SAFETY_NOTIFICATION_MAIL_WORKFLOW_CANCELED_TITLE: {
    ko: "[Maven Safety] {status} 요청이 취소되었습니다",
    en: "[Maven Safety] {status} request has been canceled",
  },
  SAFETY_NOTIFICATION_MAIL_WORKFLOW_SEND_BACK_TITLE: {
    ko: "[Maven Safety] {status} 요청에 대한 검토 의견이 전달되었습니다",
    en: "[Maven Safety] Review comments for {status} request have arrived",
  },
};

MvnCodeInitiate(SAFETY_NOTIFICATION_MAIL_LANG);
