import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_PAYLOAD_SCHEDULE_LANG: {
  BUILDER_STUDY_PAYLOAD_SCHEDULE_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_PROPERTY_NOT_SELECTED_DESCRIPTION: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_SAVE_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_SAVE_FAIL: MvnCodeFE;
} = {
  BUILDER_STUDY_PAYLOAD_SCHEDULE_PAGE_TITLE_DESCRIPTION: {
    ko: "Study schedule을 설정할 수 있습니다.",
    en: "Build your study schedule.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_PROPERTY_NOT_SELECTED_DESCRIPTION: {
    ko: "Folder 혹은 Visit을 선택하여 속성을 설정하세요.",
    en: "Select folder or visit to set property.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_SAVE_SUCC: {
    ko: "Schedule 정보를 저장했습니다.",
    en: "You have saved schedule successfully.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_SAVE_FAIL: {
    ko: "Schedule 정보 저장에 실패하였습니다. ({message})",
    en: "Failed to save the schedule. ({message})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_SCHEDULE_LANG);
