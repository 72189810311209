import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SSO_USER_DEVICE_CODE: {
  SSO_USER_DEVICE_CODE_USER_DEVICE_TOKEN_INVALID: MvnCodeBE;
  SSO_USER_DEVICE_CODE_USER_DEVICE_DELETE_FAIL: MvnCodeBE;
  SSO_USER_DEVICE_CODE_BROWSER_AND_OS_INVALID: MvnCodeBE;
} = {
  SSO_USER_DEVICE_CODE_USER_DEVICE_TOKEN_INVALID: {
    code: "INVALID-USER-DEVICE",
    status: 403,
    ko: "유효하지 않은 기기입니다.",
    en: "The device is invalid.",
  },
  SSO_USER_DEVICE_CODE_USER_DEVICE_DELETE_FAIL: {
    status: 400,
    ko: "기기 삭제에 실패하였습니다.",
    en: "Failed to delete a device.",
  },
  SSO_USER_DEVICE_CODE_BROWSER_AND_OS_INVALID: {
    status: 400,
    ko: "브라우저와 OS 정보가 유효하지 않습니다. 해당 환경에서는 로그인이 불가능합니다.",
    en: "Your browser and OS information are invalid. Login is not possible in your environment.",
  },
};

MvnCodeInitiate(SSO_USER_DEVICE_CODE);
