/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SSO_USER_VERIFICATION_CODE_LANG: {
  SSO_USER_VERIFICATION_CODE_PLACEHOLDER: MvnCodeFE;
  SSO_USER_VERIFICATION_CODE_USER_DEVICE_EMAIL_SENT: MvnCodeFE;
  SSO_USER_VERIFICATION_CODE_SIGN_UP_EMAIL_SENT: MvnCodeFE;
} = {
  SSO_USER_VERIFICATION_CODE_PLACEHOLDER: {
    ko: "이메일로 전송된 인증코드를 입력해 주세요.",
    en: "Please enter the verification code sent to your email.",
  },
  SSO_USER_VERIFICATION_CODE_USER_DEVICE_EMAIL_SENT: {
    ko: "로그인에 필요한 인증코드가 전송되었습니다. 메일을 확인하여 인증코드를 입력해 주세요.",
    en: "Sent you a verification code to login. Please check your email and enter the verification code.",
  },
  SSO_USER_VERIFICATION_CODE_SIGN_UP_EMAIL_SENT: {
    ko: "회원가입에 필요한 인증코드가 전송되었습니다. 메일을 확인하여 인증코드를 입력해 주세요.",
    en: "A verification code was sent for sign-up. Please check your email and enter the verification code.",
  },
};

MvnCodeInitiate(SSO_USER_VERIFICATION_CODE_LANG);
