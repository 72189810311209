import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BILLING_FUNCTIONALITY_CODE: {
  BILLING_FUNCTIONALITY_NOT_SUBSCRIBED: MvnCodeBE;
  BILLING_FUNCTIONALITY_USAGE_EXCEEDED_DOCS_STORAGE: MvnCodeBE;
  BILLING_FUNCTIONALITY_USAGE_EXCEEDED_DOCS_ESIGN: MvnCodeBE;
  BILLING_FUNCTIONALITY_NOT_SUBSCRIBED_DOCS_ESIGN_PERSONAL_IDENTIFICATION: MvnCodeBE;
  BILLING_FUNCTIONALITY_USAGE_EXCEEDED_SAFETY_WORKSPACE: MvnCodeBE;
  BILLING_FUNCTIONALITY_USAGE_EXCEEDED_SAFETY_XML_EXPORT: MvnCodeBE;
} = {
  BILLING_FUNCTIONALITY_NOT_SUBSCRIBED: {
    status: 409,
    ko: "현재 구독중인 Plan에서는 해당 기능을 사용할 수 없습니다.",
    en: "The feature is not available on the plan you are currently subscribed.",
  },
  BILLING_FUNCTIONALITY_USAGE_EXCEEDED_DOCS_STORAGE: {
    status: 409,
    ko: "사용 가능한 용량을 모두 사용하였습니다.",
    en: "All available storage space has been used.",
  },
  BILLING_FUNCTIONALITY_USAGE_EXCEEDED_DOCS_ESIGN: {
    status: 409,
    ko: "사용 가능한 전자서명 건수를 모두 사용하였습니다.",
    en: "All available e-sign requests have been used.",
  },
  BILLING_FUNCTIONALITY_NOT_SUBSCRIBED_DOCS_ESIGN_PERSONAL_IDENTIFICATION: {
    status: 409,
    ko: "본인 인증 기능은 Pro Plan부터 사용할 수 있습니다.",
    en: "The personal identification feature is available from the Pro Plan.",
  },
  BILLING_FUNCTIONALITY_USAGE_EXCEEDED_SAFETY_WORKSPACE: {
    status: 409,
    ko: "생성 가능한 워크스페이스의 건수를 모두 사용하였습니다.",
    en: "All workspaces that can be created have been used.",
  },
  BILLING_FUNCTIONALITY_USAGE_EXCEEDED_SAFETY_XML_EXPORT: {
    status: 409,
    ko: "사용 가능한 export 건수를 모두 사용하였습니다.",
    en: "All available export requests have been used.",
  },
};

MvnCodeInitiate(BILLING_FUNCTIONALITY_CODE);
