import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CASE_REPORT_LANG: {
  SAFETY_CASE_REPORT_ADD_SUCC: MvnCodeFE;
  SAFETY_CASE_REPORT_ADD_FAIL: MvnCodeFE;
  SAFETY_CASE_REPORT_ADD: MvnCodeFE;
  SAFETY_CASE_REPORT_ADD_DIALOG_DESCRIPTION: MvnCodeFE;
} = {
  SAFETY_CASE_REPORT_ADD_SUCC: {
    ko: "Report가 성공적으로 생성되었습니다.",
    en: "Report has been created successfully.",
  },
  SAFETY_CASE_REPORT_ADD_FAIL: {
    ko: "Report 생성에 실패했습니다. ({message})",
    en: "Failed to create report. ({message})",
  },
  SAFETY_CASE_REPORT_ADD: {
    ko: "Add Report",
    en: "Add Report",
  },
  SAFETY_CASE_REPORT_ADD_DIALOG_DESCRIPTION: {
    ko: "Follow-up 리포트를 생성하시겠습니까? \nFollow-up 리포트가 생성되면, \n기존에 생성된 리포트는 승인 취소가 불가능합니다.",
    en: "Are you sure you want to add follow-up report? \nAfter follow-up report is created, \nyou can not request Cancel Approval for previous reports.",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_LANG);
