/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_COUNTRY_CODE: {
  TMF_STUDY_COUNTRY_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_COUNTRY_ID_MISSING: MvnCodeBE;
  TMF_STUDY_COUNTRY_EXIST: MvnCodeBE;
  TMF_STUDY_COUNTRY_NOT_EXIST: MvnCodeBE;
  TMF_STUDY_COUNTRY_DUPLICATED: MvnCodeBE;
  TMF_STUDY_COUNTRY_COUNTRY_ID_DUPLICATED: MvnCodeBE;
  TMF_STUDY_COUNTRY_NO_SITES: MvnCodeBE;
} = {
  TMF_STUDY_COUNTRY_NOT_FOUND: {
    code: "TMF_STUDY_COUNTRY_NOT_FOUND",
    status: 404,
    ko: "요청하신 Study country({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study country({id}) could not be found.",
  },
  TMF_STUDY_COUNTRY_ID_MISSING: {
    code: "TMF_STUDY_COUNTRY_ID_MISSING",
    status: 400,
    ko: "studyCountryId({id})가 입력되지 않았습니다.",
    en: "studyCountryId({id}) is missing.",
  },
  TMF_STUDY_COUNTRY_EXIST: {
    code: "TMF_STUDY_COUNTRY_EXIST",
    status: 409,
    ko: "Study country({id})가 이미 존재합니다.",
    en: "Study country({id}) already exists.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_COUNTRY_NOT_EXIST: {
    code: "TMF_STUDY_COUNTRY_NOT_EXIST",
    status: 409,
    ko: "Study country({id})가 존재하지 않습니다.",
    en: "Study country({id}) dose not exists.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_COUNTRY_DUPLICATED: {
    code: "TMF_STUDY_COUNTRY_DUPLICATED",
    status: 409,
    ko: "중복된 Study country({id})가 존재합니다.",
    en: "Study country({id}) is duplicated.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_COUNTRY_COUNTRY_ID_DUPLICATED: {
    code: "TMF_STUDY_COUNTRY_COUNTRY_ID_DUPLICATED",
    status: 409,
    ko: "스터디 내에 중복된 country({id})가 존재합니다.",
    en: "Country({id}) in study is duplicated.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_COUNTRY_NO_SITES: {
    code: "TMF_STUDY_COUNTRY_NO_SITES",
    status: 400,
    ko: "국가(country) 내에 존재하는 site가 없습니다.",
    en: "No sites exist in the country(country).", // TODO: 번역 확인 필요
  },
};

MvnCodeInitiate(TMF_STUDY_COUNTRY_CODE);
