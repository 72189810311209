/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_MILESTONE_TIMELINE_CODE: {
  TMF_STUDY_MILESTONE_TIMELINE_LEVEL_INVALID: MvnCodeBE;

  TMF_STUDY_MILESTONE_COUNTRY_MISSING: MvnCodeBE;
  TMF_STUDY_MILESTONE_SITE_MISSING: MvnCodeBE;

  TMF_STUDY_MILESTONE_TIMELINE_BASELINE_START_DATE_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_TIMELINE_PLANNED_START_DATE_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_TIMELINE_ACTUAL_START_DATE_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_TIMELINE_BASELINE_FINISH_DATE_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_TIMELINE_PLANNED_FINISH_DATE_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_TIMELINE_ACTUAL_FINISH_DATE_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_TIMELINE_ESSENTIAL_DOCUMENT_ID_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_TIMELINE_ESSENTIAL_EXPECTED_INVALID: MvnCodeBE;

} = {
  TMF_STUDY_MILESTONE_TIMELINE_LEVEL_INVALID: {
    code: "TMF_STUDY_MILESTONE_TIMELINE_LEVEL_INVALID",
    status: 400,
    ko: "TMF Level({level})가 잘못되었습니다.",
    en: "TMF Level({level}) is invalid."
  },

  TMF_STUDY_MILESTONE_COUNTRY_MISSING: {
    code: "TMF_STUDY_MILESTONE_COUNTRY_MISSING",
    status: 400,
    ko: "Study Country가 입력되지 않았습니다.",
    en: "Study Country is missing."
  },
  TMF_STUDY_MILESTONE_SITE_MISSING: {
    code: "TMF_STUDY_MILESTONE_SITE_MISSING",
    status: 400,
    ko: "Study Site가 입력되지 않았습니다.",
    en: "Study Site is missing."
  },

  TMF_STUDY_MILESTONE_TIMELINE_BASELINE_START_DATE_INVALID: {
    code: "TMF_STUDY_MILESTONE_TIMELINE_BASELINE_START_DATE_INVALID",
    status: 400,
    ko: "Baseline Start Date가 유호하지 않습니다.",
    en: "Baseline Start Date is invalid."
  },
  TMF_STUDY_MILESTONE_TIMELINE_PLANNED_START_DATE_INVALID: {
    code: "TMF_STUDY_MILESTONE_TIMELINE_PLANNED_START_DATE_INVALID",
    status: 400,
    ko: "Planned Start Date가 유호하지 않습니다.",
    en: "Planned Start Date is invalid."
  },
  TMF_STUDY_MILESTONE_TIMELINE_ACTUAL_START_DATE_INVALID: {
    code: "TMF_STUDY_MILESTONE_TIMELINE_ACTUAL_START_DATE_INVALID",
    status: 400,
    ko: "Actual Start Date가 유호하지 않습니다.",
    en: "Actual Start Date is invalid."
  },
  TMF_STUDY_MILESTONE_TIMELINE_BASELINE_FINISH_DATE_INVALID: {
    code: "TMF_STUDY_MILESTONE_TIMELINE_BASELINE_FINISH_DATE_INVALID",
    status: 400,
    ko: "Baseline Finish Date가 유호하지 않습니다.",
    en: "Baseline Finish Date is invalid."
  },
  TMF_STUDY_MILESTONE_TIMELINE_PLANNED_FINISH_DATE_INVALID: {
    code: "TMF_STUDY_MILESTONE_TIMELINE_PLANNED_FINISH_DATE_INVALID",
    status: 400,
    ko: "Planned Finish Date가 유호하지 않습니다.",
    en: "Planned Finish Date is invalid."
  },
  TMF_STUDY_MILESTONE_TIMELINE_ACTUAL_FINISH_DATE_INVALID: {
    code: "TMF_STUDY_MILESTONE_TIMELINE_ACTUAL_FINISH_DATE_INVALID",
    status: 400,
    ko: "Actual Finish Date가 유호하지 않습니다.",
    en: "Actual Finish Date is invalid."
  },

  TMF_STUDY_MILESTONE_TIMELINE_ESSENTIAL_DOCUMENT_ID_INVALID: {
    code: "TMF_STUDY_MILESTONE_TIMELINE_ESSENTIAL_DOCUMENT_ID_INVALID",
    status: 400,
    ko: "Study Milestone Essential Document id가 유효하지 않습니다.",
    en: "Study Milestone Essential Document id is invalid.",
  },
  TMF_STUDY_MILESTONE_TIMELINE_ESSENTIAL_EXPECTED_INVALID: {
    code: "TMF_STUDY_MILESTONE_TIMELINE_ESSENTIAL_EXPECTED_INVALID",
    status: 400,
    ko: "Study Milestone Essential expected가 유효하지 않습니다.",
    en: "Study Milestone Essential expected is invalid.",
  },
};

MvnCodeInitiate(TMF_STUDY_MILESTONE_TIMELINE_CODE);
