import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { layoutSlice } from "./reducers/layout";
import { requestSlice } from "./reducers/requestResource";
import { localeSlice, sessionSlice } from "./reducers/session";

export const store = configureStore({
  reducer: {
    layout: layoutSlice.reducer,
    session: sessionSlice.reducer,
    locale: localeSlice.reducer,
    request: requestSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export * from "./reducers/index";
export * from "./selectors/index";
