import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_NOTIFICATION_MAIL_LANG: {
  AUTH_NOTIFICATION_MAIL_ORGANIZATION_INVITE_TITLE: MvnCodeFE;
  AUTH_NOTIFICATION_MAIL_ORGANIZATION_INVITE_CONTENT_001: MvnCodeFE;
  AUTH_NOTIFICATION_MAIL_ORGANIZATION_INVITE_CONTENT_002: MvnCodeFE;
  AUTH_NOTIFICATION_MAIL_ORGANIZATION_KICK_OUT_TITLE: MvnCodeFE;
  AUTH_NOTIFICATION_MAIL_ORGANIZATION_KICK_OUT_CONTENT_001: MvnCodeFE;
} = {
  AUTH_NOTIFICATION_MAIL_ORGANIZATION_INVITE_TITLE: {
    ko: "Maven Clinical Cloud에 당신을 초대합니다",
    en: "You’ve been invited to Maven Clinical Cloud",
  },
  AUTH_NOTIFICATION_MAIL_ORGANIZATION_INVITE_CONTENT_001: {
    ko: "{name} ({email})님의 초대가 도착했습니다.",
    en: "{name} ({email}) has invited you.",
  },
  AUTH_NOTIFICATION_MAIL_ORGANIZATION_INVITE_CONTENT_002: {
    ko: "Join 버튼을 클릭하여 새로운 Organization 에 참여할 수 있습니다.",
    en: "Please join the organization by clicking the Join button.",
  },
  AUTH_NOTIFICATION_MAIL_ORGANIZATION_KICK_OUT_TITLE: {
    ko: "Organization에서 제외되었습니다",
    en: "You have been eliminated from Organization",
  },
  AUTH_NOTIFICATION_MAIL_ORGANIZATION_KICK_OUT_CONTENT_001: {
    ko: "{organization}에서 제거되었습니다.",
    en: "You have been eliminated from {organization}.",
  },
};

MvnCodeInitiate(AUTH_NOTIFICATION_MAIL_LANG);
