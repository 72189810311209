import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CASE_COMMENT_LANG: {
  SAFETY_CASE_COMMENT_ADD_SUCC: MvnCodeFE;
  SAFETY_CASE_COMMENT_ADD_FAIL: MvnCodeFE;
  SAFETY_CASE_COMMENT_MODIFY_SUCC: MvnCodeFE;
  SAFETY_CASE_COMMENT_MODIFY_FAIL: MvnCodeFE;
  SAFETY_CASE_COMMENT_WRITE_LABEL: MvnCodeFE;
  SAFETY_CASE_COMMENT_WRITE_INPUT_PLACEHOLDER: MvnCodeFE;
  SAFETY_CASE_COMMENT_WRITE_INPUT_PLACEHOLDER_V2: MvnCodeFE;
  SAFETY_CASE_COMMENT_WRITE_EXCEEDED_INPUT_LENGTH_V2: MvnCodeFE;
} = {
  SAFETY_CASE_COMMENT_ADD_SUCC: {
    ko: "Comment가 성공적으로 추가되었습니다.",
    en: "Comment has been created successfully.",
  },
  SAFETY_CASE_COMMENT_ADD_FAIL: {
    ko: "Comment 추가에 실패했습니다. ({message})",
    en: "Failed to create a comment. ({message})",
  },
  SAFETY_CASE_COMMENT_MODIFY_SUCC: {
    ko: "Comment가 성공적으로 수정되었습니다.",
    en: "Comment has been modified successfully.",
  },
  SAFETY_CASE_COMMENT_MODIFY_FAIL: {
    ko: "Comment 수정에 실패했습니다. ({message})",
    en: "Failed to modify the comment. ({message})",
  },
  SAFETY_CASE_COMMENT_WRITE_LABEL: {
    ko: "Comment 작성",
    en: "Write Comment",
  },
  SAFETY_CASE_COMMENT_WRITE_INPUT_PLACEHOLDER: {
    ko: "Comment 내용을 입력해 주세요.",
    en: "Please enter comment.",
  },
  SAFETY_CASE_COMMENT_WRITE_INPUT_PLACEHOLDER_V2: {
    ko: "코멘트 입력",
    en: "Enter comment",
  },
  SAFETY_CASE_COMMENT_WRITE_EXCEEDED_INPUT_LENGTH_V2: {
    ko: "메세지의 길이는 최대 {length}자 입니다. ({count}/{length})",
    en: "Messages can be up to {length} characters long. ({count}/{length})",
  },
};

MvnCodeInitiate(SAFETY_CASE_COMMENT_LANG);
