/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_CODE: {
  TMF_STUDY_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_ID_MISSING: MvnCodeBE;
  TMF_STUDY_EXIST: MvnCodeBE;
  TMF_STUDY_NOT_EXIST: MvnCodeBE;
  TMF_STUDY_LOCKED: MvnCodeBE;
  TMF_STUDY_UNLOCKED: MvnCodeBE;

  TMF_STUDY_ORG_MISSING: MvnCodeBE;
  TMF_STUDY_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_PROTOCOL_NO_MISSING: MvnCodeBE;
  TMF_STUDY_PROTOCOL_NO_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_PROTOCOL_NO_TOO_LONG: MvnCodeBE;
} = {
  TMF_STUDY_NOT_FOUND: {
    code: "TMF_STUDY_NOT_FOUND",
    status: 404,
    ko: "요청하신 Study({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study({id}) could not be found.",
  },
  TMF_STUDY_ID_MISSING: {
    code: "TMF_STUDY_ID_MISSING",
    status: 400,
    ko: "studyId({id})가 입력되지 않았습니다.",
    en: "StudyId({id}) is missing.",
  },
  TMF_STUDY_EXIST: {
    code: "TMF_STUDY_EXIST",
    status: 409,
    ko: "Study({id})가 이미 존재합니다.",
    en: "Study({id}) already exists.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_NOT_EXIST: {
    code: "TMF_STUDY_NOT_EXIST",
    status: 409,
    ko: "Study({id})가 존재하지 않습니다.",
    en: "Study({id}) dose not exists.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_LOCKED: {
    code: "TMF_STUDY_LOCKED",
    status: 400,
    ko: "요청하신 Study는 Lock 되어있습니다.",
    en: "Requested study is locked.",
  },
  TMF_STUDY_UNLOCKED: {
    code: "TMF_STUDY_UNLOCKED",
    status: 400,
    ko: "요청하신 Study는 Unlock 되어있습니다.",
    en: "Requested study is unlocked.",
  },

  TMF_STUDY_ORG_MISSING: {
    code: "TMF_STUDY_ORG_MISSING",
    status: 400,
    ko: "Organization이 입력되지 않았습니다.",
    en: "Organization is missing."
  },
  TMF_STUDY_NAME_MISSING: {
    code: "TMF_STUDY_NAME_MISSING",
    status: 400,
    ko: "Study 이름이 입력되지 않았습니다.",
    en: "Study Name is missing."
  },
  TMF_STUDY_NAME_TOO_SHORT: {
    code: "TMF_STUDY_NAME_TOO_SHORT",
    status: 400,
    ko: "Study 이름의 길이는 최소 {length}자 이상입니다.",
    en: "Study name must be at least {length} characters long.",
  },
  TMF_STUDY_NAME_TOO_LONG: {
    code: "TMF_STUDY_NAME_TOO_LONG",
    status: 400,
    ko: "Study 이름의 길이는 최대 {length}자 입니다.",
    en: "Study name can be up to {length} characters long.",
  },
  TMF_STUDY_PROTOCOL_NO_MISSING: {
    code: "TMF_STUDY_PROTOCOL_NO_MISSING",
    status: 400,
    ko: "Study Protocol No가 입력되지 않았습니다.",
    en: "Study Protocol No is missing."
  },
  TMF_STUDY_PROTOCOL_NO_TOO_SHORT: {
    code: "TMF_STUDY_PROTOCOL_NO_TOO_SHORT",
    status: 400,
    ko: "Study Protocol No의 길이는 최소 {length}자 이상입니다.",
    en: "Study Protocol No must be at least {length} characters long.",
  },
  TMF_STUDY_PROTOCOL_NO_TOO_LONG: {
    code: "TMF_STUDY_PROTOCOL_NO_TOO_LONG",
    status: 400,
    ko: "Study Protocol No의 길이는 최대 {length}자 입니다.",
    en: "Study Protocol No can be up to {length} characters long.",
  },
};

MvnCodeInitiate(TMF_STUDY_CODE);
