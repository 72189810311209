/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const MAILING_LIST_CODE: { EMAIL_INVALID_ERROR: MvnCodeBE; EMAIL_DUPLICATED: MvnCodeBE } = {
  EMAIL_INVALID_ERROR: {
    status: 400,
    ko: "입력하신 이메일 주소({email})가 올바르지 않습니다.",
    en: "Email address({email}) is not valid.",
  },
  EMAIL_DUPLICATED: {
    status: 409,
    ko: "입력하신 이메일 주소({email})는 이미 등록되어 있습니다.",
    en: "The Email address({email}) is already registered.",
  },
};

//

MvnCodeInitiate(MAILING_LIST_CODE);
