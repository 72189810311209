import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_REPORT_CODE: {
  NOT_FOUND: MvnCodeBE;
  NOT_AVAILABLE: MvnCodeBE;
} = {
  NOT_FOUND: {
    status: 404,
    ko: "Study Report 정보가 존재하지 않습니다.",
    en: "Study report entity does not exist.",
  },
  NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study Report({id}) 정보가 올바르지 않습니다.",
    en: "Requested study report({id}) is not valid.",
  },
};

MvnCodeInitiate(STUDY_REPORT_CODE);
