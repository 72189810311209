import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_LAB_TEST_LANG: {
  BUILDER_STUDY_LAB_TEST_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
  BUILDER_STUDY_LAB_TEST_ITEM_ADD_SUCC: MvnCodeFE;
  BUILDER_STUDY_LAB_TEST_ITEM_ADD_FAIL: MvnCodeFE;
  BUILDER_STUDY_LAB_TEST_ITEM_EDIT_SUCC: MvnCodeFE;
  BUILDER_STUDY_LAB_TEST_ITEM_EDIT_FAIL: MvnCodeFE;
  BUILDER_STUDY_LAB_TEST_ITEM_DELETE_CONFIRM: MvnCodeFE;
  BUILDER_STUDY_LAB_TEST_ITEM_RESTORE_CONFIRM: MvnCodeFE;
} = {
  BUILDER_STUDY_LAB_TEST_PAGE_TITLE_DESCRIPTION: {
    ko: "실험실 항목을 관리할 수 있습니다.",
    en: "Manage your lab tests.",
  },
  BUILDER_STUDY_LAB_TEST_ITEM_ADD_SUCC: {
    ko: "실험실 항목을 생성했습니다.",
    en: "You have created a lab test item successfully.",
  },
  BUILDER_STUDY_LAB_TEST_ITEM_ADD_FAIL: {
    ko: "실험실 항목 생성에 실패했습니다. ({message})",
    en: "Failed to add lab test item. ({message})",
  },
  BUILDER_STUDY_LAB_TEST_ITEM_EDIT_SUCC: {
    ko: "실험실 항목을 수정했습니다.",
    en: "You have edited a lab test item successfully.",
  },
  BUILDER_STUDY_LAB_TEST_ITEM_EDIT_FAIL: {
    ko: "실험실 항목 수정에 실패했습니다. ({message})",
    en: "Failed to edit lab test item. ({message})",
  },
  BUILDER_STUDY_LAB_TEST_ITEM_DELETE_CONFIRM: {
    ko: "실험실 검사 항목을 삭제하시겠습니까?",
    en: "Are you sure you want to delete this lab test item?",
  },
  BUILDER_STUDY_LAB_TEST_ITEM_RESTORE_CONFIRM: {
    ko: "실험실 검사 항목을 복원하시겠습니까?",
    en: "Are you sure you want to restore this lab test item?",
  },
};

MvnCodeInitiate(BUILDER_STUDY_LAB_TEST_LANG);
