import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const VDR_ROOM_USER_LANG: {
  // BE Invitation Mail
  VDR_ROOM_USER_MAIL_INVITATION_TITLE: MvnCodeFE;
  VDR_ROOM_USER_MAIL_INVITATION_CONTENT_INVITED: MvnCodeFE;
  VDR_ROOM_USER_MAIL_INVITATION_CONTENT_JOIN: MvnCodeFE;

  // Description
  USER_GROUP_SELECT_DESCRIPTION: MvnCodeFE;
  DELETE_GROUP_DESCRIPTION: MvnCodeFE;
  DELETE_GROUP_USER_DESCRIPTION: MvnCodeFE;
  DELETE_USER_DESCRIPTION: MvnCodeFE;
  SEND_INVITATION_CHECKBOX_DESCRIPTION: MvnCodeFE;
  USER_EMAIL_VALIDATION_FAIL: MvnCodeFE;
  DELETE_ROLE_DESCRIPTION: MvnCodeFE;
  ROLE_WATERMARK_DESCRIPTION_1: MvnCodeFE;
  ROLE_WATERMARK_DESCRIPTION_2: MvnCodeFE;

  // Title
  USER_INVITE_TITLE: MvnCodeFE;
  USER_EMAIL_TITLE: MvnCodeFE;
  USER_INVITE_EMAIL_PLACEHOLDER: MvnCodeFE;
  GROUP_TITLE: MvnCodeFE;
  ROLE_TITLE: MvnCodeFE;
  ROLE_NAME_TITLE: MvnCodeFE;
  GROUP_SELECT_PLACEHOLDER: MvnCodeFE;
  GROUP_INPUT_PLACEHOLDER: MvnCodeFE;
  ROLE_SELECT_PLACEHOLDER: MvnCodeFE;
  ROLE_INPUT_PLACEHOLDER: MvnCodeFE;
  USER_SEND_INVITATION: MvnCodeFE;
  ADD_GROUP_TITLE: MvnCodeFE;
  ADD_GROUP_PLACEHOLDER: MvnCodeFE;
  DELETE_GROUP_TITLE: MvnCodeFE;
  DELETE_GROUP_USER_TITLE: MvnCodeFE;
  DELETE_USER_TITLE: MvnCodeFE;
  EDIT_GROUP_TITLE: MvnCodeFE;
  MOVE_GROUP_TITLE: MvnCodeFE;
  MOVE_TO_GROUP_TITLE: MvnCodeFE;
  ADD_ROLE_TITLE: MvnCodeFE;
  DOWNLOAD_USER_TITLE: MvnCodeFE;
  EDIT_ROLE_TITLE: MvnCodeFE;
  DELETE_ROLE_TITLE: MvnCodeFE;
  EMPTY_USER_TITLE: MvnCodeFE;

  // Toast
  USER_INVITE_SUCCESS: MvnCodeFE;
  USER_INVITE_FAIL: MvnCodeFE;
  ADD_GROUP_SUCCESS: MvnCodeFE;
  ADD_GROUP_FAIL: MvnCodeFE;
  USER_INVITATION_SEND_SUCCESS: MvnCodeFE;
  USER_INVITATION_SEND_FAIL: MvnCodeFE;
  DELETE_GROUP_SUCCESS: MvnCodeFE;
  DELETE_GROUP_FAIL: MvnCodeFE;
  DELETE_GROUP_USER_SUCCESS: MvnCodeFE;
  DELETE_GROUP_USER_FAIL: MvnCodeFE;
  DELETE_USER_SUCCESS: MvnCodeFE;
  DELETE_USER_FAIL: MvnCodeFE;
  EDIT_GROUP_SUCCESS: MvnCodeFE;
  EDIT_GROUP_FAIL: MvnCodeFE;
  MOVE_GROUP_SUCCESS: MvnCodeFE;
  MOVE_GROUP_FAIL: MvnCodeFE;
  DOWNLOAD_USER_START: MvnCodeFE;
  DOWNLOAD_USER_FAIL: MvnCodeFE;
  DELETE_ROLE_SUCCESS: MvnCodeFE;
  DELETE_ROLE_FAIL: MvnCodeFE;
  ROLE_SAVE_SUCCESS: MvnCodeFE;
  ROLE_SAVE_FAIL: MvnCodeFE;
} = {
  VDR_ROOM_USER_MAIL_INVITATION_TITLE: {
    ko: "[Maven VDR] 초대가 도착하였습니다.",
    en: "[Maven VDR] You have been invited.",
  },
  VDR_ROOM_USER_MAIL_INVITATION_CONTENT_INVITED: {
    ko: "{name} ({email}) 이 귀하를 {roomName} room에 초대하였습니다.",
    en: "{name} ({email}) has invited you to join the {roomName} room.",
  },
  VDR_ROOM_USER_MAIL_INVITATION_CONTENT_JOIN: {
    ko: "아래의 '로그인'을 클릭하여 초대를 수락하고 계정에 로그인하세요.",
    en: "Please click Sign In below to accept this invitation and log in to your account.",
  },

  // Description
  USER_GROUP_SELECT_DESCRIPTION: {
    ko: "{groups} 그룹, {users} 유저 선택됨",
    en: "{groups} Groups, {users} Users Selected",
  },
  DELETE_GROUP_DESCRIPTION: {
    ko: "선택한 그룹을 삭제하시겠습니까?",
    en: "Do you want to delete the selected group?",
  },
  DELETE_GROUP_USER_DESCRIPTION: {
    ko: "선택된 그룹 및 사용자를 삭제하시겠습니까?",
    en: "Do you want to delete the selected group and user?",
  },
  DELETE_USER_DESCRIPTION: {
    ko: "선택한 사용자를 삭제하시겠습니까?",
    en: "Do you want to delete the selected user?",
  },
  SEND_INVITATION_CHECKBOX_DESCRIPTION: {
    ko: "초대 메일을 발송합니다.",
    en: "Send an invitation email.",
  },
  USER_EMAIL_VALIDATION_FAIL: {
    ko: "유효한 이메일 주소를 입력해주세요.",
    en: "Please enter a valid email address.",
  },
  DELETE_ROLE_DESCRIPTION: {
    ko: "선택한 역할을 삭제하시겠습니까?",
    en: "Are you sure you want to delete this role?",
  },
  ROLE_WATERMARK_DESCRIPTION_1: {
    ko: "모든 PDF 문서에",
    en: "Include",
  },
  ROLE_WATERMARK_DESCRIPTION_2: {
    ko: "를 적용합니다.",
    en: "to all PDF documents.",
  },

  // Title
  USER_INVITE_TITLE: {
    ko: "사용자 초대",
    en: "Invite User",
  },
  USER_EMAIL_TITLE: {
    ko: "사용자 이메일",
    en: "User Email",
  },
  USER_INVITE_EMAIL_PLACEHOLDER: {
    ko: "초대 대상 이메일 입력",
    en: "Enter an invitee's email address",
  },
  GROUP_TITLE: {
    ko: "그룹",
    en: "Group",
  },
  ROLE_TITLE: {
    ko: "역할",
    en: "Role",
  },
  ROLE_NAME_TITLE: {
    ko: "역할 이름",
    en: "Role Name",
  },
  GROUP_SELECT_PLACEHOLDER: {
    ko: "그룹 선택 또는 입력",
    en: "Select or Enter Group",
  },
  GROUP_INPUT_PLACEHOLDER: {
    ko: "그룹 이름 입력",
    en: "Enter Group",
  },
  ROLE_SELECT_PLACEHOLDER: {
    ko: "역할 선택 또는 입력",
    en: "Select or Enter Role",
  },
  ROLE_INPUT_PLACEHOLDER: {
    ko: "역할 이름 입력",
    en: "Enter role name",
  },
  USER_SEND_INVITATION: {
    ko: "초대 이메일 발송",
    en: "Send an Invitation Email",
  },
  ADD_GROUP_TITLE: {
    ko: "그룹 추가",
    en: "Add Group",
  },
  ADD_GROUP_PLACEHOLDER: {
    ko: "그룹 이름 입력",
    en: "Enter Group Name",
  },
  DELETE_GROUP_TITLE: {
    ko: "그룹 삭제",
    en: "Delete Group",
  },
  DELETE_GROUP_USER_TITLE: {
    ko: "유저/그룹 삭제",
    en: "Delete User/Group",
  },
  DELETE_USER_TITLE: {
    ko: "사용자 삭제",
    en: "Delete User",
  },
  EDIT_GROUP_TITLE: {
    ko: "그룹 수정",
    en: "Edit Group",
  },
  MOVE_GROUP_TITLE: {
    ko: "그룹 이동",
    en: "Move Group",
  },
  MOVE_TO_GROUP_TITLE: {
    ko: "이동할 그룹",
    en: "Move to Group",
  },
  ADD_ROLE_TITLE: {
    ko: "역할 추가",
    en: "Add Role",
  },
  DOWNLOAD_USER_TITLE: {
    ko: "사용자 다운로드",
    en: "Download Users",
  },
  EDIT_ROLE_TITLE: {
    ko: "역할 수정",
    en: "Edit Role",
  },
  DELETE_ROLE_TITLE: {
    ko: "역할 삭제",
    en: "Delete Role",
  },
  EMPTY_USER_TITLE: {
    ko: "사용자 데이터가 존재하지 않습니다.",
    en: "No user data exists.",
  },

  // Toast
  USER_INVITE_SUCCESS: {
    ko: "사용자를 초대하였습니다.",
    en: "The user has been invited.",
  },
  USER_INVITE_FAIL: {
    ko: "사용자 초대에 실패하였습니다.",
    en: "Failed to invite user.",
  },
  ADD_GROUP_SUCCESS: {
    ko: "그룹이 추가 되었습니다.",
    en: "The group has been added.",
  },
  ADD_GROUP_FAIL: {
    ko: "그룹 추가에 실패하였습니다.",
    en: "Failed to add group.",
  },
  USER_INVITATION_SEND_SUCCESS: {
    ko: "초대 메일을 발송하였습니다.",
    en: "The invitation email has been sent.",
  },
  USER_INVITATION_SEND_FAIL: {
    ko: "초대 메일 발송에 실패하였습니다.",
    en: "Failed to send an invitation email.",
  },
  DELETE_GROUP_SUCCESS: {
    ko: "{count} 개의 그룹이 삭제되었습니다.",
    en: "{count} groups have been deleted.",
  },
  DELETE_GROUP_FAIL: {
    ko: "그룹 삭제에 실패하였습니다.",
    en: "Failed to delete group.",
  },
  DELETE_GROUP_USER_SUCCESS: {
    ko: "{groups} 개의 그룹, {users} 명의 유저가 삭제되었습니다.",
    en: "{groups} groups, {users} users have been deleted.",
  },
  DELETE_GROUP_USER_FAIL: {
    ko: "유저/그룹 삭제에 실패하였습니다.",
    en: "Failed to delete user/group.",
  },
  DELETE_USER_SUCCESS: {
    ko: "{count} 명의 사용자가 삭제되었습니다.",
    en: "{count} users have been deleted.",
  },
  DELETE_USER_FAIL: {
    ko: "사용자 삭제에 실패하였습니다.",
    en: "Failed to delete user.",
  },
  EDIT_GROUP_SUCCESS: {
    ko: "그룹이 수정되었습니다.",
    en: "The group has been edited.",
  },
  EDIT_GROUP_FAIL: {
    ko: "그룹 수정에 실패하였습니다.",
    en: "Failed to edit group.",
  },
  MOVE_GROUP_SUCCESS: {
    ko: "사용자 그룹 이동이 완료되었습니다.",
    en: "The group has been moved.",
  },
  MOVE_GROUP_FAIL: {
    ko: "사용자 그룹 이동에 실패하였습니다.",
    en: "Failed to move group.",
  },
  DOWNLOAD_USER_START: {
    ko: "다운로드가 시작되었습니다.",
    en: "Download has started.",
  },
  DOWNLOAD_USER_FAIL: {
    ko: "다운로드에 실패하였습니다.",
    en: "Failed to download.",
  },
  DELETE_ROLE_SUCCESS: {
    ko: "Role을 삭제하었습니다.",
    en: "The role has been deleted.",
  },
  DELETE_ROLE_FAIL: {
    ko: "Role 삭제에 실패하였습니다.",
    en: "Failed to delete role.",
  },
  ROLE_SAVE_SUCCESS: {
    ko: "Role이 저장되었습니다.",
    en: "The role has been saved.",
  },
  ROLE_SAVE_FAIL: {
    ko: "Role 저장에 실패하였습니다.",
    en: "Failed to save role.",
  },
};

MvnCodeInitiate(VDR_ROOM_USER_LANG);
