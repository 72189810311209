/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CODE: {
  STUDY_PROTOCOL_NOT_FOUND: MvnCodeBE;
  STUDY_NOT_FOUND: MvnCodeBE;
  STUDY_NOT_AVAILABLE: MvnCodeBE;
  STUDY_NOT_ACCESS_GRANTED: MvnCodeBE;
  STUDY_LOCKED: MvnCodeBE;
  STUDY_DISABLED: MvnCodeBE;
  STUDY_INVALID_BUILDER_STUDY_EDIT_DISABLED: MvnCodeBE;
} = {
  STUDY_PROTOCOL_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study protocol 정보를 찾을 수 없습니다.",
    en: "Requested study protocol could not be found.",
  },
  STUDY_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study({id}) could not be found.",
  },
  STUDY_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study({id}) 정보가 올바르지 않습니다.",
    en: "Requested study({id}) is not valid.",
  },
  STUDY_NOT_ACCESS_GRANTED: {
    status: 401,
    ko: "요청하신 Study({id}) 정보를 조회할 권한이 없습니다.",
    en: "No access granted to Requested study({id}).",
  },
  STUDY_DISABLED: {
    status: 400,
    ko: "요청하신 Study는 비활성화 되어있습니다.",
    en: "Requested study is disabled.",
  },
  STUDY_LOCKED: {
    status: 400,
    ko: "요청하신 Study는 Lock 되어있습니다.",
    en: "Requested study is locked.",
  },
  STUDY_INVALID_BUILDER_STUDY_EDIT_DISABLED: {
    status: 400,
    ko: "Builder에서 생성한 study는 CDMS에서 수정할 수 없습니다.",
    en: "Requested builder study cannot be modified on CDMS.",
  },
};

//

MvnCodeInitiate(STUDY_CODE);
