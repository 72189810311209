/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const DOCS_PROPOSAL_CODE: {
  DOCS_PROPOSAL_CONTENT_MODIFIED_INVALID: MvnCodeBE;
  DOCS_PROPOSAL_VERSION_DUPLICATED: MvnCodeBE;
  DOCS_PROPOSAL_VERSION_INVALID: MvnCodeBE;
  DOCS_PROPOSAL_VERSION_PRIOR: MvnCodeBE;
  DOCS_PROPOSAL_VERSION_IS_LEGACY: MvnCodeBE;
  DOCS_PROPOSAL_COMMENT_INVALID: MvnCodeBE;
  DOCS_PROPOSAL_COMMENT_ALREADY_DELETED: MvnCodeBE;
  DOCS_PROPOSAL_COMMENT_ALREADY_RESOLVED: MvnCodeBE;
} = {
  DOCS_PROPOSAL_CONTENT_MODIFIED_INVALID: {
    status: 400,
    ko: "변경 사항이 올바르지 않습니다.",
    en: "The modifications are not valid.",
  },
  DOCS_PROPOSAL_VERSION_DUPLICATED: {
    status: 409,
    ko: "동일한 버전이 이미 존재합니다.",
    en: "The specified version already exists.",
  },
  DOCS_PROPOSAL_VERSION_INVALID: {
    status: 400,
    ko: "버전이 올바르지 않습니다.",
    en: "The specified version is not valid.",
  },
  DOCS_PROPOSAL_VERSION_PRIOR: {
    status: 400,
    ko: "현재 버전보다 높은 버전을 입력해 주세요. (현재 버전: {version})",
    en: "Please enter a version higher than the current version. (Current Version: {version})",
  },
  DOCS_PROPOSAL_VERSION_IS_LEGACY: {
    status: 409,
    ko: "Proposal 수정은 최신 버전에서만 가능합니다.",
    en: "Proposal modification is allowed in the latest version only.",
  },
  DOCS_PROPOSAL_COMMENT_INVALID: {
    status: 409,
    ko: "댓글이 유효하지 않습니다.",
    en: "The specified comment is not valid.",
  },
  DOCS_PROPOSAL_COMMENT_ALREADY_DELETED: {
    status: 409,
    ko: "이미 삭제된 댓글입니다.",
    en: "This comment has been deleted.",
  },
  DOCS_PROPOSAL_COMMENT_ALREADY_RESOLVED: {
    status: 409,
    ko: "해결된 댓글입니다.",
    en: "This comment has been resolved.",
  },
};

MvnCodeInitiate(DOCS_PROPOSAL_CODE);
