import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_NOTICE_LANG: {
  ADD_LABEL_TITLE: MvnCodeFE;
  ADD_LABEL_CONTENT: MvnCodeFE;
  ADD_LABEL_ATTACHMENT: MvnCodeFE;
  ADD_STUDY_NOTICE_SUCC: MvnCodeFE;
  ADD_STUDY_NOTICE_FAIL: MvnCodeFE;
  LIST_BTN_ADD: MvnCodeFE;
  LIST_BTN_DELETE: MvnCodeFE;
  DELETE_STUDY_NOTICE_SUCC: MvnCodeFE;
  DELETE_STUDY_NOTICE_FAIL: MvnCodeFE;
  DELETE_DIALOG_HEADER: MvnCodeFE;
  DELETE_DIALOG_TITLE: MvnCodeFE;
  VIEW_LABEL_ATTACHMENT: MvnCodeFE;
  EDIT_LABEL_TITLE: MvnCodeFE;
  EDIT_LABEL_CONTENT: MvnCodeFE;
  EDIT_LABEL_ATTACHMENT: MvnCodeFE;
  EDIT_STUDY_NOTICE_SUCC: MvnCodeFE;
  EDIT_STUDY_NOTICE_FAIL: MvnCodeFE;
} = {
  ADD_LABEL_TITLE: {
    ko: "Title",
    en: "Title",
  },
  ADD_LABEL_CONTENT: {
    ko: "Content",
    en: "Content",
  },
  ADD_LABEL_ATTACHMENT: {
    ko: "Attachment",
    en: "Attachment",
  },
  ADD_STUDY_NOTICE_SUCC: {
    ko: "공지사항을 작성하였습니다.",
    en: "Notice has been wrote successfully.",
  },
  ADD_STUDY_NOTICE_FAIL: {
    ko: "공지사항 작성에 실패하였습니다. ({message})",
    en: "Failed to write notice. ({message})",
  },
  LIST_BTN_ADD: {
    ko: "Add Notice",
    en: "Add Notice",
  },
  LIST_BTN_DELETE: {
    ko: "Delete Notice",
    en: "Delete Notice",
  },
  DELETE_STUDY_NOTICE_SUCC: {
    ko: "공지사항을 삭제하였습니다.",
    en: "You have deleted notice successfully.",
  },
  DELETE_STUDY_NOTICE_FAIL: {
    ko: "공지사항 삭제에 실패하였습니다. ({message})",
    en: "Failed to delete notice. ({message})",
  },
  DELETE_DIALOG_HEADER: {
    ko: "Delete Notice",
    en: "Delete Notice",
  },
  DELETE_DIALOG_TITLE: {
    ko: "삭제하시겠습니까?",
    en: "Are you sure you want to delete?",
  },
  VIEW_LABEL_ATTACHMENT: {
    ko: "Attachment",
    en: "Attachment",
  },
  EDIT_LABEL_ATTACHMENT: {
    ko: "Attachment",
    en: "Attachment",
  },
  EDIT_LABEL_TITLE: {
    ko: "Title",
    en: "Title",
  },
  EDIT_LABEL_CONTENT: {
    ko: "Content",
    en: "Content",
  },
  EDIT_STUDY_NOTICE_SUCC: {
    ko: "공지사항을 수정하였습니다.",
    en: "Notice has been modified successfully.",
  },
  EDIT_STUDY_NOTICE_FAIL: {
    ko: "공지사항 수정에 실패하였습니다. ({message})",
    en: "Failed to modify notice. ({message})",
  },
};

MvnCodeInitiate(STUDY_NOTICE_LANG);
