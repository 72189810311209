import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_SITE_CRF_CODE: {
  STUDY_SITE_CRF_NOT_FOUND: MvnCodeBE;
  STUDY_SITE_CRF_NOT_AVAILABLE: MvnCodeBE;
} = {
  STUDY_SITE_CRF_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study Site CRF({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study site CRF({id}) could not be found.",
  },
  STUDY_SITE_CRF_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study Site CRF({id}) 정보가 올바르지 않습니다.",
    en: "Requested study site CRF({id}) is not valid.",
  },
};

MvnCodeInitiate(STUDY_SITE_CRF_CODE);
