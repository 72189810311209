import React from "react";
import { CrDialog } from "../../../../components/base/CrDialog";
import { nl2br } from "../../../../components/helper/nl2br";

export interface DuplicateLoginDialogMessageProps {
  dialogText: {
    default: string;
  };
}

interface DuplicateLoginDialogProps {
  messages: DuplicateLoginDialogMessageProps;
  visibility: boolean;
  confirmEventHandler: () => void;
  cancelEventHandler: () => void;
  header?: string;
}

export function DuplicateLoginDialog(props: DuplicateLoginDialogProps) {
  const { messages, visibility, confirmEventHandler, cancelEventHandler, header } = props;

  return (
    <CrDialog
      header={header}
      visibility={visibility}
      onConfirm={() => confirmEventHandler()}
      onCancel={() => cancelEventHandler()}
      confirm="Confirm"
      cancel="Cancel"
    >
      {nl2br(
        messages?.dialogText.default ||
          "해당 계정으로 이미 로그인 한 사용자가 있습니다. \n이전 로그인 사용자의 접속을 끊고, 계속 진행하시겠습니까?"
      )}
    </CrDialog>
  );
}
