import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_TEMPLATE_PAYLOAD_REQUEST_CODE: {
  BUILDER_STUDY_TEMPLATE_PAYLOAD_REQUEST_ALREADY_EXIST: MvnCodeBE;
  BUILDER_STUDY_TEMPLATE_PAYLOAD_REQUEST_INVALID_ISSUER: MvnCodeBE;
} = {
  BUILDER_STUDY_TEMPLATE_PAYLOAD_REQUEST_ALREADY_EXIST: {
    status: 409,
    ko: "Study Template 승인 요청이 이미 존재합니다. (id: {id})",
    en: "Study template approval request already exists. (id: {id})",
  },
  BUILDER_STUDY_TEMPLATE_PAYLOAD_REQUEST_INVALID_ISSUER: {
    status: 400,
    ko: "Study Template 승인 요청자가 아닙니다. (requestId: {id}, issuerId: {issuerId})",
    en: "The user who requests for approval may cancel the request. (requestId: {id}, issuerId: {issuerId})",
  },
};
MvnCodeInitiate(BUILDER_STUDY_TEMPLATE_PAYLOAD_REQUEST_CODE);
