import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_IP_CODE: {
  STUDY_IP_CODE_NOT_FOUND: MvnCodeBE;
  STUDY_IP_CODE_ALLOCABLE_NOT_FOUND: MvnCodeBE;
  STUDY_IP_CODE_NOT_AVAILABLE: MvnCodeBE;
  STUDY_IP_CODE_ALREADY_GENERATED: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_STUDY_DISABLED: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_STUDY_LOCKED: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_FILE_NOT_EXIST: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_CATEGORY_NOT_ENTERED: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_DRUG_NOT_ENTERED: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_ENV_MISSING: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_ENV_INVALID: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_ENV_ONLY_REAL: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_ENV_ONLY_TEST: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_CATEGORY_MISSING: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_CATEGORY_INVALID: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_DRUG_ID_MISSING: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_DRUG_ID_INVALID: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_NATION_CODE_MISSING: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_NATION_CODE_INVALID: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_IP_CODE_MISSING: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_IP_CODE_TOO_LONG: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_IP_CODE_DUP: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_LOT_NO_TOO_LONG: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_EXPIRE_DATE_INVALID: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_EXPIRE_SYS_DATE_INVALID: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_DESC_TOO_LONG: MvnCodeBE;
  STUDY_IP_CODE_UPLOAD_IP_CODE_ONLY_ADD_AFTER_ASSIGNED: MvnCodeBE;
  STUDY_IP_CODE_INITIALIZE_ALREADY_SUBJECT_ASSIGNED: MvnCodeBE;
  STUDY_IP_CODE_ALREADY_SUBJECT_ASSIGNED: MvnCodeBE;
  STUDY_IP_CODE_CHANGEABLE_ONLY_ERROR_IP_CODE: MvnCodeBE;
  STUDY_IP_CODE_RETURNABLE_ONLY_UNUSED_IP_CODE: MvnCodeBE;
  STUDY_IP_CODE_LOG_DISPENSE_RETURN_STATUS: MvnCodeBE;
  STUDY_IP_CODE_NOT_ALLOCABLE_TO_SUBJECT_VISIT: MvnCodeBE;
} = {
  STUDY_IP_CODE_NOT_FOUND: {
    status: 404,
    ko: "Study IP code 정보가 존재하지 않습니다.",
    en: "Study IP code entity does not exist.",
  },
  STUDY_IP_CODE_ALLOCABLE_NOT_FOUND: {
    status: 404,
    ko: "배정 가능한 IP를 찾을 수 없습니다.",
    en: "Available IP allocation not found.",
  },
  STUDY_IP_CODE_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study IP code 정보가 올바르지 않습니다.",
    en: "Requested study IP code is not valid.",
  },
  STUDY_IP_CODE_ALREADY_GENERATED: {
    status: 409,
    ko: "IP Code가 생성된 뒤에는 수정할 수 없습니다. IP Code 초기화 후에 시도해 주십시오.",
    en: "IP Category cannot be modified. Please initilize IP code and try again.",
  },
  STUDY_IP_CODE_UPLOAD_STUDY_DISABLED: {
    status: 400,
    ko: "Study 정보가 잘못되었습니다.",
    en: "Study information is not valid.",
  },
  STUDY_IP_CODE_UPLOAD_STUDY_LOCKED: {
    status: 400,
    ko: "Study가 Lock 상태입니다.",
    en: "Study is locked.",
  },
  STUDY_IP_CODE_UPLOAD_FILE_NOT_EXIST: {
    status: 400,
    ko: "File 정보가 존재하지 않습니다.",
    en: "File does not exist.",
  },
  STUDY_IP_CODE_UPLOAD_CATEGORY_NOT_ENTERED: {
    status: 400,
    ko: "IP Code 업로드를 위한 Category가 입력되지 않았습니다.",
    en: "No category is entered for IP code upload.",
  },
  STUDY_IP_CODE_UPLOAD_DRUG_NOT_ENTERED: {
    status: 400,
    ko: "IP Code 업로드를 위한 Drug가 입력되지 않았습니다.",
    en: "No drug is entered for IP code upload.",
  },
  STUDY_IP_CODE_UPLOAD_ENV_MISSING: {
    status: 400,
    ko: "Env가 누락되었습니다.",
    en: "Env is missing.",
  },
  STUDY_IP_CODE_UPLOAD_ENV_INVALID: {
    status: 400,
    ko: "Env가 잘못되었습니다.",
    en: "Env is invalid.",
  },
  STUDY_IP_CODE_UPLOAD_ENV_ONLY_REAL: {
    status: 400,
    ko: "리얼 사이트에서는 Real Code만 업로드할 수 있습니다.",
    en: "You can only upload real codes on the real site.",
  },
  STUDY_IP_CODE_UPLOAD_ENV_ONLY_TEST: {
    status: 400,
    ko: "베타 사이트에서는 Test Code만 업로드할 수 있습니다.",
    en: "You can only upload test codes on the beta site.",
  },
  STUDY_IP_CODE_UPLOAD_CATEGORY_MISSING: {
    status: 400,
    ko: "Category가 누락되었습니다.",
    en: "Category is missing.",
  },
  STUDY_IP_CODE_UPLOAD_CATEGORY_INVALID: {
    status: 400,
    ko: "Category가 잘못되었습니다.",
    en: "Category is invalid.",
  },
  STUDY_IP_CODE_UPLOAD_DRUG_ID_MISSING: {
    status: 400,
    ko: "Drug ID가 누락되었습니다.",
    en: "Drug ID is missing.",
  },
  STUDY_IP_CODE_UPLOAD_DRUG_ID_INVALID: {
    status: 400,
    ko: "Drug ID가 잘못되었습니다.",
    en: "Drug ID is invalid.",
  },
  STUDY_IP_CODE_UPLOAD_NATION_CODE_MISSING: {
    status: 400,
    ko: "Nation Code가 누락되었습니다.",
    en: "Nation Code is missing.",
  },
  STUDY_IP_CODE_UPLOAD_NATION_CODE_INVALID: {
    status: 400,
    ko: "Nation Code가 잘못되었습니다.",
    en: "Nation Code is invalid.",
  },
  STUDY_IP_CODE_UPLOAD_IP_CODE_MISSING: {
    status: 400,
    ko: "IP Code가 누락되었습니다.",
    en: "IP Code is missing.",
  },
  STUDY_IP_CODE_UPLOAD_IP_CODE_TOO_LONG: {
    status: 400,
    ko: "IP Code가 64자를 초과했습니다.",
    en: "IP Code has exceeded 64 characters in length.",
  },
  STUDY_IP_CODE_UPLOAD_IP_CODE_DUP: {
    status: 400,
    ko: "IP Code가 중복되었습니다.",
    en: "IP Code is duplicated.",
  },
  STUDY_IP_CODE_UPLOAD_LOT_NO_TOO_LONG: {
    status: 400,
    ko: "Lot no가 255자를 초과했습니다.",
    en: "Lot no has exceeded 255 characters in length.",
  },
  STUDY_IP_CODE_UPLOAD_EXPIRE_DATE_INVALID: {
    status: 400,
    ko: "Expire date가 잘못되었습니다.",
    en: "Expire date is invalid.",
  },
  STUDY_IP_CODE_UPLOAD_EXPIRE_SYS_DATE_INVALID: {
    status: 400,
    ko: "Expire date(system)가 잘못되었습니다.",
    en: "Expire date(system) is invalid.",
  },
  STUDY_IP_CODE_UPLOAD_DESC_TOO_LONG: {
    status: 400,
    ko: "Description이 255자를 초과했습니다.",
    en: "Description has exceeded 255 characters in length.",
  },
  STUDY_IP_CODE_UPLOAD_IP_CODE_ONLY_ADD_AFTER_ASSIGNED: {
    status: 400,
    ko: "IP Code 수정 불가 (IP Code 할당 시작 이후에는 추가만 가능)",
    en: "IP Code cannot be modified (You may only add after IP code assigned)",
  },
  STUDY_IP_CODE_INITIALIZE_ALREADY_SUBJECT_ASSIGNED: {
    status: 400,
    ko: "이미 대상자에 할당된 IP Code가 있어서 초기화 기능을 수행할 수 없습니다.",
    en: "IP Code cannot be initialized since IP Code is alreay assigned to subject.",
  },
  STUDY_IP_CODE_ALREADY_SUBJECT_ASSIGNED: {
    status: 400,
    ko: "이미 대상자에 할당된 IP Code 입니다.",
    en: "IP Code is alreay assigned to subject.",
  },
  STUDY_IP_CODE_CHANGEABLE_ONLY_ERROR_IP_CODE: {
    status: 400,
    ko: "파손/분실/미사용/취소/오불출/배정 후 분실/배정 후 파손 상태인 IP Code만 상태를 변경할 수 있습니다.",
    en: "Only IP Code that is damaged/lost/unused/cancelled/misdispensed/damaged after allocation/lost after allocation can change their status.",
  },
  STUDY_IP_CODE_RETURNABLE_ONLY_UNUSED_IP_CODE: {
    status: 400,
    ko: "가용재고/파손/분실/미사용 상태인 IP Code만 반납을 요청할 수 있습니다.",
    en: "Only IP Code that is available stock/damaged/loss/unused can be requested to be returned.",
  },
  STUDY_IP_CODE_LOG_DISPENSE_RETURN_STATUS: {
    status: 400,
    ko: "IP 불출/반납이 먼저 이루어져야 합니다.",
    en: "IP dispatch/return is mandatory first.",
  },
  STUDY_IP_CODE_NOT_ALLOCABLE_TO_SUBJECT_VISIT: {
    status: 400,
    ko: "해당 Subject/Visit에 배정할 수 없는 IP 입니다.",
    en: "This IP cannot be allocated to this Subject/Visit.",
  },
};

MvnCodeInitiate(STUDY_IP_CODE);
