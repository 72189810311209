import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CRF_CODE: {
  STUDY_CRF_NOT_FOUND: MvnCodeBE;
  STUDY_CRF_NOT_AVAILABLE: MvnCodeBE;
  STUDY_CRF_PAYLOAD_URL_INVALID: MvnCodeBE;
  STUDY_CRF_PAYLOAD_INVALID: MvnCodeBE;
  STUDY_CRF_ADD_MISSING_PAYLOAD: MvnCodeBE;
  STUDY_CRF_MODIFY_FAIL_NO_CHANGES: MvnCodeBE;
  STUDY_CRF_PAYLOAD_NOT_EXISTS: MvnCodeBE;
} = {
  STUDY_CRF_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study CRF({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study CRF({id}) could not be found.",
  },
  STUDY_CRF_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study CRF({id}) 정보가 올바르지 않습니다.",
    en: "Requested study CRF({id}) is not valid.",
  },
  STUDY_CRF_PAYLOAD_URL_INVALID: {
    status: 400,
    ko: "요청하신 Study CRF Payload URL 정보가 올바르지 않습니다.",
    en: "Requested study CRF payload URL is not valid.",
  },
  STUDY_CRF_PAYLOAD_INVALID: {
    status: 400,
    ko: "요청하신 Study CRF Payload 정보가 올바르지 않습니다.",
    en: "Requested study CRF payload is not valid.",
  },
  STUDY_CRF_ADD_MISSING_PAYLOAD: {
    status: 400,
    ko: "Study CRF Payload 정보가 누락되었습니다.",
    en: "Study CRF Payload information is missing.",
  },
  STUDY_CRF_MODIFY_FAIL_NO_CHANGES: {
    status: 400,
    ko: "Study CRF 변경 내용이 없습니다.",
    en: "No Study CRF changes",
  },
  STUDY_CRF_PAYLOAD_NOT_EXISTS: {
    status: 404,
    ko: "요청하신 Study CRF Payload가 없습니다.",
    en: "No Study CRF Payload.",
  },
};

MvnCodeInitiate(STUDY_CRF_CODE);
