/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_SITE_CODE: {
  STUDY_SITE_NOT_FOUND: MvnCodeBE;
  STUDY_SITE_NOT_AVAILABLE: MvnCodeBE;
  STUDY_SITE_NOT_SELECTION: MvnCodeBE;
  STUDY_SITE_UNAVAILABLE: MvnCodeBE;
  STUDY_SITE_CODE_DUPLICATED: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_INVALID: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_INVALID_EFFECTIVE_AT_DUPLICATED: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_INVALID_CRF_DUPLICATED: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_INVALID_VERSION: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_MISSING_EFFECTIVE_AT: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_MISSING_CRF_ID: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_INVALID_MODIFY_ONLY_ONE_CATEGORY: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_INVALID_ADD_ONLY_ONE: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_INVALID_MODIFY_ONLY_ONE: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_INVALID_DELETE_ONLY_ONE: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_INVALID_DELETE_SUBJECT_EXIST: MvnCodeBE;
  STUDY_SITE_CRFS_PARAM_INVALID_CRF_EDIT_DISABLE: MvnCodeBE;
  STUDY_SITE_MIGRATABLE_SUBJECT_EXIST: MvnCodeBE;
  STUDY_SITE_INVALID_REGISTERED_SUBJECT_EXIST: MvnCodeBE;
  STUDY_SITE_NOT_AUTHORIZED: MvnCodeBE;
} = {
  STUDY_SITE_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study Site({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study site({id}) could not be found.",
  },
  STUDY_SITE_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study Site({id}) 정보가 올바르지 않습니다.",
    en: "Requested study site({id}) is not valid.",
  },
  STUDY_SITE_NOT_SELECTION: {
    status: 400,
    ko: "실시기관을 선택해 주세요.",
    en: "Please select the site",
  },
  STUDY_SITE_UNAVAILABLE: {
    status: 500,
    ko: "요청하신 Study Site({id}) 정보가 올바르지 않습니다.",
    en: "Requested study site({id}) is not valid.",
  },
  STUDY_SITE_CODE_DUPLICATED: {
    status: 409,
    ko: "요청하신 Study Site Code({code})가 이미 존재합니다.",
    en: "Requested study site code({code}) is already exist.",
  },
  STUDY_SITE_CRFS_PARAM_INVALID: {
    status: 400,
    ko: "studySiteCrfs 데이터 형식이 올바르지 않습니다.",
    en: "The studySiteCrfs data type is not valid.",
  },
  STUDY_SITE_CRFS_PARAM_INVALID_EFFECTIVE_AT_DUPLICATED: {
    status: 400,
    ko: "CRF Effective Date가 중복됩니다.",
    en: "The specified CRF effective date is overlapped.",
  },
  STUDY_SITE_CRFS_PARAM_INVALID_CRF_DUPLICATED: {
    status: 400,
    ko: "Study CRF가 중복됩니다.",
    en: "Study CRFs are duplicated.",
  },
  STUDY_SITE_CRFS_PARAM_INVALID_VERSION: {
    status: 400,
    ko: "CRF 버전이 올바르지 않습니다. CRF 버전은 이전 날짜 정보의 버전보다 낮을 수 없습니다.",
    en: "The CRF version is invalid. The CRF version cannot be lower than the version of the previous date information.",
  },
  STUDY_SITE_CRFS_PARAM_MISSING_EFFECTIVE_AT: {
    status: 400,
    ko: "CRF Effective Date 값이 누락되었습니다.",
    en: "The CRF Effective Date is required.",
  },
  STUDY_SITE_CRFS_PARAM_MISSING_CRF_ID: {
    status: 400,
    ko: "CRF 값이 누락되었습니다.",
    en: "The CRF is required.",
  },
  STUDY_SITE_CRFS_PARAM_INVALID_MODIFY_ONLY_ONE_CATEGORY: {
    status: 400,
    ko: "Site CRF 추가/수정/삭제 중 하나의 작업만 수행 가능합니다.",
    en: "Only one operation of adding/modifying/deleting site CRF can be performed.",
  },
  STUDY_SITE_CRFS_PARAM_INVALID_ADD_ONLY_ONE: {
    status: 400,
    ko: "Site CRF 추가는 1건만 가능합니다.",
    en: "Only one site CRF can be added at a time.",
  },
  STUDY_SITE_CRFS_PARAM_INVALID_MODIFY_ONLY_ONE: {
    status: 400,
    ko: "Site CRF 수정은 1건만 가능합니다.",
    en: "Only one site CRF can be modified at a time.",
  },
  STUDY_SITE_CRFS_PARAM_INVALID_DELETE_ONLY_ONE: {
    status: 400,
    ko: "Site CRF 삭제는 1건만 가능합니다.",
    en: "Only one site CRF can be deleted at a time.",
  },
  STUDY_SITE_CRFS_PARAM_INVALID_DELETE_SUBJECT_EXIST: {
    status: 400,
    ko: "Site CRF 삭제는 해당 Site CRF로 생성된 Subject가 없을 때만 가능합니다.",
    en: "Site CRF deletion is possible only when there is no subject created with the site CRF.",
  },
  STUDY_SITE_MIGRATABLE_SUBJECT_EXIST: {
    status: 400,
    ko: "Migrate 할 수 있는 Subject가 {count}건 존재합니다.",
    en: "There are {count} subjects that can be migrated.",
  },
  STUDY_SITE_CRFS_PARAM_INVALID_CRF_EDIT_DISABLE: {
    status: 400,
    ko: "Subject CRF Migrate가 진행중입니다. Migrate가 완료된 후 Site CRF 수정이 가능합니다.",
    en: "Subject CRF Migrate is in progress. Site CRF can be modified after migration is completed.",
  },
  STUDY_SITE_INVALID_REGISTERED_SUBJECT_EXIST: {
    status: 400,
    ko: "대상자가 등록된 Site입니다.",
    en: "Subject is registered already on site.",
  },
  STUDY_SITE_NOT_AUTHORIZED: {
    status: 401,
    ko: "해당 실시기관에 대한 권한이 없습니다. ({site})",
    en: "User does not have privileges for this site. ({site})",
  },
};

MvnCodeInitiate(STUDY_SITE_CODE);
