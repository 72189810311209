import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_DASHBOARD_LANG: {
  SAFETY_DASHBARD_CASE_REPORT_SUBMIT_DUE_DATE_EXCEED: MvnCodeFE;
} = {
  SAFETY_DASHBARD_CASE_REPORT_SUBMIT_DUE_DATE_EXCEED: {
    ko: "제출 기간 경과",
    en: "Due Date Exceeded",
  },
};

MvnCodeInitiate(SAFETY_DASHBOARD_LANG);
