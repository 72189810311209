/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const ECOA_ACCESS_CODE_LANG: {
  ECOA_INPUT_RESET_TEXT: MvnCodeFE;
  ECOA_SMS_TITLE_TEMPLATE: MvnCodeFE;
  ECOA_SMS_CONTENT_TEMPLATE: MvnCodeFE;
  ECOA_VERIFY_INPUT_RESET_TEXT: MvnCodeFE;
  ECOA_VERIFY_TEXT_LABEL: MvnCodeFE;
  ECOA_VERIFY_PLACEHOLDER: MvnCodeFE;
  ECOA_VERIFY_FAIL: MvnCodeFE;
  ECOA_AUTH_SMS_ALREADY_RESEND_TEXT: MvnCodeFE;
  ECOA_AUTH_SMS_REQUEST_RESEND_TEXT: MvnCodeFE;
  ECOA_AUTH_SMS_SEND: MvnCodeFE;
  ECOA_AUTH_SMS_SEND_ALREADY: MvnCodeFE;
  ECOA_AUTH_SMS_SEND_FAIL: MvnCodeFE;
  ECOA_AUTH_SMS_SEND_FAIL_TEXT_LABEL: MvnCodeFE;
  ECOA_AUTH_SMS_SENDING: MvnCodeFE;
  ECOA_AUTH_SMS_TEXT_LABEL: MvnCodeFE;
  ECOA_AUTH_SMS_PLACEHOLDER: MvnCodeFE;
  ECOA_AUTH_SMS_FAIL: MvnCodeFE;
  ECOA_AUTH_BIRTH_TEXT_LABEL: MvnCodeFE;
  ECOA_AUTH_BIRTH_PLACEHOLDER: MvnCodeFE;
  ECOA_AUTH_BIRTH_FAIL: MvnCodeFE;
  ECOA_AUTH_PRESS_BUTTON_TEXT_LABEL: MvnCodeFE;
} = {
  ECOA_INPUT_RESET_TEXT: {
    ko: "입력값 초기화",
    en: "Initialize input",
    fr: "Réinitialiser la saisie",
    nl: "Invoer initialiseren"
  },
  ECOA_SMS_TITLE_TEMPLATE: {
    ko: "eCOA Code 및 URL 발송",
    en: "Send eCOA Code and URL",
  },
  ECOA_SMS_CONTENT_TEMPLATE: {
    ko: "eCOA 인증번호 [{ecoaAccessCode}]를 {mavenEcoaUrl} 에서 입력 후 본인인증 진행해주세요.",
    en: "Please enter the eCOA authentication number [{ecoaAccessCode}] in {mavenEcoaUrl} and proceed with the authentication.",
  },
  ECOA_VERIFY_INPUT_RESET_TEXT: {
    ko: "인증코드 입력 초기화",
    en: "Initialize the code input",
  },
  ECOA_VERIFY_TEXT_LABEL: {
    ko: "임상시험 실시기관으로 부터 부여받은 인증코드를 입력해 주세요.",
    en: "Please enter the certification code given by the clinical trial implementation agency.",
    fr: "Veuillez entrer le code de certification fourni par l'agence de mise en œuvre de l'essai clinique.",
    nl: "Voer de certificeringscode in die is verstrekt door het klinische proefuitvoeringsbureau."
  },
  ECOA_VERIFY_PLACEHOLDER: {
    ko: "인증코드를 입력해 주세요.",
    en: "Please enter the authentication code.",
    fr: "Veuillez entrer le code d'authentification.",
    nl: "Voer de authenticatiecode in."
  },
  ECOA_VERIFY_FAIL: {
    ko: "인증코드를 확인할 수 없습니다. ({message})",
    en: "Access code is not valid. ({message})",
    fr: "Le code d'authentification n'est pas valide. ({message})",
    nl: "De toegangscode is niet geldig. ({message})"
  },
  ECOA_AUTH_SMS_ALREADY_RESEND_TEXT: {
    ko: "이미 인증코드가 전송되었습니다.\n1분 후 다시 시도해주세요.",
    en: "Your code has already been sent.\nTry again in a minute.",
  },
  ECOA_AUTH_SMS_REQUEST_RESEND_TEXT: {
    ko: "인증코드를 받지 못하셨나요?",
    en: "Didn't get a authenticate code?",
    fr: "Vous n'avez pas reçu de code d'authentification ?",
    nl: "Geen authenticatiecode ontvangen?"
  },
  ECOA_AUTH_SMS_SEND: {
    ko: "인증번호가 발송되었습니다. (Request ID: {id})",
    en: "Authentication code has been sent. (Request ID: {id})",
    fr: "Le code d'authentification a été envoyé. (ID de demande : {id})",
    nl: "De authenticatiecode is verzonden. (Aanvraag-ID: {id})"
  },
  ECOA_AUTH_SMS_SEND_ALREADY: {
    ko: "이미 인증번호가 발송되었습니다. 받았던 인증번호를 입력해주세요.",
    en: "Authentication code has already been sent. Please enter the authentication code.",
    fr: "Le code d'authentification a déjà été envoyé. Veuillez entrer le code d'authentification.",
    nl: "De authenticatiecode is al verzonden. Voer de ontvangen authenticatiecode in."
  },
  ECOA_AUTH_SMS_SEND_FAIL: {
    ko: "인증번호 요청에 실패하였습니다. ({message})",
    en: "Authentication code request failed. ({message})",
    fr: "La demande de code d'authentification a échoué. ({message})",
    nl: "Het aanvragen van de authenticatiecode is mislukt. ({message})"
  },
  // TODO 재발송 기능 구현 때, 재발송 눌러달라는 멘트 추가 예정
  ECOA_AUTH_SMS_SEND_FAIL_TEXT_LABEL: {
    ko: "인증번호 요청에 실패하였습니다.",
    en: "Authentication code request failed.",
    fr: "La demande de code d'authentification a échoué.",
    nl: "Het aanvragen van de authenticatiecode is mislukt."
  },
  ECOA_AUTH_SMS_SENDING: {
    ko: "인증번호를 전송중입니다.",
    en: "Sending authentication code.",
    fr: "Envoi du code d'authentification.",
    nl: "Authenticatiecode verzenden."
  },
  ECOA_AUTH_SMS_TEXT_LABEL: {
    ko: "뒷자리 '{contact}' 전화번호로 인증코드가 발송되었습니다.",
    en: "Authentication code has been sent to the last phone number '{contact}'.",
    fr: "Le code d'authentification a été envoyé au numéro de téléphone se terminant par '{contact}'.",
    nl: "De authenticatiecode is verzonden naar het telefoonnummer dat eindigt op '{contact}'."
  },
  ECOA_AUTH_SMS_PLACEHOLDER: {
    ko: "SMS 인증코드를 입력해 주세요.",
    en: "Please enter the SMS authentication code.",
    fr: "Veuillez entrer le code d'authentification par SMS.",
    nl: "Voer de SMS-authenticatiecode in."
  },
  ECOA_AUTH_SMS_FAIL: {
    ko: "SMS 인증코드가 일치하지 않습니다.",
    en: "The SMS authentication code does not match.",
    fr: "Le code d'authentification par SMS ne correspond pas.",
    nl: "De SMS-authenticatiecode komt niet overeen."
  },
  ECOA_AUTH_BIRTH_TEXT_LABEL: {
    ko: "등록된 대상자의 출생연도를 확인해 주세요.",
    en: "Please check the birth of registered subject.",
    fr: "Veuillez vérifier l'année de naissance du sujet enregistré.",
    nl: "Controleer alstublieft het geboortejaar van de geregistreerde proefpersoon."

  },
  ECOA_AUTH_BIRTH_PLACEHOLDER: {
    ko: "출생연도를 입력해 주세요.",
    en: "Please enter your birth year.",
    fr: "Veuillez entrer votre année de naissance.",
    nl: "Voer uw geboortejaar in."
  },
  ECOA_AUTH_BIRTH_FAIL: {
    ko: "출생연도가 일치하지 않습니다.",
    en: "The year of birth does not match.",
  },
  ECOA_AUTH_PRESS_BUTTON_TEXT_LABEL: {
    ko: "하단 버튼을 눌러서 본인 인증을 진행해 주세요.",
    en: "Click the button below to verify ID.",
    fr: "Cliquez sur le bouton ci-dessous pour vérifier votre identité.",
    nl: "Klik op de knop hieronder om uw identiteit te verifiëren."

  },
};

MvnCodeInitiate(ECOA_ACCESS_CODE_LANG);
