import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_SAFETY_INTEGRATION_LANG: {
  CDMS_STUDY_SAFETY_INTG_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_REP_PV: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_MY_INVITATION_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_SEND_INVITATION_MAIL: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVE_STATUS: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVE_DATE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_LAST_MAIL_SENT: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REQUESTED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REJECTED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REQUEST_EXPIRED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_APPROVED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_SELECTED_ORGANIZATION: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_SELECTED_ORGANIZATION_TOOLTIP_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_PV_USER_LIST_SYSTEM_ACCESS_PRIV_TOOLTIP_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_PV_USER_LIST_REP_PV_TOOLTIP_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_SELECT_ORGANIZATION_DIALOG_HEADER: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_SELECT_SAFETY_ORGANIZATION: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_SELECT_SAFETY_ORGANIZATION_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVED_SUCC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVED_FAIL: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECT_CONFIRM_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECTED_SUCC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECTED_FAIL: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_DIALOG_EXIST_APPROVED_USER_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_DIALOG_EXIST_REQUESTED_USER_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_SUCC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_FAIL: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_VIEW_TITLE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_VIEW_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_ORGANIZATION_MODIFY_SUCC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_ORGANIZATION_MODIFY_FAIL: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_REQUEST_SUCC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_REQUEST_FAIL: MvnCodeFE;
} = {
  CDMS_STUDY_SAFETY_INTG_PAGE_TITLE: {
    ko: "Safety Integration",
    en: "Safety Integration",
  },
  CDMS_STUDY_SAFETY_INTG_PAGE_TITLE_DESCRIPTION: {
    ko: "Safety로 연동된 이상 반응 데이터 내역을 확인할 수 있습니다.",
    en: "Check the list of SAEs integrated into Safety.",
  },
  CDMS_STUDY_SAFETY_INTG_REP_PV: {
    ko: "대표 PV",
    en: "Rep PV",
  },
  CDMS_STUDY_SAFETY_INTG_MY_INVITATION_DESC: {
    ko: "해당 Study의 대표 PV로 승인 요청받았습니다. 연동 받을 Organization을 선택해야 승인 절차가 완료됩니다.",
    en: "You have been requested to be the rep PV of this study. Please select the Organization for integration to approve the request.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_SEND_INVITATION_MAIL: {
    ko: "메일 전송",
    en: "Send Mail",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVE_STATUS: {
    ko: "승인 여부",
    en: "Approval Status",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVE_DATE: {
    ko: "승인일",
    en: "Approved Date",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_LAST_MAIL_SENT: {
    ko: "최근 메일 전송일",
    en: "Last sent Date",
  },
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REQUESTED: {
    ko: "승인 대기",
    en: "Pending",
  },
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REJECTED: {
    ko: "승인 거절",
    en: "Rejected",
  },
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REQUEST_EXPIRED: {
    ko: "요청 만료",
    en: "Expired",
  },
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_APPROVED: {
    ko: "승인",
    en: "Approved",
  },
  CDMS_STUDY_SAFETY_INTG_USER_SELECTED_ORGANIZATION: {
    ko: "Selected Organization",
    en: "Selected Organization",
  },
  CDMS_STUDY_SAFETY_INTG_USER_SELECTED_ORGANIZATION_TOOLTIP_DESC: {
    ko: "대표 PV는 이상 반응 데이터를 연동할 Safety Organization을 선택합니다.\n선택된 Safety Organization으로 이상 반응 데이터가 연동됩니다.",
    en: "The rep PV selects the Safety Organization to integrate the SAE data.\nSAE data will be integrated into the selected Safety Organization.",
  },
  CDMS_STUDY_SAFETY_INTG_PV_USER_LIST_SYSTEM_ACCESS_PRIV_TOOLTIP_DESC: {
    ko: "System 접근 권한이 없는 계정은 Maven Clinical Cloud에 로그인 할 수 없습니다.",
    en: "Accounts without system access privilege cannot log in to Maven Clinical Cloud.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_PV_USER_LIST_REP_PV_TOOLTIP_DESC: {
    ko: "대표 PV는 PV User 상세페이지에서 지정할 수 있습니다.",
    en: "The rep PV can be designated in the PV User details page.",
  },
  CDMS_STUDY_SAFETY_INTG_SELECT_ORGANIZATION_DIALOG_HEADER: {
    ko: "Select Organization",
    en: "Select Organization",
  },
  CDMS_STUDY_SAFETY_INTG_SELECT_SAFETY_ORGANIZATION: {
    ko: "Safety Organization",
    en: "Safety Organization",
  },
  CDMS_STUDY_SAFETY_INTG_SELECT_SAFETY_ORGANIZATION_DESC: {
    ko: "CDMS 이상 반응 데이터를 연동 받을 Safety Organization을 선택해주세요.",
    en: "Please select a Safety Organization to integrate the CDMS SAE data.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVED_SUCC: {
    ko: "대표 PV 승인 및 Organization 선택이 완료되었습니다.",
    en: "Rep PV approval and organization selection has been completed.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVED_FAIL: {
    ko: "대표 PV 승인 및 Organization 선택에 실패하였습니다. ({message})",
    en: "Rep PV approval and organization selection has failed. ({message})",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECT_CONFIRM_DESC: {
    ko: "대표 PV 지정을 거절하시겠습니까?",
    en: "Reject the Rep PV designation?",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECTED_SUCC: {
    ko: "대표 PV 지정이 거절되었습니다.",
    en: "You have rejected the Rep PV designation.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECTED_FAIL: {
    ko: "대표 PV 지정 거절에 실패하였습니다. ({message})",
    en: "You have failed to reject the Rep PV designation. ({message})",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_DIALOG_EXIST_APPROVED_USER_DESC: {
    ko: "이미 대표 PV가 지정되어있습니다.\n메일을 전송하여 대표 PV를 변경하시겠습니까?",
    en: "A rep PV has already been designated.\nWould you like to send the email to change the rep PV?",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_DIALOG_EXIST_REQUESTED_USER_DESC: {
    ko: "이미 다른 계정에게 대표 PV 지정 요청 메일을 전송한 상태입니다.\n메일을 전송하시겠습니까? (이전에 보낸 메일은 만료됩니다.)",
    en: "You have sent a rep PV designation approval request to another account.\nWould you like to send the email? (The previously sent email will expire.)",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_SUCC: {
    ko: "메일 전송에 성공하였습니다.",
    en: "Successfully sent the email.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_FAIL: {
    ko: "메일 전송에 실패하였습니다. ({message})",
    en: "Failed to send the email. {{message}}",
  },
  CDMS_STUDY_SAFETY_INTG_USER_VIEW_TITLE: {
    ko: "대표 PV 지정",
    en: "Designate a Rep PV",
  },
  CDMS_STUDY_SAFETY_INTG_USER_VIEW_DESC: {
    ko: "해당 계정의 E-mail로 대표 PV 지정 승인 요청 메일을 보냅니다.",
    en: "Send a Rep PV designation approval mail to the email address.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_ORGANIZATION_MODIFY_SUCC: {
    ko: "Safety Organization이 변경되었습니다.",
    en: "The Safety Organization has been changed.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_ORGANIZATION_MODIFY_FAIL: {
    ko: "Safety Organization 변경에 실패하였습니다. ({message})",
    en: "Failed to change the Safety Organization.",
  },
  CDMS_STUDY_SAFETY_INTG_REQUEST_SUCC: {
    ko: "Safety Integration 요청에 성공하였습니다.",
    en: "Succeeded to Safety Integration request.",
  },
  CDMS_STUDY_SAFETY_INTG_REQUEST_FAIL: {
    ko: "Safety Integration 요청에 실패하였습니다. ({message})",
    en: "Failed to Safety Integration request. ({message})",
  },
};

MvnCodeInitiate(CDMS_STUDY_SAFETY_INTEGRATION_LANG);
