import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const ECOA_SUBJECT_ACCESS_CODE_LANG: {
  ECOA_SUBJECT_CONTACT_INVALID: MvnCodeFE;
} = {
  ECOA_SUBJECT_CONTACT_INVALID: {
    ko: "전화번호가 입력되지 않았습니다.",
    en: "Phone number has not been entered.",
  },
};

MvnCodeInitiate(ECOA_SUBJECT_ACCESS_CODE_LANG);
