import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SSO_USER_CODE: {
  SSO_USER_NOT_FOUND: MvnCodeBE;
  SSO_USER_DISABLED: MvnCodeBE;
  SSO_USER_DESTROYED: MvnCodeBE;
  SSO_USER_INIT_PASSWORD_INVALID_FORMAT: MvnCodeBE;
  SSO_USER_INIT_PASSWORD_INVALID_ALREADY_INITIALIZED: MvnCodeBE;
  SSO_USER_INIT_PASSWORD_iNVALID_RECENTLY_USED: MvnCodeBE;
} = {
  SSO_USER_NOT_FOUND: {
    status: 404,
    ko: "존재하지 않는 유저입니다.",
    en: "The requested user does not exist.",
  },
  SSO_USER_DISABLED: {
    status: 409,
    ko: "계정 정보가 유효하지 않습니다. 관리자에게 확인 바랍니다. (CODE: DI-01)",
    en: "Your account is not valid. Please contact system administrator. (CODE: DI-01)",
  },
  SSO_USER_DESTROYED: {
    status: 409,
    ko: "계정 정보가 유효하지 않습니다. 관리자에게 확인 바랍니다. (CODE: DE-02)",
    en: "Your account is not valid. Please contact system administrator. (CODE: DE-02)",
  },
  SSO_USER_INIT_PASSWORD_INVALID_FORMAT: {
    status: 400,
    ko: "비밀번호는 최소 8글자 이상, 1개 이상의 숫자, 영문, 특수문자를 포함해야 합니다.",
    en: "Password must contain at least 8 characters, 1 number, 1 English and 1 symbol.",
  },
  SSO_USER_INIT_PASSWORD_INVALID_ALREADY_INITIALIZED: {
    status: 409,
    ko: "사용자의 초기 비밀번호가 이미 설정되어 있습니다.",
    en: "The specified user's initial password already set.",
  },
  SSO_USER_INIT_PASSWORD_iNVALID_RECENTLY_USED: {
    status: 409,
    ko: "새로운 비밀번호가 최근에 사용되었습니다.",
    en: "The new password was used recently.",
  },
};

MvnCodeInitiate(SSO_USER_CODE);
