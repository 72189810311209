import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CRF_PAYLOAD_LANG: {
  STUDY_PAYLOAD_CREATE_DIALOG_HEADER: MvnCodeFE;
  STUDY_PAYLOAD_CREATE_DIALOG_TITLE: MvnCodeFE;
  STUDY_PAYLOAD_CREATE_NOTIFY_INVALID_PAYLOAD: MvnCodeFE;
  STUDY_PAYLOAD_CREATED_NOTIFY_SUCC: MvnCodeFE;
  STUDY_PAYLOAD_CREATED_NOTIFY_FAIL: MvnCodeFE;
} = {
  STUDY_PAYLOAD_CREATE_DIALOG_HEADER: {
    ko: "Add CRF Payload",
    en: "Add CRF Payload",
  },
  STUDY_PAYLOAD_CREATE_DIALOG_TITLE: {
    ko: "CRF Payload를 구성하는 필수요소(Domain, Page, Folder) 및 기본 설정(Property)을 입력해 주세요.\n입력된 내용을 바탕으로 CRF Entry Screen 및 Trigger를 구성할 수 있습니다.",
    en: "Please enter the essential elements (Domain, Page, Folder) and the default settings that make up the CRF  payload.\nCRF Entry Screen and Trigger can be configured based on the entered properties.",
  },
  STUDY_PAYLOAD_CREATE_NOTIFY_INVALID_PAYLOAD: {
    ko: "CRF Payload 속성이 올바르지 않습니다.",
    en: "The specified CRF payload property is not valid.",
  },
  STUDY_PAYLOAD_CREATED_NOTIFY_SUCC: {
    ko: "성공적으로 Study CRF Payload를 등록하였습니다.",
    en: "The study CRF payload has been created successfully.",
  },
  STUDY_PAYLOAD_CREATED_NOTIFY_FAIL: {
    ko: "Study CRF Payload 등록에 실패하였습니다. ({message})",
    en: "Failed to create a study CRF payload. ({message})",
  },
};

MvnCodeInitiate(STUDY_CRF_PAYLOAD_LANG);
