import React from "react";
import { CommandButton } from "@fluentui/react/lib/Button";
import { ContextualMenuItemType, DirectionalHint, IContextualMenuItem } from "@fluentui/react/lib/ContextualMenu";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { classNames } from "maven-lib/dist/utils/misc";

interface CrContextualMenuItemPlain {
  type: "normal";
  key?: string;
  text: string;
  icon?: string;
  disabled?: boolean;
  onClick: () => void;
}

interface CrContextualMenuItemDivider {
  type: "divider";
  key?: string;
}

interface CrContextualMenuItemSection {
  type: "section";
  key?: string;
  items: CrContextualMenuItemPlain[];
}

export type CrContextualMenuItem = CrContextualMenuItemSection | CrContextualMenuItemPlain | CrContextualMenuItemDivider;
export type CrContextualMenuAppearance = "PLAIN" | "BORDERED";
export type CrContextualMenuColor = "PLAIN" | "WARNING" | "DANGER" | "SUCCESS" | "PRIMARY";

export interface CrContextualMenuProps {
  className?: string;
  children?: React.ReactNode;
  text?: string;
  directionalHint: "bottomAutoEdge" | "bottomCenter";
  items: CrContextualMenuItem[];
  gapSpace?: number;
  doNotLayer?: boolean;
  disabled?: boolean;
  loading?: boolean;
  appearance: CrContextualMenuAppearance;
  color: CrContextualMenuColor;
}

const itemTransformer = (item: CrContextualMenuItem, idx = 0): IContextualMenuItem => {
  if (item.type === "divider") {
    return {
      itemType: ContextualMenuItemType.Divider,
      key: String(idx),
    };
  } else if (item.type === "section") {
    return {
      itemType: ContextualMenuItemType.Section,
      key: String(idx),
      items: itemsTransformer(item.items),
    };
  } else {
    return {
      itemType: ContextualMenuItemType.Normal,
      key: String(idx),
      text: item.text,
      onClick: item.onClick,
      iconProps: item.icon ? { iconName: item.icon } : undefined,
      onRenderIcon: () => <>{item.icon && <FontIcon className="cr-contextual-menu-item-icon" iconName={item.icon} />}</>,
      disabled: item.disabled,
    };
  }
};

const itemsTransformer = (items: CrContextualMenuItem[], idx = 0): IContextualMenuItem[] =>
  items.map((item, sIdx) => itemTransformer(item, idx * 100 + sIdx));

export function CrContextualMenu(props: CrContextualMenuProps) {
  return (
    <>
      <CommandButton
        className={classNames(
          "cr-contextual-menu",
          props.className,
          !!props.loading && "state--loading",
          `color--${String(props.color).toLowerCase()}`,
          `style--${String(props.appearance).toLowerCase()}`
        )}
        menuProps={{
          gapSpace: props.gapSpace,
          doNotLayer: props.doNotLayer,
          directionalHint: props.directionalHint === "bottomCenter" ? DirectionalHint.bottomCenter : DirectionalHint.bottomAutoEdge,
          items: itemsTransformer(props.items),
        }}
        disabled={props.disabled || props.loading}
      >
        {props.children}
      </CommandButton>
    </>
  );
}

CrContextualMenu.defaultProps = {
  directionalHint: "bottomAutoEdge",
  gapSpace: 2,
  doNotLayer: true,
  appearance: "PLAIN",
  color: "PLAIN",
};
