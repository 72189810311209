import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const RESOURCE_RESOURCE_CODE: {
  RESOURCE_RESOURCE_NOTFOUND: MvnCodeBE;
  RESOURCE_RESOURCE_FILTER_REQUIRED: MvnCodeBE;
  RESOURCE_RESOURCE_UPDATE_NOT_APPLICABLE: MvnCodeBE;
} = {
  RESOURCE_RESOURCE_NOTFOUND: {
    status: 404,
    ko: "요청하신 Resource ID({resourceId})를 찾을 수 없습니다.",
    en: "The requested Resource ID({resourceId}) could not be found.",
  },
  RESOURCE_RESOURCE_FILTER_REQUIRED: {
    status: 400,
    ko: "조회 필터값이 누락되었습니다.",
    en: "Filtering parameter is required.",
  },
  RESOURCE_RESOURCE_UPDATE_NOT_APPLICABLE: {
    status: 400,
    ko: "요청하신 Resource의 갱신이 이루어지지 않았습니다.",
    en: "The requested resource has not been updated.",
  },
};

MvnCodeInitiate(RESOURCE_RESOURCE_CODE);
