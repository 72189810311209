import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CAPTURE_LANG: {
  CAPTURE_SAVE_SUCC: MvnCodeFE;
  CAPTURE_SAVE_FAIL_BY_DATA: MvnCodeFE;
  CAPTURE_SAVE_FAIL_BY_ESIGN: MvnCodeFE;
  CAPTURE_BTN_SAVE: MvnCodeFE;
  CAPTURE_BTN_SAVE_AND_NEXT: MvnCodeFE;
  CAPTURE_BTN_REFRESH: MvnCodeFE;
  CAPTURE_BTN_REFRESH_MULTIPLE_CRFS: MvnCodeFE;
  CAPTURE_REFRESH_SUCC: MvnCodeFE;
  CAPTURE_REFRESH_FAIL: MvnCodeFE;
  CAPTURE_REFRESH_CONFIRM: MvnCodeFE;
  CAPTURE_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  CAPTURE_FILE_SIZE_EXCEEDED: MvnCodeFE;
} = {
  CAPTURE_SAVE_SUCC: {
    ko: "데이터를 저장하였습니다.",
    en: "Data has been saved successfully.",
  },
  CAPTURE_SAVE_FAIL_BY_DATA: {
    ko: "데이터 저장에 실패하였습니다. ({message})",
    en: "Failed to save data. ({message})",
  },
  CAPTURE_SAVE_FAIL_BY_ESIGN: {
    ko: "전자서명에 실패하였습니다. ({message})",
    en: "Failed to e-sign. ({message})",
  },
  CAPTURE_BTN_SAVE: {
    ko: "Save",
    en: "Save",
  },
  CAPTURE_BTN_SAVE_AND_NEXT: {
    ko: "Save and Next",
    en: "Save and Next",
  },
  CAPTURE_BTN_REFRESH: {
    ko: "Refresh",
    en: "Refresh",
  },
  CAPTURE_BTN_REFRESH_MULTIPLE_CRFS: {
    ko: "Refresh for Selected Subjects",
    en: "Refresh for Selected Subjects ",
  },
  CAPTURE_REFRESH_SUCC: {
    ko: "[{label}] 페이지 새로고침에 성공했습니다.",
    en: "[{domain}] page has been refreshed successfully.",
  },
  CAPTURE_REFRESH_FAIL: {
    ko: "[{label}] 페이지 새로고침에 실패하였습니다. ({message})",
    en: "[{domain}] page refresh has been failed. ({message})",
  },
  CAPTURE_REFRESH_CONFIRM: {
    ko: "[{label}] 페이지 새로고침을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter the password to refresh [{domain}] page",
  },
  CAPTURE_CONFIRM_DIALOG_TITLE: {
    ko: "데이터 저장을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to capture data.",
  },
  CAPTURE_FILE_SIZE_EXCEEDED: {
    ko: "최대 업로드 가능한 용량을 초과하였습니다.",
    en: "The file exceeds the maximum upload file size.",
  },
};

MvnCodeInitiate(STUDY_CAPTURE_LANG);
