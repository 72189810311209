import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_NOTICE_LANG: {
  NOTICE_PAGE_DESCRIPTION: MvnCodeFE;
  NOTICE_LIST_EMPTY_DESCRIPTION: MvnCodeFE;
} = {
  NOTICE_PAGE_DESCRIPTION: {
    ko: "시스템 점검 안내 공지를 확인할 수 있습니다.",
    en: "Check system maintenance notice.",
  },
  NOTICE_LIST_EMPTY_DESCRIPTION: {
    ko: "등록된 Notice가 없습니다.",
    en: "There is no notice registered.",
  },
};

MvnCodeInitiate(AUTH_NOTICE_LANG);
