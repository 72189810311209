import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CASE_REPORT_STATUS_LANG: {
  SAFETY_CASE_REPORT_STATUS_TABLE_TOOLTIP_LOCKED_BY_USER: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_LOCK_BUTTON_TOOLTIP_ITEM_MIXED: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_LOCK_BUTTON_TOOLTIP_LOCKED_BY_OTHER_USER: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_LOCK_BUTTON_TOOLTIP_LOCKED_BY_IMPORT: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_LOCK_DIALOG_HEADER: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_LOCK_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_UNLOCK_DIALOG_HEADER: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_UNLOCK_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_UNLOCK_INVALID_DIALOG_HEADER: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_UNLOCK_INVALID_DIALOG_DESCRIPTION_001: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_UNLOCK_INVALID_DIALOG_DESCRIPTION_002: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_FORCE_UNLOCK_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_LOCK_SUCC: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_LOCK_FAIL: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_UNLOCK_SUCC: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_UNLOCK_FAIL: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_LOCK_ALERT_MESSAGE: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_UNLOCK_ALERT_MESSAGE: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_LOCKED_CASE_BY_OTHER_USER_TOOLTIP_CONTENT: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_INVALID_STATUS_CASE_TOOLTIP_CONTENT: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_UNSIGNED_WORKFLOW_INCLUDED_TOOLTIP_CONTENT: MvnCodeFE;
  SAFETY_CASE_REPORT_STATUS_FOLDER_INCLUDED_TOOLTIP_CONTENT: MvnCodeFE;
} = {
  SAFETY_CASE_REPORT_STATUS_TABLE_TOOLTIP_LOCKED_BY_USER: {
    ko: "{name}이(가) Case를 잠갔습니다.",
    en: "{name} locked case.",
  },
  SAFETY_CASE_REPORT_STATUS_LOCK_BUTTON_TOOLTIP_ITEM_MIXED: {
    ko: "동일한 잠금 상태의 Case만 선택되어야 잠금 또는 잠금 해제가 가능합니다.",
    en: "Please select the same locked case, if you want to lock or unlock the case.",
  },
  SAFETY_CASE_REPORT_STATUS_LOCK_BUTTON_TOOLTIP_LOCKED_BY_OTHER_USER: {
    ko: "{name}이(가) 이 Case를 잠갔습니다.",
    en: "{name} locked this case.",
  },
  SAFETY_CASE_REPORT_STATUS_LOCK_BUTTON_TOOLTIP_LOCKED_BY_IMPORT: {
    ko: "이 Case는 현재 Import 중으로 일시적으로 잠겨 있습니다.",
    en: "This case is temporarily locked due to importing process.",
  },
  SAFETY_CASE_REPORT_STATUS_LOCK_DIALOG_HEADER: {
    ko: "선택한 Case를 잠그시겠습니까?",
    en: "Are you sure you want to lock selected case?",
  },
  SAFETY_CASE_REPORT_STATUS_LOCK_DIALOG_DESCRIPTION: {
    ko: "잠금 해제 전까지 다른 사용자가 Case를 편집할 수 없습니다.",
    en: "If case is locked, other user cannot edit the case before unlocked.",
  },
  SAFETY_CASE_REPORT_STATUS_UNLOCK_DIALOG_HEADER: {
    ko: "선택한 Case를 잠금 해제하시겠습니까?",
    en: "Are you sure you want to unlock selected case?",
  },
  SAFETY_CASE_REPORT_STATUS_UNLOCK_DIALOG_DESCRIPTION: {
    ko: "다른 사용자가 Case를 수정할 수 있게 됩니다.",
    en: "If case is unlocked, other user can edit the case.",
  },
  SAFETY_CASE_REPORT_STATUS_UNLOCK_INVALID_DIALOG_HEADER: {
    ko: "선택한 Case에 대한 잠금 해제 권한이 없습니다.",
    en: "You have no permission to unlock selected case.",
  },
  SAFETY_CASE_REPORT_STATUS_UNLOCK_INVALID_DIALOG_DESCRIPTION_001: {
    ko: "{name}이(가) 아래 Case를 잠갔습니다.",
    en: "{name} locked selected case.",
  },
  SAFETY_CASE_REPORT_STATUS_UNLOCK_INVALID_DIALOG_DESCRIPTION_002: {
    ko: "해당 사용자 또는 관리자에게 잠금 해제를 요청하시기 바랍니다.",
    en: "If you want to unlock selected cases, please contact above user or administrator.",
  },
  SAFETY_CASE_REPORT_STATUS_FORCE_UNLOCK_DIALOG_DESCRIPTION: {
    ko: "관리자 권한으로 선택한 Case를 잠금 해제하시겠습니까?",
    en: "Are you sure want to unlock selected case as administrator?",
  },
  SAFETY_CASE_REPORT_STATUS_LOCK_SUCC: {
    ko: "Case가 성공적으로 잠겼습니다.",
    en: "Case has been locked successfully.",
  },
  SAFETY_CASE_REPORT_STATUS_LOCK_FAIL: {
    ko: "Case 잠금에 실패했습니다. ({message})",
    en: "Failed to lock case. ({message})",
  },
  SAFETY_CASE_REPORT_STATUS_UNLOCK_SUCC: {
    ko: "Case 잠금이 성공적으로 해제되었습니다.",
    en: "Case has been unlocked successfully.",
  },
  SAFETY_CASE_REPORT_STATUS_UNLOCK_FAIL: {
    ko: "Case 잠금 해제에 실패했습니다. ({message})",
    en: "Failed to unlock case. ({message})",
  },
  SAFETY_CASE_REPORT_STATUS_LOCK_ALERT_MESSAGE: {
    ko: "저장되지 않은 자료가 있습니다. Case를 잠그시겠습니까?",
    en: "You have unsaved data. Do you want to lock the case?",
  },
  SAFETY_CASE_REPORT_STATUS_UNLOCK_ALERT_MESSAGE: {
    ko: "저장되지 않은 자료가 있습니다. Case를 잠금 해제하시겠습니까?",
    en: "You have unsaved data. Do you want to unlock the case?",
  },
  SAFETY_CASE_REPORT_STATUS_LOCKED_CASE_BY_OTHER_USER_TOOLTIP_CONTENT: {
    ko: "다른 사람이 잠근 Case가 포함되어 있습니다.",
    en: "Contains cases locked by others.",
  },
  SAFETY_CASE_REPORT_STATUS_INVALID_STATUS_CASE_TOOLTIP_CONTENT: {
    ko: "{status} 상태의 case가 포함되어 있습니다.",
    en: "Contains a case with a {status} status.",
  },
  SAFETY_CASE_REPORT_STATUS_UNSIGNED_WORKFLOW_INCLUDED_TOOLTIP_CONTENT: {
    ko: "나에게 할당되지 않은 Workflow가 포함되어 있습니다.",
    en: "Contains workflows that aren't assigned to you.",
  },
  SAFETY_CASE_REPORT_STATUS_FOLDER_INCLUDED_TOOLTIP_CONTENT: {
    ko: "Folder가 포함되어 있습니다.",
    en: "Folder is included.",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_STATUS_LANG);
