import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const TMF_STUDY_MILESTONE_TIMELINE_LANG: {
  NAME_NOUN: MvnCodeFE;
  STATUS_NOUN: MvnCodeFE;
  COMPLETE_RATE_NOUN: MvnCodeFE;
  BASELINE_FINISH_DATE_NOUN: MvnCodeFE;
  PLANNED_FINISH_DATE_NOUN: MvnCodeFE;
  ACTUAL_FINISH_DATE_NOUN: MvnCodeFE;

  IRB_SUBMISSION_TITLE: MvnCodeFE;
  IRB_SUBMISSION_INFORMATION_SUBTITLE: MvnCodeFE;
  IRB_SUBMISSION_MILESTONE_ID: MvnCodeFE;
  IRB_SUBMISSION_MILESTONE_NAME: MvnCodeFE;
  IRB_SUBMISSION_MILESTONE_GROUP: MvnCodeFE;
  IRB_SUBMISSION_TRIAL_DESCRIPTION: MvnCodeFE;
  IRB_SUBMISSION_COUNTRY_DESCRIPTION: MvnCodeFE;
  IRB_SUBMISSION_SITE_DESCRIPTION: MvnCodeFE;

  IRB_SUBMISSION_MILESTONE_DATE_SUBTITLE: MvnCodeFE;
  IRB_SUBMISSION_BASELINE_START_DATE: MvnCodeFE;
  IRB_SUBMISSION_PLANNED_START_DATE: MvnCodeFE;
  IRB_SUBMISSION_ACTUAL_START_DATE: MvnCodeFE;
  IRB_SUBMISSION_BASELINE_FINISH_DATE: MvnCodeFE;
  IRB_SUBMISSION_PLANNED_FINISH_DATE: MvnCodeFE;
  IRB_SUBMISSION_ACTUAL_FINISH_DATE: MvnCodeFE;

  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_SUBTITLE: MvnCodeFE;

  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_LIST_SUBTITLE: MvnCodeFE;
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_TYPE: MvnCodeFE;
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_ARTIFACT_NAME: MvnCodeFE;
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_COMPLETENESS: MvnCodeFE;
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_EXPECTED: MvnCodeFE;
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_FINALIZED: MvnCodeFE;
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_UPLOADED: MvnCodeFE;
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_MANDATORY: MvnCodeFE;

  MILESTONE_TIMELINE_LIST_LOAD_FAIL_TOAST: MvnCodeFE;
  MILESTONE_TIMELINE_LOAD_FAIL_TOAST: MvnCodeFE;

  MILESTONE_TIMELINE_UPDATE_SUCCESS_TOAST: MvnCodeFE;
  MILESTONE_TIMELINE_UPDATE_FAIL_TOAST: MvnCodeFE;

  ESSENTIAL_DOCUMENT_DIALOG_NUMBER_NOUN: MvnCodeFE;
  ESSENTIAL_DOCUMENT_DIALOG_DOCUMENT_DATE_NOUN: MvnCodeFE;
} = {
  NAME_NOUN: {
    ko: "Study Milestone",
    en: "Study Milestone",
  },
  STATUS_NOUN: {
    ko: "Status",
    en: "Status",
  },
  COMPLETE_RATE_NOUN: {
    ko: "% Complete",
    en: "% Complete",
  },
  BASELINE_FINISH_DATE_NOUN: {
    ko: "Baseline Finish Date",
    en: "Baseline Finish Date",
  },
  PLANNED_FINISH_DATE_NOUN: {
    ko: "Planned Finish Date",
    en: "Planned Finish Date",
  },
  ACTUAL_FINISH_DATE_NOUN: {
    ko: "Actual Finish Date",
    en: "Actual Finish Date",
  },

  IRB_SUBMISSION_TITLE: {
    ko: "Milestone - IRB Submission",
    en: "Milestone - IRB Submission",
  },
  IRB_SUBMISSION_INFORMATION_SUBTITLE: {
    ko: "Information",
    en: "Information",
  },
  IRB_SUBMISSION_MILESTONE_ID: {
    ko: "Milestone ID",
    en: "Milestone ID",
  },
  IRB_SUBMISSION_MILESTONE_NAME: {
    ko: "Milestone Name",
    en: "Milestone Name",
  },
  IRB_SUBMISSION_MILESTONE_GROUP: {
    ko: "Milestone Group",
    en: "Milestone Group",
  },
  IRB_SUBMISSION_TRIAL_DESCRIPTION: {
    ko: "Study Description",
    en: "Study Description",
  },
  IRB_SUBMISSION_COUNTRY_DESCRIPTION: {
    ko: "Country Description",
    en: "Country Description",
  },
  IRB_SUBMISSION_SITE_DESCRIPTION: {
    ko: "Site Description",
    en: "Site Description",
  },

  IRB_SUBMISSION_MILESTONE_DATE_SUBTITLE: {
    ko: "Milestone Date",
    en: "Milestone Date",
  },
  IRB_SUBMISSION_BASELINE_START_DATE: {
    ko: "Baseline Start Date",
    en: "Baseline Start Date",
  },
  IRB_SUBMISSION_PLANNED_START_DATE: {
    ko: "Planned Start Date",
    en: "Planned Start Date",
  },
  IRB_SUBMISSION_ACTUAL_START_DATE: {
    ko: "Actual Start Date",
    en: "Actual Start Date",
  },
  IRB_SUBMISSION_BASELINE_FINISH_DATE: {
    ko: "Baseline Finish Date",
    en: "Baseline Finish Date",
  },
  IRB_SUBMISSION_PLANNED_FINISH_DATE: {
    ko: "Planned Finish Date",
    en: "Planned Finish Date",
  },
  IRB_SUBMISSION_ACTUAL_FINISH_DATE: {
    ko: "Actual Finish Date",
    en: "Actual Finish Date",
  },

  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_SUBTITLE: {
    ko: "Essential Document",
    en: "Essential Document",
  },

  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_LIST_SUBTITLE: {
    ko: "Essential Document List",
    en: "Essential Document List",
  },
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_TYPE: {
    ko: "Document Type",
    en: "Document Type",
  },
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_ARTIFACT_NAME: {
    ko: "Artifact",
    en: "Artifact",
  },
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_COMPLETENESS: {
    ko: "Completeness",
    en: "Completeness",
  },
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_EXPECTED: {
    ko: "Expected",
    en: "Expected",
  },
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_FINALIZED: {
    ko: "Finished",
    en: "Finished",
  },
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_UPLOADED: {
    ko: "Uploaded",
    en: "Uploaded",
  },
  IRB_SUBMISSION_ESSENTIAL_DOCUMENT_MANDATORY: {
    ko: "Mandatory",
    en: "Mandatory",
  },

  MILESTONE_TIMELINE_LIST_LOAD_FAIL_TOAST: {
    ko: "Milestone List 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Milestone List. ({message})",
  },
  MILESTONE_TIMELINE_LOAD_FAIL_TOAST: {
    ko: "Milestone 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Milestone. ({message})",
  },
  MILESTONE_TIMELINE_UPDATE_SUCCESS_TOAST: {
    ko: "마일스톤 수정에 성공했습니다.",
    en: "The milestone updated successfully.",
  },
  MILESTONE_TIMELINE_UPDATE_FAIL_TOAST: {
    ko: "마일스톤 수정에 실패했습니다. ({message})",
    en: "Failed to update the milestone. ({message})",
  },

  ESSENTIAL_DOCUMENT_DIALOG_NUMBER_NOUN: {
    ko: "No",
    en: "No",
  },
  ESSENTIAL_DOCUMENT_DIALOG_DOCUMENT_DATE_NOUN: {
    ko: "Document Date",
    en: "Document Date",
  },
};

MvnCodeInitiate(TMF_STUDY_MILESTONE_TIMELINE_LANG);
