import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_REPORT_DATASET_LANG: {
  CDMS_STUDY_REPORT_DATASET_TYPE_LABEL: MvnCodeFE;
  CDMS_STUDY_REPORT_DATASET_TYPE_PLACEHOLDER: MvnCodeFE;
  CDMS_STUDY_REPORT_DATASET_INFORMATION_LABEL: MvnCodeFE;
  CDMS_STUDY_REPORT_DATASET_ITEMS_LABEL: MvnCodeFE;
  CDMS_STUDY_REPORT_DATASET_REQUEST_SUCC: MvnCodeFE;
  CDMS_STUDY_REPORT_DATASET_REQUEST_FAIL: MvnCodeFE;
} = {
  CDMS_STUDY_REPORT_DATASET_TYPE_LABEL: {
    ko: "Report Type",
    en: "Report Type",
  },
  CDMS_STUDY_REPORT_DATASET_TYPE_PLACEHOLDER: {
    ko: "Report Type을 선택해 주세요.",
    en: "Please select a report type.",
  },
  CDMS_STUDY_REPORT_DATASET_INFORMATION_LABEL: {
    ko: "Report Information",
    en: "Report Information",
  },
  CDMS_STUDY_REPORT_DATASET_ITEMS_LABEL: {
    ko: "Report Items",
    en: "Report Items",
  },
  CDMS_STUDY_REPORT_DATASET_REQUEST_SUCC: {
    ko: "Report Listing 생성이 요청되었습니다.",
    en: "Creation of Report Listing has been requested.",
  },
  CDMS_STUDY_REPORT_DATASET_REQUEST_FAIL: {
    ko: "Report Listing 생성 요청에 실패하였습니다. ({message})",
    en: "Failed to create Report Listing. ({message})",
  },
};

MvnCodeInitiate(CDMS_STUDY_REPORT_DATASET_LANG);
