import {
  CreateMyDeviceRequest,
  CreateMyDeviceResponse,
  DeleteMyDeviceRequest,
  NotifyMyUnauthorizedDeviceRequest,
  PaginateMyDevicesRequest,
  PaginateMyDevicesResponse,
} from "./UserDevice.dto";
import { UserDeviceUrl } from "./UserDevice.url";
import { restReq } from "../common/cyan.types";
import { FxApiRequestData, FxRequestData } from "../common/flax.types";
import { extractKey } from "../common/HttpRequest";

export class UserDeviceFlax {
  public static paginateMyDevices(data: FxRequestData<PaginateMyDevicesRequest>): FxApiRequestData<PaginateMyDevicesResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserDeviceUrl.paginateMyDevices, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static deleteMyDevice(data: FxRequestData<DeleteMyDeviceRequest>): FxApiRequestData<boolean> {
    const deviceId = extractKey(data, "deviceId");

    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserDeviceUrl.deleteMyDevice(deviceId), data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static notifyMyUnauthorizedDevice(data: FxRequestData<NotifyMyUnauthorizedDeviceRequest>): FxApiRequestData<boolean> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserDeviceUrl.notifyMyUnauthorizedDevice, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static createMyDevice(data: FxRequestData<CreateMyDeviceRequest>): FxApiRequestData<CreateMyDeviceResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserDeviceUrl.createMyDevice, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
