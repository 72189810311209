import React from "react";
import { ContextualMenuItemType, DefaultButton, IContextualMenuProps } from "@fluentui/react";
import { classNames, hasOwnProperty } from "maven-lib/dist/utils/misc";
import { IconName } from "../interface/Icon";

export type ButtonSize = "square" | "normal" | "wide";
export type CrButtonThickness = "slim" | "normal" | "thick";
export type CrButtonColor = "primary" | "bordered" | "inner-bordered" | "warning" | "danger" | "success";

interface CrButtonDefaultMenuItemProps {
  icon?: IconName;
  iconClassName?: string;
  key: string;
  text: string;
  secondaryText?: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

interface CrButtonDividerMenuItemProps {
  reserved: "DIVIDER";
}

export type CrButtonMenuItemProps = CrButtonDefaultMenuItemProps | CrButtonDividerMenuItemProps;

export interface CrButtonProps extends React.AllHTMLAttributes<HTMLButtonElement> {
  elementRef?: React.RefObject<HTMLElement>;
  icon?: IconName;
  href?: string;
  text?: string;
  disabled?: boolean;
  loading?: boolean;
  busy?: boolean;
  menuItems?: CrButtonMenuItemProps[];
  color?: CrButtonColor;
  thickness?: CrButtonThickness;
  buttonSize?: ButtonSize;
}

export const CrButtonMenuItemPropsTransformer = (
  menuItems?: CrButtonMenuItemProps[],
  props?: Partial<IContextualMenuProps>
): IContextualMenuProps | undefined => {
  return !menuItems
    ? undefined
    : {
        ...(props || {}),
        items: menuItems.map((e, i) => {
          if (hasOwnProperty(e, "reserved")) {
            return {
              key: `divider_${i}`,
              itemType: ContextualMenuItemType.Divider,
            };
          } else {
            return {
              ...e,
              iconProps: e.icon
                ? { iconName: e.icon, className: classNames("cr-contextual-menu-item-icon", e.iconClassName, `--icon-${e.icon}`) }
                : undefined,
            };
          }
        }),
      };
};

export function CrButton(props: CrButtonProps) {
  const { icon, text, disabled, menuItems, elementRef, className, loading, busy, buttonSize, thickness, ...extras } = props;

  return (
    <DefaultButton
      elementRef={elementRef}
      iconProps={icon ? { iconName: icon } : undefined}
      menuProps={CrButtonMenuItemPropsTransformer(menuItems)}
      className={classNames(
        "cr-button",
        props.color,
        icon && "--icon",
        icon && `--icon-${icon}`,
        (loading || busy) && "state--loading",
        `size--${String(buttonSize || "default").toLowerCase()}`,
        `thickness--${String(thickness || "normal").toLowerCase()}`,
        className
      )}
      text={text}
      disabled={loading || disabled || busy ? true : false}
      {...extras}
    />
  );
}
