import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_GATEWAY_AS2_ACCOUNT_CODE: {
  GATEWAY_AS2_ACCOUNT_REQUIRED_VALUE_MISSING: MvnCodeBE;
  GATEWAY_AS2_ACCOUNT_INVALID_IDENTIFIER_DUPLICATED: MvnCodeBE;
  GATEWAY_AS2_ACCOUNT_INVALID_HOST_CERTIFICATE_PRIVATE_KEY_REQUIRED: MvnCodeBE;
  GATEWAY_AS2_ACCOUNT_MODIFY_TYPE_INVALID_ACCOUNT_IN_USE: MvnCodeBE;
  GATEWAY_AS2_ACCOUNT_ACTIVATION_INVALID_ALREADY_ACTIVATED: MvnCodeBE;
  GATEWAY_AS2_ACCOUNT_ACTIVATION_INVALID_CERTIFICATE_DEACTIVATED: MvnCodeBE;
  GATEWAY_AS2_ACCOUNT_DEACTIVATION_INVALID_ALREADY_DEACTIVATED: MvnCodeBE;
  GATEWAY_AS2_ACCOUNT_DEACTIVATION_INVALID_ACCOUNT_IN_USE: MvnCodeBE;
} = {
  GATEWAY_AS2_ACCOUNT_REQUIRED_VALUE_MISSING: {
    status: 400,
    ko: "필수값이 누락되었습니다. (label: {label})",
    en: "Required Item value is missing. (label: {label})",
  },
  GATEWAY_AS2_ACCOUNT_INVALID_IDENTIFIER_DUPLICATED: {
    status: 409,
    ko: "이미 사용되고 있는 Account Identifier입니다. (identifier: {identifier})",
    en: "Account Identifer is duplicated. (identifier: {identifier})",
  },
  GATEWAY_AS2_ACCOUNT_INVALID_HOST_CERTIFICATE_PRIVATE_KEY_REQUIRED: {
    status: 409,
    ko: "Host Account에 등록할 수 없는 Certificate 입니다. Certificate의 privateKey가 필요합니다.",
    en: "This certificate cannot be used by host account. PrivateKey of certificate is required.",
  },
  GATEWAY_AS2_ACCOUNT_MODIFY_TYPE_INVALID_ACCOUNT_IN_USE: {
    status: 409,
    ko: "Connection에서 사용 중인 Account는 type을 수정할 수 없습니다.",
    en: "Type of account in use cannot be updated.",
  },
  GATEWAY_AS2_ACCOUNT_ACTIVATION_INVALID_ALREADY_ACTIVATED: {
    status: 409,
    ko: "이미 활성화된 Account입니다.",
    en: "Account has been activated already.",
  },
  GATEWAY_AS2_ACCOUNT_ACTIVATION_INVALID_CERTIFICATE_DEACTIVATED: {
    status: 409,
    ko: "Certificate가 비활성화 상태인 Account는 활성화 할 수 없습니다.",
    en: "Account using inactive certificate cannot be activated.",
  },
  GATEWAY_AS2_ACCOUNT_DEACTIVATION_INVALID_ALREADY_DEACTIVATED: {
    status: 409,
    ko: "이미 비활성화된 Account입니다.",
    en: "Account has been deactivated already.",
  },
  GATEWAY_AS2_ACCOUNT_DEACTIVATION_INVALID_ACCOUNT_IN_USE: {
    status: 409,
    ko: "활성화된 Connection에서 사용 중인 Account는 비활성화할 수 없습니다.",
    en: "Account being used by active Connection cannot be deactivated.",
  },
};

MvnCodeInitiate(SAFETY_GATEWAY_AS2_ACCOUNT_CODE);
