import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_GATEWAY_CERTIFICATE_CODE: {
  GATEWAY_CERTIFICATE_INVALID_IDENTIFIER_DUPLICATED: MvnCodeBE;
  GATEWAY_CERTIFICATE_MODIFY_INVALID_UNSET_PRIVATE_KEY_OF_HOST_ACCOUNT_CERTIFICATE: MvnCodeBE;
  GATEWAY_CERTIFICATE_ACTIVATION_INVALID_ALREADY_ACTIVATED: MvnCodeBE;
  GATEWAY_CERTIFICATE_DEACTIVATION_INVALID_ALREADY_DEACTIVATED: MvnCodeBE;
  GATEWAY_CERTIFICATE_DEACTIVATION_INVALID_CERTIFICATE_IN_USE: MvnCodeBE;
} = {
  GATEWAY_CERTIFICATE_INVALID_IDENTIFIER_DUPLICATED: {
    status: 409,
    ko: "이미 사용되고 있는 Certificate Identifier입니다. (identifier: {identifier}) ",
    en: "Certificate Identifer is duplicated. (identifier: {identifier})",
  },
  GATEWAY_CERTIFICATE_MODIFY_INVALID_UNSET_PRIVATE_KEY_OF_HOST_ACCOUNT_CERTIFICATE: {
    status: 409,
    ko: "Host Account에서 사용하고 있는 Certificate의 Private Key를 해제할 수 없습니다.",
    en: "Cannot unset Private Key of Certificate being used by Host Account.",
  },
  GATEWAY_CERTIFICATE_ACTIVATION_INVALID_ALREADY_ACTIVATED: {
    status: 409,
    ko: "이미 활성화된 Certificate입니다.",
    en: "Certificate has been activated already.",
  },
  GATEWAY_CERTIFICATE_DEACTIVATION_INVALID_ALREADY_DEACTIVATED: {
    status: 409,
    ko: "이미 비활성화된 Certificate입니다.",
    en: "Certificate has been deactivated already.",
  },
  GATEWAY_CERTIFICATE_DEACTIVATION_INVALID_CERTIFICATE_IN_USE: {
    status: 409,
    ko: "활성화된 Account에서 사용 중인 Certificate는 비활성화할 수 없습니다.",
    en: "Certificate being used by active Account cannot be deactivated.",
  },
};

MvnCodeInitiate(SAFETY_GATEWAY_CERTIFICATE_CODE);
