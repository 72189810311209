import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const PERSONAL_IDENTIFICATION_CODE: {
  PERSONAL_IDENTIFICATION_TRANS_ERROR: MvnCodeBE;
  PERSONAL_IDENTIFICATION_TRANS_ERROR_UNKNOWN: MvnCodeBE;
  PERSONAL_IDENTIFICATION_DECRYPTION_INVALID: MvnCodeBE;
  PERSONAL_IDENTIFICATION_DECRYPTION_FAILED: MvnCodeBE;
  PERSONAL_IDENTIFICATION_DECRYPTION_EXPIRED: MvnCodeBE;
} = {
  PERSONAL_IDENTIFICATION_TRANS_ERROR: {
    status: 500,
    ko: "시스템 오류가 발생했습니다. 잠시 후 다시 시도해 주세요. (Code: {code})",
    en: "An error has occurred. Please try again later. (Code: {code})",
  },
  PERSONAL_IDENTIFICATION_TRANS_ERROR_UNKNOWN: {
    status: 500,
    ko: "시스템 오류가 발생했습니다. 잠시 후 다시 시도해 주세요. (Code: {code})",
    en: "An error has occurred. Please try again later. (Code: {code})",
  },
  PERSONAL_IDENTIFICATION_DECRYPTION_INVALID: {
    status: 400,
    ko: "본인인증 정보가 올바르지 않습니다.",
    en: "The requested personal identification information is not valid.",
  },
  PERSONAL_IDENTIFICATION_DECRYPTION_FAILED: {
    status: 400,
    ko: "본인인증 정보 복호화에 실패하였습니다.",
    en: "Failed to decrypt a personal identification information.",
  },
  PERSONAL_IDENTIFICATION_DECRYPTION_EXPIRED: {
    status: 409,
    ko: "본인인증 정보가 만료되었습니다.",
    en: "The requested personal identification information has expired.",
  },
};

MvnCodeInitiate(PERSONAL_IDENTIFICATION_CODE);
