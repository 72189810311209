import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CASE_SYNC_LANG: {
  SAFETY_CASE_SYNC_IDENTIFIER_DIALOG_DESCRIPTION_001: MvnCodeFE;
  SAFETY_CASE_SYNC_IDENTIFIER_DIALOG_DESCRIPTION_002: MvnCodeFE;
  SAFETY_CASE_SYNC_IDENTIFIER_DIALOG_INPUT_LABEL: MvnCodeFE;
  SAFETY_CASE_SYNC_IDENTIFIER_DIALOG_INPUT_INVALID_DUPLICATED: MvnCodeFE;
  SAFETY_CASE_SYNC_MULTI_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_SYNC_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_SYNC_DIALOG_HEADER: MvnCodeFE;
  SAFETY_CASE_SYNC_DIALOG_CONFIRM_BUTTON: MvnCodeFE;
  SAFETY_CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_CONFIRM: MvnCodeFE;
  SAFETY_CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_WORKSPACE_DROPDOWN_LABEL: MvnCodeFE;
  SAFETY_CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_CRF_DROPDOWN_LABEL: MvnCodeFE;
} = {
  SAFETY_CASE_SYNC_IDENTIFIER_DIALOG_DESCRIPTION_001: {
    ko: "선택한 이상사례를 Safety Case로 입력하시겠습니까?",
    en: "Are you sure you want to import selected AEs?",
  },
  SAFETY_CASE_SYNC_IDENTIFIER_DIALOG_DESCRIPTION_002: {
    ko: "고유식별 보고자관리번호를 입력해주세요.",
    en: "Please enter a new unique case identification.",
  },
  SAFETY_CASE_SYNC_IDENTIFIER_DIALOG_INPUT_LABEL: {
    ko: "Unique Case Identification",
    en: "Unique Case Identification",
  },
  SAFETY_CASE_SYNC_IDENTIFIER_DIALOG_INPUT_INVALID_DUPLICATED: {
    ko: "이미 존재하는 고유식별 보고자관리번호입니다.",
    en: "This unique case identification is already used.",
  },
  SAFETY_CASE_SYNC_MULTI_DIALOG_DESCRIPTION: {
    ko: "선택한 이상사례들을 1개의 Safety Case으로 생성하시겠습니까?",
    en: "Are you sure you want to import selected AEs as single case?",
  },
  SAFETY_CASE_SYNC_PAGE_TITLE_DESCRIPTION: {
    ko: "CDMS에서 전송된 이상사례를 확인하고 관리할 수 있습니다.",
    en: "Check and manage adverse events sent from CDMS.",
  },
  SAFETY_CASE_SYNC_DIALOG_HEADER: {
    ko: "Synchronized Subject / AE List",
    en: "Synchronized Subject / AE List",
  },
  SAFETY_CASE_SYNC_DIALOG_CONFIRM_BUTTON: {
    ko: "Sync",
    en: "Sync",
  },
  SAFETY_CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_DESCRIPTION: {
    ko: "선택한 이상사례를 추가할 Workspace와 CRF Version을 선택해주세요.",
    en: "Please select workspace and CRF version to import a selected case.",
  },
  SAFETY_CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_CONFIRM: {
    ko: "Sync",
    en: "Sync",
  },
  SAFETY_CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_WORKSPACE_DROPDOWN_LABEL: {
    ko: "Workspace Name (Protocol No)",
    en: "Workspace Name (Protocol No)",
  },
  SAFETY_CASE_SYNC_SELECT_WORKSPACE_AND_CRF_DIALOG_CRF_DROPDOWN_LABEL: {
    ko: "CRF",
    en: "CRF",
  },
};

MvnCodeInitiate(SAFETY_CASE_SYNC_LANG);
