import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const ECOA_ACCESS_CODE_CODE: {
  ECOA_AUTH_SMS_ALREADY_RESEND: MvnCodeBE;
  ECOA_AUTH_TYPE_INVALID: MvnCodeBE;
  ECOA_ACCESS_CODE_INVALID: MvnCodeBE;
  ECOA_ACCESS_CODE_NOT_FOUND: MvnCodeBE;
  ECOA_ACCESS_CODE_RATE_LIMIT: MvnCodeBE;
  ECOA_SMS_CODE_INVALID: MvnCodeBE;
  ECOA_SMS_CODE_RATE_LIMIT: MvnCodeBE;
  ECOA_SMS_ID_INVALID: MvnCodeBE;
  ECOA_BIRTH_YEAR_INVALID: MvnCodeBE;
  ECOA_BIRTH_YEAR_RATE_LIMIT: MvnCodeBE;
} = {
  ECOA_AUTH_SMS_ALREADY_RESEND: {
    status: 400,
    ko: "이미 인증코드가 전송되었습니다.",
    en: "Authentication code has already been sent.",
  },
  ECOA_AUTH_TYPE_INVALID: {
    status: 400,
    ko: "인증유형이 유효하지 않습니다.",
    en: "Authentication type is invalid.",
  },
  ECOA_ACCESS_CODE_INVALID: {
    status: 400,
    ko: "인증코드가 유효하지 않습니다.",
    en: "Authentication code is invalid.",
  },
  ECOA_ACCESS_CODE_NOT_FOUND: {
    status: 404,
    ko: "인증코드를 확인할 수 없습니다.\n\n임상시험 실시기관으로부터 부여받은 인증코드를 입력해 주세요.",
    en: "Access code is not valid.\n\nPlease enter the access code given by the clinical trial site.",
  },
  ECOA_ACCESS_CODE_RATE_LIMIT: {
    status: 429,
    ko: "인증 최대 시도 횟수를 초과하였습니다. {min}분 후에 다시 시도해 주세요.",
    en: "You have exceeded the number of attempts. Please try again in {min} minute{minPlural}.",
  },
  ECOA_SMS_CODE_INVALID: {
    status: 400,
    ko: "인증코드가 올바르지 않습니다. 대상자 등록시 기재한 핸드폰번호로 전송된 인증코드를 입력해 주세요.",
    en: "The authentication code is not valid. Please enter the authentication code sent to the mobile phone number entered when registering the subject.",
  },
  ECOA_SMS_CODE_RATE_LIMIT: {
    status: 429,
    ko: "인증 최대 시도 횟수를 초과하였습니다. {min}분 후에 다시 시도해 주세요.",
    en: "You have exceeded the number of attempts. Please try again in {min} minute{minPlural}.",
  },
  ECOA_SMS_ID_INVALID: {
    status: 400,
    ko: "eCOA Authenticate SMS ID가 올바르지 않습니다.",
    en: "eCOA Authenticate SMS ID is not valid.",
  },
  ECOA_BIRTH_YEAR_INVALID: {
    status: 400,
    ko: "출생연도가 일치하지 않습니다.",
    en: "The year of birth does not match.",
  },
  ECOA_BIRTH_YEAR_RATE_LIMIT: {
    status: 429,
    ko: "인증 최대 시도 횟수를 초과하였습니다. {min}분 후에 다시 시도해 주세요.",
    en: "You have exceeded the number of attempts. Please try again in {min} minute{minPlural}.",
  },
};

MvnCodeInitiate(ECOA_ACCESS_CODE_CODE);
