import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CERTIFICATE_LANG: {
  SAFETY_CERTIFICATE_ADD_SUCC: MvnCodeFE;
  SAFETY_CERTIFICATE_ADD_FAIL: MvnCodeFE;
  SAFETY_CERTIFICATE_EDIT_SUCC: MvnCodeFE;
  SAFETY_CERTIFICATE_EDIT_FAIL: MvnCodeFE;
  SAFETY_CERTIFICATE_IDENTIFIER_IN_USE: MvnCodeFE;
  SAFETY_CERTIFICATE_ACTIVATE_SUCC: MvnCodeFE;
  SAFETY_CERTIFICATE_ACTIVATE_FAIL: MvnCodeFE;
  SAFETY_CERTIFICATE_DEACTIVATE_SUCC: MvnCodeFE;
  SAFETY_CERTIFICATE_DEACTIVATE_FAIL: MvnCodeFE;
  SAFETY_CERTIFICATE_ACTIVATE_DIALOG: MvnCodeFE;
  SAFETY_CERTIFICATE_DEACTIVATE_DIALOG_001: MvnCodeFE;
  SAFETY_CERTIFICATE_DEACTIVATE_DIALOG_002: MvnCodeFE;
  SAFETY_CERTIFICATE_DEACTIVATE_FAILED_DIALOG_001: MvnCodeFE;
  SAFETY_CERTIFICATE_DEACTIVATE_FAILED_DIALOG_002: MvnCodeFE;
} = {
  SAFETY_CERTIFICATE_ADD_SUCC: {
    ko: "Gateway Certificate가 성공적으로 추가되었습니다.",
    en: "Gateway Certificate has been created successfully.",
  },
  SAFETY_CERTIFICATE_ADD_FAIL: {
    ko: "Gateway Certificate 추가에 실패했습니다. ({message})",
    en: "Failed to create Gateway Certificate. ({message})",
  },
  SAFETY_CERTIFICATE_EDIT_SUCC: {
    ko: "Gateway Certificate가 성공적으로 수정되었습니다.",
    en: "Gateway Certificate has been modified successfully.",
  },
  SAFETY_CERTIFICATE_EDIT_FAIL: {
    ko: "Gateway Certificate 수정에 실패했습니다. ({message})",
    en: "Failed to modify Gateway Certificate. ({message})",
  },
  SAFETY_CERTIFICATE_ACTIVATE_SUCC: {
    ko: "Gateway Certificate가 활성화되었습니다.",
    en: "Gateway Certificate has been activated successfully.",
  },
  SAFETY_CERTIFICATE_ACTIVATE_FAIL: {
    ko: "Gateway Certificate 활성화에 실패했습니다. ({message})",
    en: "Failed to activate Gateway Certificate. ({message})",
  },
  SAFETY_CERTIFICATE_DEACTIVATE_SUCC: {
    ko: "Gateway Certificate가 비활성화되었습니다.",
    en: "Gateway Certificate has been deactivated successfully.",
  },
  SAFETY_CERTIFICATE_DEACTIVATE_FAIL: {
    ko: "Gateway Certificate 비활성화에 실패했습니다. ({message})",
    en: "Failed to deactivate Gateway Certificate. ({message})",
  },
  SAFETY_CERTIFICATE_IDENTIFIER_IN_USE: {
    ko: "이미 사용되고 있는 Certificate Identifier입니다.",
    en: "Identifier is already in use.",
  },
  SAFETY_CERTIFICATE_ACTIVATE_DIALOG: {
    ko: "Gateway Certificate를 활성화 하시겠습니까?",
    en: "Are you sure you want to activate Gateway Certificate?",
  },
  SAFETY_CERTIFICATE_DEACTIVATE_DIALOG_001: {
    ko: "Gateway Certificate를 비활성화 하시겠습니까?",
    en: "Are you sure you want to deactivate Gateway Certificate?",
  },
  SAFETY_CERTIFICATE_DEACTIVATE_DIALOG_002: {
    ko: "Gateway Certificate가 비활성화되면 Certificate 정보를 사용하고 있는 Gateway에 영향을 끼칠 수 있습니다.",
    en: "If Gateway Certificate is deactivated, this may affect the Gateway that is using the certificate information.",
  },
  SAFETY_CERTIFICATE_DEACTIVATE_FAILED_DIALOG_001: {
    ko: "비활성화할 수 없습니다.",
    en: "Deactivation is not available.",
  },
  SAFETY_CERTIFICATE_DEACTIVATE_FAILED_DIALOG_002: {
    ko: "Gateway Certificate가 다른 Gateway Account와 연결되어 있습니다. 연결 해제 후 비활성화할 수 있습니다.",
    en: "Gateway Certificate is linked with Gateway Account. Deactivation is available after disconnecting.",
  },
};

MvnCodeInitiate(SAFETY_CERTIFICATE_LANG);
