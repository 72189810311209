import qs, { ParsedQs } from "qs";
import { useHistory, useLocation } from "react-router-dom";

export interface Query {
  [key: string]: string | number | string[] | boolean;
}

export function useRoute() {
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search.slice(1));
  const state = (location.state || {}) as Query;

  const pushPath = (path: string, params?: Query) => {
    if (params) {
      Object.keys(params).forEach((k) => {
        query[k] = String(params[k]);
      });

      history.push(path + "?" + qs.stringify(query));
      return;
    }
    history.push(path);
  };

  const replacePath = (path: string) => {
    history.replace(path);
  };

  const pushBack = (path?: string) => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(path || "/");
    }
  };

  const pushQuery = (params: Query) => {
    Object.keys(params).forEach((k) => {
      query[k] = String(params[k]);
    });

    history.push(location.pathname + "?" + qs.stringify(query));
  };

  const removeQuery = (key: string) => {
    delete query[key];
    pushQuery({});
  };

  const replaceQuery = (params: Query, preserveExists?: boolean) => {
    if (preserveExists !== true) {
      Object.keys(query).forEach((k) => {
        delete query[k];
      });
    }

    Object.keys(params).forEach((k) => {
      query[k] = String(params[k]);
    });

    history.replace(location.pathname + "?" + qs.stringify(query));
  };

  const pushState = (params: Query, withHistoryPush = true) => {
    Object.keys(params).forEach((k) => {
      state[k] = String(params[k]);
    });

    withHistoryPush && history.push(location.pathname + "?" + qs.stringify(query), state);
  };

  const replaceState = (params: Query, preserveExists?: boolean) => {
    if (preserveExists !== true) {
      Object.keys(state).forEach((k) => {
        delete state[k];
      });
    }

    Object.keys(params).forEach((k) => {
      state[k] = String(params[k]);
    });

    history.replace(location.pathname + "?" + qs.stringify(query), state);
  };

  const removeState = (key: string) => {
    delete state[key];
    history.replace(location.pathname + "?" + qs.stringify(query), state);
  };

  return {
    location,
    history,
    pushBack,
    pushPath,
    pushQuery,
    removeQuery,
    replaceQuery,
    replacePath,
    query,
    pushState,
    replaceState,
    removeState,
    state,
  };
}

export function useStringParam(val: string | number | ParsedQs | string[] | ParsedQs[] | undefined, allowNull?: boolean): string {
  const history = useHistory();

  if (!val && allowNull) return null;

  try {
    const v = String(val);

    if (!v) throw new Error(":)");

    return v;
  } catch {
    history.replace(`/404?sv=${encodeURIComponent(String(val))}`);

    return null;
  }
}

export function useIntParam(val: string | number, allowNull?: boolean): number {
  const history = useHistory();

  if (!val && allowNull) return null;

  try {
    const v = parseInt(String(val), 10);

    if (isNaN(v)) throw new Error(":)");

    return v;
  } catch {
    history.replace(`/404?iv=${encodeURIComponent(String(val))}`);

    return 0;
  }
}

export function useBigIntParam(val: string | bigint): bigint {
  const history = useHistory();

  try {
    return BigInt(val);
  } catch {
    history.replace(`/404?bi=${encodeURIComponent(String(val))}`);

    return BigInt(0);
  }
}

export function useParamGuard<T = any>(val: T, allowNull?: boolean, identifier?: string): T {
  const history = useHistory();

  if (!val && allowNull) return null;

  try {
    if (!val) throw new Error(":)");

    return val;
  } catch {
    history.replace(`/404?pg=${identifier || ""}${!!identifier && ","}${encodeURIComponent(String(val))}`);

    return null;
  }
}
