import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CAPTURE_STATUS_CODE: {
  CAPTURE_STATUS_ENTITY_LOCKED: MvnCodeBE;
  CAPTURE_STATUS_ENTITY_FREEZED: MvnCodeBE;
  CAPTURE_STATUS_ENTITY_NOT_SAVED: MvnCodeBE;
  CAPTURE_STATUS_ENTITY_PATCH_NOT_ALLOWED: MvnCodeBE;
  CAPTURE_STATUS_ENTITY_BUSY_QUERY: MvnCodeBE;
  CAPTURE_STATUS_UPDATE_NOT_AFFECTED: MvnCodeBE;
  CAPTURE_STATUS_ENTITY_ESIGN_NOT_ALLOWED_BY_LOCKED: MvnCodeBE;
  CAPTURE_STATUS_ENTITY_ESIGN_NOT_ALLOWED_BY_NOT_SAVED: MvnCodeBE;
  CAPTURE_STATUS_REQUEST_INVALID_FORMAT_NOT_SUPPORT: MvnCodeBE;
  CAPTURE_STATUS_ENTITY_DISABLE_NOT_ALLOWED_TO_SAVED: MvnCodeBE;
  CAPTURE_STATUS_INSERT_FAIL_DUPLICATED_KEY: MvnCodeBE;
} = {
  CAPTURE_STATUS_ENTITY_LOCKED: {
    status: 409,
    ko: "CRF Page({pageInfo})가 Lock 되어있습니다.",
    en: "CRF Page({pageInfo}) is locked.",
  },
  CAPTURE_STATUS_ENTITY_FREEZED: {
    status: 409,
    ko: "CRF Page({pageInfo})가 Freeze 되어있습니다.",
    en: "CRF Page({pageInfo}) is frozen.",
  },
  CAPTURE_STATUS_ENTITY_NOT_SAVED: {
    status: 409,
    ko: "CRF Page({pageInfo})가 저장되지 않았습니다.",
    en: "CRF Page({pageInfo}) is not saved.",
  },
  CAPTURE_STATUS_ENTITY_PATCH_NOT_ALLOWED: {
    status: 409,
    ko: "요청을 수행하기 위해 전자서명이 필요합니다.",
    en: "E-signature credential is required.",
  },
  CAPTURE_STATUS_ENTITY_BUSY_QUERY: {
    status: 409,
    ko: "CRF Page({pageInfo})에 해결되지 않은 Query가 남아있습니다.",
    en: "A pending query exists in the CRF Page({pageInfo}).",
  },
  CAPTURE_STATUS_UPDATE_NOT_AFFECTED: {
    status: 409,
    ko: "변경된 항목이 없습니다.",
    en: "No changes.",
  },
  CAPTURE_STATUS_ENTITY_ESIGN_NOT_ALLOWED_BY_LOCKED: {
    status: 409,
    ko: "CRF Page({pageInfo})가 Lock 되어있습니다.",
    en: "CRF Page({pageInfo}) is locked.",
  },
  CAPTURE_STATUS_ENTITY_ESIGN_NOT_ALLOWED_BY_NOT_SAVED: {
    status: 409,
    ko: "CRF Page({pageInfo})가 저장되지 않았습니다.",
    en: "CRF Page({pageInfo}) is not saved.",
  },
  CAPTURE_STATUS_REQUEST_INVALID_FORMAT_NOT_SUPPORT: {
    status: 400,
    ko: "지원하지 않는 파일형식({format})입니다.",
    en: "The requested export format({format}) is not supported.",
  },
  CAPTURE_STATUS_ENTITY_DISABLE_NOT_ALLOWED_TO_SAVED: {
    status: 409,
    ko: "데이터 저장 이력이 있는 CRF는 비활성화할 수 없습니다.",
    en: "CRF that has been data captured cannot be disabled.",
  },
  CAPTURE_STATUS_INSERT_FAIL_DUPLICATED_KEY: {
    status: 409,
    ko: "({pageInfo})에 해당하는 CRF Page가 이미 존재합니다.",
    en: "검수 필요",
  },
};

MvnCodeInitiate(STUDY_CAPTURE_STATUS_CODE);
