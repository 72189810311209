import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_ROLE_CODE: {
  NOTFOUND: MvnCodeBE;
  UNAVAILABLE: MvnCodeBE;
  DUPLICATED_NAME: MvnCodeBE;
  COPY_PERMISSION_DENIED: MvnCodeBE;
  UPLOAD_NOT_EXISTS: MvnCodeBE;
  UPLOAD_FILE_NOT_EXISTS: MvnCodeBE;
  UPLOAD_DATA_MISSING_ROLE: MvnCodeBE;
  UPLOAD_DATA_DUPLICATED_NAME: MvnCodeBE;
  UPLOAD_DATA_INVALID_ROLE_INACTIVE: MvnCodeBE;
  UPLOAD_DATA_INVALID_PRIV_COUNT: MvnCodeBE;
  UPLOAD_DATA_INVALID_PRIV_ITEM: MvnCodeBE;
  UPLOAD_DATA_INVALID_PRIV_SYMBOL: MvnCodeBE;
} = {
  NOTFOUND: {
    status: 404,
    ko: "Study Role 정보가 존재하지 않습니다.",
    en: "Study role entity does not exist.",
  },
  UNAVAILABLE: {
    status: 400,
    ko: "요청하신 Study Role({id}) 정보가 올바르지 않습니다.",
    en: "Requested study role({id}) is not valid.",
  },
  DUPLICATED_NAME: {
    status: 409,
    ko: "해당 Study Role 이름({name})이 이미 존재합니다.",
    en: "Study role name({name}) is duplicated.",
  },
  COPY_PERMISSION_DENIED: {
    status: 401,
    ko: "원본 Study에 대하여 Study Role 접근 권한이 없습니다.",
    en: "Not authorized to access study role for the original study.",
  },
  UPLOAD_NOT_EXISTS: {
    status: 400,
    ko: "Study Role Upload 정보가 존재하지 않습니다.",
    en: "Study role upload entity does not exist.",
  },
  UPLOAD_FILE_NOT_EXISTS: {
    status: 400,
    ko: "Study Role Upload File이 존재하지 않습니다.",
    en: "Study role upload file dose not exist.",
  },
  UPLOAD_DATA_MISSING_ROLE: {
    status: 400,
    ko: "일부 Study Role이 누락되었습니다.",
    en: "Study roles are partially missing.",
  },
  UPLOAD_DATA_DUPLICATED_NAME: {
    status: 400,
    ko: "동일한 이름의 Study Role이 존재합니다.",
    en: "Study Role with the same name exists.",
  },
  UPLOAD_DATA_INVALID_ROLE_INACTIVE: {
    status: 400,
    ko: "비활성화 상태 Role이 포함되어 있습니다.",
    en: "Please check inactive roles.",
  },
  UPLOAD_DATA_INVALID_PRIV_COUNT: {
    status: 400,
    ko: "Study 권한 갯수가 올바르지 않습니다.",
    en: "Incorrect number of study privileges.",
  },
  UPLOAD_DATA_INVALID_PRIV_ITEM: {
    status: 400,
    ko: "Study 권한이 올바르지 않습니다.",
    en: "Incorrect study privilege.",
  },
  UPLOAD_DATA_INVALID_PRIV_SYMBOL: {
    status: 400,
    ko: "Study 권한 보유 표기가 올바르지 않습니다.",
    en: "Incorrect symbol of study privilege.",
  },
};

MvnCodeInitiate(STUDY_ROLE_CODE);
