/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_TEMPLATE_CODE: {
  TMF_STUDY_TEMPLATE_NOT_EXIST: MvnCodeBE;
  TMF_STUDY_TEMPLATE_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_TEMPLATE_NAME_DUPLICATED: MvnCodeBE;
  TMF_STUDY_TEMPLATE_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_TEMPLATE_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_TEMPLATE_CONTENT_KEY_MISSING: MvnCodeBE;
  TMF_STUDY_TEMPLATE_FILE_SIZE_LIMIT: MvnCodeBE;
  TMF_STUDY_TEMPLATE_FILE_TYPE_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_FILE_READ_FAILED: MvnCodeBE;
  TMF_STUDY_TEMPLATE_FILE_SHEET_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_FILE_CONTENT_READ_FAILED: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ALREADY_DESTROYED: MvnCodeBE;
  TMF_STUDY_TEMPLATE_MILESTONE_IDENTIFIER_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_MILESTONE_IDENTIFIER_DUPLICATED: MvnCodeBE;
  TMF_STUDY_TEMPLATE_MILESTONE_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_TEMPLATE_MILESTONE_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_TEMPLATE_MILESTONE_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_TEMPLATE_MILESTONE_NAME_DUPLICATED: MvnCodeBE;
  TMF_STUDY_TEMPLATE_MILESTONE_GROUP_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_MILESTONE_TRIAL_DESC_TOO_LONG: MvnCodeBE;
  TMF_STUDY_TEMPLATE_MILESTONE_COUNTRY_DESC_TOO_LONG: MvnCodeBE;
  TMF_STUDY_TEMPLATE_MILESTONE_SITE_DESC_TOO_LONG: MvnCodeBE;

  TMF_STUDY_TEMPLATE_ZONE_NO_FORMAT_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ZONE_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ZONE_NAME_INVALID_WITH_ZONE_NO: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ZONE_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ZONE_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ZONE_NAME_DUPLICATED: MvnCodeBE;

  TMF_STUDY_TEMPLATE_SECTION_NO_FORMAT_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SECTION_NO_ZONE_PART_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SECTION_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SECTION_NAME_INVALID_WITH_SECTION_NO: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SECTION_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SECTION_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SECTION_NAME_DUPLICATED: MvnCodeBE;

  TMF_STUDY_TEMPLATE_ARTIFACT_NO_FORMAT_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_NO_ZONE_PART_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_NO_SECTION_PART_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_NAME_INVALID_WITH_ARTIFACT_NO: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_NAME_DUPLICATED: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_DESC_TOO_LONG: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SUB_ARTIFACT_MISSING: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SUB_ARTIFACT_LIMIT: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SUB_ARTIFACT_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SUB_ARTIFACT_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SUB_ARTIFACT_NAME_DUPLICATED: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_MANDATORY_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_ATTR_VALUE_ICH_CODE_TOO_LONG: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_UNIQUE_ID_DUPLICATED: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_UNIQUE_ID_FORMAT_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_SPONSOR_DOCUMENT_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_INV_DOCUMENT_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_TRIAL_LEVEL_DOC_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_TRIAL_LEVEL_MILESTONE_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_COUNTRY_LEVEL_DOC_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_COUNTRY_LEVEL_MILESTONE_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SITE_LEVEL_DOC_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_SITE_LEVEL_MILESTONE_INVALID: MvnCodeBE;
  TMF_STUDY_TEMPLATE_ARTIFACT_DATING_CONVENTION_INVALID: MvnCodeBE;
} = {
  TMF_STUDY_TEMPLATE_NOT_EXIST: {
    code: "TMF_STUDY_TEMPLATE_NOT_EXIST",
    status: 404,
    ko: "Study Template({id})가 존재하지 않습니다.",
    en: "Study Template({id}) does not exists."
  },
  TMF_STUDY_TEMPLATE_INVALID: {
    code: "TMF_STUDY_TEMPLATE_INVALID",
    status: 400,
    ko: "Study Template({id})가 유효하지 않습니다.",
    en: "Study Template({id}) is invalid."
  },
  TMF_STUDY_TEMPLATE_NAME_MISSING: {
    code: "TMF_STUDY_TEMPLATE_NAME_MISSING",
    status: 400,
    ko: "TMF 템플릿의 이름이 입력되지 않았습니다.",
    en: "TMF Template Name is missing."
  },
  TMF_STUDY_TEMPLATE_NAME_DUPLICATED: {
    code: "TMF_STUDY_TEMPLATE_NAME_DUPLICATED",
    status: 409,
    ko: "TMF 템플릿의 이름이 중복되었습니다.",
    en: "TMF Template Name is duplicated."
  },
  TMF_STUDY_TEMPLATE_NAME_TOO_SHORT: {
    code: "TMF_STUDY_TEMPLATE_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF 템플릿 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Template name must be at least {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_NAME_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_NAME_TOO_LONG",
    status: 400,
    ko: "TMF 템플릿 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Template name can be up to {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_CONTENT_KEY_MISSING: {
    code: "TMF_STUDY_TEMPLATE_CONTENT_KEY_MISSING",
    status: 400,
    ko: "업로드 될 TMF 템플릿 파일이 첨부되지 않았습니다.",
    en: "The TMF template file to be uploaded is not attached.",
  },
  TMF_STUDY_TEMPLATE_FILE_SIZE_LIMIT: {
    code: "TMF_STUDY_TEMPLATE_FILE_SIZE_LIMIT",
    status: 400,
    ko: "업로드 할 수 있는 TMF 템플릿 파일은 최대 {mb}mb 입니다.",
    en: "The TMF template file that can be uploaded are up to {mb}mb.",
  },
  TMF_STUDY_TEMPLATE_FILE_TYPE_INVALID: {
    code: "TMF_STUDY_TEMPLATE_FILE_TYPE_INVALID",
    status: 400,
    ko: "{fileType} 확장자의 파일만 업로드할 수 있습니다.",
    en: "You can upload a file {fileType} only",
  },
  TMF_STUDY_TEMPLATE_FILE_READ_FAILED: {
    code: "TMF_STUDY_TEMPLATE_FILE_READ_FAILED",
    status: 400,
    ko: "업로드된 템플릿 파일을 읽는데 실패했습니다.",
    en: "Failed to read uploaded template file.",
  },
  TMF_STUDY_TEMPLATE_FILE_SHEET_INVALID: {
    code: "TMF_STUDY_TEMPLATE_FILE_SHEET_INVALID",
    status: 400,
    ko: "엑셀 파일 내 sheet 구성이 올바르지 않습니다.",
    en: "The sheets configuration in the Excel file is invalid.",
  },
  TMF_STUDY_TEMPLATE_FILE_CONTENT_READ_FAILED: {
    code: "TMF_STUDY_TEMPLATE_FILE_CONTENT_READ_FAILED",
    status: 400,
    ko: "엑셀 파일로부터 컨텐츠를 읽는데 실패했습니다.",
    en: "Failed to read content from excel file.",
  },
  TMF_STUDY_TEMPLATE_ALREADY_DESTROYED: {
    code: "TMF_STUDY_TEMPLATE_ALREADY_DESTROYED",
    status: 400,
    ko: "이미 삭제된 Study Template 입니다.",
    en: "The study template is already destroyed.",
  },

  TMF_STUDY_TEMPLATE_MILESTONE_IDENTIFIER_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_IDENTIFIER_INVALID",
    status: 400,
    ko: "TMF milestone id는 2글자로 이루어진 숫자만 입력할 수 있습니다.",
    en: "The TMF milestone id can only be entered as a two-character number."
  },
  TMF_STUDY_TEMPLATE_MILESTONE_IDENTIFIER_DUPLICATED: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_IDENTIFIER_DUPLICATED",
    status: 409,
    ko: "TMF milestone id가 중복되었습니다.",
    en: "TMF milestone id is duplicated.",
  },
  TMF_STUDY_TEMPLATE_MILESTONE_NAME_MISSING: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_NAME_MISSING",
    status: 400,
    ko: "TMF milestone 이름이 입력되지 않았습니다.",
    en: "TMF milestone name is missing."
  },
  TMF_STUDY_TEMPLATE_MILESTONE_NAME_TOO_SHORT: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF milestone 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF milestone name must be at least {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_MILESTONE_NAME_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_NAME_TOO_LONG",
    status: 400,
    ko: "TMF milestone 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF milestone name can be up to {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_MILESTONE_NAME_DUPLICATED: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_NAME_DUPLICATED",
    status: 409,
    ko: "TMF milestone 이름이 중복되었습니다.",
    en: "TMF milestone name is duplicated.",
  },
  TMF_STUDY_TEMPLATE_ZONE_NAME_INVALID_WITH_ZONE_NO: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ZONE_NAME_INVALID_WITH_ZONE_NO",
    status: 400,
    ko: "동일한 Zone No에는 Zone 이름이 모두 동일해야합니다.",
    en: "The same zone no must have the same zone name."
  },

  TMF_STUDY_TEMPLATE_MILESTONE_GROUP_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_GROUP_INVALID",
    status: 400,
    ko: "TMF milestone group이 유효하지 않습니다.",
    en: "The TMF milestone group is invalid."
  },
  TMF_STUDY_TEMPLATE_MILESTONE_TRIAL_DESC_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_TRIAL_DESC_TOO_LONG",
    status: 400,
    ko: "TMF milestone 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF milestone name can be up to {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_MILESTONE_COUNTRY_DESC_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_COUNTRY_DESC_TOO_LONG",
    status: 400,
    ko: "TMF milestone 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF milestone name can be up to {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_MILESTONE_SITE_DESC_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SITE_DESC_TOO_LONG",
    status: 400,
    ko: "TMF milestone 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF milestone name can be up to {length} characters long.",
  },

  TMF_STUDY_TEMPLATE_ZONE_NO_FORMAT_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ZONE_NO_FORMAT_INVALID",
    status: 400,
    ko: "TMF Zone의 No Format이 유효하지 않습니다.",
    en: "The format of TMF Zone's No is invalid."
  },
  TMF_STUDY_TEMPLATE_ZONE_NAME_MISSING: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ZONE_NAME_MISSING",
    status: 400,
    ko: "TMF Zone 이름이 입력되지 않았습니다.",
    en: "TMF Zone Name is missing."
  },
  TMF_STUDY_TEMPLATE_SECTION_NAME_INVALID_WITH_SECTION_NO: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SECTION_NAME_INVALID_WITH_SECTION_NO",
    status: 400,
    ko: "동일한 Section No에는 Section 이름이 모두 동일해야합니다.",
    en: "The same section no must have the same section name."
  },
  TMF_STUDY_TEMPLATE_ZONE_NAME_TOO_SHORT: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ZONE_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Zone 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Zone name must be at least {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_ZONE_NAME_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ZONE_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Zone 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Zone name can be up to {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_ZONE_NAME_DUPLICATED: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ZONE_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Zone 이름이 중복되었습니다.",
    en: "TMF Zone name is duplicated.",
  },

  TMF_STUDY_TEMPLATE_SECTION_NO_FORMAT_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SECTION_NO_FORMAT_INVALID",
    status: 400,
    ko: "TMF Section의 No Format이 유효하지 않습니다.",
    en: "The format of TMF Section's No is invalid."
  },
  TMF_STUDY_TEMPLATE_SECTION_NO_ZONE_PART_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SECTION_NO_ZONE_PART_INVALID",
    status: 400,
    ko: "TMF Section No의 Zone 영역이 상이합니다.",
    en: "The Zone part of TMF Section's No is different."
  },
  TMF_STUDY_TEMPLATE_SECTION_NAME_MISSING: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SECTION_NAME_MISSING",
    status: 400,
    ko: "TMF Section 이름이 입력되지 않았습니다.",
    en: "TMF Section Name is missing."
  },

  TMF_STUDY_TEMPLATE_SECTION_NAME_TOO_SHORT: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SECTION_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Section 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Section name must be at least {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_SECTION_NAME_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SECTION_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Section 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Section name can be up to {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_SECTION_NAME_DUPLICATED: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SECTION_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Section 이름이 중복되었습니다.",
    en: "TMF Section name is duplicated.",
  },

  TMF_STUDY_TEMPLATE_ARTIFACT_NO_FORMAT_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_NO_FORMAT_INVALID",
    status: 400,
    ko: "TMF Artifact의 No Format이 유효하지 않습니다.",
    en: "The format of TMF Artifact's No is invalid."
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_NO_ZONE_PART_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_NO_ZONE_PART_INVALID",
    status: 400,
    ko: "TMF Artifact No의 Zone 영역이 상이합니다.",
    en: "The Zone part of TMF Artifact's No is different."
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_NO_SECTION_PART_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_NO_SECTION_PART_INVALID",
    status: 400,
    ko: "TMF Artifact No의 Section 영역이 상이합니다.",
    en: "The Section part of TMF Artifact's No is different."
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_NAME_MISSING: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_NAME_MISSING",
    status: 400,
    ko: "TMF Artifact 이름이 입력되지 않았습니다.",
    en: "TMF Artifact Name is missing."
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_NAME_INVALID_WITH_ARTIFACT_NO: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_NAME_INVALID_WITH_ARTIFACT_NO",
    status: 400,
    ko: "동일한 Artifact No에는 Artifact 이름이 모두 동일해야합니다.",
    en: "The same artifact no must have the same artifact name."
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_NAME_TOO_SHORT: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Artifact 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Artifact name must be at least {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_NAME_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Artifact 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Artifact name can be up to {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_NAME_DUPLICATED: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Artifact 이름이 중복되었습니다.",
    en: "TMF Artifact name is duplicated.",
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_DESC_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_DESC_TOO_LONG",
    status: 400,
    ko: "TMF Artifact 설명의 길이는 최대 {length}자 입니다.",
    en: "TMF Artifact description can be up to {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_SUB_ARTIFACT_MISSING: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SUB_ARTIFACT_MISSING",
    status: 400,
    ko: "TMF Sub Artifacts가 입력되지 않았습니다.",
    en: "TMF Sub Artifacts are missing."
  },
  TMF_STUDY_TEMPLATE_SUB_ARTIFACT_LIMIT: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SUB_ARTIFACT_LIMIT",
    status: 400,
    ko: "TMF Sub Artifacts는 최대 {limit}개 입니다.",
    en: "The maximum number of TMF Sub Artifacts are {limit}.",
  },
  TMF_STUDY_TEMPLATE_SUB_ARTIFACT_NAME_TOO_SHORT: {
    code: "TMF_STUDY_TEMPLATE_SUB_ARTIFACT_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Sub Artifact 이름은 최소 {length}자 입니다.",
    en: "TMF Sub Artifact name must be at least {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_SUB_ARTIFACT_NAME_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SUB_ARTIFACT_TOO_LONG",
    status: 400,
    ko: "TMF Sub Artifact 이름은 최대 {length}자 입니다.",
    en: "TMF Sub Artifact name can be up to {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_SUB_ARTIFACT_NAME_DUPLICATED: {
    code: "TMF_STUDY_TEMPLATE_SUB_ARTIFACT_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Sub Artifact 이름이 중복되었습니다.",
    en: "TMF Sub Artifact name is duplicated.",
  },

  TMF_STUDY_TEMPLATE_ARTIFACT_MANDATORY_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_MANDATORY_INVALID",
    status: 400,
    ko: "TMF Artifact Core or Recommended 값이 유효하지 않습니다.",
    en: "The TMF Artifact Core or Recommended is invalid."
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_ATTR_VALUE_ICH_CODE_TOO_LONG: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_ATTR_VALUE_ICH_CODE_TOO_LONG",
    status: 400,
    ko: "TMF Artifact ICH Code의 길이는 최대 {length}자 입니다.",
    en: "TMF Artifact ICH Code can be up to {length} characters long.",
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_UNIQUE_ID_DUPLICATED: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_UNIQUE_ID_DUPLICATED",
    status: 409,
    ko: "TMF Artifact unique id이 중복되었습니다.",
    en: "TMF Artifact unique id is duplicated.",
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_UNIQUE_ID_FORMAT_INVALID: {
    code: "TMF_STUDY_TEMPLATE_ARTIFACT_UNIQUE_ID_FORMAT_INVALID",
    status: 400,
    ko: "TMF Artifact unique id 의 format이 잘못되었습니다.",
    en: "TMF Artifact unique id is invalid",
  },

  TMF_STUDY_TEMPLATE_ARTIFACT_SPONSOR_DOCUMENT_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_SPONSOR_DOCUMENT_INVALID",
    status: 400,
    ko: "TMF Artifact Sponsor Document가 유효하지 않습니다.",
    en: "The TMF Artifact Sponsor Document is invalid."
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_INV_DOCUMENT_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_INV_DOCUMENT_INVALID",
    status: 400,
    ko: "TMF Artifact Investigator Document 유효하지 않습니다.",
    en: "The TMF Artifact Investigator Document is invalid."
  },

  TMF_STUDY_TEMPLATE_TRIAL_LEVEL_DOC_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_TRIAL_LEVEL_DOC_INVALID",
    status: 400,
    ko: "TMF Artifact Trial Level Document 값이 유효하지 않습니다.",
    en: "The TMF Artifact Trial Level Document is invalid."
  },
  TMF_STUDY_TEMPLATE_TRIAL_LEVEL_MILESTONE_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_TRIAL_LEVEL_MILESTONE_INVALID",
    status: 409,
    ko: "TMF Artifact Trial Level Milestone 값이 유효하지 않습니다.",
    en: "TMF Artifact Trial Level Milestone is invalid.",
  },
  TMF_STUDY_TEMPLATE_COUNTRY_LEVEL_DOC_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_COUNTRY_LEVEL_DOC_INVALID",
    status: 400,
    ko: "TMF Artifact Country Level Document 값이 유효하지 않습니다.",
    en: "The TMF Artifact Country Level Document is invalid."
  },
  TMF_STUDY_TEMPLATE_COUNTRY_LEVEL_MILESTONE_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_COUNTRY_LEVEL_MILESTONE_INVALID",
    status: 409,
    ko: "TMF Artifact Country Level Milestone 값이 유효하지 않습니다.",
    en: "TMF Artifact Country Level Milestone is invalid.",
  },
  TMF_STUDY_TEMPLATE_SITE_LEVEL_DOC_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SITE_LEVEL_DOC_INVALID",
    status: 400,
    ko: "TMF Artifact Site Level Document 값이 유효하지 않습니다.",
    en: "The TMF Artifact Site Level Document is invalid."
  },
  TMF_STUDY_TEMPLATE_SITE_LEVEL_MILESTONE_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_SITE_LEVEL_MILESTONE_INVALID",
    status: 409,
    ko: "TMF Artifact Site Level Milestone 값이 유효하지 않습니다.",
    en: "TMF Artifact Site Level Milestone is invalid.",
  },
  TMF_STUDY_TEMPLATE_ARTIFACT_DATING_CONVENTION_INVALID: {
    code: "TMF_STUDY_TEMPLATE_MILESTONE_ARTIFACT_DATING_CONVENTION_INVALID",
    status: 400,
    ko: "TMF Artifact 날짜 규칙이 유효하지 않습니다.",
    en: "The TMF Artifact dating convention is invalid."
  },
};

MvnCodeInitiate(TMF_STUDY_TEMPLATE_CODE);
