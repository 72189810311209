/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const ECOA_CAPTURE_LANG: {
  ECOA_CAPTURE_AGREE_AND_START: MvnCodeFE;
  ECOA_CAPTURE_CAPTURE_FAIL: MvnCodeFE;
  ECOA_CAPTURE_SUBMIT_FAIL: MvnCodeFE;
  ECOA_CAPTURE_SUBMIT_SUCC_TITLE: MvnCodeFE;
  ECOA_CAPTURE_SUBMIT_SUCC_DESCRIPTION: MvnCodeFE;
  ECOA_CAPTURE_EXIT_CONFIRM_MESSAGE: MvnCodeFE;
  ECOA_CAPTURE_NEXT: MvnCodeFE;
  ECOA_CAPTURE_PARTICIPATE: MvnCodeFE;
  ECOA_CAPTURE_TAKE_SURVEY: MvnCodeFE;
  ECOA_CAPTURE_DONE: MvnCodeFE;
  ECOA_CAPTURE_SUBMIT: MvnCodeFE;
  ECOA_CAPTURE_SAVE: MvnCodeFE;
  ECOA_CAPTURE_VERIFY: MvnCodeFE;
  ECOA_CAPTURE_PLACEHOLDER_HOUR: MvnCodeFE;
  ECOA_CAPTURE_PLACEHOLDER_MIN: MvnCodeFE;
  ECOA_CAPTURE_PLACEHOLDER_SELECT: MvnCodeFE;
  ECOA_CAPTURE_PLACEHOLDER_ENTER_TEXT: MvnCodeFE;
  ECOA_CAPTURE_PLACEHOLDER_TIME_FORMAT_AM: MvnCodeFE;
  ECOA_CAPTURE_PLACEHOLDER_TIME_FORMAT_PM: MvnCodeFE;
  ECOA_CAPTURE_NOT_AVAILABLE: MvnCodeFE;
} = {
  ECOA_CAPTURE_AGREE_AND_START: {
    ko: "동의 및 설문 시작",
    en: "Agree and Start",
    fr: "Accepter et commencer",
    nl: "Akkoord en beginnen"
  },
  ECOA_CAPTURE_CAPTURE_FAIL: {
    ko: "데이터를 저장할 수 없습니다. ({message})",
    en: "Failed to data capture. ({message})",
  },
  ECOA_CAPTURE_SUBMIT_FAIL: {
    ko: "데이터를 저장할 수 없습니다. ({message})",
    en: "Failed to data capture. ({message})",
  },
  ECOA_CAPTURE_SUBMIT_SUCC_TITLE: {
    ko: "설문을 제출하였습니다.",
    en: "You have submitted\nyour answer successfully.",
    fr: "Vous avez soumis\nvotre réponse avec succès.",
    nl: "U heeft uw antwoord\nsuccesvol ingediend.",
  },
  ECOA_CAPTURE_SUBMIT_SUCC_DESCRIPTION: {
    ko: "제출한 설문 내용에 대해서 수정이 필요할 경우 임상시험 실시기관으로 문의해 주세요.",
    en: "If you need to modify your answers,\nPlease contact the clinical trial site.",
    fr: "Si vous devez modifier vos réponses,\nveuillez contacter le site de l'essai clinique.",
    nl: "Als u uw antwoorden moet wijzigen,\nneem dan contact op met de klinische proeflocatie.",
  },
  ECOA_CAPTURE_EXIT_CONFIRM_MESSAGE: {
    ko: "설문을 종료하시겠습니까?",
    en: "Do you want to end the survey?",
    fr: "Voulez-vous terminer l'enquête ?",
    nl: "Wilt u de enquête beëindigen?",
  },
  ECOA_CAPTURE_NEXT: {
    ko: "다음",
    en: "Next",
    fr: "Suivant",
    nl: "Volgende",
  },
  ECOA_CAPTURE_PARTICIPATE: {
    ko: "참여하기",
    en: "Participate",
    fr: "Participer",
    nl: "Deelnemen",
  },
  ECOA_CAPTURE_TAKE_SURVEY: {
    ko: "설문 시작",
    en: "Take survey",
    fr: "Commencer l'enquête",
    nl: "Enquête starten",
  },
  ECOA_CAPTURE_DONE: {
    ko: "설문 완료",
    en: "Done",
    fr: "Terminé",
    nl: "Gedaan"
  },
  ECOA_CAPTURE_SUBMIT: {
    ko: "설문 제출",
    en: "Submit",
    fr: "Envoyer",
    nl: "Verzenden",
  },
  ECOA_CAPTURE_SAVE: {
    ko: "저장",
    en: "Submit",
    fr: "Soumettre",
    nl: "Indienen",
  },
  ECOA_CAPTURE_VERIFY: {
    ko: "본인 인증",
    en: "Verify",
    fr: "Vérifier",
    nl: "Verifiëren"
  },
  ECOA_CAPTURE_PLACEHOLDER_HOUR: {
    ko: "시",
    en: "hh",
    fr: "hh",
    nl: "hh",
  },
  ECOA_CAPTURE_PLACEHOLDER_MIN: {
    ko: "분",
    en: "mm",
    fr: "mm",
    nl: "mm",
  },
  ECOA_CAPTURE_PLACEHOLDER_SELECT: {
    ko: "선택",
    en: "select",
    fr: "Sélectionner",
    nl: "Selecteren",
  },
  ECOA_CAPTURE_PLACEHOLDER_ENTER_TEXT: {
    ko: "내용을 입력해 주세요.",
    en: "Enter text",
    fr: "Entrez du texte",
    nl: "Voer tekst in",
  },
  ECOA_CAPTURE_PLACEHOLDER_TIME_FORMAT_AM: {
    ko: "오전",
    en: "AM",
    fr: "AM",
    nl: "AM",
  },
  ECOA_CAPTURE_PLACEHOLDER_TIME_FORMAT_PM: {
    ko: "오후",
    en: "PM",
    fr: "PM",
    nl: "PM",
  },
  ECOA_CAPTURE_NOT_AVAILABLE: {
    ko: "입력 불가",
    en: "Not Available",
    fr: "Non Disponible",
    nl: "Niet Beschikbaar",
  },
};

MvnCodeInitiate(ECOA_CAPTURE_LANG);
