import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { classNames } from "maven-lib/dist/utils/misc";
import { nl2br } from "../helper/nl2br";

const VERTICAL = "VERTICAL";
const HORIZONTAL = "HORIZONTAL";

export interface CrInputGroupProps {
  title?: string;
  description?: string;
  className?: string;
  children: React.ReactNode;
  spacing: number;
  direction: typeof VERTICAL | typeof HORIZONTAL | { type: typeof HORIZONTAL; break: number };
  align?: "START" | "CENTER" | "END";
}

export function CrInputGroup(props: CrInputGroupProps) {
  const { title, description, spacing, className, children, direction, align } = props;

  const breakCriteria = typeof direction !== "string" && direction.break ? direction.break : 0;
  const mediaMatch = window.matchMedia(`(min-width: ${breakCriteria}px)`);
  const [matches, setMatches] = useState(breakCriteria ? mediaMatch.matches : true);

  useEffect(() => {
    const handler = () => {
      setMatches(breakCriteria ? mediaMatch.matches : true);
    };

    mediaMatch.addEventListener("change", handler);

    return () => mediaMatch.removeEventListener("change", handler);
  });

  const adjustedDirection = ((dr) => (dr === HORIZONTAL && !matches ? VERTICAL : dr))(
    String(typeof direction === "string" ? direction : direction.type)
  );

  return (
    <Stack
      className={classNames(
        "cr-input-group",
        className,
        `direction--${adjustedDirection.toLowerCase()}`,
        `spacing--${spacing}`,
        `align--${(align || "CENTER").toLowerCase()}`
      )}
      horizontal={adjustedDirection === HORIZONTAL}
    >
      {title && <strong>{title}</strong>}
      {description && <p>{nl2br(description)}</p>}
      {children}
    </Stack>
  );
}

CrInputGroup.defaultProps = {
  spacing: 16,
  direction: "VERTICAL",
};
