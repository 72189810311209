import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_GATEWAY_AS2_CONNECTION_CODE: {
  GATEWAT_AS2_CONNECTION_INVALID_IDENTIFIER_DUPLICATED: MvnCodeBE;
  GATEWAT_AS2_CONNECTION_INVALID_ACCOUNTS_DUPLICATED: MvnCodeBE;
  GATEWAY_AS2_CONNECTION_ACTIVATION_INVALID_ALREADY_ACTIVATED: MvnCodeBE;
  GATEWAY_AS2_CONNECTION_ACTIVATION_INVALID_ACCOUNT_DEACTIVATED: MvnCodeBE;
  GATEWAT_AS2_CONNECTION_DEACTIVATION_INVALID_ALREADY_DEACTIVATED: MvnCodeBE;
} = {
  GATEWAT_AS2_CONNECTION_INVALID_IDENTIFIER_DUPLICATED: {
    status: 409,
    ko: "이미 사용되고 있는 Connection Identifier입니다. (identifier: {identifier})",
    en: "Connection Identifer is duplicated. (identifier: {identifier})",
  },
  GATEWAT_AS2_CONNECTION_INVALID_ACCOUNTS_DUPLICATED: {
    status: 409,
    ko: "Host Account와 Partner Account가 동일한 Connection이 존재합니다. (hostAccountId: {hostAccountId}, partnerAccountId: {partnerAccountId})",
    en: "Host Account and Partner Account is duplicated. Identifer is duplicated. (hostAccountId: {hostAccountId}, partnerAccountId: {partnerAccountId})",
  },
  GATEWAY_AS2_CONNECTION_ACTIVATION_INVALID_ALREADY_ACTIVATED: {
    status: 409,
    ko: "이미 활성화된 Connection입니다.",
    en: "Connection has been activated already.",
  },
  GATEWAY_AS2_CONNECTION_ACTIVATION_INVALID_ACCOUNT_DEACTIVATED: {
    status: 409,
    ko: "Account가 비활성화 상태인 Connection은 활성화 할 수 없습니다.",
    en: "Connection using inactive account cannot be activated.",
  },
  GATEWAT_AS2_CONNECTION_DEACTIVATION_INVALID_ALREADY_DEACTIVATED: {
    status: 409,
    ko: "이미 비활성화된 Connection입니다.",
    en: "Connection has been deactivated already.",
  },
};

MvnCodeInitiate(SAFETY_GATEWAY_AS2_CONNECTION_CODE);
