import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_USER_VERIFICATION_CODE_LANG: {
  USER_VERIFICATION_CODE_EMAIL_SEND_SUCC: MvnCodeFE;
  USER_VERIFICATION_CODE_EMAIL_SEND_FAIL: MvnCodeFE;
  USER_VERIFICATION_CODE_EMAIL_VERIFY_FAIL: MvnCodeFE;
  USER_VERIFICATION_CODE_EMAIL_DIALOG_HEADER: MvnCodeFE;
  USER_VERIFICATION_CODE_EMAIL_DIALOG_TITLE: MvnCodeFE;
  USER_VERIFICATION_CODE_IDENTITY_VERIFY_FAIL: MvnCodeFE;
  USER_VERIFICATION_CODE_LABEL: MvnCodeFE;
  USER_VERIFICATION_CODE_PLACEHOLDER: MvnCodeFE;
} = {
  USER_VERIFICATION_CODE_EMAIL_SEND_SUCC: {
    ko: "이메일 인증코드가 발송되었습니다. 메일함을 확인해 주세요.",
    en: "We've sent a verification code. Please check your email inbox.",
  },
  USER_VERIFICATION_CODE_EMAIL_SEND_FAIL: {
    ko: "이메일 인증코드 발송에 실패하였습니다. ({message})",
    en: "Email verification code seding has been failed. ({message})",
  },
  USER_VERIFICATION_CODE_EMAIL_VERIFY_FAIL: {
    ko: "이메일 인증에 실패하였습니다. ({message})",
    en: "Email verification has been failed. ({message})",
  },
  USER_VERIFICATION_CODE_EMAIL_DIALOG_HEADER: {
    ko: "Verify Your Email",
    en: "Verify Your Email",
  },
  USER_VERIFICATION_CODE_EMAIL_DIALOG_TITLE: {
    ko: "이메일 인증코드를 입력하신 이메일({email})로 전송하였습니다. 메일함을 확인해 주세요.",
    en: "We've sent a verification code to {email} to verify your email address. Please check your email inbox.",
  },
  USER_VERIFICATION_CODE_IDENTITY_VERIFY_FAIL: {
    ko: "본인 인증에 실패하였습니다. ({message})",
    en: "Failed to personal identification. ({message})",
  },
  USER_VERIFICATION_CODE_LABEL: {
    ko: "인증코드",
    en: "Verification Code",
  },
  USER_VERIFICATION_CODE_PLACEHOLDER: {
    ko: "인증코드를 입력해 주세요.",
    en: "Please enter the verification code.",
  },
};

MvnCodeInitiate(AUTH_USER_VERIFICATION_CODE_LANG);
