import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const PROFILE_LANG: {
  VIEW_TITLE: MvnCodeFE;
  VIEW_LABEL_NAME: MvnCodeFE;
  VIEW_LABEL_EMAIL: MvnCodeFE;
  VIEW_LABEL_PW: MvnCodeFE;
  VIEW_LABEL_PHONE: MvnCodeFE;
  VIEW_LABEL_AFFILIATION: MvnCodeFE;
  EDIT_TITLE: MvnCodeFE;
  EDIT_LABEL_CURRENT_PW: MvnCodeFE;
  EDIT_LABEL_NEW_PW: MvnCodeFE;
  EDIT_LABEL_NAME: MvnCodeFE;
  EDIT_LABEL_PHONE: MvnCodeFE;
  EDIT_LABEL_EMAIL: MvnCodeFE;
  EDIT_LABEL_AFFILIATION: MvnCodeFE;
  EDIT_SUCC: MvnCodeFE;
  EDIT_FAIL: MvnCodeFE;
  EDIT_DESC_LABEL_PASSWORD_INVALID: MvnCodeFE;
  EDIT_ERROR_LABEL_PASSWORD_SAME_WITH_EMAIL: MvnCodeFE;
} = {
  VIEW_TITLE: {
    ko: "Account Detail",
    en: "Account Detail",
  },
  VIEW_LABEL_NAME: {
    ko: "Name",
    en: "Name",
  },
  VIEW_LABEL_EMAIL: {
    ko: "Email",
    en: "Email",
  },
  VIEW_LABEL_PW: {
    ko: "Password",
    en: "Password",
  },
  VIEW_LABEL_PHONE: {
    ko: "Phone",
    en: "Phone",
  },
  VIEW_LABEL_AFFILIATION: {
    ko: "Affiliation",
    en: "Affiliation",
  },
  EDIT_TITLE: {
    ko: "Account Edit",
    en: "Account Edit",
  },
  EDIT_LABEL_CURRENT_PW: {
    ko: "Current Password",
    en: "Current Password",
  },
  EDIT_LABEL_NEW_PW: {
    ko: "New Password",
    en: "New Password",
  },
  EDIT_LABEL_NAME: {
    ko: "Name",
    en: "Name",
  },
  EDIT_LABEL_PHONE: {
    ko: "Phone",
    en: "Phone",
  },
  EDIT_LABEL_EMAIL: {
    ko: "EMAIL",
    en: "EMAIL",
  },
  EDIT_LABEL_AFFILIATION: {
    ko: "Affiliation",
    en: "Affiliation",
  },
  EDIT_SUCC: {
    ko: "Profile을 성공적으로 갱신하였습니다.",
    en: "Profile has been saved successfully.",
  },
  EDIT_FAIL: {
    ko: "Profile 갱신에 실패하였습니다. ({message})",
    en: "Failed to save Profile. ({message})",
  },
  EDIT_DESC_LABEL_PASSWORD_INVALID: {
    ko: "비밀번호는 최소 8글자 이상, 1개 이상의 숫자, 영문, 특수문자를 포함해야 합니다.",
    en: "Password must contain at least 8 characters, 1 number, 1 English and 1 symbol.",
  },
  EDIT_ERROR_LABEL_PASSWORD_SAME_WITH_EMAIL: {
    ko: "이메일과 동일한 비밀번호로 변경할 수 없습니다.",
    en: "You can not change with the same password as your email",
  },
};

MvnCodeInitiate(PROFILE_LANG);
