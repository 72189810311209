import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delay: number, isPause: boolean = false) {
  const savedCallback = useRef(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (!isPause) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }

    return () => {};
  }, [delay, isPause]);
}
