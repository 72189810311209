import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CODING_CODE: {
  STUDY_CODING_INVALID: MvnCodeBE;
  STUDY_CODING_NOT_FOUND: MvnCodeBE;
  STUDY_CODING_SPEC_NOT_FOUND: MvnCodeBE;
  STUDY_CODING_MIGRATE_NOT_SUPPORT: MvnCodeBE;
  STUDY_CODING_MIGRATE_INVALID_VERSION: MvnCodeBE;
  STUDY_CODING_MIGRATE_INVALID_DICTIONARY_TYPE: MvnCodeBE;
  STUDY_CODING_MIGRATE_BAD_REQUEST: MvnCodeBE;
  STUDY_CODING_DATA_NOT_SELECTED: MvnCodeBE;
  STUDY_CODING_CODE_NOT_ENTERED: MvnCodeBE;
  STUDY_CODING_AUTO_CODING_NOT_AVAILABLE: MvnCodeBE;
  CODING_SYNC_UPDATE_REQUEST_MISMATCH: MvnCodeBE;
} = {
  STUDY_CODING_NOT_FOUND: {
    status: 404,
    ko: "존재하지 않는 코딩 데이터입니다.",
    en: "Coding data does not exist.",
  },
  STUDY_CODING_INVALID: {
    status: 400,
    ko: "유효하지 않은 코딩 데이터입니다.",
    en: "Invalid coding data.",
  },
  STUDY_CODING_SPEC_NOT_FOUND: {
    status: 400,
    ko: "존재하지 않는 코딩 스펙입니다.",
    en: "Coding Specification does not exist.",
  },
  STUDY_CODING_MIGRATE_NOT_SUPPORT: {
    status: 400,
    ko: "지원하지 않는 마이그레이션 유형입니다.",
    en: "Unsupported migration type.",
  },
  STUDY_CODING_MIGRATE_INVALID_VERSION: {
    status: 400,
    ko: "코딩 Migration 버전이 유효하지 않습니다.",
    en: "The Version of coding migrate is not valid.",
  },
  STUDY_CODING_MIGRATE_INVALID_DICTIONARY_TYPE: {
    status: 400,
    ko: "코딩 Dictionary 유형이 일치하지 않습니다.",
    en: "The type of coding dictionary is not matched.",
  },
  STUDY_CODING_MIGRATE_BAD_REQUEST: {
    status: 400,
    ko: "코딩 Migrate 요청 정보가 올바르지 않습니다.",
    en: "The request information of coding migrate is not valid.",
  },
  STUDY_CODING_DATA_NOT_SELECTED: {
    status: 400,
    ko: "선택된 항목이 없습니다.",
    en: "No selected coding data.",
  },
  STUDY_CODING_CODE_NOT_ENTERED: {
    status: 400,
    ko: "코드가 입력되지 않았습니다.",
    en: "No code is entered.",
  },
  STUDY_CODING_AUTO_CODING_NOT_AVAILABLE: {
    status: 400,
    ko: "미사용 LLT는 Auto Coding이 지원되지 않는 항목입니다. ({manualCodingList})",
    en: "The request information is not supported for auto coding of unused LLT. ({manualCodingList})",
  },
  CODING_SYNC_UPDATE_REQUEST_MISMATCH: {
    status: 400,
    ko: "Update 요청 개수와 실제 개수가 맞지 않습니다. {requestedCount} {updatedCount}",
    en: "Update requests and the actual request numbers do not match. {requestedCount} {updatedCount}",
  },
};

MvnCodeInitiate(STUDY_CODING_CODE);
