import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_ROLE_LANG: {
  ROLE_MANAGE_ROLE_BREADCRUMB: MvnCodeFE;
  ROLE_ADD_TITLE: MvnCodeFE;
  ROLE_ADD_DESCRIPTION: MvnCodeFE;
  ROLE_ADD_SUCC: MvnCodeFE;
  ROLE_ADD_FAIL: MvnCodeFE;
  LIST_ROLE_PRIV_EXIST: MvnCodeFE;
  LIST_ROLE_PRIV_NOT_EXIST: MvnCodeFE;
  LIST_BTN_ADD: MvnCodeFE;
  LIST_BTN_COPY_AND_PASTE: MvnCodeFE;
  LIST_BTN_REMOVE: MvnCodeFE;
  LIST_BTN_DOWNLOAD: MvnCodeFE;
  COPY_SUCC: MvnCodeFE;
  COPY_FAIL: MvnCodeFE;
  COPY_STUDY_DROPDOWN_PLACEHOLDER_EXIST_CASE: MvnCodeFE;
  COPY_STUDY_DROPDOWN_PLACEHOLDER_EMPTY_CASE: MvnCodeFE;
  ROLE_MANAGEMENT_TITLE: MvnCodeFE;
  ROLE_MANAGEMENT_UPDATE_SUCC: MvnCodeFE;
  ROLE_MANAGEMENT_UPDATE_FAIL: MvnCodeFE;
  ROLE_REMOVE_SUCC: MvnCodeFE;
  ROLE_REMOVE_FAIL: MvnCodeFE;
  ROLE_DOWNLOAD_FAIL: MvnCodeFE;
  ROLE_DELETE_CONFIRM: MvnCodeFE;
  UPLOAD_BTN_ROLE_UPLOAD: MvnCodeFE;
  UPLOAD_BTN_DOWNLOAD_TEMPLATE: MvnCodeFE;
  UPLOAD_SUCC: MvnCodeFE;
  UPLOAD_FAIL: MvnCodeFE;
  UPLOAD_DIALOG_HEADER: MvnCodeFE;
  UPLOAD_DIALOG_BTN_CONFIRM: MvnCodeFE;
  UPLOAD_DIALOG_BTN_CANCEL: MvnCodeFE;
  UPLOAD_DIALOG_LABEL_TASK_NAME: MvnCodeFE;
  UPLOAD_DIALOG_LABEL_FILE: MvnCodeFE;
  SAMPLE_DOWNLOAD_FAIL: MvnCodeFE;
  UPLOAD_LIST_RESULT_CODE_DONE: MvnCodeFE;
  UPLOAD_FILE_DOWNLOAD_FAIL: MvnCodeFE;
} = {
  ROLE_MANAGE_ROLE_BREADCRUMB: {
    ko: "등록된 권한을 확인하고 관리할 수 있습니다.",
    en: "Manage role and previleges.",
  },
  ROLE_ADD_TITLE: {
    ko: "Role 추가",
    en: "Add Role",
  },
  ROLE_ADD_DESCRIPTION: {
    ko: "Role의 이름을 입력하세요.",
    en: "Enter the name of the role to be added.",
  },
  ROLE_ADD_SUCC: {
    ko: "성공적으로 Role({name})을 등록했습니다. ",
    en: "Role({name}) has been added successfully.",
  },
  ROLE_ADD_FAIL: {
    ko: "Role 등록에 실패하였니다. ({error})",
    en: "Role add has been failed. ({error})",
  },
  LIST_ROLE_PRIV_EXIST: {
    ko: "{privCount}개 권한 ({privs})",
    en: "{privCount} privilege{privPlural} ({privs})",
  },
  LIST_ROLE_PRIV_NOT_EXIST: {
    ko: "포함된 권한 없음",
    en: "No privileges included",
  },
  LIST_BTN_ADD: {
    ko: "Add Role",
    en: "Add Role",
  },
  LIST_BTN_COPY_AND_PASTE: {
    ko: "Copy & Paste Role",
    en: "Copy & Paste Role",
  },
  LIST_BTN_REMOVE: {
    ko: "Remove",
    en: "Remove",
  },
  LIST_BTN_DOWNLOAD: {
    ko: "Download",
    en: "Download",
  },
  COPY_SUCC: {
    ko: "성공적으로 Role을 복사했습니다.",
    en: "Roles have been copied successfully.",
  },
  COPY_FAIL: {
    ko: "Role 복사에 실패했습니다. ({message})",
    en: "Failed to copy Roles. ({message})",
  },
  COPY_STUDY_DROPDOWN_PLACEHOLDER_EXIST_CASE: {
    ko: "Study를 선택해 주세요.",
    en: "Please select a study.",
  },
  COPY_STUDY_DROPDOWN_PLACEHOLDER_EMPTY_CASE: {
    ko: "선택 가능한 Study가 존재하지 않습니다.",
    en: "There are no selectable studies.",
  },
  ROLE_MANAGEMENT_TITLE: {
    ko: "Role 정보",
    en: "Role Information",
  },
  ROLE_MANAGEMENT_UPDATE_SUCC: {
    ko: "성공적으로 Role({role}) 정보를 갱신하였습니다.",
    en: "You have updated the role({role}) information successfully.",
  },
  ROLE_MANAGEMENT_UPDATE_FAIL: {
    ko: "Role 정보 갱신에 실패하였습니다. ({error})",
    en: "Role update has been failed. ({error})",
  },
  ROLE_REMOVE_SUCC: {
    ko: "성공적으로 Role을 삭제하였습니다.",
    en: "Roles have been removed successfully.", // Role을 복수로 처리하는게 좋을거같음..
  },
  ROLE_REMOVE_FAIL: {
    ko: "Role 삭제에 실패하였습니다. ({message})",
    en: "Role removal has been failed. ({message})",
  },
  ROLE_DOWNLOAD_FAIL: {
    ko: "다운로드에 실패했습니다. ({message})",
    en: "Role download has been failed. ({message})",
  },
  ROLE_DELETE_CONFIRM: {
    ko: "선택된 Role 삭제를 위해 비밀번호를 입력해 주세요.",
    en: "Enter your password to delete selected Roles.",
  },
  UPLOAD_BTN_ROLE_UPLOAD: {
    ko: "Role Upload",
    en: "Role Upload",
  },
  UPLOAD_BTN_DOWNLOAD_TEMPLATE: {
    ko: "Download Template",
    en: "Download Template",
  },
  UPLOAD_SUCC: {
    ko: "Role Upload를 성공했습니다.",
    en: "Roles have been uploaded successfully.",
  },
  UPLOAD_FAIL: {
    ko: "Role Upload를 실패했습니다. ({message})",
    en: "Role upload has been failed. ({message})",
  },
  UPLOAD_DIALOG_HEADER: {
    ko: "Upload Role",
    en: "Upload Role",
  },
  UPLOAD_DIALOG_BTN_CONFIRM: {
    ko: "Upload",
    en: "Upload",
  },
  UPLOAD_DIALOG_BTN_CANCEL: {
    ko: "Cancel",
    en: "Cancel",
  },
  UPLOAD_DIALOG_LABEL_TASK_NAME: {
    ko: "Task Name",
    en: "Task Name",
  },
  UPLOAD_DIALOG_LABEL_FILE: {
    ko: "File",
    en: "File",
  },
  SAMPLE_DOWNLOAD_FAIL: {
    ko: "샘플 포맷 다운로드에 실패했습니다. ({message})",
    en: "Failed to download Sample Format. ({message})",
  },
  UPLOAD_LIST_RESULT_CODE_DONE: {
    ko: "작업이 정상적으로 완료되었습니다.",
    en: "Completed successfully.", // 의미 알아야함
  },
  UPLOAD_FILE_DOWNLOAD_FAIL: {
    ko: "다운로드에 실패했습니다. ({message})",
    en: "Failed to download. ({message})",
  },
};

MvnCodeInitiate(STUDY_ROLE_LANG);
