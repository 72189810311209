/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const PRIVILEGE_CODE: {
  NOTFOUND: MvnCodeBE;
} = {
  NOTFOUND: {
    status: 404,
    ko: "해당 Privilege({id})를 찾을 수 없습니다",
    en: "Privilege({id}) could not be found.",
  },
};

MvnCodeInitiate(PRIVILEGE_CODE);
