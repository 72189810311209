import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CASE_REPORT_STATUS_CODE: {
  CASE_REPORT_STATUS_LOCK_INVALID_CASE_ALREADY_LOCKED: MvnCodeBE;
  CASE_REPORT_STATUS_LOCK_INVALID_WORKFLOW_STATUS: MvnCodeBE;
  CASE_REPORT_STATUS_UNLOCK_INVALID_CASE_ALREADY_UNLOCKED: MvnCodeBE;
  CASE_REPORT_STATUS_UNLOCK_INVALID_CASE_LOCKED_BY_IMPORT: MvnCodeBE;
  CASE_REPORT_STATUS_UNLOCK_INVALID_NOT_AUTHORIZED: MvnCodeBE;
} = {
  CASE_REPORT_STATUS_LOCK_INVALID_CASE_ALREADY_LOCKED: {
    status: 400,
    ko: "Case가 이미 잠겨 있습니다.",
    en: "Case is already locked.",
  },
  CASE_REPORT_STATUS_LOCK_INVALID_WORKFLOW_STATUS: {
    status: 400,
    ko: "{status} 상태의 case(s)는 잠글 수 없습니다.",
    en: "You cannot lock case(s) in the {status} state.",
  },
  CASE_REPORT_STATUS_UNLOCK_INVALID_CASE_ALREADY_UNLOCKED: {
    status: 400,
    ko: "Case가 이미 잠금 해제 상태입니다.",
    en: "Case is already unlocked.",
  },
  CASE_REPORT_STATUS_UNLOCK_INVALID_CASE_LOCKED_BY_IMPORT: {
    status: 400,
    ko: "Import 중인 Case의 잠금을 해제할 수 없습니다.",
    en: "Cannot unlock case being imported.",
  },
  CASE_REPORT_STATUS_UNLOCK_INVALID_NOT_AUTHORIZED: {
    status: 403,
    ko: "Case 잠금 해제 권한이 필요합니다.",
    en: "Case unlock privilege is required.",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_STATUS_CODE);
