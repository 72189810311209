import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const DASHBOARD_LANG: {
  NOTICE_EMPTY: MvnCodeFE;
  DASHBOARD_STATISTICS_CUMULATIVE: MvnCodeFE;
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_SITE_TITLE: MvnCodeFE;
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_SITE_DESC: MvnCodeFE;
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_SITE_EMPTY: MvnCodeFE;
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_SITE_PER_MONTH_TITLE: MvnCodeFE;
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_SITE_PER_MONTH_DESC: MvnCodeFE;
  DASHBOARD_STATISTICS_PER_SITE_TITLE: MvnCodeFE;
  DASHBOARD_STATISTICS_PER_SITE_DESC: MvnCodeFE;
  DASHBOARD_STATISTICS_SITE_DROPDOWN_PLACEHOLDER: MvnCodeFE;
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_MONTH_TITLE: MvnCodeFE;
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_MONTH_DESC: MvnCodeFE;
  DASHBOARD_STATISTICS_SUBJECT_STATUS_COUNT_PER_MONTH_LABEL: MvnCodeFE;
  DASHBOARD_STATISTICS_SUBJECT_STATUS_SUM_PER_MONTH_LABEL: MvnCodeFE;
  DASHBOARD_STATISTICS_STATUS_AND_SITE_EMPTY: MvnCodeFE;
  DASHBOARD_STATISTICS_STATUS_EMPTY: MvnCodeFE;
  DASHBOARD_STATISTICS_STATUS_GRAPH_EMPTY: MvnCodeFE;
  DASHBOARD_STATISTICS_STATUS_TOOLTIP: MvnCodeFE;
} = {
  NOTICE_EMPTY: {
    ko: "등록된 공지사항이 없습니다.",
    en: "There is no notice to display.",
  },
  DASHBOARD_STATISTICS_CUMULATIVE: {
    ko: "누적",
    en: "Cumulative",
  },
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_SITE_TITLE: {
    ko: "Subject Status",
    en: "Subject Status",
  },
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_SITE_DESC: {
    ko: "선택한 기관의 모든 Subject에 대하여 현재 진행 상태를 확인할 수 있습니다.",
    en: "This diagram shows the current status of all subjects at the selected site(s).",
  },
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_SITE_EMPTY: {
    ko: "등록된 Subject가 없습니다.",
    en: "There are no registered subjects.",
  },
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_SITE_PER_MONTH_TITLE: {
    ko: "월별 누적 Subject Status",
    en: "Cumulative Subject Status by Month",
  },
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_SITE_PER_MONTH_DESC: {
    ko: "선택한 기관의 모든 Subject Status의 월별 누적 수를 확인할 수 있습니다.",
    en: "This diagram shows the monthly cumulative subject status at the selected site(s).",
  },
  DASHBOARD_STATISTICS_PER_SITE_TITLE: {
    ko: "Subject Status by Site",
    en: "Subject Status by Site",
  },
  DASHBOARD_STATISTICS_PER_SITE_DESC: {
    ko: "선택한 Subject Status의 해당 월의 기관별 누적 수를 확인할 수 있습니다.",
    en: "This diagram shows the cumulative count of the selected subject status within the selected month by site.",
  },
  DASHBOARD_STATISTICS_SITE_DROPDOWN_PLACEHOLDER: {
    ko: "기관 선택",
    en: "Select Site",
  },
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_MONTH_TITLE: {
    ko: "Subject Status by Month",
    en: "Subject Status by Month",
  },
  DASHBOARD_STATISTICS_SUBJECT_STATUS_PER_MONTH_DESC: {
    ko: "선택한 Subject Status의 월별 누적 수와 누적 합계를 확인할 수 있습니다.",
    en: "This diagram shows the  monthly cumulative count and monthly cumulative total of the selected subject status.",
  },
  DASHBOARD_STATISTICS_SUBJECT_STATUS_COUNT_PER_MONTH_LABEL: {
    ko: "월별 누적 수",
    en: "cumulative count by month",
  },
  DASHBOARD_STATISTICS_SUBJECT_STATUS_SUM_PER_MONTH_LABEL: {
    ko: "월별 누적 합계",
    en: "cumulative total by month",
  },
  DASHBOARD_STATISTICS_STATUS_AND_SITE_EMPTY: {
    ko: "보고싶은 Subject Status나 기관(Sites)를 선택해주세요.",
    en: "Please select the subject status or site you would like to see.",
  },
  DASHBOARD_STATISTICS_STATUS_EMPTY: {
    ko: "보고싶은 Subject Status를 선택해주세요.",
    en: "Please select the subject status you would like to see.",
  },
  DASHBOARD_STATISTICS_STATUS_GRAPH_EMPTY: {
    ko: "그래프 유형을 선택하여 확인할 수 있습니다.",
    en: "Select the type of graph you'd like to view.",
  },
  DASHBOARD_STATISTICS_STATUS_TOOLTIP: {
    ko: "Subject의 Status가 변경되면 각 Status Count는 해당 월에 누적됩니다. Status 날짜가 변경될 경우 마지막 날짜 기준으로 월 누적 Count가 변경됩니다.",
    en: "When the subject status changes, the count for each status is cumulated for that month. If the date for the change in the subject status is changed, the latest date saved is reflected in the cumulative graph.",
  },
};

MvnCodeInitiate(DASHBOARD_LANG);
