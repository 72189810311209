/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const CRF_CODE: {
  CRF_DOMAIN_NOT_AVAILABLE: MvnCodeBE;
  CRF_CONFIG_ECOA_ECONSENT_SMS_NOT_AVAILABLE: MvnCodeBE;
} = {
  CRF_DOMAIN_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 CRF({id})의 Domain 정보를 확인할 수 없습니다.",
    en: "The domain information of CRF({id}) is not available.",
  },
  CRF_CONFIG_ECOA_ECONSENT_SMS_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study({id})의 CRF에서 SMS 정보를 확인할 수 없습니다.",
    en: "The SMS information of study({id})'s CRF is not available.",
  },
};

MvnCodeInitiate(CRF_CODE);
