import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_LANG: {
  CDMS_STUDY_CRF_LABEL_PAYLOAD_FILE_UPLOAD: MvnCodeFE;
  CDMS_STUDY_CRF_PLACEHOLDER_PAYLOAD_FILE_UPLOAD: MvnCodeFE;
} = {
  CDMS_STUDY_CRF_LABEL_PAYLOAD_FILE_UPLOAD: {
    ko: "Add File",
    en: "Add File",
  },
  CDMS_STUDY_CRF_PLACEHOLDER_PAYLOAD_FILE_UPLOAD: {
    ko: "Add Payload TXT File",
    en: "Add Payload TXT File",
  },
};

MvnCodeInitiate(CDMS_STUDY_LANG);
