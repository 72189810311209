import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface MsaAuthRouteProps extends RouteProps {
  session?: {
    token: string | null;
    expiryAt: Date | null;
  };
  authStrategy?: "AUTHORIZED_ONLY" | "UNAUTHORIZED_ONLY";
  denyRedirectTo?: string;
}

export function MsaAuthRoute({
  children,
  session,
  authStrategy,
  denyRedirectTo = "/auth",
  render,
  component: Comp,
  ...rest
}: MsaAuthRouteProps) {
  const Component: any = Comp;
  const available = (() => {
    if (authStrategy) {
      switch (authStrategy) {
        case "AUTHORIZED_ONLY":
          return !!session && !!session.token;
        case "UNAUTHORIZED_ONLY":
          return !session || !session.token;
        default:
          throw new Error("Unexpected Usage");
      }
    }

    return !!session && !!session.token;
  })();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!available) return <Redirect to={{ pathname: denyRedirectTo, state: { from: props.location } }} />;

        if (render) {
          return render({ ...props });
        }

        return <Component {...props} />;
      }}
    />
  );
}