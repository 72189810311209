import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const VDR_ROOM_REPORT_LANG: {
  ALL_USERS_VERB: MvnCodeFE;
  ALL_ACTIONS_VERB: MvnCodeFE;
  ALL_TIME_VERB: MvnCodeFE;
  LAST_24_HOURS: MvnCodeFE;
  LAST_7_DAYS: MvnCodeFE;
  LAST_30_DAYS: MvnCodeFE;
  CUSTOM_TIME: MvnCodeFE;
  DOWNLOAD_ALL_TITLE: MvnCodeFE;
  DOWNLOAD_START: MvnCodeFE;
  DOWNLOAD_FAIL: MvnCodeFE;
  REPORT_PAGE_SUBTITLE: MvnCodeFE;
} = {
  ALL_USERS_VERB: {
    ko: "모든 사용자",
    en: "All Users",
  },
  ALL_ACTIONS_VERB: {
    ko: "모든 활동",
    en: "All Actions",
  },
  ALL_TIME_VERB: {
    ko: "전체 기간",
    en: "All Time",
  },
  LAST_24_HOURS: {
    ko: "24시간 전",
    en: "Last 24 Hours",
  },
  LAST_7_DAYS: {
    ko: "7일 전",
    en: "Last 7 Days",
  },
  LAST_30_DAYS: {
    ko: "30일 전",
    en: "Last 30 Days",
  },
  CUSTOM_TIME: {
    ko: "기간 설정",
    en: "Custom Time",
  },
  DOWNLOAD_ALL_TITLE: {
    ko: "전체 다운로드",
    en: "Download All",
  },
  DOWNLOAD_START: {
    ko: "다운로드가 시작되었습니다.",
    en: "Download has been started.",
  },
  DOWNLOAD_FAIL: {
    ko: "다운로드에 실패하였습니다.",
    en: "Failed to download.",
  },
  REPORT_PAGE_SUBTITLE: {
    ko: "룸 내에 사용자의 활동을 확인할 수 있습니다.",
    en: "Track all actions and changes in the room.",
  },
};

MvnCodeInitiate(VDR_ROOM_REPORT_LANG);
