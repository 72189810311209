import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const ECOA_VISIT_CODE: {
  ECOA_VISIT_NOT_FOUND: MvnCodeBE;
} = {
  ECOA_VISIT_NOT_FOUND: {
    status: 404,
    ko: "eCOA Visit 정보가 존재하지 않습니다.",
    en: "The requested eCOA visit does not exist.",
  },
};

MvnCodeInitiate(ECOA_VISIT_CODE);
