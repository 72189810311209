import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_LANG: {
  BUILDER_STUDY_CREATE_TITLE: MvnCodeFE;
  BUILDER_STUDY_CREATE_BUTTON_LABEL: MvnCodeFE;
  BUILDER_STUDY_NAME_LABEL: MvnCodeFE;
  BUILDER_STUDY_NAME_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_PROTOCOL_NO_LABEL: MvnCodeFE;
  BUILDER_STUDY_PROTOCOL_NO_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_SPONSOR_LABEL: MvnCodeFE;
  BUILDER_STUDY_SPONSOR_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_CRO_LABEL: MvnCodeFE;
  BUILDER_STUDY_CRO_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_ALLOCATION_LABEL: MvnCodeFE;
  BUILDER_STUDY_ALLOCATION_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_RANDOM_ALLOCATION_LABEL: MvnCodeFE;
  BUILDER_STUDY_RANDOM_ALLOCATION_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_TITLE_LANG_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_TITLE_LABEL: MvnCodeFE;
  BUILDER_STUDY_TITLE_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_PHASE_LABEL: MvnCodeFE;
  BUILDER_STUDY_PHASE_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_PHASE_DISPLAY_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_DEFAULT_LANG_LABEL: MvnCodeFE;
  BUILDER_STUDY_DEFAULT_LANG_PLACEHOLDER: MvnCodeFE;
  BUILDER_STUDY_ADD_SUCC: MvnCodeFE;
  BUILDER_STUDY_ADD_FAIL: MvnCodeFE;
  BUILDER_STUDY_EDIT_SUCC: MvnCodeFE;
  BUILDER_STUDY_EDIT_FAIL: MvnCodeFE;
  BUILDER_STUDY_TITLE_DESCRIPTION: MvnCodeFE;
} = {
  BUILDER_STUDY_CREATE_TITLE: {
    ko: "Create Study",
    en: "Create Study",
  },
  BUILDER_STUDY_CREATE_BUTTON_LABEL: {
    ko: "Create Study",
    en: "Create Study",
  },
  BUILDER_STUDY_NAME_LABEL: {
    ko: "Study Name",
    en: "Study Name",
  },
  BUILDER_STUDY_NAME_PLACEHOLDER: {
    ko: "Study Name을 입력해주세요.",
    en: "Please enter the Study name.",
  },
  BUILDER_STUDY_PROTOCOL_NO_LABEL: {
    ko: "Protocol No.",
    en: "Protocol No.",
  },
  BUILDER_STUDY_PROTOCOL_NO_PLACEHOLDER: {
    ko: "Protocol No.를 입력해주세요.",
    en: "Please enter the Protocol No.",
  },
  BUILDER_STUDY_SPONSOR_LABEL: {
    ko: "Sponsor",
    en: "Sponsor",
  },
  BUILDER_STUDY_SPONSOR_PLACEHOLDER: {
    ko: "Sponsor를 입력해주세요.",
    en: "Please enter the Sponsor.",
  },
  BUILDER_STUDY_CRO_LABEL: {
    ko: "CRO",
    en: "CRO",
  },
  BUILDER_STUDY_CRO_PLACEHOLDER: {
    ko: "CRO를 입력해주세요.",
    en: "Please enter the CRO.",
  },
  BUILDER_STUDY_ALLOCATION_LABEL: {
    ko: "Number of Subjects",
    en: "Number of Subjects",
  },
  BUILDER_STUDY_ALLOCATION_PLACEHOLDER: {
    ko: "Number of Subjects을 입력해주세요.",
    en: "Enter Number of Subjects.",
  },
  BUILDER_STUDY_RANDOM_ALLOCATION_LABEL: {
    ko: "Number of Randomization",
    en: "Number of Randomization",
  },
  BUILDER_STUDY_RANDOM_ALLOCATION_PLACEHOLDER: {
    ko: "Number of Randomization을 입력해주세요.",
    en: "Enter Number of Randomization.",
  },
  BUILDER_STUDY_TITLE_LANG_PLACEHOLDER: {
    ko: "Select Language",
    en: "Select Language",
  },
  BUILDER_STUDY_TITLE_LABEL: {
    ko: "Study Title",
    en: "Study Title",
  },
  BUILDER_STUDY_TITLE_PLACEHOLDER: {
    ko: "Study Title을 입력해 주세요.",
    en: "Please Enter the study Title.",
  },
  BUILDER_STUDY_PHASE_LABEL: {
    ko: "Study Phase",
    en: "Study Phase",
  },
  BUILDER_STUDY_PHASE_PLACEHOLDER: {
    ko: "Study Phase를 선택해주세요.",
    en: "Please select the Study Phase.",
  },
  BUILDER_STUDY_DEFAULT_LANG_LABEL: {
    ko: "Default Language",
    en: "Default Language",
  },
  BUILDER_STUDY_DEFAULT_LANG_PLACEHOLDER: {
    ko: "Default Language를 선택해주세요.",
    en: "Please select the Default Language.",
  },
  BUILDER_STUDY_PHASE_DISPLAY_PLACEHOLDER: {
    ko: "Study Phase를 입력해주세요.",
    en: "Please enter the Study Phase.",
  },
  BUILDER_STUDY_ADD_SUCC: {
    ko: "Study를 생성하였습니다.",
    en: "You have created a new study successfully.",
  },
  BUILDER_STUDY_ADD_FAIL: {
    ko: "Study 생성에 실패하였습니다. ({message})",
    en: "Failed to create a new study. ({message})",
  },
  BUILDER_STUDY_EDIT_SUCC: {
    ko: "Study를 수정하였습니다.",
    en: "You have edited study successfully.",
  },
  BUILDER_STUDY_EDIT_FAIL: {
    ko: "Study 수정에 실패하였습니다. ({message})",
    en: "Failed to edit study. ({message})",
  },
  BUILDER_STUDY_TITLE_DESCRIPTION: {
    ko: "Protocol No.를 선택하여 Study 생성할 수 있습니다.",
    en: "Select Protocol No. to start building a study.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_LANG);
