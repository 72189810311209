/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const COMMON_CODE: {
  UNHANDLED_EXCEPTION: MvnCodeBE;
  UNHANDLED_EXCEPTION_WITH_MSG: MvnCodeBE;
  PERMISSION_DENIED: MvnCodeBE;
  PARAMETER_MISSING: MvnCodeBE;
  PARAMETER_INVALID: MvnCodeBE;
  NOT_AUTHORIZED: MvnCodeBE;
} = {
  UNHANDLED_EXCEPTION: {
    status: 500,
    ko: "일시적으로 시스템 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.",
    en: "An error has occurred. Please try again later.",
  },
  UNHANDLED_EXCEPTION_WITH_MSG: {
    status: 500,
    ko: "오류가 발생했습니다. ({message})",
    en: "An error has occurred. ({message})",
  },
  PERMISSION_DENIED: {
    status: 403,
    ko: "해당 기능에 접근 권한이 없습니다. 관리자에게 문의해 주세요.",
    en: "Not authorized to access. Please contact your administrator.",
  },
  PARAMETER_MISSING: { status: 400, ko: "필수 요청값이 누락되었습니다. ({id})", en: "Required value is missing. ({id})" },
  PARAMETER_INVALID: {
    status: 400,
    ko: "요청값이 올바르지 않습니다. ({id}: {val})",
    en: "The specified value is not valid. ({id}: {val})",
  },
  NOT_AUTHORIZED: {
    status: 401,
    ko: "해당 작업을 수행할 수 없습니다. ({priv})",
    en: "You are not authorized to perform. ({priv})",
  },
};

MvnCodeInitiate(COMMON_CODE);
