import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_REPORT_INFO_CODE: {
  NOT_FOUND: MvnCodeBE;
  NOT_AVAILABLE: MvnCodeBE;
  ADD_INVALID_PRIV_NOT_SUBMITTED: MvnCodeBE;
  SYNC_INVALID_IDENTIFIER_MISSING: MvnCodeBE;
  SYNC_INVALID_CRF_SETUP: MvnCodeBE;
} = {
  NOT_FOUND: {
    status: 404,
    ko: "Study Report Info 정보가 존재하지 않습니다.",
    en: "Study report info entity does not exist.",
  },
  NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study Report Info({id}) 정보가 올바르지 않습니다.",
    en: "Requested study report info({id}) is not valid.",
  },
  ADD_INVALID_PRIV_NOT_SUBMITTED: {
    status: 400,
    ko: "이전 Study Report가 제출되지 않았습니다.",
    en: "No previous study reports have been submitted.",
  },
  SYNC_INVALID_IDENTIFIER_MISSING: {
    status: 400,
    ko: "Sync 기능이 지원되지 않는 Trigger를 통해 생성된 Study Report Info입니다.",
    en: "This is the Study Report Info created through a Trigger that does not support the Sync function.",
  },
  SYNC_INVALID_CRF_SETUP: {
    status: 400,
    ko: "CRF Set Up 정보가 올바르지 않아 해당 기능을 수행할 수 없습니다.",
    en: "The function cannot be performed because the CRF Set Up information is incorrect.",
  },
};

MvnCodeInitiate(STUDY_REPORT_INFO_CODE);
