import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_WORKSPACE_MEMBERS_CODE: {
  SAFETY_WORKSPACE_MEMBERS_INVITE_INVALID_MEMBER_EXIST_ALREADY: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_INVALID_EMAIL_LENGTH_EXCEED: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_MODIFY_INVALID_MEMBER_DUPLICATE: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_MODIFY_INVALID_MEMBER_NOT_EXIST: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_DELETE_INVALID_IS_OWNER: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_INVITE_NOT_FOUND: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_INVALID_SELF_ASSIGN: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_INVALID_PERMISSION_DENIED: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_INVALID_NOT_ORG_MEMBER: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_INVALID_ROLE_UNAVAILABLE: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_INVALID_PRIVILEGES: MvnCodeBE;
  SAFETY_WORKSPACE_MEMBERS_INVALID_ONGOING_WORKFLOW: MvnCodeBE;
} = {
  SAFETY_WORKSPACE_MEMBERS_INVITE_INVALID_MEMBER_EXIST_ALREADY: {
    status: 400,
    ko: "이미 추가된 멤버입니다. (memberIds: {memberIds})",
    en: "The member is invited already. (memberIds: {memberIds})",
  },
  SAFETY_WORKSPACE_MEMBERS_INVALID_EMAIL_LENGTH_EXCEED: {
    status: 400,
    ko: "Member email의 길이는 최대 {length}자 입니다.",
    en: "Member's email can be up to {length} characters long.",
  },
  SAFETY_WORKSPACE_MEMBERS_MODIFY_INVALID_MEMBER_DUPLICATE: {
    status: 400,
    ko: "요청 본문에 중복된 member가 존재합니다.",
    en: "Duplicated member exist in request.",
  },
  SAFETY_WORKSPACE_MEMBERS_MODIFY_INVALID_MEMBER_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 member는 수정할 수 없습니다.",
    en: "Not existed member can not be modified.",
  },
  SAFETY_WORKSPACE_MEMBERS_DELETE_INVALID_IS_OWNER: {
    status: 400,
    ko: "Owner role를 가지고 있는 멤버는 추방할 수 없습니다. (memberId: {memberId})",
    en: "Member with owner role can not be deleted. (memberId: {memberId})",
  },
  SAFETY_WORKSPACE_MEMBERS_INVITE_NOT_FOUND: {
    status: 404,
    ko: "초대 정보가 없습니다.",
    en: "There is no invitation information.",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_INVALID_SELF_ASSIGN: {
    status: 400,
    ko: "자기 자신에게 owner 권한을 넘겨줄 수 없습니다.",
    en: "You cannot transfer ownership to yourself.",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_INVALID_PERMISSION_DENIED: {
    status: 403,
    ko: "Owner만 owner 권한을 넘겨줄 수 있습니다.",
    en: "Only onwer can transfer ownership.",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_INVALID_NOT_ORG_MEMBER: {
    status: 400,
    ko: "Owner 권한은 동일한 organization에 속한 멤버에게만 권한을 넘겨줄 수 있습니다.",
    en: "Ownership can transfer same organization member.",
  },
  SAFETY_WORKSPACE_MEMBERS_TRANSFER_OWNERSHIP_INVALID_ROLE_UNAVAILABLE: {
    status: 400,
    ko: "제거되었거나 비활성화 처리된 role입니다.",
    en: "The role has been removed or disabled.",
  },
  SAFETY_WORKSPACE_MEMBERS_INVALID_PRIVILEGES: {
    status: 400,
    ko: "유효하지 않은 권한을 가진 멤버에 대한 요청입니다.",
    en: "Request for a member with invalid privileges.",
  },
  SAFETY_WORKSPACE_MEMBERS_INVALID_ONGOING_WORKFLOW: {
    status: 400,
    ko: "진행 중인 workflow가 존재하는 멤버의 경우 추방할 수 없습니다.",
    en: "Members with ongoing workflows cannot be deleted.",
  },
};

MvnCodeInitiate(SAFETY_WORKSPACE_MEMBERS_CODE);
