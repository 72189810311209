import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_RANDOMIZATION_LANG: {
  RANDOMIZATION_METHOD_LABEL: MvnCodeFE;
  RANDOMIZATION_METHOD_BLOCK_UPLOAD: MvnCodeFE;
  RANDOMIZATION_METHOD_BLOCK_GENERATE: MvnCodeFE;
  RANDOMIZATION_ISOLATE_RANGE_LABEL: MvnCodeFE;
  RANDOMIZATION_ISOLATE_RANGE_ALL: MvnCodeFE;
  RANDOMIZATION_ISOLATE_RANGE_SITE: MvnCodeFE;
  RANDOMIZATION_CODE_FORMAT_LABEL: MvnCodeFE;
  RANDOMIZATION_CODE_FORMAT_PLACEHOLDER: MvnCodeFE;
  RANDOMIZATION_RANDOM_GROUP_LABEL: MvnCodeFE;
  RANDOMIZATION_STRATIFICATION_LABEL: MvnCodeFE;
  RANDOMIZATION_DOWNLOAD_SUCCESS: MvnCodeFE;
  RANDOMIZATION_DOWNLOAD_FAIL: MvnCodeFE;
  RANDOMIZATION_DOWNLOAD_BUTTON: MvnCodeFE;
  RANDOMIZATION_RANDOM_CODE_DOWNLOAD_BUTTON: MvnCodeFE;
  RANDOMIZATION_UPLOAD_DIALOG_HEADER: MvnCodeFE;
  RANDOMIZATION_UPLOAD_LABEL: MvnCodeFE;
  RANDOMIZATION_UPLOAD_BUTTON: MvnCodeFE;
  RANDOMIZATION_UPLOAD_HISTORY_BUTTON: MvnCodeFE;
  RANDOMIZATION_NAME_LABEL: MvnCodeFE;
  RANDOMIZATION_SAVE_SUCCESS: MvnCodeFE;
  RANDOMIZATION_SAVE_FAIL: MvnCodeFE;
  RANDOMIZATION_INITIALIZE_SUCCESS: MvnCodeFE;
  RANDOMIZATION_INITIALIZE_FAIL: MvnCodeFE;
  RANDOMIZATION_INITIALIZE_BUTTON: MvnCodeFE;
  RANDOMIZATION_INITIALIZE_POPUP_TITLE: MvnCodeFE;
  RANDOMIZATION_PAUSE_SUCCESS: MvnCodeFE;
  RANDOMIZATION_PAUSE_FAIL: MvnCodeFE;
  RANDOMIZATION_PAUSE_BUTTON: MvnCodeFE;
  RANDOMIZATION_PAUSE_POPUP_TITLE: MvnCodeFE;
  RANDOMIZATION_RESUME_SUCCESS: MvnCodeFE;
  RANDOMIZATION_RESUME_FAIL: MvnCodeFE;
  RANDOMIZATION_RESUME_BUTTON: MvnCodeFE;
  RANDOMIZATION_RESUME_POPUP_TITLE: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_DONE: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_STUDY_DISABLED: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_STUDY_LOCKED: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_RANDOMIZATION_NOT_SETUP: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_SITE_NOT_EXIST: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_FILE_NOT_EXIST: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_SITE_CODE_MISSING: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_SITE_CODE_INVALID: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_SITE_CODE_ONLY_POSSIBLE_ALL: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_SITE_CODE_ONLY_POSSIBLE_SITE: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_STRATIFICATION_MISSING: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_SEQ_MISSING: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_SITE_STRATIFICATION_SEQ_DUP: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_SEQ_INVALID: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_GROUP_MISSING: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_GROUP_TOO_LONG: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_CODE_MISSING: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_CODE_TOO_LONG: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_CODE_DUP: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_DESC_TOO_LONG: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_STRATIFICATION_INVALID: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_GROUP_INVALID: MvnCodeFE;
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_CODE_ONLY_ADD_AFTER_ASSIGNED: MvnCodeFE;
} = {
  RANDOMIZATION_METHOD_LABEL: {
    ko: "Method",
    en: "Method",
  },
  RANDOMIZATION_METHOD_BLOCK_UPLOAD: {
    ko: "Stratified Block - Upload",
    en: "Stratified Block - Upload",
  },
  RANDOMIZATION_METHOD_BLOCK_GENERATE: {
    ko: "Stratified Block - System Generation",
    en: "Stratified Block - System Generation",
  },
  RANDOMIZATION_ISOLATE_RANGE_LABEL: {
    ko: "Isolation Range",
    en: "Isolation Range",
  },
  RANDOMIZATION_ISOLATE_RANGE_ALL: {
    ko: "All",
    en: "All",
  },
  RANDOMIZATION_ISOLATE_RANGE_SITE: {
    ko: "Site",
    en: "Site",
  },
  RANDOMIZATION_CODE_FORMAT_LABEL: {
    ko: "Random Code Format",
    en: "Random Code Format",
  },
  RANDOMIZATION_CODE_FORMAT_PLACEHOLDER: {
    ko: "Random Code Format을 입력해주세요.",
    en: "Please Enter a Random Code Format.",
  },
  RANDOMIZATION_RANDOM_GROUP_LABEL: {
    ko: "Random Groups",
    en: "Random Groups",
  },
  RANDOMIZATION_STRATIFICATION_LABEL: {
    ko: "Stratifications",
    en: "Stratifications",
  },
  RANDOMIZATION_DOWNLOAD_SUCCESS: {
    ko: "다운로드가 시작되었습니다.",
    en: "Download has started.",
  },
  RANDOMIZATION_DOWNLOAD_FAIL: {
    ko: "다운로드에 실패하였습니다. 다시 시도해주세요. ({message})",
    en: "Failed to download. Please try again. ({message})",
  },
  RANDOMIZATION_DOWNLOAD_BUTTON: {
    ko: "Download Sample",
    en: "Download Sample",
  },
  RANDOMIZATION_RANDOM_CODE_DOWNLOAD_BUTTON: {
    ko: "Download",
    en: "Download",
  },
  RANDOMIZATION_UPLOAD_DIALOG_HEADER: {
    ko: "Upload Randomization",
    en: "Upload Randomization",
  },
  RANDOMIZATION_UPLOAD_LABEL: {
    ko: "Randomization File(.xlsx)",
    en: "Randomization File(.xlsx)",
  },
  RANDOMIZATION_UPLOAD_BUTTON: {
    ko: "Upload Randomization File",
    en: "Upload Randomization File",
  },
  RANDOMIZATION_UPLOAD_HISTORY_BUTTON: {
    ko: "Upload History",
    en: "Upload History",
  },
  RANDOMIZATION_NAME_LABEL: {
    ko: "Name",
    en: "Name",
  },
  RANDOMIZATION_SAVE_SUCCESS: {
    ko: "Randomization 등록에 성공하였습니다.",
    en: "Randomization saved successfully.",
  },
  RANDOMIZATION_SAVE_FAIL: {
    ko: "Randomization 저장에 실패하였습니다. 다시 시도해주세요. ({message})",
    en: "Failed to save. Please try again. ({message})",
  },
  RANDOMIZATION_INITIALIZE_SUCCESS: {
    ko: "Randomization 초기화에 성공하였습니다.",
    en: "Randomization initialized successfully.",
  },
  RANDOMIZATION_INITIALIZE_FAIL: {
    ko: "Randomization 초기화에 실패하였습니다. 다시 시도해주세요. ({message})",
    en: "Failed to initialize. Please try again. ({message})",
  },
  RANDOMIZATION_INITIALIZE_BUTTON: {
    ko: "Initialize Randomization",
    en: "Initialize Randomization",
  },
  RANDOMIZATION_INITIALIZE_POPUP_TITLE: {
    ko: "Randomization 초기화를 위해 비밀번호를 입력해주세요",
    en: "Enter your password to initialize Randomization.",
  },
  RANDOMIZATION_PAUSE_SUCCESS: {
    ko: "Randomization 정지에 성공하였습니다.",
    en: "Randomization paused successfully.",
  },
  RANDOMIZATION_PAUSE_FAIL: {
    ko: "Randomization 정지에 실패하였습니다. 다시 시도해주세요. ({message})",
    en: "Failed to pause. Please try again. ({message})",
  },
  RANDOMIZATION_PAUSE_BUTTON: {
    ko: "Pause Randomization",
    en: "Pause Randomization",
  },
  RANDOMIZATION_PAUSE_POPUP_TITLE: {
    ko: "Randomization 정지를 위해 비밀번호를 입력해주세요",
    en: "Enter your password to pause Randomization",
  },
  RANDOMIZATION_RESUME_SUCCESS: {
    ko: "Randomization 재개에 성공하였습니다.",
    en: "Randomization resumes successfully.",
  },
  RANDOMIZATION_RESUME_FAIL: {
    ko: "Randomization 재개에 실패하였습니다. 다시 시도해주세요. ({message})",
    en: "Failed to resume. Please try again. ({message})",
  },
  RANDOMIZATION_RESUME_BUTTON: {
    ko: "Resume Randomization",
    en: "Resume Randomization",
  },
  RANDOMIZATION_RESUME_POPUP_TITLE: {
    ko: "Randomization 재개를 위해 비밀번호를 입력해주세요",
    en: "Enter your password to resume Randomization",
  },
  RANDOMIZATION_UPLOAD_RESULT_DONE: {
    ko: "작업완료",
    en: "Completed",
  },
  RANDOMIZATION_UPLOAD_RESULT_STUDY_DISABLED: {
    ko: "Study 비활성화",
    en: "Study Disabled",
  },
  RANDOMIZATION_UPLOAD_RESULT_STUDY_LOCKED: {
    ko: "Study Locked",
    en: "Study Locked",
  },
  RANDOMIZATION_UPLOAD_RESULT_RANDOMIZATION_NOT_SETUP: {
    ko: "Randomization 미설정",
    en: "No Randomization",
  },
  RANDOMIZATION_UPLOAD_RESULT_SITE_NOT_EXIST: {
    ko: "Site 미존재",
    en: "Site does not exist",
  },
  RANDOMIZATION_UPLOAD_RESULT_FILE_NOT_EXIST: {
    ko: "File 미존재",
    en: "File does not exist",
  },
  RANDOMIZATION_UPLOAD_RESULT_SITE_CODE_MISSING: {
    ko: "Site Code 누락",
    en: "Missing SiteCode",
  },
  RANDOMIZATION_UPLOAD_RESULT_SITE_CODE_INVALID: {
    ko: "Site Code 오입력",
    en: "Invalid SiteCode",
  },
  RANDOMIZATION_UPLOAD_RESULT_SITE_CODE_ONLY_POSSIBLE_ALL: {
    ko: "Site Code 오입력 (ALL로 지정됨)",
    en: "Invalid Site Code (ALL)",
  },
  RANDOMIZATION_UPLOAD_RESULT_SITE_CODE_ONLY_POSSIBLE_SITE: {
    ko: "Site Code 오입력 (Site로 지정됨)",
    en: "Invalid Site Code (Site)",
  },
  RANDOMIZATION_UPLOAD_RESULT_STRATIFICATION_MISSING: {
    ko: "Stratification 정보 누락",
    en: "Missing Stratification",
  },
  RANDOMIZATION_UPLOAD_RESULT_SEQ_MISSING: {
    ko: "Seq 누락",
    en: "Missing Seq",
  },
  RANDOMIZATION_UPLOAD_RESULT_SITE_STRATIFICATION_SEQ_DUP: {
    ko: "Seq 중복",
    en: "Duplicated Seq",
  },
  RANDOMIZATION_UPLOAD_RESULT_SEQ_INVALID: {
    ko: "Seq 오입력",
    en: "Invalid Seq",
  },
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_GROUP_MISSING: {
    ko: "Random Group 누락",
    en: "Missing Random Group",
  },
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_GROUP_TOO_LONG: {
    ko: "Random Group 최대길이 초과",
    en: "Exceeded maximum length of Random Group",
  },
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_CODE_MISSING: {
    ko: "Random Code 누락",
    en: "Missing Random Code",
  },
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_CODE_TOO_LONG: {
    ko: "Random Code 최대길이 초과",
    en: "Exceeded maximum length of Random Code",
  },
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_CODE_DUP: {
    ko: "Random Code 중복",
    en: "Duplicated Random Code",
  },
  RANDOMIZATION_UPLOAD_RESULT_DESC_TOO_LONG: {
    ko: "Description 최대길이 초과",
    en: "Exceeded maximum length of Description",
  },
  RANDOMIZATION_UPLOAD_RESULT_STRATIFICATION_INVALID: {
    ko: "Stratification 오입력",
    en: "Invalid Stratification",
  },
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_GROUP_INVALID: {
    ko: "Random Group 오입력",
    en: "Invalid Random Group",
  },
  RANDOMIZATION_UPLOAD_RESULT_RANDOM_CODE_ONLY_ADD_AFTER_ASSIGNED: {
    ko: "Random Code 수정 불가 (Random Code 할당 시작 이후에는 추가만 가능)",
    en: "Random code cannot be modified (You may only add after Random code assigned)",
  },
};

MvnCodeInitiate(STUDY_RANDOMIZATION_LANG);
