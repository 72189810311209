import { classNames } from "@maven-helper/functions/Misc.function";
import React, { lazy, Suspense, useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useStore } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { setDefaultTransformResponse } from "@coralblack/flax";
import { loadTheme } from "@fluentui/react";
import { SSO } from "@pages/auth/layout/SSO";
import { PasswordLoading } from "@pages/auth/password/PasswordLoading";
import { ErrorLayout } from "@pages/system/layout/Error";
import { RootState } from "maven-lib/dist/states";
import { MyInfoGuard } from "./MyInfoGuard";
import pkg from "../package.json";
import { dialogIfTokenDestroyedByNewToken } from "@maven-surface/components/common/auth/TokenInvalidDialog";
import { AuthRoute } from "@maven-surface/components/common/AuthRoute";
import { MsaAuthRoute } from "@maven-surface/components/common/MsaAuthRoute";

import "@assets/style/fonts.scss";
import "@maven-surface/assets/style/App.scss";
import "@coralblack/flax/dist/styles/index.css";
import "cropperjs/dist/cropper.css";

loadTheme({
  defaultFontStyle: { fontFamily: "'Nunito', 'Noto Sans KR', sans-serif", fontWeight: "400" },
  fonts: {
    xSmall: {
      fontSize: "0.69rem",
    },
    small: {
      fontSize: "0.75rem",
    },
    medium: {
      fontSize: "0.82rem",
    },
    large: {
      fontSize: "0.91rem",
    },
    xLarge: {
      fontSize: "1rem",
    },
  },
});

if (process.env.REACT_APP_STAGE !== "local") {
  datadogRum.init({
    applicationId: "398bcb4e-d268-401d-9aa2-e3b2bc4f5a0f",
    clientToken: "pube711c05809805ceb1bcbf855b7a497e7",
    site: "datadoghq.com",
    service: "com.mavenclinical.cdms",
    env: process.env.REACT_APP_STAGE?.toLowerCase() || "UNKNOWN",
    version: pkg.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}

const AuthLayout = lazy(() => import("@pages/auth/layout/Auth"));
const HomeLayout = lazy(() => import("@layouts/home/Index"));
const StudyLayout = lazy(() => import("@layouts/study/Index"));
const PasswordLayout = lazy(() => import("@pages/auth/password/layout/Password"));

export const USER_CUSTOM_CONFIRMATION_LOCATION = "user_custom_confirmation_location";

function App() {
  const session = useStore<RootState>().getState().session;
  const appBadge = process.env.REACT_APP_BADGE;
  const appBadgeDisabled = process.env.REACT_APP_BADGE_DISABLE;

  useEffect(() => {
    document.body.classList.add("ready");

    setTimeout(() => {
      document.getElementById("spinner")?.remove();
    }, 1500);
  });

  setDefaultTransformResponse([
    function (data) {
      const o = JSON.parse(data);

      dialogIfTokenDestroyedByNewToken(o);

      return o;
    },
  ]);

  const demo = ((v) => (["local", "develop", "demo"].includes(String(process.env.REACT_APP_STAGE)) && v?.length < 30 ? v : null))(
    String(String(window.location.search || "").split("?")[1] || "")
      .split("&")
      .filter((x) => x.startsWith("demo-msg="))
      .map((x) => x.split("=")[1])[0]
  );

  const getCustomConfirmation = (message: string, callback: (ok: boolean) => void) => {
    const allowTransition = window.confirm(message);

    if (allowTransition) {
      callback(allowTransition);
    } else {
      const location = JSON.parse(sessionStorage.getItem(USER_CUSTOM_CONFIRMATION_LOCATION) ?? "");

      if (
        location &&
        (location.pathname !== window.location.pathname ||
          location.search !== window.location.search ||
          location.hash !== window.location.hash)
      ) {
        const search = ((search) => (search ? `?${search}` : ""))(String(location.search || "").slice(1));
        const hash = ((hash) => (hash ? `#${hash}` : ""))(String(location.hash || "").slice(1));

        window.history.pushState(null, "", `${location.pathname}${search}${hash}`);
      }
    }
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 30000,
        refetchOnWindowFocus: false,
        retry: () => false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter getUserConfirmation={getCustomConfirmation}>
        <div className={classNames("app-wrapper", !!demo && "--demo")}>
          <div className="app-demo-message">{demo}</div>
          {!appBadgeDisabled && appBadge && (
            <div className={classNames("notranslate app-sidebar-ribbon", `--${String(appBadge).toLowerCase()}`)}>{appBadge}</div>
          )}
          <Suspense
            fallback={
              <div className="suspense-loading">
                <div className="loading">
                  <div className="loader" />
                </div>
              </div>
            }
          >
            {process.env.REACT_APP_FEATURE_SSO_ENABLED !== "true" && (
              <Switch>
                <Route exact={true} path="/404" component={ErrorLayout} />
                <Route path="/auth/password/loading" render={() => <PasswordLoading />} />
                <AuthRoute session={session} authorizedOnly={false} denyRedirectTo="/" exact={true} path="/auth" component={AuthLayout} />
                <AuthRoute session={session} authorizedOnly={false} denyRedirectTo="/" path="/auth/password" component={PasswordLayout} />
                <AuthRoute session={session} path="/s/:studyId" component={StudyLayout} />
                <AuthRoute session={session} path="/" component={HomeLayout} />
                <Redirect from="*" to="/404" />
              </Switch>
            )}
            {process.env.REACT_APP_FEATURE_SSO_ENABLED === "true" && (
              <Switch>
                <Route exact={true} path="/404" component={ErrorLayout} />
                <Route exact={true} path="/auth" render={() => <SSO session={session} />} />
                <MsaAuthRoute
                  authStrategy={"AUTHORIZED_ONLY"}
                  session={session}
                  path="/s/:studyId"
                  render={() => <MyInfoGuard component={StudyLayout} />}
                />
                <MsaAuthRoute
                  authStrategy={"AUTHORIZED_ONLY"}
                  session={session}
                  path="/"
                  render={() => <MyInfoGuard component={HomeLayout} />}
                />
                <Redirect from="*" to="/404" />
              </Switch>
            )}
          </Suspense>
        </div>
      </BrowserRouter>
      {process.env.REACT_APP_STAGE === "local" && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
}

export default App;
