import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const AUTH_TOKEN_CODE: {
  INITIAL_PASSWORD_NOT_SET: MvnCodeBE;
  INITIAL_PASSWORD_EMAIL_VERIFY_NOT_SET: MvnCodeBE;
  AUTH_TOKEN_EXPIRED: MvnCodeBE;
  AUTH_TOKEN_ERROR: MvnCodeBE;
  AUTH_TOKEN_NOT_ACTIVE: MvnCodeBE;
  PASSWORD_CHANGE_CYCLE_EXCEED: MvnCodeBE;
  PASSWORD_INVALID: MvnCodeBE;
  PREVIOUS_AUTH_TOKEN_ALIVE: MvnCodeBE;
  VERIFY_CODE_LOCK: MvnCodeBE;
  USER_EXPIRED: MvnCodeBE;
  USER_LOCKED_BY_INVALID_PASSWORD: MvnCodeBE;
  USER_ID_NOT_MATCHED: MvnCodeBE;
  USER_NOT_VALID: MvnCodeBE;
  USER_DISABLED: MvnCodeBE;
  USER_DESTROYED: MvnCodeBE;
  VERIFICATION_CODE_EXCEED: MvnCodeBE;
  USER_TYPE_INVALID: MvnCodeBE;
  USER_TYPE_INVALID_PASSWORD: MvnCodeBE;
} = {
  INITIAL_PASSWORD_NOT_SET: {
    status: 400,
    ko: "초기 비밀번호가 설정되지 않았습니다. 비밀번호를 설정해주십시오.",
    en: "No initial password has been set. Please set your password.",
  },
  INITIAL_PASSWORD_EMAIL_VERIFY_NOT_SET: {
    status: 400,
    ko: "초기 비밀번호가 설정되지 않았습니다. 비밀번호를 설정해주십시오.",
    en: "No initial password has been set. Please set your password.",
  },
  AUTH_TOKEN_EXPIRED: {
    code: "CREDENTIAL-INVALID",
    status: 400,
    ko: "로그인 세션이 만료되었습니다.",
    en: "Your login session has expired.",
  },
  AUTH_TOKEN_ERROR: {
    code: "CREDENTIAL-INVALID",
    status: 400,
    ko: "로그인 혹은 세션 정보가 유효하지 않습니다",
    en: "Invalid login or session information.",
  },
  AUTH_TOKEN_NOT_ACTIVE: {
    code: "CREDENTIAL-INVALID",
    status: 400,
    ko: "토큰이 비활성화 상태입니다.",
    en: "Token inactive.",
  },
  PASSWORD_CHANGE_CYCLE_EXCEED: {
    code: "PASSWORD-CHANGE-CYCLE-EXCEED",
    status: 400,
    ko: "비밀번호 변경 이후 ({maxPasswordChangeCycle})일이 지났습니다. 비밀번호를 재설정해주십시오.",
    en: "({maxPasswordChangeCycle}) days have passed since last password change. Please reset your password.",
  },
  PASSWORD_INVALID: {
    code: "INVALID-PASSWORD",
    status: 400,
    ko: "이메일 또는 비밀번호가 유효하지 않습니다 ({failureStatus}).",
    en: "The email or password is invalid ({failureStatus}).",
  },
  PREVIOUS_AUTH_TOKEN_ALIVE: {
    status: 400,
    ko: "해당 계정으로 이미 로그인 한 사용자가 있습니다.",
    en: "This Account is already logged in.",
  },
  VERIFY_CODE_LOCK: {
    status: 400,
    ko: "이메일 인증이 가능한 최대 시도 횟수를 초과하였습니다.",
    en: "You have exceeded the number of attempts.",
  },
  USER_EXPIRED: {
    code: "USER-EXPIRED",
    status: 400,
    ko: "계정 사용기간이 만료되었습니다. 관리자에게 확인 바랍니다.",
    en: "Your account has expired. Please contact system administrator.",
  },
  USER_LOCKED_BY_INVALID_PASSWORD: {
    code: "USER-LOCKED",
    status: 400,
    ko: "비밀번호 최대 오류 횟수를 초과하였습니다. 비밀번호를 초기화 해주세요.",
    en: "Exceed Max Limit of Password validation. Please reset your password.",
  },
  USER_ID_NOT_MATCHED: {
    code: "USER-NOT-MATCHED",
    status: 400,
    ko: "계정 정보가 유효하지 않습니다.",
    en: "The email or password is invalid.",
  },
  USER_NOT_VALID: {
    code: "USER-INVALID",
    status: 400,
    ko: "계정 정보가 유효하지 않습니다.",
    en: "The email or password is invalid.",
  },
  USER_DISABLED: {
    code: "USER-DISABLED",
    status: 409,
    ko: "계정 정보가 유효하지 않습니다. 관리자에게 확인 바랍니다. (CODE: DI-01)",
    en: "Your account is not valid. Please contact system administrator. (CODE: DI-01)",
  },
  USER_DESTROYED: {
    status: 409,
    ko: "계정 정보가 유효하지 않습니다. 관리자에게 확인 바랍니다. (CODE: DE-02)",
    en: "Your account is not valid. Please contact system administrator. (CODE: DE-02)",
  },
  VERIFICATION_CODE_EXCEED: {
    status: 400,
    ko: "만료된 코드입니다. 다시 요청하십시오.",
    en: "Verficiation code expired. Please try again.",
  },
  USER_TYPE_INVALID: {
    status: 401,
    ko: "로그인 할 수 없는 사용자 유형입니다.",
    en: "The account is not allowed to sign in due to the user type.",
  },
  USER_TYPE_INVALID_PASSWORD: {
    status: 401,
    ko: "패스워드 재설정이 불가한 사용자 유형입니다.",
    en: "Password reset is not allowed for this user type.",
  },
};

MvnCodeInitiate(AUTH_TOKEN_CODE);
