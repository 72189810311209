import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_LAB_NORMAL_RANGE_CODE: {
  NOTFOUND: MvnCodeBE;
  INVALID_EFFECTIVE_DATE: MvnCodeBE;
  INVALID_AGE: MvnCodeBE;
  DUPLICATED_LAB_NORMAL_RANGE: MvnCodeBE;
  INVALID_RANGE: MvnCodeBE;
} = {
  NOTFOUND: {
    status: 404,
    ko: "정상 범위가 존재하지 않습니다.",
    en: "There is no normal range.",
  },
  INVALID_EFFECTIVE_DATE: {
    status: 400,
    ko: "종료 날짜는 시작 날짜 이후여야 합니다.",
    en: "End Date must be later than Start Date.",
  },
  INVALID_AGE: {
    status: 400,
    ko: "상한 연령 제한은 하한 연령 제한보다 커야 합니다.",
    en: "Upper age limit must be greater than lower age limit.",
  },
  DUPLICATED_LAB_NORMAL_RANGE: {
    status: 400,
    ko: "정상 범위 [{range}]에 해당하는 기준이 이미 존재합니다.",
    en: "The criteria for the lab normal range [{range}] already exists.",
  },
  INVALID_RANGE: {
    status: 400,
    ko: "정상 범위 상한 수치가 정상 범위 하한 수치보다 커야 합니다.",
    en: "Upper Limit of the normal range must be greater than Lower limit of the normal range.",
  },
};

MvnCodeInitiate(STUDY_LAB_NORMAL_RANGE_CODE);
