import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const AUTH_USER_CI_CODE: {
  USER_CI_DUPLICATED: MvnCodeBE;
} = {
  USER_CI_DUPLICATED: {
    status: 409,
    ko: "이미 등록된 사용자 정보입니다. 안내페이지에 기재된 문의처로 연락주세요.",
    en: "The user information is already registered. Please contact the number on the information page.",
  },
};

MvnCodeInitiate(AUTH_USER_CI_CODE);
