import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_IP_AUTO_CONDITION: {
  STUDY_IP_AUTO_CONDITION_NOT_FOUND: MvnCodeBE;
  STUDY_IP_AUTO_CONDITION_NOT_AVAILABLE: MvnCodeBE;
  STUDY_IP_AUTO_CONDITION_UNEXPECTED: MvnCodeBE;
} = {
  STUDY_IP_AUTO_CONDITION_NOT_FOUND: {
    status: 404,
    ko: "Study IP 자동요청 정보가 존재하지 않습니다.",
    en: "Study IP auto request entity does not exist.",
  },
  STUDY_IP_AUTO_CONDITION_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study IP 자동요청 정보가 올바르지 않습니다.",
    en: "Requested study IP auto request is not valid.",
  },
  STUDY_IP_AUTO_CONDITION_UNEXPECTED: {
    status: 500,
    ko: "요청하신 Study IP 자동요청 정보가 올바르지 않습니다.",
    en: "Requested study IP auto request is not valid.",
  },
};

MvnCodeInitiate(STUDY_IP_AUTO_CONDITION);
