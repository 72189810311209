import { useState } from "react";
import { LoLang, PRIVILEGE } from "maven-lib/dist/consts";
import { StudyUserRolePriv, StudyUserRoles } from "maven-lib/dist/dto/study";
import { UserRoles } from "maven-lib/dist/dto/user";
import { getBrowserDefaultLanguage, LOCALE_LANG_KEY } from "maven-lib/dist/states/reducers/session";
import { hasOwnProperty, isAuthorized } from "maven-lib/dist/utils/misc";

type TabLocation =
  | "home/study"
  | "home/study/closed"
  | "home/user"
  | "home/role"
  | "home/faq"
  | "home/inquiry"
  | "home/training"
  | "study/dashboard"
  | "study/notice"
  | "study/pre-subject"
  | "study/subject"
  | "study/query"
  | "study/report"
  | "study/coding"
  | "study/crf/sdv"
  | "study/crf/freezing"
  | "study/crf/locking"
  | "study/crf/esign"
  | "study/blind"
  | "study/ip-delivery"
  | "study/ip-management"
  | "study/ip-accountability"
  | "study/dw/dataset"
  | "study/dw/blank-crf"
  | "study/dw/db-spec"
  | "study/dw/subject-crfs"
  | "study/dw/audit-trail"
  | "study/dw/data-reports"
  | "study/dw/comments"
  | "study/data-integration"
  | "study/data-export"
  | "study/digest"
  | "study/certificate"
  | "study/user"
  | "study/role"
  | "study/site"
  | "study/close/lock"
  | "study/close/terminate"
  | "study/uat"
  | "admin/study"
  | "admin/cs/inquiry"
  | "admin/settings"
  | "admin/settings/user"
  | "admin/settings/api-user"
  | "admin/settings/faq"
  | "admin/settings/e-training"
  | "admin/settings/site"
  | "admin/settings/sponsor"
  | "admin/settings/cro"
  | "admin/settings/role"
  | "admin/settings/study-role"
  | "builder/home/study"
  | "builder/study/home"
  | "builder/study/crf-payload"
  | "builder/study/crf-build/entry-screen"
  | "builder/study/randomization"
  | "builder/study/ip-management"

  // Docs
  | "docs/viewer"
  | "docs/viewer/folder"
  | "docs/viewer/document"
  | "docs/viewer/document/view"
  | "docs/viewer/document/add"
  | "docs/viewer/training-record"
  | "docs/sign"
  | "docs/composer"
  | "docs/composer/folder"
  | "docs/composer/proposal"
  | "docs/composer/proposal/view"
  | "docs/composer/proposal/add"
  | "docs/template"
  | "docs/template/add"
  | "docs/template/view"

  // Safety
  | "safety/dashboard"
  | "safety/companies"
  | "safety/management/crfs"
  | "safety/management/companies"
  | "safety/management/users"
  | "safety/company/dashboard"
  | "safety/company/cases"
  | "safety/company/submissions"
  | "safety/company/management/crfs"
  | "safety/company/settings"

  // Legacy
  | "validator/study"
  | "validator/study/dashboard"
  | "validator/study/entry-screen"
  | "validator/study/trigger"
  | "validator/study/rtsm"
  | "eol"
  | "my/login-histories";

const get = (
  location?: TabLocation,
  lang?: LoLang
): {
  icon: string;
  title: string;
  breadcrumbs: Array<string>;
  description: string | null;
} => {
  switch (location) {
    // CDMS Study

    case "home/study":
      return {
        icon: "send",
        title: "Study",
        breadcrumbs: ["Study"],
        description:
          lang === LoLang.EN
            ? "A list of studies can be checked. By selecting the study, you can enter the study detail page."
            : "현재 진행중인 스터디 목록을 확인할 수 있습니다. 스터디를 선택하면 상세페이지로 이동할 수 있습니다.",
      };

    case "home/study/closed":
      return {
        title: "Closed Study",
        icon: "send",
        breadcrumbs: ["Study", "Closed"],
        description:
          lang === LoLang.EN
            ? "A list of closed studies can be checked. Closed study data will be contained for a certain period of time and then will be deleted."
            : "종료된 스터디 목록을 확인할 수 있습니다. 종료된 스터디의 임상시험데이터는 일정 기간 보관 후 삭제됩니다.",
      };

    case "home/user":
      return {
        title: "User & Permissions",
        icon: "supervisor_account",
        breadcrumbs: ["User & Permissions"],
        description:
          lang === LoLang.EN
            ? "Check and manage each user's access information and privileges."
            : "사용자별 접속정보와 접근 권한을 확인하고 관리할 수 있습니다.",
      };

    case "home/role":
      return {
        title: "Role & Privileges",
        icon: "security",
        breadcrumbs: ["Role & Privileges"],
        description: lang === LoLang.EN ? "Check and manage each role's privileges." : "역할별 권한을 확인하고 관리할 수 있습니다.",
      };

    case "home/faq":
      return {
        title: "FAQ",
        icon: "question_answer",
        breadcrumbs: ["Faq"],
        description: lang === LoLang.EN ? "Check frequently asked questions." : "자주 묻는 질문을 확인 할 수 있습니다.",
      };

    case "home/inquiry":
      return {
        title: "Inquiry",
        icon: "chat",
        breadcrumbs: ["Inquiry"],
        description: lang === LoLang.EN ? "Check 1:1 questions and answers." : "1:1 질문과 답변을 확인 할 수 있습니다.",
      };

    case "home/training":
      return {
        title: "E-Training",
        icon: "school",
        breadcrumbs: ["E-Training"],
        description:
          lang === LoLang.EN ? "Take the E-training and receive the certificate." : "E-Training을 완수하고 인증서를 발급 받을 수 있습니다.",
      };

    case "study/dashboard":
      return {
        title: "Dashboard",
        icon: "equalizer",
        breadcrumbs: ["Study", "Dashboard"],
        description: null,
      };

    case "study/notice":
      return {
        title: "Notice",
        icon: "mic",
        breadcrumbs: ["Study", "Notice"],
        description: null,
      };

    case "study/pre-subject":
      return {
        title: "Pre Subjects",
        icon: "people_alt",
        breadcrumbs: ["Study", "Pre Subjects"],
        description:
          lang === LoLang.EN
            ? "Check and collect data from pre-registered subjects."
            : "등록된 임시 대상자를 확인하고 데이터를 수집할 수 있습니다.",
      };

    case "study/subject":
      return {
        title: "Subjects",
        icon: "people_alt",
        breadcrumbs: ["Study", "Subjects"],
        description:
          lang === LoLang.EN
            ? "Check and collect data from the registered subjects."
            : "등록된 대상자를 확인하고 데이터를 수집할 수 있습니다.",
      };

    case "study/query":
      return {
        title: "Queries",
        icon: "people_alt",
        breadcrumbs: ["Study", "Queries"],
        description: lang === LoLang.EN ? "Check issued query." : "발행된 쿼리를 확인할 수 있습니다.",
      };

    case "study/report":
      return {
        title: "Reports",
        icon: "people_alt",
        breadcrumbs: ["Study", "Reports"],
        description: lang === LoLang.EN ? "Manage the reported SAE/AE reports." : "등록된 SAE/AE 리포트를 관리할 수 있습니다.",
      };

    case "study/coding":
      return {
        title: "Coding",
        icon: "people_alt",
        breadcrumbs: ["Study", "Coding"],
        description:
          lang === LoLang.EN
            ? "Apply standard code to medical/surgical history, symptoms, and concomitant medications."
            : "병력, 증상, 약품 등에 표준코드를 할당할 수 있습니다.",
      };

    case "study/crf/sdv":
      return {
        title: "CRF SDV",
        icon: "people_alt",
        breadcrumbs: ["Study", "CRF SDV"],
        description:
          lang === LoLang.EN
            ? "Perform or check SDV by Registered Subject/Site/Visit/CRF."
            : "등록된 대상자/사이트/Visit/CRF 별 일괄 SDV 처리를 진행하거나 조회할 수 있습니다.",
      };

    case "study/crf/freezing":
      return {
        title: "CRF Freezing",
        icon: "people_alt",
        breadcrumbs: ["Study", "CRF Freezing"],
        description:
          lang === LoLang.EN
            ? "Perform or check Freezing by Registered Subject/Site/Visit/CRF."
            : "등록된 대상자/사이트/Visit/CRF 별 일괄 Freezing 처리를 진행하거나 조회할 수 있습니다.",
      };

    case "study/crf/locking":
      return {
        title: "CRF Locking",
        icon: "people_alt",
        breadcrumbs: ["Study", "CRF Locking"],
        description:
          lang === LoLang.EN
            ? "Perform or check Locking by Registered Subject/Site/Visit/CRF."
            : "등록된 대상자/사이트/Visit/CRF 별 일괄 Locking 처리를 진행하거나 조회할 수 있습니다.",
      };

    case "study/crf/esign":
      return {
        title: "CRF E-Sign",
        icon: "people_alt",
        breadcrumbs: ["Study", "CRF E-Sign"],
        description:
          lang === LoLang.EN
            ? "Perform or check E-sign by Registered Subject/Site/Visit/CRF."
            : "등록된 대상자/사이트/Visit/CRF 별 일괄 E-Sign을 진행하거나 조회할 수 있습니다.",
      };

    case "study/blind":
      return {
        title: "Blind",
        icon: "visibility",
        breadcrumbs: ["Study", "Blind"],
        description:
          lang === LoLang.EN
            ? "Inquire blind requests and approve unblind request."
            : "눈가림과 관련된 요청을 조회하거나 눈가림 해제 요청을 승인할 수 있습니다.",
      };

    case "study/ip-delivery":
      return {
        title: "IP Delivery",
        icon: "local_shipping",
        breadcrumbs: ["Study", "IP Delivery"],
        description:
          lang === LoLang.EN
            ? "Manage IP delivery status and export relevant documents."
            : "IP 배송 상태를 관리하고, 관련 문서를 출력할 수 있습니다.",
      };

    case "study/ip-management":
      return {
        title: "IP Management",
        icon: "assignment",
        breadcrumbs: ["Study", "IP Management"],
        description:
          lang === LoLang.EN
            ? "Manage IP status of IP assignment, dispense, return and export relevant documents."
            : "IP 배정, 불출, 반납 등 IP 상태를 관리하고, 관련 문서를 출력할 수 있습니다.",
      };

    case "study/ip-accountability":
      return {
        title: "IP Accountability",
        icon: "assignment",
        breadcrumbs: ["Study", "IP Accountability"],
        description:
          lang === LoLang.EN
            ? "Download IP accountability and inquire dispense/return/CRA signature relevant IP."
            : "IP 수불기록을 다운로드 하거나 불출/반납/CRA 서명 정보를 조회할 수 있습니다.",
      };

    case "study/dw/dataset":
      return {
        title: "Data Warehouse",
        icon: "bento",
        breadcrumbs: ["Study", "Data Warehouse", "Dataset"],
        description: lang === LoLang.EN ? "Download the selected subject's data." : "입력된 대상자의 데이터를 내려받을 수 있습니다.",
      };

    case "study/dw/blank-crf":
      return {
        title: "Data Warehouse",
        icon: "bento",
        breadcrumbs: ["Study", "Data Warehouse", "Blank CRFs"],
        description: lang === LoLang.EN ? "Download Blank CRFs." : "Blank CRFs를 내려받을 수 있습니다.",
      };

    case "study/dw/db-spec":
      return {
        title: "Data Warehouse",
        icon: "bento",
        breadcrumbs: ["Study", "Data Warehouse", "DB Specifications"],
        description: lang === LoLang.EN ? "Download DB Specifications." : "DB Specifications를 내려받을 수 있습니다.",
      };

    case "study/dw/subject-crfs":
      return {
        title: "Data Warehouse",
        icon: "bento",
        breadcrumbs: ["Study", "Data Warehouse", "Data CRFs"],
        description: lang === LoLang.EN ? "Download Data CRFs." : "Data CRFs를 내려받을 수 있습니다.",
      };

    case "study/dw/audit-trail":
      return {
        title: "Data Warehouse",
        icon: "bento",
        breadcrumbs: ["Study", "Data Warehouse", "Audit Trail"],
        description: lang === LoLang.EN ? "Download Audit Trail." : "Audit Trail을 내려받을 수 있습니다.",
      };

    case "study/dw/data-reports":
      return {
        title: "Data Warehouse",
        icon: "analytics",
        breadcrumbs: ["Study", "Data Warehouse", "Data Reports"],
        description:
          lang === LoLang.EN ? "Download the Report for Study Operation." : "스터디 운영에 필요한 Report를 내려받을 수 있습니다.",
      };

    case "study/dw/comments":
      return {
        title: "Data Warehouse",
        icon: "bento",
        breadcrumbs: ["Study", "Data Warehouse", "Comments"],
        description: lang === LoLang.EN ? "Download Comments." : "Comments를 내려받을 수 있습니다.",
      };

    case "study/data-integration":
      return {
        title: "Data Integration",
        icon: "widgets",
        breadcrumbs: ["Study", "Data Integration"],
        description: lang === LoLang.EN ? "Perform Data Integration." : "Data Integration을 수행할 수 있습니다.",
      };

    case "study/data-export":
      return {
        title: "Data Export",
        icon: "open_in_new",
        breadcrumbs: ["Study", "Data Export"],
        description: lang === LoLang.EN ? "Perform Data Export." : "Data Export를 수행할 수 있습니다.",
      };

    case "study/digest":
      return {
        title: "Digest",
        icon: "vpn_key",
        breadcrumbs: ["Study", "Digest"],
        description:
          lang === LoLang.EN
            ? "Monitor Audit Trail's Digest and check data integrity."
            : "Audit Trail에 대한 Digest를 확인하고 데이터 위변조 여부를 모니터링 할 수 있습니다.",
      };

    case "study/certificate":
      return {
        title: "Manage Certificate",
        icon: "school",
        breadcrumbs: ["Study", "Certificates"],
        description:
          lang === LoLang.EN
            ? "Check Study member's E-training certificate."
            : "임상시험 스터디 구성원의 E-Training 인증서 목록을 확인할 수 있습니다.",
      };

    case "study/site":
      return {
        title: "Manage Site",
        icon: "business",
        breadcrumbs: ["Study", "Sites"],
        description: lang === LoLang.EN ? "Check and monitor the site." : "임상시험 연구 실시기관을 확인하고 관리할 수 있습니다.",
      };

    case "study/close/lock":
      return {
        title: "DB Lock",
        icon: "power_settings_new",
        breadcrumbs: ["Study", "Close", "DB Lock"],
        description:
          lang === LoLang.EN
            ? "Perform DB Lock to disable any data updates including subject creation and data inputs."
            : "대상자 생성 및 데이터 입력을 포함한 모든 신규 데이터 입력을 할 수 없도록 DB Lock 처리를 할 수 있습니다.",
      };

    case "study/close/terminate":
      return {
        title: "Study Terminate",
        icon: "power_settings_new",
        breadcrumbs: ["Study", "Close", "Terminate"],
        description: lang === LoLang.EN ? "Close the Study." : "임상시험 스터디 진행을 종료할 수 있습니다.",
      };

    case "study/uat":
      return {
        title: "UAT",
        icon: "pivot_table_chart",
        breadcrumbs: ["Study", "UAT"],
        description:
          lang === LoLang.EN ? "Check and manage the UAT list for the Study." : "Study의 UAT 목록을 확인하고 관리할 수 있습니다.",
      };

    case "study/user":
      return {
        title: "Manage User",
        icon: "manage_accounts",
        breadcrumbs: ["Study", "Users"],
        description: lang === LoLang.EN ? "Check and manage the registered subjects." : "등록된 대상자를 확인하고 관리할 수 있습니다.",
      };

    case "study/role":
      return {
        title: "Manage Role",
        icon: "admin_panel_settings",
        breadcrumbs: ["Study", "Roles"],
        description: lang === LoLang.EN ? "Check and manage the privileges of the users." : "등록된 권한을 확인하고 관리할 수 있습니다.",
      };

    // CDMS Admin

    case "admin/study":
      return {
        icon: "wysiwyg",
        title: "Manage Study",
        breadcrumbs: ["Manage Study"],
        description: "등록된 스터디 목록을 확인하고 관리할 수 있습니다.",
      };

    case "admin/cs/inquiry":
      return {
        icon: "headset_mic",
        title: "CS",
        breadcrumbs: ["CS"],
        description: null,
      };

    case "admin/settings":
      return {
        icon: "settings",
        title: "System Settings",
        breadcrumbs: ["System Settings"],
        description: "Maven CDMS 시스템 환경설정을 확인하고 관리할 수 있습니다.",
      };

    case "admin/settings/user":
      return {
        icon: "settings",
        title: "Manage User",
        breadcrumbs: ["System Settings", "User"],
        description: "Maven CDMS에 등록된 사용자를 확인하고 관리할 수 있습니다.",
      };

    case "admin/settings/api-user":
      return {
        icon: "settings",
        title: "Manage API User",
        breadcrumbs: ["System Settings", "API User"],
        description: "Maven CDMS에 등록된 API 사용자를 확인하고 관리할 수 있습니다.",
      };

    case "admin/settings/faq":
      return {
        icon: "settings",
        title: "Manage FAQ",
        breadcrumbs: ["System Settings", "FAQ"],
        description: "Maven CDMS에 등록된 FAQ를 확인하고 관리할 수 있습니다.",
      };

    case "admin/settings/e-training":
      return {
        icon: "settings",
        title: "Manage E-Training",
        breadcrumbs: ["System Settings", "E-Training"],
        description: "Maven CDMS에 등록된 E-Training을 확인하고 관리할 수 있습니다.",
      };

    case "admin/settings/site":
      return {
        icon: "settings",
        title: "Manage Site",
        breadcrumbs: ["System Settings", "Site"],
        description: "Maven CDMS에 등록된 기관(Site)을 확인하고 관리할 수 있습니다.",
      };

    case "admin/settings/sponsor":
      return {
        icon: "settings",
        title: "Manage Sponsor",
        breadcrumbs: ["System Settings", "Sponsor"],
        description: "Maven CDMS에 등록된 스폰서 목록을 확인하고 관리할 수 있습니다.",
      };

    case "admin/settings/cro":
      return {
        icon: "settings",
        title: "Manage CRO",
        breadcrumbs: ["System Settings", "CRO"],
        description: "Maven CDMS에 등록된 CRO 목록을 확인하고 관리할 수 있습니다.",
      };

    case "admin/settings/role":
      return {
        icon: "settings",
        title: "Manage Role",
        breadcrumbs: ["System Settings", "Role"],
        description: "Maven CDMS에 등록된 Role 목록을 확인하고 관리할 수 있습니다.",
      };

    case "admin/settings/study-role":
      return {
        icon: "settings",
        title: "Manage Study Role",
        breadcrumbs: ["System Settings", "Study-Role"],
        description: "Maven CDMS에 등록된 Study Role 목록을 확인하고 관리할 수 있습니다.",
      };

    // Builder

    case "builder/home/study":
      return {
        icon: "send",
        title: "Maven Builder",
        breadcrumbs: ["Builder", "Study"],
        description: "Maven Builder에 등록된 Study를 확인하고 관리할 수 있습니다.",
      };

    case "builder/study/home":
      return {
        icon: "construction",
        title: "Maven Builder",
        breadcrumbs: ["Builder", "Study"],
        description: null,
      };

    case "builder/study/crf-payload":
      return {
        icon: "text_snippet",
        title: "CRF Payloads",
        breadcrumbs: ["Builder", "Study", "CRF Payload"],
        description: "스터디에 등록된 CRF Payload를 확인하고 관리할 수 있습니다.",
      };

    case "builder/study/crf-build/entry-screen":
      return {
        icon: null,
        title: null,
        breadcrumbs: ["Builder", "Study", "Entry Screen"],
        description: null,
      };

    case "builder/study/randomization":
      return {
        icon: "shuffle",
        title: "Manage Randomization",
        breadcrumbs: ["Builder", "Study", "Randomization"],
        description: "Maven CDMS에 등록된 Study의 Randomization 설정을 확인하고 관리할 수 있습니다.",
      };

    case "builder/study/ip-management":
      return {
        icon: "medication",
        title: "IP Management",
        breadcrumbs: ["Builder", "Study", "IP Management"],
        description: "IP Category, Drug, Code 등의 설정을 확인하고 관리할 수 있습니다.",
      };

    // Docs - Viewer

    case "docs/viewer":
      return {
        icon: "description",
        title: "Viewer",
        breadcrumbs: ["Viewer"],
        description: "Document Viewer",
      };

    case "docs/viewer/folder":
      return {
        icon: "folder_open",
        title: "Folders",
        breadcrumbs: ["Viewer", "Project"],
        description: null,
      };

    case "docs/viewer/document":
      return {
        icon: "description",
        title: "Documents",
        breadcrumbs: ["Viewer", "Project", "Folder", "Document"],
        description: null,
      };

    case "docs/viewer/document/view":
      return {
        icon: "description",
        title: "View Document",
        breadcrumbs: ["Viewer", "Project", "Folder", "Document"],
        description: null,
      };

    case "docs/viewer/document/add":
      return {
        icon: "add_circle_outline",
        title: "Add Document",
        breadcrumbs: ["Viewer", "Project", "Folder", "Document", "Add"],
        description: null,
      };

    case "docs/viewer/training-record":
      return {
        icon: "verified",
        title: "Training Records",
        breadcrumbs: ["Project", "Training Record"],
        description: null,
      };

    case "docs/sign":
      return {
        icon: "draw",
        title: "Document Sign",
        breadcrumbs: ["Sign"],
        description: null,
      };

    // Docs - Composer

    case "docs/composer":
      return {
        icon: "auto_awesome",
        title: "Composer",
        breadcrumbs: ["Composer"],
        description: "Document Composer",
      };

    case "docs/composer/folder":
      return {
        icon: "folder_open",
        title: "Folders",
        breadcrumbs: ["Composer", "Project"],
        description: null,
      };

    case "docs/composer/proposal":
      return {
        icon: "folder_special",
        title: "Proposals",
        breadcrumbs: ["Composer", "Project", "Folder"],
        description: null,
      };

    case "docs/composer/proposal/view":
      return {
        icon: "description",
        title: "View Proposal",
        breadcrumbs: ["Composer", "Project", "Folder", "Proposal"],
        description: null,
      };

    case "docs/composer/proposal/add":
      return {
        icon: "add_circle_outline",
        title: "Create Proposal",
        breadcrumbs: ["Composer", "Project", "Folder", "Proposal", "Add"],
        description: null,
      };

    // Docs - Template

    case "docs/template":
      return {
        icon: "dashboard_customize",
        title: "Templates",
        breadcrumbs: ["Template"],
        description: null,
      };

    case "docs/template/add":
      return {
        icon: "add_circle_outline",
        title: "Add Template",
        breadcrumbs: ["Template", "Add"],
        description: null,
      };

    case "docs/template/view":
      return {
        icon: "auto_awesome_motion",
        title: "Template",
        breadcrumbs: ["Template", "View"],
        description: null,
      };

    // Validator

    case "validator/study":
      return {
        icon: "send",
        title: "Study",
        breadcrumbs: ["Study"],
        description: "현재 진행중인 스터디 목록을 확인할 수 있습니다. 스터디를 선택하면 상세페이지로 이동할 수 있습니다.",
      };

    case "validator/study/dashboard":
      return {
        icon: "equalizer",
        title: "Dashboard",
        breadcrumbs: ["Study", "Dashboard"],
        description: null,
      };

    case "validator/study/entry-screen":
      return {
        icon: "description",
        title: "EntryScreen",
        breadcrumbs: ["Study", "EntryScreen"],
        description: null,
      };

    case "validator/study/trigger":
      return {
        icon: "description",
        title: "Trigger",
        breadcrumbs: ["Study", "Trigger"],
        description: null,
      };

    case "validator/study/rtsm":
      return {
        icon: "description",
        title: "Rtsm",
        breadcrumbs: ["Study", "Rtsm"],
        description: null,
      };

    case "my/login-histories":
      return {
        title: "User Login History",
        icon: "history",
        breadcrumbs: [],
        description: lang === LoLang.EN ? "Check and manage user login history." : "유저 로그인 기록을 확인할 수 있습니다.",
      };

    // Safety

    case "safety/dashboard":
      return {
        title: "Dashboard",
        icon: "equalizer",
        breadcrumbs: ["Dashboard"],
        description: null,
      };

    case "safety/companies":
      return {
        title: "Company",
        icon: "business",
        breadcrumbs: ["Company"],
        description: "고객사 목록을 확인할 수 있습니다. 고객사를 선택하면 상세페이지로 이동할 수 있습니다.",
      };

    case "safety/management/crfs":
      return {
        title: "CRF",
        icon: "space_dashboard",
        breadcrumbs: ["Management", "CRF"],
        description: "등록된 CRF를 확인하고 관리할 수 있습니다.",
      };

    case "safety/management/companies":
      return {
        title: "Company",
        icon: "business",
        breadcrumbs: ["Management", "Company"],
        description: "등록된 고객사를 확인하고 관리할 수 있습니다.",
      };

    case "safety/management/users":
      return {
        title: "User",
        icon: "people",
        breadcrumbs: ["Management", "User"],
        description: "등록된 사용자를 확인하고 관리할 수 있습니다.",
      };

    case "safety/company/dashboard":
      return {
        title: "Dashboard",
        icon: "equalizer",
        breadcrumbs: ["Company", "Dashboard"],
        description: null,
      };

    case "safety/company/cases":
      return {
        title: "Case",
        icon: "assignment",
        breadcrumbs: ["Company", "Case"],
        description: "등록된 Case를 확인하고 데이터를 관리할 수 있습니다.",
      };

    case "safety/company/submissions":
      return {
        title: "Submission",
        icon: "send",
        breadcrumbs: ["Company", "Submission"],
        description: "Case의 전송내역을 확인할 수 있습니다.",
      };

    case "safety/company/management/crfs":
      return {
        title: "Report",
        icon: "space_dashboard",
        breadcrumbs: ["Company", "Report"],
        description: "등록된 Report를 확인하고 관리할 수 있습니다.",
      };

    case "safety/company/settings":
      return {
        title: "Settings",
        icon: "settings",
        breadcrumbs: ["Company", "Settings"],
        description: "설정을 확인하고 관리할 수 있습니다.",
      };

    default:
      return { icon: "toys", title: "Maven", breadcrumbs: ["Maven"], description: "" };
  }
};

const getItemFromLocalStorage = (key: string): string | null => {
  try {
    // @ts-ignore
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

export function useBreadcrumb(location?: TabLocation, defaultDescription?: string) {
  const localStorageLanguage = getItemFromLocalStorage(LOCALE_LANG_KEY) as LoLang;
  const lang = localStorageLanguage || getBrowserDefaultLanguage();

  const { icon: di, title: dt, breadcrumbs: db, description: dd } = get(location, lang);

  const [icon, setIcon] = useState<string>(di);
  const [title, setTitle] = useState<string>(dt);
  const [description, setDescription] = useState<string | null>(defaultDescription || dd);
  const [breadcrumbs, setBreadcrumbs] = useState<Array<string>>(db);

  const onMatch = (location: TabLocation, defaultDescription?: string) => {
    const { icon: i, title: t, breadcrumbs: b, description: d } = get(location);

    setIcon(i);
    setTitle(t);
    setDescription(defaultDescription || d);
    setBreadcrumbs(b);
  };

  const availableOne = (
    navis: Array<{ priv: PRIVILEGE | PRIVILEGE[]; path: string } | { default: string }>,
    roles: UserRoles | StudyUserRoles | StudyUserRolePriv | undefined
  ) => {
    for (const navi of navis) {
      if (hasOwnProperty(navi, "priv")) {
        if (isAuthorized(navi.priv, roles)) {
          return navi.path;
        }
      } else {
        return navi.default;
      }
    }

    return "/";
  };

  return {
    icon,
    title,
    description,
    breadcrumbs,
    onMatch,
    availableOne,
  };
}
