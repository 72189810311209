/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const ROLE_CODE: {
  STUDY_NOTFOUND: MvnCodeBE;
  NOTFOUND: MvnCodeBE;
  UNAVAILABLE: MvnCodeBE;
  DUPLICATED_NAME: MvnCodeBE;
  INVALID_PRIV: MvnCodeBE;
} = {
  STUDY_NOTFOUND: {
    status: 404,
    ko: "해당 Study가 존재하지 않습니다.",
    en: "Study does not exist.",
  },
  NOTFOUND: {
    status: 404,
    ko: "해당 Role({id})을 찾을 수 없습니다",
    en: "Role({id}) could not be found.",
  },
  UNAVAILABLE: {
    status: 400,
    ko: "요청하신 Role({id}) 정보가 올바르지 않습니다.",
    en: "Requested role({id}) is not valid.",
  },
  DUPLICATED_NAME: {
    status: 409,
    ko: "해당 Role 이름({name})이 이미 존재합니다.",
    en: "Role name({name}) is duplicated.",
  },
  INVALID_PRIV: {
    status: 400,
    ko: "해당 Role Privilege({privilege})가 잘못되었습니다.",
    en: "Role Privilege({privilege}) is invalid.",
  },
};

MvnCodeInitiate(ROLE_CODE);
