import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_IP_CATEGORY: {
  STUDY_IP_CATEGORY_NOT_FOUND: MvnCodeBE;
  STUDY_IP_CATEGORY_NOT_AVAILABLE: MvnCodeBE;
  STUDY_IP_CATEGORY_NAME_DUPLICATED: MvnCodeBE;
  STUDY_IP_CATEGORY_ID_DUPLICATED: MvnCodeBE;
  STUDY_IP_CATEGORY_NOT_CHANGED: MvnCodeBE;
  STUDY_IP_CATEGORY_UNEXPECTED: MvnCodeBE;
  STUDY_IP_CATEGORY_NAME_MISSING: MvnCodeBE;
  STUDY_IP_CATEGORY_ID_MISSING: MvnCodeBE;
  STUDY_IP_CATEGORY_ID_INVALID: MvnCodeBE;
  STUDY_IP_CATEGORY_ID_EXCEED_MAXLEN: MvnCodeBE;
  STUDY_IP_CATEGORY_NAME_EXCEED_MAXLEN: MvnCodeBE;
  STUDY_IP_CATEGORY_DELETE_FAIL: MvnCodeBE;
} = {
  STUDY_IP_CATEGORY_NOT_FOUND: {
    status: 404,
    ko: "Study IP Category 상세 정보가 존재하지 않습니다.",
    en: "Study IP category does not exist.",
  },
  STUDY_IP_CATEGORY_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study IP Category 상세 정보가 올바르지 않습니다.",
    en: "Requested study IP Category is not valid.",
  },
  STUDY_IP_CATEGORY_NAME_DUPLICATED: {
    status: 400,
    ko: "요청하신 Study IP Category Label이 중복 존재합니다.",
    en: "Requested study IP Category Label is duplicated.",
  },
  STUDY_IP_CATEGORY_ID_DUPLICATED: {
    status: 400,
    ko: "요청하신 Study IP Category ID가 중복 존재합니다.",
    en: "Requested study IP Category ID is duplicated.",
  },
  STUDY_IP_CATEGORY_NOT_CHANGED: {
    status: 400,
    ko: "요청하신 Study IP Category 상세 정보의 변경사항이 없습니다.",
    en: "No study IP Category changed.",
  },
  STUDY_IP_CATEGORY_UNEXPECTED: {
    status: 500,
    ko: "요청하신 Study IP Category 상세 정보가 올바르지 않습니다.",
    en: "Requested study IP Category is not valid.",
  },
  STUDY_IP_CATEGORY_NAME_MISSING: {
    status: 400,
    ko: "IP Category Label이 누락되었습니다.",
    en: "IP Category Label is missing.",
  },
  STUDY_IP_CATEGORY_ID_MISSING: {
    status: 400,
    ko: "IP Category ID가 누락되었습니다.",
    en: "IP Category ID is missing.",
  },
  STUDY_IP_CATEGORY_ID_INVALID: {
    status: 400,
    ko: "IP Category ID가 올바르지 않습니다.",
    en: "IP Category ID is invalid.",
  },
  STUDY_IP_CATEGORY_ID_EXCEED_MAXLEN: {
    status: 400,
    ko: "IP Category ID가 255자를 초과했습니다.",
    en: "IP Category ID has exceeded 255 characters in length.",
  },
  STUDY_IP_CATEGORY_NAME_EXCEED_MAXLEN: {
    status: 400,
    ko: "IP Category Labe이 255자를 초과했습니다.",
    en: "IP Category label has exceeded 255 characters in length.",
  },
  STUDY_IP_CATEGORY_DELETE_FAIL: {
    status: 400,
    ko: "IP Category({identifier}) 삭제에 실패하였습니다.",
    en: "Failed to delete the IP Category({identifier}).",
  },
};

MvnCodeInitiate(STUDY_IP_CATEGORY);
