/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const ECONSENT_LANG: {
  ECONSENT_URL_SMS_TITLE_TEMPLATE: MvnCodeFE;
  ECONSENT_URL_SMS_CONTENT_TEMPLATE: MvnCodeFE;
} = {
  ECONSENT_URL_SMS_TITLE_TEMPLATE: {
    ko: "eConsent URL 발송",
    en: "Send eConsent URL",
  },
  ECONSENT_URL_SMS_CONTENT_TEMPLATE: {
    ko: "eConsent ({eConsentUrl})에 접속하여 동의서 서명을 진행해 주세요.",
    en: "Please access eConsent ({eConsentUrl}) and proceed with signing the consent.",
  },
};

MvnCodeInitiate(ECONSENT_LANG);
