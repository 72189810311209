/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_MILESTONE_CODE: {

  TMF_STUDY_MILESTONE_ID_NOT_FOUND: MvnCodeBE;

  TMF_STUDY_MILESTONE_STRUCTURE_ID_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_ID_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_IDENTIFIER_FORMAT_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_IDENTIFIER_DUPLICATED: MvnCodeBE;
  TMF_STUDY_MILESTONE_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_MILESTONE_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_MILESTONE_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_MILESTONE_NAME_DUPLICATED: MvnCodeBE;
  TMF_STUDY_MILESTONE_GROUP_INVALID: MvnCodeBE;
  TMF_STUDY_MILESTONE_TRIAL_DESC_TOO_LONG: MvnCodeBE;
  TMF_STUDY_MILESTONE_COUNTRY_DESC_TOO_LONG: MvnCodeBE;
  TMF_STUDY_MILESTONE_SITE_DESC_TOO_LONG: MvnCodeBE;

  TMF_STUDY_MILESTONE_NOT_ALLOW_DESTROYED: MvnCodeBE;

} = {
  TMF_STUDY_MILESTONE_ID_NOT_FOUND: {
    code: "TMF_STUDY_MILESTONE_ID_NOT_FOUND",
    status: 400,
    ko: "studyMilestoneId({id})가 존재하지 않습니다.",
    en: "studyMilestoneId({id}) does not exists."
  },

  TMF_STUDY_MILESTONE_STRUCTURE_ID_INVALID: {
    code: "TMF_STUDY_MILESTONE_STRUCTURE_ID_INVALID",
    status: 400,
    ko: "TMF Structure({id})가 잘못되었습니다.",
    en: "TMF Structure({id}) is invalid."
  },
  TMF_STUDY_MILESTONE_ID_INVALID: {
    code: "TMF_STUDY_MILESTONE_ID_INVALID",
    status: 400,
    ko: "TMF Milestone id({id})가 잘못되었습니다.",
    en: "TMF Milestone id({id}) is invalid."
  },
  TMF_STUDY_MILESTONE_IDENTIFIER_FORMAT_INVALID: {
    code: "TMF_STUDY_MILESTONE_IDENTIFIER_FORMAT_INVALID",
    status: 400,
    ko: "TMF Milestone의 id({id}) Format이 유효하지 않습니다.",
    en: "The format of TMF Milestone's id({id}) is invalid."
  },
  TMF_STUDY_MILESTONE_IDENTIFIER_DUPLICATED: {
    code: "TMF_STUDY_MILESTONE_IDENTIFIER_DUPLICATED",
    status: 409,
    ko: "TMF Milestone의 id({id})가 중복되었습니다.",
    en: "TMF Milestone id({id}) is duplicated.",
  },
  TMF_STUDY_MILESTONE_NAME_MISSING: {
    code: "TMF_STUDY_MILESTONE_NAME_MISSING",
    status: 400,
    ko: "TMF Milestone 이름이 입력되지 않았습니다.",
    en: "TMF Milestone Name is missing."
  },
  TMF_STUDY_MILESTONE_NAME_TOO_SHORT: {
    code: "TMF_STUDY_MILESTONE_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Milestone 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Milestone name must be at least {length} characters long.",
  },
  TMF_STUDY_MILESTONE_NAME_TOO_LONG: {
    code: "TMF_STUDY_MILESTONE_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Milestone 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Milestone name can be up to {length} characters long.",
  },
  TMF_STUDY_MILESTONE_NAME_DUPLICATED: {
    code: "TMF_STUDY_MILESTONE_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Milestone 이름({name})이 중복되었습니다.",
    en: "TMF Milestone name({name}) is duplicated.",
  },
  TMF_STUDY_MILESTONE_GROUP_INVALID: {
    code: "TMF_STUDY_MILESTONE_GROUP_INVALID",
    status: 400,
    ko: "TMF Milestone의 group({group})이 유효하지 않습니다.",
    en: "TMF Milestone group({group}) is invalid."
  },
  TMF_STUDY_MILESTONE_TRIAL_DESC_TOO_LONG: {
    code: "TMF_STUDY_MILESTONE_TRIAL_DESC_TOO_LONG",
    status: 400,
    ko: "TMF Milestone Trial Description 길이는 최대 {length}자 입니다.",
    en: "TMF Milestone Trial Description can be up to {length} characters long.",
  },
  TMF_STUDY_MILESTONE_COUNTRY_DESC_TOO_LONG: {
    code: "TMF_STUDY_MILESTONE_COUNTRY_DESC_TOO_LONG",
    status: 400,
    ko: "TMF Milestone Country Description 길이는 최대 {length}자 입니다.",
    en: "TMF Milestone Country Description can be up to {length} characters long.",
  },
  TMF_STUDY_MILESTONE_SITE_DESC_TOO_LONG: {
    code: "TMF_STUDY_MILESTONE_SITE_DESC_TOO_LONG",
    status: 400,
    ko: "TMF Milestone Site Description 길이는 최대 {length}자 입니다.",
    en: "TMF Milestone Site Description can be up to {length} characters long.",
  },

  TMF_STUDY_MILESTONE_NOT_ALLOW_DESTROYED: {
    code: "TMF_STUDY_MILESTONE_NOT_ALLOW_DESTROYED",
    status: 400,
    ko: "TMF Milestone을 삭제할 수 없습니다.",
    en: "TMF Milestone can not be deleted.",
  },
};

MvnCodeInitiate(TMF_STUDY_MILESTONE_CODE);
