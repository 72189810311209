import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_COMMENT_LANG: {
  CDMS_STUDY_COMMENT_WRITE_SUCC: MvnCodeFE;
  CDMS_STUDY_COMMENT_WRITE_FAIL: MvnCodeFE;
  CDMS_STUDY_COMMENT_LIST_DIALOG_HEADER: MvnCodeFE;
  CDMS_STUDY_COMMENT_WRITE_DIALOG_BTN_CONFIRM: MvnCodeFE;
  CDMS_STUDY_COMMENT_WRITE_INPUT_LABEL: MvnCodeFE;
  CDMS_STUDY_COMMENT_WRITE_INPUT_PLACEHOLDER: MvnCodeFE;
  CDMS_STUDY_COMMENT_EDIT_SUCC: MvnCodeFE;
  CDMS_STUDY_COMMENT_EDIT_FAIL: MvnCodeFE;
  CDMS_STUDY_COMMENT_EDIT_DIALOG_HEADER: MvnCodeFE;
  CDMS_STUDY_COMMENT_EDIT_DIALOG_BTN_CONFIRM: MvnCodeFE;
  CDMS_STUDY_COMMENT_DELETE_DIALOG_HEADER: MvnCodeFE;
  CDMS_STUDY_COMMENT_DELETE_DIALOG_TITLE: MvnCodeFE;
  CDMS_STUDY_COMMENT_DELETE_SUCC: MvnCodeFE;
  CDMS_STUDY_COMMENT_DELETE_FAIL: MvnCodeFE;
  CDMS_STUDY_COMMENT_AUDIT_DIALOG_HEADER: MvnCodeFE;
} = {
  CDMS_STUDY_COMMENT_LIST_DIALOG_HEADER: {
    ko: "Comments",
    en: "Comments",
  },
  CDMS_STUDY_COMMENT_WRITE_SUCC: {
    ko: "Comment를 작성하였습니다.",
    en: "Comment has been wrote successfully.",
  },
  CDMS_STUDY_COMMENT_WRITE_FAIL: {
    ko: "Comment 작성에 실패하였습니다. ({message})",
    en: "Failed to write comment. ({message})",
  },
  CDMS_STUDY_COMMENT_WRITE_DIALOG_BTN_CONFIRM: {
    ko: "Comment",
    en: "Comment",
  },
  CDMS_STUDY_COMMENT_WRITE_INPUT_LABEL: {
    ko: "Comment 작성",
    en: "Write Comment",
  },
  CDMS_STUDY_COMMENT_WRITE_INPUT_PLACEHOLDER: {
    ko: "Comment 내용을 입력해 주세요. (최대 1023자까지 입력 가능합니다.)",
    en: "Please enter comment.",
  },
  CDMS_STUDY_COMMENT_EDIT_SUCC: {
    ko: "Comment를 수정하였습니다.",
    en: "Comment has been edited successfully.",
  },
  CDMS_STUDY_COMMENT_EDIT_FAIL: {
    ko: "Comment 수정에 실패하였습니다. ({message})",
    en: "Failed to edit comment. ({message})",
  },
  CDMS_STUDY_COMMENT_EDIT_DIALOG_HEADER: {
    ko: "Edit Comment",
    en: "Edit Comment",
  },
  CDMS_STUDY_COMMENT_EDIT_DIALOG_BTN_CONFIRM: {
    ko: "Save",
    en: "Save",
  },
  CDMS_STUDY_COMMENT_DELETE_DIALOG_HEADER: {
    ko: "Delete Comment",
    en: "Delete Comment",
  },
  CDMS_STUDY_COMMENT_DELETE_DIALOG_TITLE: {
    ko: "Comment를 삭제하시겠습니까?",
    en: "Do you want to delete the comment?",
  },
  CDMS_STUDY_COMMENT_DELETE_SUCC: {
    ko: "Comment를 삭제하였습니다.",
    en: "Comment has been deleted successfully.",
  },
  CDMS_STUDY_COMMENT_DELETE_FAIL: {
    ko: "Comment 삭제에 실패하였습니다. ({message})",
    en: "Failed to delete comment. ({message})",
  },
  CDMS_STUDY_COMMENT_AUDIT_DIALOG_HEADER: {
    ko: "Comment Audit Trail",
    en: "Comment Audit Trail",
  },
};

MvnCodeInitiate(CDMS_STUDY_COMMENT_LANG);
