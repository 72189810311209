import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_WORKSPACE_CODE: {
  SAFETY_WORKSPACE_INVALID_MSG_MAX_LENGTH_EXCEED: MvnCodeBE;
} = {
  SAFETY_WORKSPACE_INVALID_MSG_MAX_LENGTH_EXCEED: {
    status: 400,
    ko: "{key}의 길이는 최대 {length}자 입니다.",
    en: "{key} can be up to {length} characters long.",
  },
};

MvnCodeInitiate(SAFETY_WORKSPACE_CODE);
