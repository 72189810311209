import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const LOGIN_HISTORY_LANG: {
  VIEW_LABEL_LOGIN_TIME: MvnCodeFE;
  VIEW_LABEL_IP: MvnCodeFE;
} = {
  VIEW_LABEL_LOGIN_TIME: {
    ko: "최종 접속 시간",
    en: "Last Login Time",
  },
  VIEW_LABEL_IP: {
    ko: "IP 주소",
    en: "IP Address",
  },
};

MvnCodeInitiate(LOGIN_HISTORY_LANG);
