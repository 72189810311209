/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const TRAINING_CODE: {
  TRAINING_NOT_VALID: MvnCodeBE;
  TRAINING_NOT_FOUND: MvnCodeBE;
  TRAINING_PAGE_ADD_FAIL_SEQ_DUPLICATED: MvnCodeBE;
  TRAINING_QUESTION_ADD_FAIL_SEQ_DUPLICATED: MvnCodeBE;
  TRAINING_ANSWER_ADD_FAIL_SEQ_DUPLICATED: MvnCodeBE;
  TRAINING_PAGE_UPDATE_FAIL_SEQ_DUPLICATED: MvnCodeBE;
  TRAINING_QUESTION_UPDATE_FAIL_SEQ_DUPLICATED: MvnCodeBE;
  TRAINING_ANSWER_UPDATE_FAIL_SEQ_DUPLICATED: MvnCodeBE;
  TRAINING_PAGE_SEQ_INVALID: MvnCodeBE;
  TRAINING_QUESTION_SEQ_INVALID: MvnCodeBE;
  TRAINING_ANSWER_SEQ_INVALID: MvnCodeBE;
  TRAINING_CERTIFICATE_EXISTS_ALREADY: MvnCodeBE;
  TRAINING_CERTIFICATE_NOT_VALID: MvnCodeBE;
  TRAINING_CERTIFICATE_NOT_FOUND: MvnCodeBE;
  TRAINING_CERTIFICATE_DOWNLOAD_REQUEST_NOT_EXISTS: MvnCodeBE;
} = {
  TRAINING_NOT_VALID: {
    status: 400,
    ko: "Training 정보({id})가 유효하지 않습니다.",
    en: "Requested training information ({id}) is not valid.",
  },
  TRAINING_NOT_FOUND: {
    status: 404,
    ko: "Training 정보({id})가 존재하지 않습니다.",
    en: "Requested training information ({id}) could not be found.",
  },
  TRAINING_PAGE_ADD_FAIL_SEQ_DUPLICATED: {
    status: 400,
    ko: "이미 존재하는 Sequence 입니다.",
    en: "This sequence already exists.",
  },
  TRAINING_QUESTION_ADD_FAIL_SEQ_DUPLICATED: {
    status: 400,
    ko: "이미 존재하는 Sequence 입니다.",
    en: "This sequence already exists.",
  },
  TRAINING_ANSWER_ADD_FAIL_SEQ_DUPLICATED: {
    status: 400,
    ko: "이미 존재하는 Sequence 입니다.",
    en: "This sequence already exists.",
  },
  TRAINING_PAGE_UPDATE_FAIL_SEQ_DUPLICATED: {
    status: 400,
    ko: "이미 존재하는 Sequence 입니다.",
    en: "This sequence already exists.",
  },
  TRAINING_QUESTION_UPDATE_FAIL_SEQ_DUPLICATED: {
    status: 400,
    ko: "이미 존재하는 Sequence 입니다.",
    en: "This sequence already exists.",
  },
  TRAINING_ANSWER_UPDATE_FAIL_SEQ_DUPLICATED: {
    status: 400,
    ko: "이미 존재하는 Sequence 입니다.",
    en: "This sequence already exists.",
  },
  TRAINING_PAGE_SEQ_INVALID: {
    status: 400,
    ko: "Sequence는 양의 정수를 입력해 주세요.",
    en: "Please enter a positive integer in the sequence inputbox.",
  },
  TRAINING_QUESTION_SEQ_INVALID: {
    status: 400,
    ko: "Sequence는 양의 정수를 입력해 주세요.",
    en: "Please enter a positive integer in the sequence inputbox.",
  },
  TRAINING_ANSWER_SEQ_INVALID: {
    status: 400,
    ko: "Sequence는 양의 정수를 입력해 주세요.",
    en: "Please enter a positive integer in the sequence inputbox.",
  },
  TRAINING_CERTIFICATE_EXISTS_ALREADY: {
    status: 409,
    ko: "Training Certificate를 이미 발급하였습니다.",
    en: "Requested training certificate is already issued.",
  },
  TRAINING_CERTIFICATE_NOT_VALID: {
    status: 400,
    ko: "Training Certificate 정보({id})가 유효하지 않습니다.",
    en: "Requested training certificate information ({id}) is not valid.",
  },
  TRAINING_CERTIFICATE_NOT_FOUND: {
    status: 404,
    ko: "Training Certificate 정보({id})가 존재하지 않습니다.",
    en: "Requested training certificate information ({id}) could not be found.",
  },
  TRAINING_CERTIFICATE_DOWNLOAD_REQUEST_NOT_EXISTS: {
    status: 409,
    ko: "Training Certificate 정보가 존재하지 않습니다.",
    en: "Requested training certificate does not exist.",
  },
};

MvnCodeInitiate(TRAINING_CODE);
