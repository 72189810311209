import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RequestState {
  postCaptures: boolean;
}

const initialState: RequestState = { postCaptures: false };

export const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    capture(state, action: PayloadAction<RequestState>) {
      state.postCaptures = action.payload.postCaptures;
    },
  },
});
