import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_DATA_INTEGRATION_LANG: {
  CDMS_DATA_INTEGRATION_UPLOAD_TXT_FILE_DESCRIPTION: MvnCodeFE;
} = {
  CDMS_DATA_INTEGRATION_UPLOAD_TXT_FILE_DESCRIPTION: {
    ko: "파일 내 데이터는 아래의 구분자로 구분됩니다.",
    en: "The data in the file is separated by the delimiter below.",
  },
};

MvnCodeInitiate(CDMS_DATA_INTEGRATION_LANG);
