/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_STRUCTURE_CODE: {
  TMF_STUDY_STRUCTURE_ALREADY_EXIST: MvnCodeBE;
  TMF_STUDY_STRUCTURE_NOT_EXIST: MvnCodeBE;
  TMF_STUDY_STRUCTURE_INVALID: MvnCodeBE;
  TMF_STUDY_STRUCTURE_ID_MISSING: MvnCodeBE;
  TMF_STUDY_STRUCTURE_VERSION_ALREADY_DEPLOYED: MvnCodeBE;

  TMF_STUDY_STRUCTURE_ZONE_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_STRUCTURE_SECTION_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_STRUCTURE_ARTIFACT_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_STRUCTURE_ARTIFACT_MILESTONE_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_STRUCTURE_SUB_ARTIFACT_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_STRUCTURE_ARTIFACT_ATTR_ID_NOT_FOUND: MvnCodeBE;

  TMF_STUDY_ZONE_NOT_EXISTS: MvnCodeBE;
  TMF_STUDY_ZONE_MOVE_NOT_ALLOW: MvnCodeBE;
  TMF_STUDY_ZONE_NO_CAHNGE_NOT_ALLOW: MvnCodeBE;
  TMF_STUDY_ZONE_NO_FORMAT_INVALID: MvnCodeBE;
  TMF_STUDY_ZONE_NO_DUPLICATED: MvnCodeBE;
  TMF_STUDY_ZONE_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_ZONE_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_ZONE_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_ZONE_NAME_DUPLICATED: MvnCodeBE;

  TMF_STUDY_SECTION_NOT_EXISTS: MvnCodeBE;
  TMF_STUDY_SECTION_MOVE_NOT_ALLOW: MvnCodeBE;
  TMF_STUDY_SECTION_NO_CAHNGE_NOT_ALLOW: MvnCodeBE;
  TMF_STUDY_SECTION_NO_FORMAT_INVALID: MvnCodeBE;
  TMF_STUDY_SECTION_NO_ZONE_PART_INVALID: MvnCodeBE;
  TMF_STUDY_SECTION_NO_DUPLICATED: MvnCodeBE;
  TMF_STUDY_SECTION_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_SECTION_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_SECTION_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_SECTION_NAME_DUPLICATED: MvnCodeBE;

  TMF_STUDY_ARTIFACT_NOT_EXISTS: MvnCodeBE;
  TMF_STUDY_ARTIFACT_MOVE_NOT_ALLOW: MvnCodeBE;
  TMF_STUDY_ARTIFACT_NO_CAHNGE_NOT_ALLOW: MvnCodeBE;
  TMF_STUDY_ARTIFACT_NO_FORMAT_INVALID: MvnCodeBE;
  TMF_STUDY_ARTIFACT_NO_ZONE_PART_INVALID: MvnCodeBE;
  TMF_STUDY_ARTIFACT_NO_SECTION_PART_INVALID: MvnCodeBE;
  TMF_STUDY_ARTIFACT_NO_DUPLICATED: MvnCodeBE;
  TMF_STUDY_ARTIFACT_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_ARTIFACT_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_ARTIFACT_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_ARTIFACT_NAME_DUPLICATED: MvnCodeBE;
  TMF_STUDY_ARTIFACT_DESC_TOO_LONG: MvnCodeBE;
  TMF_STUDY_ARTIFACT_UNIQUE_ID_FORMAT_INVALID: MvnCodeBE;
  TMF_STUDY_ARTIFACT_UNIQUE_ID_DUPLICATED: MvnCodeBE;
  TMF_STUDY_ARTIFACT_DATING_CONVENTION_INVALID: MvnCodeBE;
  TMF_STUDY_ARTIFACT_MANDATORY_INVALID: MvnCodeBE;

  TMF_STUDY_ARTIFACT_MILESTONE_MOVE_NOT_ALLOW: MvnCodeBE;
  TMF_STUDY_ARTIFACT_MILESTONE_LEVEL_MISSING: MvnCodeBE;
  TMF_STUDY_ARTIFACT_MILESTONE_LEVEL_INVALID: MvnCodeBE;
  TMF_STUDY_ARTIFACT_MILESTONE_LEVEL_DUPLICATED: MvnCodeBE;
  TMF_STUDY_ARTIFACT_MILESTONE_MILESTONE_ID_INVALID: MvnCodeBE;

  TMF_STUDY_SUB_ARTIFACT_MOVE_NOT_ALLOW: MvnCodeBE;
  TMF_STUDY_SUB_ARTIFACT_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_SUB_ARTIFACT_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_SUB_ARTIFACT_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_SUB_ARTIFACT_NAME_DUPLICATED: MvnCodeBE;

  TMF_STUDY_ARTIFACT_ATTR_MOVE_NOT_ALLOW: MvnCodeBE;
  TMF_STUDY_ARTIFACT_ATTR_ATTR_MISSING: MvnCodeBE;
  TMF_STUDY_ARTIFACT_ATTR_ATTR_INVALID: MvnCodeBE;
  TMF_STUDY_ARTIFACT_ATTR_ATTR_DUPLICATED: MvnCodeBE;
  TMF_STUDY_ARTIFACT_ATTR_VALUE_FORMAT_INVALID: MvnCodeBE;
  TMF_STUDY_ARTIFACT_ATTR_VALUE_DUPLICATED: MvnCodeBE;
  TMF_STUDY_ARTIFACT_ATTR_VALUE_ICH_CODE_TOO_LONG: MvnCodeBE;
  TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_SIDE_MISSING: MvnCodeBE;
  TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_SIDE_INVALID: MvnCodeBE;
  TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_LEVEL_MISSING: MvnCodeBE;
  TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_LEVEL_INVALID: MvnCodeBE;

} = {
  TMF_STUDY_STRUCTURE_ALREADY_EXIST: {
    code: "TMF_STUDY_STRUCTURE_ALREADY_EXIST",
    status: 409,
    ko: "Study Structure가 이미 존재합니다.",
    en: "Study structure already exists.",

  },
  TMF_STUDY_STRUCTURE_NOT_EXIST: {
    code: "TMF_STUDY_STRUCTURE_NOT_EXIST",
    status: 404,
    ko: "Study Structure({id})가 존재하지 않습니다.",
    en: "Study Structure({id}) does not exists."
  },
  TMF_STUDY_STRUCTURE_ID_MISSING: {
    code: "TMF_STUDY_STRUCTURE_ID_MISSING",
    status: 400,
    ko: "studyStructureId({id})가 입력되지 않았습니다.",
    en: "studyStructureId({id}) is missing."
  },
  TMF_STUDY_STRUCTURE_INVALID: {
    code: "TMF_STUDY_STRUCTURE_INVALID",
    status: 400,
    ko: "Study Structure({id})가 유효하지 않습니다.",
    en: "Study Structure({id}) is invalid."
  },
  TMF_STUDY_STRUCTURE_VERSION_ALREADY_DEPLOYED: {
    code: "TMF_STUDY_STRUCTURE_VERSION_ALREADY_DEPLOYED",
    status: 400,
    ko: "Study Structure Version이 이미 배포되었습니다.",
    en: "Study Structure Version is already deployed."
  },

  TMF_STUDY_STRUCTURE_ZONE_ID_NOT_FOUND: {
    code: "TMF_STUDY_STRUCTURE_ZONE_ID_NOT_FOUND",
    status: 400,
    ko: "studyZoneId({id})가 존재하지 않습니다.",
    en: "studyZoneId({id}) does not exists."
  },
  TMF_STUDY_STRUCTURE_SECTION_ID_NOT_FOUND: {
    code: "TMF_STUDY_STRUCTURE_SECTION_ID_NOT_FOUND",
    status: 400,
    ko: "studySectionId({id})가 존재하지 않습니다.",
    en: "studySectionId({id}) does not exists."
  },
  TMF_STUDY_STRUCTURE_ARTIFACT_ID_NOT_FOUND: {
    code: "TMF_STUDY_STRUCTURE_ARTIFACT_ID_NOT_FOUND",
    status: 400,
    ko: "studyArtifactId({id})가 존재하지 않습니다",
    en: "studyArtifactId({id}) does not exists."
  },
  TMF_STUDY_STRUCTURE_ARTIFACT_MILESTONE_ID_NOT_FOUND: {
    code: "TMF_STUDY_STRUCTURE_ARTIFACT_MILESTONE_ID_NOT_FOUND",
    status: 400,
    ko: "studyArtifactMilestoneId({id})가 존재하지 않습니다.",
    en: "studyArtifactMilestoneId({id}) does not exists."
  },
  TMF_STUDY_STRUCTURE_SUB_ARTIFACT_ID_NOT_FOUND: {
    code: "TMF_STUDY_STRUCTURE_SUB_ARTIFACT_ID_NOT_FOUND",
    status: 400,
    ko: "studySubArtifactId({id})가 존재하지 않습니다.",
    en: "studySubArtifactId({id}) does not exists."
  },
  TMF_STUDY_STRUCTURE_ARTIFACT_ATTR_ID_NOT_FOUND: {
    code: "TMF_STUDY_STRUCTURE_ARTIFACT_ATTR_ID_NOT_FOUND",
    status: 400,
    ko: "studyArtifactAttrId({id})가 존재하지 않습니다.",
    en: "studyArtifactAttrId({id}) does not exists."
  },

  TMF_STUDY_ZONE_NOT_EXISTS: {
    code: "TMF_STUDY_ZONE_NOT_EXISTS",
    status: 404,
    ko: "Study Zone({id})가 존재하지 않습니다.",
    en: "Study Zone({id}) does not exists."
  },
  TMF_STUDY_ZONE_MOVE_NOT_ALLOW: {
    code: "TMF_STUDY_ZONE_MOVE_NOT_ALLOW",
    status: 400,
    ko: "TMF Zone({id})의 위치는 변경할 수 없습니다.",
    en: "The location of the TMF Zone({id}) cannot be changed."
  },
  TMF_STUDY_ZONE_NO_CAHNGE_NOT_ALLOW: {
    code: "TMF_STUDY_ZONE_NO_CAHNGE_NOT_ALLOW",
    status: 400,
    ko: "TMF Zone의 No({before} -> {after})는 변경할 수 없습니다.",
    en: "The TMF Zone's No({before} -> {after}) cannot be changed."
  },
  TMF_STUDY_ZONE_NO_FORMAT_INVALID: {
    code: "TMF_STUDY_ZONE_NO_FORMAT_INVALID",
    status: 400,
    ko: "TMF Zone의 No({no}) Format이 유효하지 않습니다.",
    en: "The format of TMF Zone's No({no}) is invalid."
  },
  TMF_STUDY_ZONE_NO_DUPLICATED: {
    code: "TMF_STUDY_ZONE_NO_DUPLICATED",
    status: 409,
    ko: "TMF Zone No({no})가 중복되었습니다.",
    en: "TMF Zone no({no}) is duplicated.",
  },
  TMF_STUDY_ZONE_NAME_MISSING: {
    code: "TMF_STUDY_ZONE_NAME_MISSING",
    status: 400,
    ko: "TMF Zone 이름이 입력되지 않았습니다.",
    en: "TMF Zone Name is missing."
  },
  TMF_STUDY_ZONE_NAME_TOO_SHORT: {
    code: "TMF_STUDY_ZONE_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Zone 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Zone name must be at least {length} characters long.",
  },
  TMF_STUDY_ZONE_NAME_TOO_LONG: {
    code: "TMF_STUDY_ZONE_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Zone 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Zone name can be up to {length} characters long.",
  },
  TMF_STUDY_ZONE_NAME_DUPLICATED: {
    code: "TMF_STUDY_ZONE_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Zone 이름({name})이 중복되었습니다.",
    en: "TMF Zone name({name}) is duplicated.",
  },

  TMF_STUDY_SECTION_NOT_EXISTS: {
    code: "TMF_STUDY_SECTION_NOT_EXISTS",
    status: 404,
    ko: "Study Section({id})가 존재하지 않습니다.",
    en: "Study Section({id}) does not exists."
  },
  TMF_STUDY_SECTION_MOVE_NOT_ALLOW: {
    code: "TMF_STUDY_SECTION_MOVE_NOT_ALLOW",
    status: 400,
    ko: "TMF Section({id}) 위치는 변경할 수 없습니다.",
    en: "The location of the TMF Section({id}) cannot be changed."
  },
  TMF_STUDY_SECTION_NO_CAHNGE_NOT_ALLOW: {
    code: "TMF_STUDY_SECTION_NO_CAHNGE_NOT_ALLOW",
    status: 400,
    ko: "TMF Section의 No({before} -> {after})는 변경할 수 없습니다.",
    en: "The TMF Section's No({before} -> {after}) cannot be changed."
  },
  TMF_STUDY_SECTION_NO_FORMAT_INVALID: {
    code: "TMF_STUDY_SECTION_NO_FORMAT_INVALID",
    status: 400,
    ko: "TMF Section의 No({no}) Format이 유효하지 않습니다.",
    en: "The format of TMF Section's No({no}) is invalid."
  },
  TMF_STUDY_SECTION_NO_ZONE_PART_INVALID: {
    code: "TMF_STUDY_SECTION_NO_ZONE_PART_INVALID",
    status: 400,
    ko: "TMF Section No({no})의 Zone 영역이 상이합니다.",
    en: "The Zone part of TMF Section's No({no}) is different."
  },
  TMF_STUDY_SECTION_NO_DUPLICATED: {
    code: "TMF_STUDY_SECTION_NO_DUPLICATED",
    status: 409,
    ko: "TMF Section No({no})가 중복되었습니다.",
    en: "TMF Section no({no}) is duplicated.",
  },
  TMF_STUDY_SECTION_NAME_MISSING: {
    code: "TMF_STUDY_SECTION_NAME_MISSING",
    status: 400,
    ko: "TMF Section 이름이 입력되지 않았습니다.",
    en: "TMF Section Name is missing."
  },
  TMF_STUDY_SECTION_NAME_TOO_SHORT: {
    code: "TMF_STUDY_SECTION_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Section 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Section name must be at least {length} characters long.",
  },
  TMF_STUDY_SECTION_NAME_TOO_LONG: {
    code: "TMF_STUDY_SECTION_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Section 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Section name can be up to {length} characters long.",
  },
  TMF_STUDY_SECTION_NAME_DUPLICATED: {
    code: "TMF_STUDY_SECTION_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Section 이름({name})이 중복되었습니다.",
    en: "TMF Section name({name}) is duplicated.",
  },

  TMF_STUDY_ARTIFACT_NOT_EXISTS: {
    code: "TMF_STUDY_ARTIFACT_NOT_EXISTS",
    status: 404,
    ko: "Study Artifact({id})가 존재하지 않습니다.",
    en: "Study Artifact({id}) does not exists."
  },
  TMF_STUDY_ARTIFACT_MOVE_NOT_ALLOW: {
    code: "TMF_STUDY_ARTIFACT_MOVE_NOT_ALLOW",
    status: 400,
    ko: "TMF Artifact({id}) 위치는 변경할 수 없습니다.",
    en: "The location of the TMF Artifact({id}) cannot be changed."
  },
  TMF_STUDY_ARTIFACT_NO_CAHNGE_NOT_ALLOW: {
    code: "TMF_STUDY_ARTIFACT_NO_CAHNGE_NOT_ALLOW",
    status: 400,
    ko: "TMF Artifact의 No({before} -> {after})는 변경할 수 없습니다.",
    en: "The TMF Artifact's No({before} -> {after}) cannot be changed."
  },
  TMF_STUDY_ARTIFACT_NO_FORMAT_INVALID: {
    code: "TMF_STUDY_ARTIFACT_NO_FORMAT_INVALID",
    status: 400,
    ko: "TMF Artifact의 No({no}) Format이 유효하지 않습니다.",
    en: "The format of TMF Artifact's No({no}) is invalid."
  },
  TMF_STUDY_ARTIFACT_NO_ZONE_PART_INVALID: {
    code: "TMF_STUDY_ARTIFACT_NO_ZONE_PART_INVALID",
    status: 400,
    ko: "TMF Artifact No({no})의 Zone 영역이 상이합니다.",
    en: "The Zone part of TMF Artifact's No({no}) is different."
  },
  TMF_STUDY_ARTIFACT_NO_SECTION_PART_INVALID: {
    code: "TMF_STUDY_ARTIFACT_NO_SECTION_PART_INVALID",
    status: 400,
    ko: "TMF Artifact No({no})의 Section 영역이 상이합니다.",
    en: "The Section part of TMF Artifact's No({no}) is different."
  },
  TMF_STUDY_ARTIFACT_NO_DUPLICATED: {
    code: "TMF_STUDY_ARTIFACT_NO_DUPLICATED",
    status: 409,
    ko: "TMF Artifact No({no})가 중복되었습니다.",
    en: "TMF Artifact no({no}) is duplicated.",
  },
  TMF_STUDY_ARTIFACT_NAME_MISSING: {
    code: "TMF_STUDY_ARTIFACT_NAME_MISSING",
    status: 400,
    ko: "TMF Artifact 이름이 입력되지 않았습니다.",
    en: "TMF Artifact Name is missing."
  },
  TMF_STUDY_ARTIFACT_NAME_TOO_SHORT: {
    code: "TMF_STUDY_ARTIFACT_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Artifact 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Artifact name must be at least {length} characters long.",
  },
  TMF_STUDY_ARTIFACT_NAME_TOO_LONG: {
    code: "TMF_STUDY_ARTIFACT_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Artifact 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Artifact name can be up to {length} characters long.",
  },
  TMF_STUDY_ARTIFACT_NAME_DUPLICATED: {
    code: "TMF_STUDY_ARTIFACT_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Artifact 이름({name})이 중복되었습니다.",
    en: "TMF Artifact name({name}) is duplicated.",
  },
  TMF_STUDY_ARTIFACT_DESC_TOO_LONG: {
    code: "TMF_STUDY_ARTIFACT_DESC_TOO_LONG",
    status: 400,
    ko: "TMF Artifact 설명의 길이는 최대 {length}자 입니다.",
    en: "TMF Artifact description can be up to {length} characters long.",
  },
  TMF_STUDY_ARTIFACT_UNIQUE_ID_FORMAT_INVALID: {
    code: "TMF_STUDY_ARTIFACT_UNIQUE_ID_FORMAT_INVALID",
    status: 400,
    ko: "TMF Artifact unique id의 format이 유효하지 않습니다.",
    en: "TMF Artifact unique id is invalid.",
  },
  TMF_STUDY_ARTIFACT_UNIQUE_ID_DUPLICATED: {
    code: "TMF_STUDY_ARTIFACT_UNIQUE_ID_DUPLICATED",
    status: 409,
    ko: "TMF Artifact unique id({uniqueId})이 중복되었습니다.",
    en: "TMF Artifact unique id({uniqueId}) is duplicated.",
  },
  TMF_STUDY_ARTIFACT_DATING_CONVENTION_INVALID: {
    code: "TMF_STUDY_ARTIFACT_DATING_CONVENTION_INVALID",
    status: 400,
    ko: "TMF Artifact 날짜 규칙({datingConvention})이 유효하지 않습니다.",
    en: "The TMF Artifact dating convention({datingConvention}) is invalid."
  },
  TMF_STUDY_ARTIFACT_MANDATORY_INVALID: {
    code: "TMF_STUDY_ARTIFACT_MANDATORY_INVALID",
    status: 400,
    ko: "TMF Artifact mandatory({isMandatory})이 유효하지 않습니다.",
    en: "The TMF Artifact Mandatory({isMandatory}) is invalid."
  },

  TMF_STUDY_ARTIFACT_MILESTONE_MOVE_NOT_ALLOW: {
    code: "TMF_STUDY_ARTIFACT_MILESTONE_MOVE_NOT_ALLOW",
    status: 400,
    ko: "TMF Artifact Milestone({id}) 위치는 변경할 수 없습니다.",
    en: "The location of the TMF Artifact Milestone({id}) cannot be changed."
  },
  TMF_STUDY_ARTIFACT_MILESTONE_LEVEL_MISSING: {
    code: "TMF_STUDY_ARTIFACT_MILESTONE_LEVEL_MISSING",
    status: 400,
    ko: "TMF Artifact Milestone Level 입력되지 않았습니다.",
    en: "TMF Sub Artifact Level is missing."
  },
  TMF_STUDY_ARTIFACT_MILESTONE_LEVEL_INVALID: {
    code: "TMF_STUDY_ARTIFACT_MILESTONE_LEVEL_INVALID",
    status: 400,
    ko: "TMF Artifact Milestone Level({level})가 유효하지 않습니다.",
    en: "The TMF Artifact Milestone Level({level}) is invalid."
  },
  TMF_STUDY_ARTIFACT_MILESTONE_LEVEL_DUPLICATED: {
    code: "TMF_STUDY_ARTIFACT_MILESTONE_LEVEL_DUPLICATED",
    status: 409,
    ko: "TMF Sub Artifact Milestone Level({level})가 중복되었습니다.",
    en: "TMF Sub Artifact Milestone Level({level}) is duplicated.",
  },
  TMF_STUDY_ARTIFACT_MILESTONE_MILESTONE_ID_INVALID: {
    code: "TMF_STUDY_ARTIFACT_MILESTONE_MILESTONE_ID_INVALID",
    status: 400,
    ko: "TMF Artifact Milestone ID({studyMilestoneId})가 유효하지 않습니다.",
    en: "The TMF Artifact Milestone ID({studyMilestoneId}) is invalid."
  },

  TMF_STUDY_SUB_ARTIFACT_MOVE_NOT_ALLOW: {
    code: "TMF_STUDY_SUB_ARTIFACT_MOVE_NOT_ALLOW",
    status: 400,
    ko: "TMF Sub Artifact({id}) 위치는 변경할 수 없습니다.",
    en: "The location of the TMF Sub Artifact({id}) cannot be changed."
  },
  TMF_STUDY_SUB_ARTIFACT_NAME_MISSING: {
    code: "TMF_STUDY_SUB_ARTIFACT_NAME_MISSING",
    status: 400,
    ko: "TMF Sub Artifact Name이 입력되지 않았습니다.",
    en: "TMF Sub Artifact name is missing."
  },
  TMF_STUDY_SUB_ARTIFACT_NAME_TOO_SHORT: {
    code: "TMF_STUDY_SUB_ARTIFACT_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Sub Artifact Name의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Sub Artifact name must be at least {length} characters long.",
  },
  TMF_STUDY_SUB_ARTIFACT_NAME_TOO_LONG: {
    code: "TMF_STUDY_SUB_ARTIFACT_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Sub Artifact 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Sub Artifact name can be up to {length} characters long.",
  },
  TMF_STUDY_SUB_ARTIFACT_NAME_DUPLICATED: {
    code: "TMF_STUDY_SUB_ARTIFACT_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Sub Artifact 이름({name})가 중복되었습니다.",
    en: "TMF Sub Artifact Name({name}) is duplicated.",
  },
  TMF_STUDY_ARTIFACT_ATTR_MOVE_NOT_ALLOW: {
    code: "TMF_STUDY_ARTIFACT_ATTR_MOVE_NOT_ALLOW",
    status: 400,
    ko: "TMF Artifact Attribute({id}) 위치는 변경할 수 없습니다.",
    en: "The location of the TMF Artifact Attribute({id}) cannot be changed."
  },
  TMF_STUDY_ARTIFACT_ATTR_ATTR_MISSING: {
    code: "TMF_STUDY_ARTIFACT_ATTR_ATTR_MISSING",
    status: 400,
    ko: "TMF Artifact Attribute가 입력되지 않았습니다.",
    en: "TMF Artifact Attribute is missing."
  },
  TMF_STUDY_ARTIFACT_ATTR_ATTR_INVALID: {
    code: "TMF_STUDY_ARTIFACT_ATTR_ATTR_INVALID",
    status: 400,
    ko: "TMF Artifact Attribute({atr})가 유효하지 않습니다.",
    en: "The TMF Artifact Attribute({attr}) is invalid."
  },
  TMF_STUDY_ARTIFACT_ATTR_ATTR_DUPLICATED: {
    code: "TMF_STUDY_ARTIFACT_ATTR_DUPLICATED",
    status: 409,
    ko: "TMF Artifact Attribute({attr})가 중복되었습니다.",
    en: "TMF Artifact Attribute({attr}) is duplicated.",
  },
  TMF_STUDY_ARTIFACT_ATTR_VALUE_FORMAT_INVALID: {
    code: "TMF_STUDY_ARTIFACT_ATTR_VALUE_FORMAT_INVALID",
    status: 400,
    ko: "TMF Artifact Attribute Value({value})는 Array로만 입력되어야 합니다.",
    en: "The TMF Artifact Attribute Value shuold be an array."
  },
  TMF_STUDY_ARTIFACT_ATTR_VALUE_DUPLICATED: {
    code: "TMF_STUDY_ARTIFACT_ATTR_VALUE_DUPLICATED",
    status: 409,
    ko: "TMF Artifact Value({value})이 중복되었습니다.",
    en: "TMF Artifact Value({value}) is duplicated.",
  },
  TMF_STUDY_ARTIFACT_ATTR_VALUE_ICH_CODE_TOO_LONG: {
    code: "TMF_STUDY_ARTIFACT_ATTR_VALUE_ICH_CODE_TOO_LONG",
    status: 400,
    ko: "TMF Artifact ICH Code의 길이는 최대 {length}자 입니다.",
    en: "TMF Artifact ICH Code can be up to {length} characters long.",
  },
  TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_SIDE_MISSING: {
    code: "TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_SIDE_MISSING",
    status: 400,
    ko: "TMF Artifact 유형이 입력되지 않았습니다.",
    en: "The TMF Artifact Side is missing."
  },
  TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_SIDE_INVALID: {
    code: "TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_SIDE_INVALID",
    status: 400,
    ko: "TMF Artifact 유형({value})이 잘못되었습니다.",
    en: "The TMF Artifact Side({value}) is invalid."
  },
  TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_LEVEL_MISSING: {
    code: "TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_LEVEL_MISSING",
    status: 400,
    ko: "TMF Artifact Level 입력되지 않았습니다.",
    en: "The TMF Artifact Level is missing."
  },
  TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_LEVEL_INVALID: {
    code: "TMF_STUDY_ARTIFACT_ATTR_VALUE_TMF_LEVEL_INVALID",
    status: 400,
    ko: "TMF Artifact 레벨({value})이 잘못되었습니다.",
    en: "The TMF Artifact Level({value}) is invalid."
  },
};

MvnCodeInitiate(TMF_STUDY_STRUCTURE_CODE);
