import React, { RefObject, useState } from "react";
import { useRequest } from "@coralblack/flax";
import { IsUserPasswordValid } from "maven-lib/dist/consts/validator";
import { userApi } from "maven-lib/dist/states/apis/user";
import { ApiErrorResponse } from "maven-lib/dist/typings/api";
import { CrDialog } from "../../../../components/base/CrDialog";
import { CrInput } from "../../../../components/base/CrInput";
import { CrInputGroup } from "../../../../components/base/CrInputGroup";

export interface InitPasswordDialogMessageProps {
  dialogTitle: {
    default: string;
  };

  label: {
    password: string;
    passwordConfirm: string;
  };

  description: {
    password: string;
    passwordConfirm: string;
  };

  placeholder: {
    password: string;
    passwordConfirm: string;
  };

  response: {
    passwordUpdateError: (error: ApiErrorResponse) => string;
  };

  errorMessage: {
    password: string;
  };
}

interface InitPasswordDialogProps {
  messages: InitPasswordDialogMessageProps;
  visibility: boolean;
  email: RefObject<HTMLInputElement>;
  currentPassword: RefObject<HTMLInputElement>;
  passwordUpdatedEventHandler: (password: string) => void;
  cancelEventHandler: () => void;
  header?: string;
}

export function InitPasswordDialog(props: InitPasswordDialogProps) {
  const { messages, visibility, email, currentPassword, passwordUpdatedEventHandler, cancelEventHandler, header } = props;

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);

  const { request: passwordUpdateRequest } = useRequest(
    { ...userApi.updateUserInitialPasswordByEmail },
    {
      success: () => passwordUpdatedEventHandler(newPassword),
      error: (error) =>
        messages?.response.passwordUpdateError(error) || `비밀번호 변경을 실패했습니다. (${error?.message || "An error has occurred."})`,
    }
  );

  const userEmail = email.current?.value;

  return (
    <CrDialog
      header={header}
      visibility={visibility}
      size="WIDE"
      title={messages?.dialogTitle.default || "초기 비밀번호 변경을 위해 새로운 비밀번호를 입력해주세요."}
      onConfirm={() => passwordUpdateRequest({ data: { email, currentPassword, newPassword } })}
      onCancel={() => {
        setNewPassword("");
        setConfirmPassword("");
        cancelEventHandler();
      }}
      confirmDisabled={!isPasswordMatch || !isPasswordValid || userEmail === newPassword}
      confirm="Confirm"
      cancel="Cancel"
    >
      <CrInputGroup spacing={7}>
        <CrInput
          label={messages?.label.password || "비밀번호"}
          type="password"
          description={
            newPassword && !isPasswordValid
              ? messages?.description.password || "비밀번호는 최소 8글자 이상, 1개 이상의 숫자, 영문, 특수문자를 포함해야 합니다."
              : ""
          }
          errorMessage={
            userEmail === newPassword ? messages?.errorMessage.password || "이메일과 동일한 비밀번호로 변경할 수 없습니다." : ""
          }
          placeholder={messages?.placeholder.password || "새로운 비밀번호를 입력해주세요."}
          autoComplete="off"
          onValueChange={(val) => {
            setIsPasswordValid(IsUserPasswordValid(val));
            setIsPasswordMatch(val === confirmPassword);
            setNewPassword(val);
          }}
        />
        <CrInput
          label={messages?.label.passwordConfirm || "비밀번호 확인"}
          type="password"
          description={confirmPassword && !isPasswordMatch ? messages?.description.passwordConfirm || "비밀번호가 일치하지 않습니다." : ""}
          placeholder={messages?.placeholder.passwordConfirm || "새로운 비밀번호를 한번 더 입력해주세요."}
          autoComplete="off"
          onValueChange={(val) => {
            setIsPasswordMatch(val === newPassword);
            setConfirmPassword(val);
          }}
        />
      </CrInputGroup>
    </CrDialog>
  );
}
