/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_DW_HISTORY_CODE: {
  TMF_STUDY_DW_HISTORY_NOT_EXIST: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_INVALID: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_TYPE_MISSING: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_TYPE_INVALID: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_LANG_MISSING: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_LANG_INVALID: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_ALL_LEVEL_EMPTY: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_TRIAL_LEVEL_INVALID: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_COUNTRY_LEVEL_INVALID: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_SITE_LEVEL_INVALID: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_MILESTONE_MISSING: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_MILESTONE_INVALID: MvnCodeBE;

  TMF_STUDY_DW_HISTORY_FROM_DATE_INVALID: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_TO_DATE_INVALID: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_NOW_BEFORE_FROM_DATE: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_NOW_BEFORE_TO_DATE: MvnCodeBE;

  TMF_STUDY_DW_HISTORY_FROM_DATE_AFTER_TO_DATE: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_NOT_YET_GENERATED: MvnCodeBE;
  TMF_STUDY_DW_HISTORY_EXPIRED: MvnCodeBE;
} = {
  TMF_STUDY_DW_HISTORY_NOT_EXIST: {
    code: "TMF_STUDY_DW_HISTORY_NOT_EXIST",
    status: 404,
    ko: "Study Data Warehose History({id})가 존재하지 않습니다.",
    en: "Study Data Warehose History({id}) does not exists."
  },
  TMF_STUDY_DW_HISTORY_INVALID: {
    code: "TMF_STUDY_DW_HISTORY_INVALID",
    status: 400,
    ko: "Study Data Warehose History({id})가 유효하지 않습니다.",
    en: "Study Data Warehose History({id}) is invalid."
  },
  TMF_STUDY_DW_HISTORY_TYPE_MISSING: {
    code: "TMF_STUDY_DW_HISTORY_TYPE_MISSING",
    status: 400,
    ko: "Study Data Warehose type이 입력되지 않았습니다.",
    en: "Study Data Warehose type is missing."
  },
  TMF_STUDY_DW_HISTORY_TYPE_INVALID: {
    code: "TMF_STUDY_DW_HISTORY_TYPE_INVALID",
    status: 400,
    ko: "Study Data Warehouse type({type})이 잘못되었습니다.",
    en: "Study Data Warehouse type({type}) is invalid."
  },
  TMF_STUDY_DW_HISTORY_LANG_MISSING: {
    code: "TMF_STUDY_DW_HISTORY_LANG_MISSING",
    status: 400,
    ko: "Study Data Warehose lang이 입력되지 않았습니다.",
    en: "Study Data Warehose lang is missing."
  },
  TMF_STUDY_DW_HISTORY_LANG_INVALID: {
    code: "TMF_STUDY_DW_HISTORY_LANG_INVALID",
    status: 400,
    ko: "Study Data Warehouse lang({lang})이 잘못되었습니다.",
    en: "Study Data Warehouse lang({lang}) is invalid."
  },

  TMF_STUDY_DW_HISTORY_ALL_LEVEL_EMPTY: {
    code: "TMF_STUDY_DW_HISTORY_ALL_LEVEL_EMPTY",
    status: 400,
    ko: "Trial, country, and site levels 중 한 가지 이상 입력되어야합니다.",
    en: "At least one of the trial, country, and site levels should be entered."
  },
  TMF_STUDY_DW_HISTORY_TRIAL_LEVEL_INVALID: {
    code: "TMF_STUDY_DW_HISTORY_TRIAL_LEVEL_INVALID",
    status: 400,
    ko: "Study Data Warehouse Trial Level({isIncludeTrialLevel})이 잘못되었습니다.",
    en: "Study Data Warehouse Trial Level({isIncludeTrialLevel}) is invalid."
  },
  TMF_STUDY_DW_HISTORY_COUNTRY_LEVEL_INVALID: {
    code: "TMF_STUDY_DW_HISTORY_COUNTRY_LEVEL_INVALID",
    status: 400,
    ko: "Study Data Warehouse Country Level({includeCountryIds})이 잘못되었습니다.",
    en: "Study Data Warehouse Country Level({includeCountryIds}) is invalid."
  },
  TMF_STUDY_DW_HISTORY_SITE_LEVEL_INVALID: {
    code: "TMF_STUDY_DW_HISTORY_SITE_LEVEL_INVALID",
    status: 400,
    ko: "Study Data Warehouse Site Level({includeSiteIds})이 잘못되었습니다.",
    en: "Study Data Warehouse Site Level({includeSiteIds}) is invalid."
  },
  TMF_STUDY_DW_HISTORY_MILESTONE_MISSING: {
    code: "TMF_STUDY_DW_HISTORY_MILESTONE_MISSING",
    status: 400,
    ko: "Study Data Warehouse Milestone이 누락되었습니다.",
    en: "Study Data Warehouse Milestone is missing."
  },
  TMF_STUDY_DW_HISTORY_MILESTONE_INVALID: {
    code: "TMF_STUDY_DW_HISTORY_MILESTONE_INVALID",
    status: 400,
    ko: "Study Data Warehouse Milestone({includeMilestoneIds})이 잘못되었습니다.",
    en: "Study Data Warehouse Milestone({includeMilestoneIds}) is invalid."
  },

  TMF_STUDY_DW_HISTORY_FROM_DATE_INVALID: {
    code: "TMF_STUDY_DW_HISTORY_FROM_DATE_INVALID",
    status: 400,
    ko: "Study Data Warehouse fromDate가 유효하지 않습니다.",
    en: "Study Data Warehouse fromDate is invalid."
  },
  TMF_STUDY_DW_HISTORY_TO_DATE_INVALID: {
    code: "TMF_STUDY_DW_HISTORY_TO_DATE_INVALID",
    status: 400,
    ko: "Study Data Warehouse toDate가 유효하지 않습니다.",
    en: "Study Data Warehouse toDate is invalid"
  },
  TMF_STUDY_DW_HISTORY_NOW_BEFORE_FROM_DATE: {
    code: "TMF_STUDY_DW_HISTORY_NOW_BEFORE_FROM_DATE",
    status: 400,
    ko: "Study Data Warehouse fromDate({fromDate})가 현재 시각보다 이후입니다.",
    en: "Study Data Warehouse fromDate({fromDate}) is after now."
  },
  TMF_STUDY_DW_HISTORY_NOW_BEFORE_TO_DATE: {
    code: "TMF_STUDY_DW_HISTORY_NOW_BEFORE_TO_DATE",
    status: 400,
    ko: "Study Data Warehouse fromDate({fromDate})가 현재 시각보다 이후입니다.",
    en: "Study Data Warehouse fromDate({fromDate}) is after now."
  },

  TMF_STUDY_DW_HISTORY_FROM_DATE_AFTER_TO_DATE: {
    code: "TMF_STUDY_DW_HISTORY_FROM_DATE_AFTER_TO_DATE",
    status: 400,
    ko: "Study Data Warehouse fromDate({fromDate})가 toDate({toDate})보다 이후입니다.",
    en: "Study Data Warehouse fromDate({fromDate}) is after toDate({toDate})."
  },

  TMF_STUDY_DW_HISTORY_NOT_YET_GENERATED: {
    code: "TMF_STUDY_DW_HISTORY_NOT_YET_GENERATED",
    status: 400,
    ko: "Study Data Warehouse History({id}) 생성이 아직 완료되지 않았습니다.",
    en: "Study Data Warehouse History({id}) not yet been genereated."
  },
  TMF_STUDY_DW_HISTORY_EXPIRED: {
    code: "TMF_STUDY_DW_HISTORY_EXPIRED",
    status: 400,
    ko: "Study Data Warehouse History({id}) 가 만료되었습니다.",
    en: "Study Data Warehouse History({id}) is expired."
  },
};

MvnCodeInitiate(TMF_STUDY_DW_HISTORY_CODE);
