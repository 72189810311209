import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const SUBJECT_LANG: {
  SUBJECT_BREADCRUMB: MvnCodeFE;
  SUBJECT_ADD_INFO_SUCC: MvnCodeFE;
  SUBJECT_ADD_INFO_FAIL: MvnCodeFE;
  SUBJECT_LIST_TABLE_ADD_PHONE_NUMBER_DESCRIPTION: MvnCodeFE;
  SUBJECT_LIST_TABLE_DELETE_PHONE_NUMBER_DESCRIPTION: MvnCodeFE;
  SUBJECT_LIST_DOWNLOAD_FAIL: MvnCodeFE;
  SUBJECT_DELETE_INFO_SUCC: MvnCodeFE;
  SUBJECT_DELETE_INFO_FAIL: MvnCodeFE;
  SUBJECT_DELETE_PHONE_NUMBER_HEADER: MvnCodeFE;
  SUBJECT_EDIT_INFO_SUCC: MvnCodeFE;
  SUBJECT_EDIT_INFO_FAIL: MvnCodeFE;
  SUBJECT_EDIT_PHONE_NUMBER_HEADER: MvnCodeFE;
  SUBJECT_EDIT_PHONE_NUMBER_DESCRIPTION: MvnCodeFE;
  SUBJECT_SMS_SENT_SUCC: MvnCodeFE;
  SUBJECT_SMS_SEND_FAIL: MvnCodeFE;
  SUBJECT_SMS_SEND_DIALOG_TITLE: MvnCodeFE;
  SUBJECT_TABLE_COLUMN_APPROVED_DATE: MvnCodeFE;
  SUBJECT_TABLE_COLUMN_FIRST_VISIT_DATE: MvnCodeFE;
  SUBJECT_TABLE_COLUMN_LAST_VISIT: MvnCodeFE;
  SUBJECT_TABLE_COLUMN_LAST_VISIT_DATE: MvnCodeFE;
  SUBJECT_TABLE_SMS_HISTORY_COLUMN_SENT_TIME: MvnCodeFE;
  SUBJECT_TOOLTIP_FOR_REMOVE_INPUT: MvnCodeFE;
  SUBJECT_TOOLTIP_FOR_REMOVE_ROW: MvnCodeFE;
  SUBJECT_TOOLTIP_FOR_REMOVE_ROW_STRIKEOUT: MvnCodeFE;
  SUBJECT_TOOLTIP_FOR_RESTORE_ROW: MvnCodeFE;
  SUBJECT_DROPDOWN_PLACEHOLDER_EMPTY_SITE: MvnCodeFE;
  SUBJECT_DROPDOWN_PLACEHOLDER_SELECT_SITE: MvnCodeFE;
  SUBJECT_DROPDOWN_PLACEHOLDER_EMPTY_SUBJECT: MvnCodeFE;
  SUBJECT_DROPDOWN_PLACEHOLDER_SELECT_SUBJECT: MvnCodeFE;
  SUBJECT_VIEW_CALLOUT_INPUT_DESCRIPTION: MvnCodeFE;
  SUBJECT_VIEW_CALLOUT_INPUT_VALUE: MvnCodeFE;
  SUBJECT_NAV_INPUT_PLACEHOLDER_SUBJECT_STATUS: MvnCodeFE;
  PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE: MvnCodeFE;
  QUERY_RELATED_ITEM_LABEL: MvnCodeFE;
} = {
  SUBJECT_BREADCRUMB: {
    ko: "등록된 대상자를 확인하고 데이터를 수집할 수 있습니다.",
    en: "Manage registered subjects and collect study data.",
  },
  SUBJECT_ADD_INFO_SUCC: {
    ko: "성공적으로 정보를 입력하였습니다.",
    en: "You have entered your information successfully.",
  },
  SUBJECT_ADD_INFO_FAIL: {
    ko: "정보 생성에 실패하였습니다. ({error})",
    en: "Information creation has been failed. ({error})",
  },
  SUBJECT_LIST_TABLE_ADD_PHONE_NUMBER_DESCRIPTION: {
    ko: "전화번호를 입력해주세요.",
    en: "Please enter the subject's phone number",
  },
  SUBJECT_LIST_DOWNLOAD_FAIL: {
    ko: "대상자 목록 다운로드에 실패하였습니다.",
    en: "Subject list download has been failed. ({error})",
  },
  SUBJECT_DELETE_INFO_SUCC: {
    ko: "성공적으로 정보를 삭제하였습니다.",
    en: "Information has been deleted successfully.",
  },
  SUBJECT_DELETE_INFO_FAIL: {
    ko: "정보 삭제에 실패하였습니다. ({error})",
    en: "Information deletion has been failed. ({error})",
  },
  SUBJECT_DELETE_PHONE_NUMBER_HEADER: {
    ko: "대상자 전화번호 삭제",
    en: "Delete subject's phone number",
  },
  SUBJECT_LIST_TABLE_DELETE_PHONE_NUMBER_DESCRIPTION: {
    ko: "대상자 전화번호를 삭제하시겠습니까?",
    en: "Are you sure you want to delete the subject's phone number?",
  },
  SUBJECT_EDIT_INFO_SUCC: {
    ko: "성공적으로 정보를 갱신하였습니다.",
    en: "Information has been updated successfully.",
  },
  SUBJECT_EDIT_INFO_FAIL: {
    ko: "정보 수정에 실패하였습니다. ({error})",
    en: "Information edit has been failed. ({error})",
  },
  SUBJECT_EDIT_PHONE_NUMBER_HEADER: {
    ko: "대상자 전화번호 수정",
    en: "Edit subject's phone number",
  },
  SUBJECT_EDIT_PHONE_NUMBER_DESCRIPTION: {
    ko: "수정된 전화번호를 저장하시겠습니까?",
    en: "Do you want to save the subject's phone number?",
  },
  SUBJECT_SMS_SENT_SUCC: {
    ko: "문자가 발송되었습니다.",
    en: "SMS has been sent.",
  },
  SUBJECT_SMS_SEND_FAIL: {
    ko: "문자 발송에 실패하였습니다. ({error})",
    en: "SMS sending has been failed. ({error})",
  },
  SUBJECT_SMS_SEND_DIALOG_TITLE: {
    ko: "문자를 전송하시겠습니까?",
    en: "Do you want to send the SMS?",
  },
  PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE: {
    ko: "저장되지 않은 자료가 있습니다. 페이지를 이동 하시겠습니까?",
    en: "You have unsaved data. Do you want to move the page?",
  },
  QUERY_RELATED_ITEM_LABEL: {
    ko: "연관 Page로 이동",
    en: "Go to related page",
  },
  SUBJECT_TABLE_COLUMN_APPROVED_DATE: {
    ko: "서면 동의일",
    en: "Approved Date",
  },
  SUBJECT_TABLE_COLUMN_FIRST_VISIT_DATE: {
    ko: "최초 방문일",
    en: "First Visit Date",
  },
  SUBJECT_TABLE_COLUMN_LAST_VISIT: {
    ko: "최종 방문",
    en: "Last Visit",
  },
  SUBJECT_TABLE_COLUMN_LAST_VISIT_DATE: {
    ko: "최종 방문일",
    en: "Last Visit Date",
  },
  SUBJECT_TOOLTIP_FOR_REMOVE_INPUT: {
    ko: "입력 값 삭제",
    en: "Remove Input",
  },
  SUBJECT_TOOLTIP_FOR_REMOVE_ROW: {
    ko: "Row 삭제",
    en: "Remove Row",
  },
  SUBJECT_TOOLTIP_FOR_REMOVE_ROW_STRIKEOUT: {
    ko: "Row 삭제(취소선)",
    en: "Remove Row(strikeout)",
  },
  SUBJECT_TOOLTIP_FOR_RESTORE_ROW: {
    ko: "Row 복원",
    en: "Restore Row",
  },
  SUBJECT_TABLE_SMS_HISTORY_COLUMN_SENT_TIME: {
    ko: "발송시각",
    en: "Sent Time",
  },
  SUBJECT_DROPDOWN_PLACEHOLDER_EMPTY_SITE: {
    ko: "등록된 Site가 없습니다.",
    en: "No registered sites.",
  },
  SUBJECT_DROPDOWN_PLACEHOLDER_SELECT_SITE: {
    ko: "Site를 선택해주세요.",
    en: "Please select a site.",
  },
  SUBJECT_DROPDOWN_PLACEHOLDER_EMPTY_SUBJECT: {
    ko: "등록된 대상자가 없습니다.",
    en: "There is no registered subject.",
  },
  SUBJECT_DROPDOWN_PLACEHOLDER_SELECT_SUBJECT: {
    ko: "대상자를 선택해주세요.",
    en: "Please select a subject.",
  },
  SUBJECT_VIEW_CALLOUT_INPUT_DESCRIPTION: {
    ko: "‘Save’ 버튼을 클릭해야 전자서명이 최종 반영됩니다.",
    en: "E-sign is completed when you click the 'Save' button.",
  },
  SUBJECT_VIEW_CALLOUT_INPUT_VALUE: {
    ko: "전자서명이 입력되었습니다.",
    en: "E-sign has been entered.",
  },
  SUBJECT_NAV_INPUT_PLACEHOLDER_SUBJECT_STATUS: {
    ko: "대상자 상태",
    en: "Subject status",
  },
};

MvnCodeInitiate(SUBJECT_LANG);
