import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_REPORT_INFO_LANG: {
  SYNC_LOAD_FAIL: MvnCodeFE;
  SYNC_SUCC: MvnCodeFE;
  SYNC_FAIL: MvnCodeFE;
  SYNC_INVALID_CRF_SETUP: MvnCodeFE;
} = {
  SYNC_LOAD_FAIL: {
    ko: "연동 데이터를 불러오는데 실패했습니다. ({message})",
    en: "Failed to load integration data. ({message})",
  },
  SYNC_SUCC: {
    ko: "데이터가 반영되었습니다.",
    en: "The data has been reflected successfully.",
  },
  SYNC_FAIL: {
    ko: "데이터 반영에 실패하였습니다. ({message})",
    en: "Failed to reflect data. ({message})",
  },
  SYNC_INVALID_CRF_SETUP: {
    ko: "CRF Set Up 정보가 올바르지 않아 해당 기능을 수행할 수 없습니다.",
    en: "The function cannot be performed because the CRF Set Up information is incorrect.",
  },
};

MvnCodeInitiate(STUDY_REPORT_INFO_LANG);
