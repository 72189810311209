import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_LAB_NORMAL_RANGE_COMMENT_CODE: {
  NOTFOUND: MvnCodeBE;
  MESSAGE_EXCEED_MAXLEN: MvnCodeBE;
} = {
  NOTFOUND: {
    status: 404,
    ko: "정상 범위에 대한 comment가 없습니다.",
    en: "There is no lab normal range comment found.",
  },
  MESSAGE_EXCEED_MAXLEN: {
    status: 400,
    ko: "Comment는 {maxLen}자 까지 입력 가능합니다.",
    en: "The comment exceeds the maximum length of {maxLen} characters.",
  },
};

MvnCodeInitiate(STUDY_LAB_NORMAL_RANGE_COMMENT_CODE);
