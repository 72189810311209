import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_IP_CODE_LANG: {
  STUDY_IP_CODE_INITIALIZE_SUCCESS: MvnCodeFE;
  STUDY_IP_CODE_INITIALIZE_FAIL: MvnCodeFE;
  STUDY_IP_CODE_INITIALIZE_BUTTON: MvnCodeFE;
  STUDY_IP_CODE_INITIALIZE_POPUP_TITLE: MvnCodeFE;
  STUDY_IP_CODE_ALLOCATE_SUCCESS: MvnCodeFE;
  STUDY_IP_CODE_ALLOCATE_FAIL: MvnCodeFE;
  STUDY_IP_CODE_REALLOCATE_SUCCESS: MvnCodeFE;
  STUDY_IP_CODE_REALLOCATE_FAIL: MvnCodeFE;
  STUDY_IP_CODE_RETRIEVE_SUCCESS: MvnCodeFE;
  STUDY_IP_CODE_RETRIEVE_FAIL: MvnCodeFE;
  STUDY_IP_CODE_RETRIEVE_DIALOG_PLACEHOLDER: MvnCodeFE;
} = {
  STUDY_IP_CODE_INITIALIZE_SUCCESS: {
    ko: "IP Code 초기화에 성공하였습니다.",
    en: "You have initialized IP code successfully.",
  },
  STUDY_IP_CODE_INITIALIZE_FAIL: {
    ko: "IP Code 초기화에 실패하였습니다. 다시 시도해주세요. ({message})",
    en: "Failed to initialize. Please try again. ({message})",
  },
  STUDY_IP_CODE_INITIALIZE_BUTTON: {
    ko: "Initialize",
    en: "Initialize",
  },
  STUDY_IP_CODE_INITIALIZE_POPUP_TITLE: {
    ko: "IP Code 초기화를 위해 비밀번호를 입력해주세요.",
    en: "Enter your password to initialize IP Code.",
  },
  STUDY_IP_CODE_ALLOCATE_SUCCESS: {
    ko: "IP Code 할당에 성공하였습니다.",
    en: "Successfully allocated an IP Code.",
  },
  STUDY_IP_CODE_ALLOCATE_FAIL: {
    ko: "IP Code 할당에 실패하였습니다. ({message})",
    en: "Failed to allocate an IP Code. ({message})",
  },
  STUDY_IP_CODE_REALLOCATE_SUCCESS: {
    ko: "IP Code 회수 후 재할당에 성공하였습니다.",
    en: "Successfully retrieved and reallocated the IP Code.",
  },
  STUDY_IP_CODE_REALLOCATE_FAIL: {
    ko: "IP Code 회수 후 재할당에 실패하였습니다. ({message})",
    en: "Failed to retrieve and reallocate the IP Code. ({message})",
  },
  STUDY_IP_CODE_RETRIEVE_SUCCESS: {
    ko: "IP Code 회수에 성공하였습니다.",
    en: "Successfully retrieved the IP Code.",
  },
  STUDY_IP_CODE_RETRIEVE_FAIL: {
    ko: "IP Code 회수에 실패하였습니다. ({message})",
    en: "Failed to retrieve the IP Code. ({message})",
  },
  STUDY_IP_CODE_RETRIEVE_DIALOG_PLACEHOLDER: {
    ko: "불출된 IP Code를 입력하세요.",
    en: "Enter the dispensed IP Code.",
  },
};

export const STUDY_IP_CODE_UPLOAD_LANG: {
  IP_CODE_SAVE_SUCCESS: MvnCodeFE;
  IP_CODE_SAVE_FAIL: MvnCodeFE;
  IP_CODE_UPLOAD_DIALOG_HEADER: MvnCodeFE;
  IP_CODE_NAME_LABEL: MvnCodeFE;
  IP_CODE_UPLOAD_LABEL: MvnCodeFE;
  IP_CODE_DOWNLOAD_SUCCESS: MvnCodeFE;
  IP_CODE_DOWNLOAD_FAIL: MvnCodeFE;
  IP_CODE_DOWNLOAD_SAMPLE_BUTTON: MvnCodeFE;
  IP_CODE_DOWNLOAD_BUTTON: MvnCodeFE;
  IP_CODE_UPLOAD_BUTTON: MvnCodeFE;
  IP_CODE_UPLOAD_HISTORY_BUTTON: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_DONE: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_STUDY_DISABLED: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_STUDY_LOCKED: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_FILE_NOT_EXIST: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_ENV_MISSING: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_ENV_INVALID: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_ENV_INVALID_ONLY_REAL: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_ENV_INVALID_ONLY_TEST: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_CATEGORY_MISSING: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_CATEGORY_INVALID: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_DRUG_ID_MISSING: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_DRUG_ID_INVALID: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_NATION_CODE_MISSING: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_NATION_CODE_INVALID: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_IP_CODE_MISSING: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_IP_CODE_TOO_LONG: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_IP_CODE_DUP: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_LOT_NO_TOO_LONG: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_EXPIRE_DATE_INVALID: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_EXPIRE_SYS_DATE_INVALID: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_DESC_TOO_LONG: MvnCodeFE;
  IP_CODE_UPLOAD_RESULT_IP_CODE_ONLY_ADD_AFTER_ASSIGNED: MvnCodeFE;
} = {
  IP_CODE_SAVE_SUCCESS: {
    ko: "IP Code 등록에 성공하였습니다.",
    en: "You have saved IP code successfully.",
  },
  IP_CODE_SAVE_FAIL: {
    ko: "IP Code 저장에 실패하였습니다. 다시 시도해주세요. ({message})",
    en: "Failed to save. Please try again. ({message})",
  },
  IP_CODE_UPLOAD_DIALOG_HEADER: {
    ko: "Upload IP Code",
    en: "Upload IP Code",
  },
  IP_CODE_NAME_LABEL: {
    ko: "Name",
    en: "Name",
  },
  IP_CODE_UPLOAD_LABEL: {
    ko: "IP Code File(.xlsx)",
    en: "IP Code File(.xlsx)",
  },
  IP_CODE_DOWNLOAD_SUCCESS: {
    ko: "다운로드가 시작되었습니다.",
    en: "Download has started.",
  },
  IP_CODE_DOWNLOAD_FAIL: {
    ko: "다운로드에 실패하였습니다. 다시 시도해주세요. ({message})",
    en: "Failed to download. Please try again. ({message})",
  },
  IP_CODE_DOWNLOAD_SAMPLE_BUTTON: {
    ko: "Download Sample",
    en: "Download Sample",
  },
  IP_CODE_DOWNLOAD_BUTTON: {
    ko: "Download",
    en: "Download",
  },
  IP_CODE_UPLOAD_BUTTON: {
    ko: "Upload IP Code File",
    en: "Upload IP Code File",
  },
  IP_CODE_UPLOAD_HISTORY_BUTTON: {
    ko: "Upload History",
    en: "Upload History",
  },
  IP_CODE_UPLOAD_RESULT_DONE: {
    ko: "작업완료",
    en: "Completed",
  },
  IP_CODE_UPLOAD_RESULT_STUDY_DISABLED: {
    ko: "Study 비활성화",
    en: "Study Disabled",
  },
  IP_CODE_UPLOAD_RESULT_STUDY_LOCKED: {
    ko: "Study Locked",
    en: "Study Locked",
  },
  IP_CODE_UPLOAD_RESULT_FILE_NOT_EXIST: {
    ko: "File 미존재",
    en: "File does not exist",
  },
  IP_CODE_UPLOAD_RESULT_ENV_MISSING: {
    ko: "Env 누락",
    en: "Missing Env",
  },
  IP_CODE_UPLOAD_RESULT_ENV_INVALID: {
    ko: "Env 오입력",
    en: "Invalid Env",
  },
  IP_CODE_UPLOAD_RESULT_ENV_INVALID_ONLY_REAL: {
    ko: "Env 오입력(리얼 사이트에서는 Real Code만 Upload 가능)",
    en: "Invalid Env(You can only upload real codes on the real site.)",
  },
  IP_CODE_UPLOAD_RESULT_ENV_INVALID_ONLY_TEST: {
    ko: "Env 오입력(베타 사이트에서는 Test Code만 Upload 가능)",
    en: "Invalid Env(You can only upload test codes on the beta site.)",
  },
  IP_CODE_UPLOAD_RESULT_CATEGORY_MISSING: {
    ko: "Category 누락",
    en: "Missing Category",
  },
  IP_CODE_UPLOAD_RESULT_CATEGORY_INVALID: {
    ko: "Category 오입력",
    en: "Invalid Category",
  },
  IP_CODE_UPLOAD_RESULT_DRUG_ID_MISSING: {
    ko: "Drug ID 누락",
    en: "Missing Drug ID",
  },
  IP_CODE_UPLOAD_RESULT_DRUG_ID_INVALID: {
    ko: "Drug ID 오입력",
    en: "Invalid Drug ID",
  },
  IP_CODE_UPLOAD_RESULT_NATION_CODE_MISSING: {
    ko: "Nation Code 누락",
    en: "Missing Nation Code",
  },
  IP_CODE_UPLOAD_RESULT_NATION_CODE_INVALID: {
    ko: "Nation Code 오입력",
    en: "Invalid Nation Code",
  },
  IP_CODE_UPLOAD_RESULT_IP_CODE_MISSING: {
    ko: "IP Code 누락",
    en: "Missing IP Code",
  },
  IP_CODE_UPLOAD_RESULT_IP_CODE_TOO_LONG: {
    ko: "IP Code 최대길이 초과",
    en: "Exceeded maximum length of IP Code",
  },
  IP_CODE_UPLOAD_RESULT_IP_CODE_DUP: {
    ko: "IP Code 중복",
    en: "Duplicated IP Code",
  },
  IP_CODE_UPLOAD_RESULT_LOT_NO_TOO_LONG: {
    ko: "Lot no. 최대길이 초과",
    en: "Exceeded maximum length of Lot no.",
  },
  IP_CODE_UPLOAD_RESULT_EXPIRE_DATE_INVALID: {
    ko: "Expire date 오입력",
    en: "Invalid Expire date",
  },
  IP_CODE_UPLOAD_RESULT_EXPIRE_SYS_DATE_INVALID: {
    ko: "Expire date(system) 오입력",
    en: "Invalid Expire date(system)",
  },
  IP_CODE_UPLOAD_RESULT_DESC_TOO_LONG: {
    ko: "Description 최대길이 초과",
    en: "Exceeded maximum length of Description",
  },
  IP_CODE_UPLOAD_RESULT_IP_CODE_ONLY_ADD_AFTER_ASSIGNED: {
    ko: "IP Code 수정 불가 (IP Code 할당 시작 이후에는 추가만 가능)",
    en: "IP code cannot be modified (You may only add after IP code assigned)",
  },
};

MvnCodeInitiate(STUDY_IP_CODE_LANG);
MvnCodeInitiate(STUDY_IP_CODE_UPLOAD_LANG);
