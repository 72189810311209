/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AFFILIATION_LANG: {
  CDMS_AFFILIATION_BUSINESS_REGISTRATION_NUMBER_ALREADY_REGISTERED: MvnCodeFE;
  CDMS_AFFILIATION_BUSINESS_REGISTRATION_NUMBER_AVAILABLE: MvnCodeFE;
  CDMS_AFFILIATION_BUSINESS_REGISTRATION_NUMBER_DUPLICATED_CHECK_FAIL: MvnCodeFE;
} = {
  CDMS_AFFILIATION_BUSINESS_REGISTRATION_NUMBER_ALREADY_REGISTERED: {
    ko: "이미 등록되어 있는 사업자 등록번호입니다.",
    en: "The business registration number already exists.",
  },
  CDMS_AFFILIATION_BUSINESS_REGISTRATION_NUMBER_AVAILABLE: {
    ko: "등록 가능한 사업자 등록번호입니다.",
    en: "The business registration number you entered is valid.",
  },
  CDMS_AFFILIATION_BUSINESS_REGISTRATION_NUMBER_DUPLICATED_CHECK_FAIL: {
    ko: "사업자 등록번호 중복 확인에 실패했습니다. ({message})",
    en: "Failed to check for duplicates (Business Registration Number). ({message}) ",
  },
};

MvnCodeInitiate(AFFILIATION_LANG);
