import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_LANG: {
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_SUCC: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_FAIL: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_HEADER: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_STUDY_CRF_VERSION_PLACEHOLDER: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_STUDY_CRF_VERSION_LABEL: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_SECTION_TITLE: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_SECTION_DESCRIPTION: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_FILE_NOT_ALLOWED: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_FILE_SIZE_EXCEEDED: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_FILE_PLACEHOLDER: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_VALIDATE_SUCC: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_VALIDATE_FAIL: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_CAPTURE_SUCC: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_CAPTURE_FAIL: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_CAPTURE_RESULT_DIALOG_HEADER: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_CAPTURE_RESULT_DIALOG_VIEW_CRF_BTN: MvnCodeFE;
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_BTN_UPLOAD_FILE: MvnCodeFE;
} = {
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_SUCC: {
    ko: "Upload Attachment File이 정상적으로 요청되었습니다.",
    en: "The Upload Attachment File was requested successfully.",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_FAIL: {
    ko: "Upload Attachment File 요청에 실패했습니다.",
    en: "Failed to request to upload the attachment file.",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_HEADER: {
    ko: "Upload Attachment File",
    en: "Upload Attachment File",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_STUDY_CRF_VERSION_PLACEHOLDER: {
    ko: "CRF Version을 선택해 주세요",
    en: "Select CRF Version.",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_STUDY_CRF_VERSION_LABEL: {
    ko: "CRF Version",
    en: "CRF Version",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_SECTION_TITLE: {
    ko: "Attachment Items",
    en: "Attachment Items",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_SECTION_DESCRIPTION: {
    ko: "CRF에 있는 Attachment Item을 선택하여 업로드 파일을 첨부할 수 있습니다.",
    en: "You can attach an upload file by selecting the Attachment Item in the CRF.",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_FILE_NOT_ALLOWED: {
    ko: "선택한 파일은 업로드를 지원하지 않는 형식입니다.",
    en: "The file format is not supported",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_FILE_SIZE_EXCEEDED: {
    ko: "업로드 가능한 파일의 최대 용량은 {maxFileSize}입니다.",
    en: "You can upload a file up to {maxFileSize}",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_UPLOAD_DIALOG_FILE_PLACEHOLDER: {
    ko: "Upload File을 첨부해 주세요.",
    en: "Attach the file to upload.",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_VALIDATE_SUCC: {
    ko: "Upload한 Attachment File의 Validate가 정상적으로 요청되었습니다.",
    en: "Validate was successfully requested for the uploaded Attachment File.",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_VALIDATE_FAIL: {
    ko: "Upload한 Attachment File의 Validate 요청에 실패했습니다. ({message})",
    en: "Failed to request to validate the uploaded attachment file. ({message})",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_CAPTURE_SUCC: {
    ko: "Upload Attachment File을 선택한 Subject에 Save가 정상적으로 요청되었습니다.",
    en: "The Upload Attachment File Save to the selected Subject was successfully requested.",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_CAPTURE_FAIL: {
    ko: "선택한 Subject에 Upload Attachment File Save 요청에 실패했습니다.",
    en: "Failed to request for save upoad attachment file to selected subject.",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_CAPTURE_RESULT_DIALOG_HEADER: {
    ko: "Save Result",
    en: "Save Result",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_CAPTURE_RESULT_DIALOG_VIEW_CRF_BTN: {
    ko: "View CRF",
    en: "View CRF",
  },
  CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_BTN_UPLOAD_FILE: {
    ko: "Upload Attachment File",
    en: "Upload Attachment File",
  },
};

MvnCodeInitiate(CDMS_STUDY_DATA_INTEGRATION_ATTACHMENT_LANG);
