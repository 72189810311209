import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const USER_CODE: {
  MAIL_ALREADY_SENT: MvnCodeBE;
  CREATION_MAIL_SENT_ALREADY: MvnCodeBE;
  CREATION_MAIL_SEND_STATUS_INVALID: MvnCodeBE;
  CREATION_MAIL_RESERVED_ALREADY: MvnCodeBE;
  ENTITY_NOT_FOUND: MvnCodeBE;
  ENTITY_INVALID: MvnCodeBE;
  PHONE_INVALID: MvnCodeBE;
  USER_AVAILABLE_ALREADY: MvnCodeBE;
} = {
  // TODO. MAIL_ALREADY_SENT Deprecated. 삭제 필요
  MAIL_ALREADY_SENT: {
    status: 400,
    ko: "메일이 이미 전송된 유저입니다.",
    en: "Mail has already been sent to this user.",
  },
  CREATION_MAIL_SENT_ALREADY: {
    status: 400,
    ko: "메일이 이미 전송된 유저입니다.",
    en: "Mail has already been sent to this user.",
  },
  CREATION_MAIL_SEND_STATUS_INVALID: {
    status: 409,
    ko: "사용자의 메일 전송 상태값이 올바르지 않습니다.",
    en: "The requested user status of email sent is not valid.",
  },
  CREATION_MAIL_RESERVED_ALREADY: {
    status: 409,
    ko: "이메일 발송이 예약된 사용자입니다.",
    en: "The requested user has been scheduled to send an email.",
  },
  ENTITY_NOT_FOUND: {
    status: 404,
    ko: "존재하지 않는 유저입니다.",
    en: "The requested user does not exist.",
  },
  ENTITY_INVALID: {
    status: 400,
    ko: "유효하지 않는 유저입니다.",
    en: "The requested user is not valid.",
  },
  PHONE_INVALID: {
    status: 400,
    ko: "입력한 전화번호 형식이 올바르지 않습니다.",
    en: "Invalid phone number format",
  },
  USER_AVAILABLE_ALREADY: {
    status: 400,
    ko: "User가 이미 활성화된 상태입니다.",
    en: "The user is already active.",
  },
};

MvnCodeInitiate(USER_CODE);
