import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CASE_REPORT_IMPORT_CODE: {
  SAFETY_CASE_REPORT_IMPORT_SPEC_NOT_FOUND: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_FILE_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_FILE_DOWNLOAD_URL_EXPIRED: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_FILE_DOWNLOAD_REQUESTER_NOT_MATCH: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_INVALID_DUPLICATE_CASE: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_INVALID_WORKFLOW_STATUS: MvnCodeBE;
} = {
  SAFETY_CASE_REPORT_IMPORT_SPEC_NOT_FOUND: {
    status: 404,
    ko: "Case report import 스펙 정보가 존재하지 않습니다.",
    en: "Case report import spec does not exist.",
  },
  SAFETY_CASE_REPORT_IMPORT_FILE_NOT_EXIST: {
    status: 404,
    ko: "Case report import File이 존재하지 않습니다.",
    en: "Case report import file dose not exist.",
  },
  SAFETY_CASE_REPORT_IMPORT_FILE_DOWNLOAD_URL_EXPIRED: {
    status: 409,
    ko: "Import file 다운로드 URL이 만료되었습니다.",
    en: "Import file download url has expired.",
  },
  SAFETY_CASE_REPORT_IMPORT_FILE_DOWNLOAD_REQUESTER_NOT_MATCH: {
    status: 400,
    ko: "Import file을 업로드한 사용자와 download 요청자가 일치하지 않습니다.",
    en: "File uploaded user and download requester do not match.",
  },
  SAFETY_CASE_REPORT_IMPORT_INVALID_DUPLICATE_CASE: {
    status: 409,
    ko: "중복된 case가 존재합니다.",
    en: "Duplicate case(s) exist.",
  },
  SAFETY_CASE_REPORT_IMPORT_INVALID_WORKFLOW_STATUS: {
    status: 400,
    ko: "진행중인 workflow가 존재합니다.",
    en: "There is a workflow in progress.",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_IMPORT_CODE);
