import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_CONTACT_SALES_LANG: {
  AUTH_CONTACT_SALES_HEADER: MvnCodeFE;
  AUTH_CONTACT_SALES_BUTTON: MvnCodeFE;
  AUTH_CONTACT_SALES_INQUIRY_BUTTON: MvnCodeFE;
  AUTH_CONTACT_SALES_PRODUCT_LABEL: MvnCodeFE;
  AUTH_CONTACT_SALES_NAME_LABEL: MvnCodeFE;
  AUTH_CONTACT_SALES_EMAIL_LABEL: MvnCodeFE;
  AUTH_CONTACT_SALES_PHONE_LABEL: MvnCodeFE;
  AUTH_CONTACT_SALES_COMPANY_LABEL: MvnCodeFE;
  AUTH_CONTACT_SALES_DIVISION_LABEL: MvnCodeFE;
  AUTH_CONTACT_SALES_ESTIMATED_USERS_LABEL: MvnCodeFE;
  AUTH_CONTACT_SALES_SERVICE_REVIEW_STATUS_LABEL: MvnCodeFE;
  AUTH_CONTACT_SALES_COMMENT_LABEL: MvnCodeFE;
  AUTH_CONTACT_SALES_DOCS_CUSTOMIZING_TEMPLATES_LABEL: MvnCodeFE;
  AUTH_CONTACT_SALES_PRIVACY_POLICY_CONSENT_PREFIX: MvnCodeFE;
  AUTH_CONTACT_SALES_PRIVACY_POLICY_CONSENT: MvnCodeFE;
  AUTH_CONTACT_SALES_PRIVACY_POLICY_CONSENT_SUFFIX: MvnCodeFE;
  AUTH_CONTACT_SALES_REVIEW_STATUS_1: MvnCodeFE;
  AUTH_CONTACT_SALES_REVIEW_STATUS_2: MvnCodeFE;
  AUTH_CONTACT_SALES_REVIEW_STATUS_3: MvnCodeFE;
  AUTH_CONTACT_SALES_REVIEW_STATUS_4: MvnCodeFE;
  AUTH_CONTACT_SALES_REVIEW_STATUS_5: MvnCodeFE;
  AUTH_CONTACT_SALES_REVIEW_STATUS_6: MvnCodeFE;
  AUTH_CONTACT_SALES_INQUIRY_SUCC: MvnCodeFE;
  AUTH_CONTACT_SALES_INQUIRY_FAIL: MvnCodeFE;
} = {
  AUTH_CONTACT_SALES_HEADER: {
    ko: "제품 및 견적 문의",
    en: "Contact Sales",
  },
  AUTH_CONTACT_SALES_BUTTON: {
    ko: "세일즈 문의",
    en: "Contact Sales",
  },
  AUTH_CONTACT_SALES_INQUIRY_BUTTON: {
    ko: "문의하기",
    en: "Contact Sales",
  },
  AUTH_CONTACT_SALES_PRODUCT_LABEL: {
    ko: "제품",
    en: "Product",
  },
  AUTH_CONTACT_SALES_NAME_LABEL: {
    ko: "성명",
    en: "Name",
  },
  AUTH_CONTACT_SALES_EMAIL_LABEL: {
    ko: "이메일",
    en: "Email",
  },
  AUTH_CONTACT_SALES_PHONE_LABEL: {
    ko: "연락처",
    en: "Phone",
  },
  AUTH_CONTACT_SALES_COMPANY_LABEL: {
    ko: "회사명",
    en: "Company Name",
  },
  AUTH_CONTACT_SALES_DIVISION_LABEL: {
    ko: "부서",
    en: "Division",
  },
  AUTH_CONTACT_SALES_ESTIMATED_USERS_LABEL: {
    ko: "예상 사용자 수",
    en: "Number of Estimated Users",
  },
  AUTH_CONTACT_SALES_SERVICE_REVIEW_STATUS_LABEL: {
    ko: "서비스 검토 단계",
    en: "Service Review Status",
  },
  AUTH_CONTACT_SALES_COMMENT_LABEL: {
    ko: "내용",
    en: "Comment",
  },
  AUTH_CONTACT_SALES_DOCS_CUSTOMIZING_TEMPLATES_LABEL: {
    ko: "커스텀 템플릿 수",
    en: "Number of Custom Templates",
  },
  AUTH_CONTACT_SALES_PRIVACY_POLICY_CONSENT_PREFIX: {
    ko: "",
    en: "I consent to ",
  },
  AUTH_CONTACT_SALES_PRIVACY_POLICY_CONSENT: {
    ko: "개인정보 수집 및 이용",
    en: "Collection and Use of Personal Information",
  },
  AUTH_CONTACT_SALES_PRIVACY_POLICY_CONSENT_SUFFIX: {
    ko: "에 동의합니다.",
    en: ".",
  },
  AUTH_CONTACT_SALES_REVIEW_STATUS_1: {
    ko: "현재 유료로 구독하고 있습니다.",
    en: "Already subscribe plan.",
  },
  AUTH_CONTACT_SALES_REVIEW_STATUS_2: {
    ko: "최대한 빨리 사용하고 싶습니다.",
    en: "As soon as possible.",
  },
  AUTH_CONTACT_SALES_REVIEW_STATUS_3: {
    ko: "3개월 안에 사용하고 싶습니다.",
    en: "Within 3 months.",
  },
  AUTH_CONTACT_SALES_REVIEW_STATUS_4: {
    ko: "6개월 안에 사용하고 싶습니다.",
    en: "Within 6 months.",
  },
  AUTH_CONTACT_SALES_REVIEW_STATUS_5: {
    ko: "12개월 안에 사용하고 싶습니다.",
    en: "Within 12 months.",
  },
  AUTH_CONTACT_SALES_REVIEW_STATUS_6: {
    ko: "정해진 일정은 없지만 도입 검토 중입니다.",
    en: "Under consideration but no fixed schedule.",
  },
  AUTH_CONTACT_SALES_INQUIRY_SUCC: {
    ko: "문의를 전송하였습니다.\n\n빠른 시일 내에 입력하신 연락처로 회신 드리겠습니다.",
    en: "Inquiry has been requested successfully.\n\nWe will reply to you as soon as possible.",
  },
  AUTH_CONTACT_SALES_INQUIRY_FAIL: {
    ko: "문의 전송에 실패하였습니다. ({message})",
    en: "Failed to request inquiry. ({message})",
  },
};

MvnCodeInitiate(AUTH_CONTACT_SALES_LANG);
