import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_ROLE_UPLOAD_CODE: {
  BUILDER_STUDY_ROLE_UPLOAD_FILE_EXTENSION_INVALID: MvnCodeBE;
} = {
  BUILDER_STUDY_ROLE_UPLOAD_FILE_EXTENSION_INVALID: {
    status: 400,
    ko: "확장자가 {type}인 파일은 업로드할 수 없습니다.",
    en: "You can't upload files with the extension {type}.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_ROLE_UPLOAD_CODE);
