/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const MSA_CODE: {
  MSA_RESOURCE_NOT_FOUND: MvnCodeBE;
  MSA_RESOURCE_NOT_AVAILABLE: MvnCodeBE;
  MSA_RESOURCE_NOT_AUTHORIZED: MvnCodeBE;
  MSA_RESOURCE_CONFLICT: MvnCodeBE;
  MSA_RESOURCE_NOT_VALID_MAX_LENGTH_EXCEED: MvnCodeBE;
  MSA_NOT_AUTHORIZED: MvnCodeBE;
  MSA_NOT_AUTHORIZED_GENERAL: MvnCodeBE;
  MSA_PARAMETER_NOT_VALID: MvnCodeBE;
  MSA_PARAMETER_MISSING: MvnCodeBE;
  MSA_TOO_MANY_REQUESTS: MvnCodeBE;
  MSA_NOT_AFFECTED: MvnCodeBE;
  MSA_REQUEST_NOT_VALID: MvnCodeBE;
  MSA_REQUEST_NOT_VALID_DETAIL: MvnCodeBE;
  // 아래 Prefix 없는 코드 사용 금지 (common.code와 Key 충돌)
  RESOURCE_NOT_FOUND: MvnCodeBE;
  RESOURCE_NOT_AVAILABLE: MvnCodeBE;
  RESOURCE_NOT_AUTHORIZED: MvnCodeBE;
  NOT_AUTHORIZED: MvnCodeBE;
  NOT_AUTHORIZED_GENERAL: MvnCodeBE;
  PARAMETER_NOT_VALID: MvnCodeBE;
} = {
  MSA_RESOURCE_NOT_FOUND: {
    code: "MSA_RESOURCE_NOT_FOUND",
    status: 404,
    ko: "요청하신 리소스({type}, {id})를 찾을 수 없습니다.",
    en: "The requested resource({type}, {id}) could not be found.",
  },
  MSA_RESOURCE_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 리소스({type}, {id})가 올바르지 않습니다.",
    en: "The requested resource({type}, {id}) is not valid.",
  },
  MSA_RESOURCE_NOT_AUTHORIZED: {
    status: 403,
    ko: "요청하신 리소스({type}, {id})에 대한 접근 권한이 없습니다.",
    en: "Access denied, You don't have permission to access on resource({type}, {id}).",
  },
  MSA_RESOURCE_CONFLICT: {
    status: 409,
    ko: "요청하신 리소스({type}, {id})가 이미 존재합니다.",
    en: "The requested resource({type}, {id}) is already exist.",
  },
  MSA_RESOURCE_NOT_VALID_MAX_LENGTH_EXCEED: {
    status: 400,
    ko: "{key}의 길이는 최대 {length}자 입니다.",
    en: "{key} can be up to {length} characters long.",
  },
  MSA_NOT_AUTHORIZED: {
    status: 403,
    ko: "요청하신 기능({type})에 대한 사용 권한이 없습니다.",
    en: "Access denied, You don't have permission to perform the action({type}).",
  },
  MSA_NOT_AUTHORIZED_GENERAL: {
    status: 403,
    ko: "해당 기능에 접근 권한이 없습니다. 관리자에게 문의해 주세요.",
    en: "Not authorized to access. Please contact your administrator.",
  },
  MSA_PARAMETER_NOT_VALID: {
    status: 400,
    ko: "입력한 요청값({key}: {value})이 올바르지 않습니다.",
    en: "The specified value({key}: {value}) is not valid.",
  },
  MSA_PARAMETER_MISSING: {
    status: 400,
    ko: "필수 요청값이 누락되었습니다. ({value})",
    en: "Required value is missing. ({value})",
  },
  MSA_TOO_MANY_REQUESTS: {
    status: 429,
    ko: "일시적으로 요청이 차단되었습니다. 잠시 후 다시 이용해 주세요.",
    en: "Your request has been temporarily blocked. Please try again later.",
  },
  MSA_NOT_AFFECTED: {
    status: 409,
    ko: "변경된 내용이 없습니다.",
    en: "There are no changes.",
  },
  MSA_REQUEST_NOT_VALID: {
    status: 400,
    ko: "유효하지 않은 요청입니다.",
    en: "The requested is not valid.",
  },
  MSA_REQUEST_NOT_VALID_DETAIL: {
    status: 400,
    ko: "유효하지 않은 요청입니다. ({desc})",
    en: "The requested is not valid. ({desc})",
  },
  RESOURCE_NOT_FOUND: {
    status: 404,
    ko: "요청하신 리소스({type}, {id})를 찾을 수 없습니다.",
    en: "The requested resource({type}, {id}) could not be found.",
  },
  RESOURCE_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 리소스({type}, {id})가 올바르지 않습니다.",
    en: "The requested resource({type}, {id}) is not valid.",
  },
  RESOURCE_NOT_AUTHORIZED: {
    status: 403,
    ko: "요청하신 리소스({type}, {id})에 대한 접근 권한이 없습니다.",
    en: "Access denied, You don't have permission to access on resource({type}, {id}).",
  },
  NOT_AUTHORIZED: {
    status: 403,
    ko: "요청하신 기능({type})에 대한 사용 권한이 없습니다.",
    en: "Access denied, You don't have permission to perform the action({type}).",
  },
  NOT_AUTHORIZED_GENERAL: {
    status: 403,
    ko: "해당 기능에 접근 권한이 없습니다. 관리자에게 문의해 주세요.",
    en: "Not authorized to access. Please contact your administrator.",
  },
  PARAMETER_NOT_VALID: {
    status: 400,
    ko: "입력한 요청값({key}: {value})이 올바르지 않습니다.",
    en: "The specified value({key}: {value}) is not valid.",
  },
};

MvnCodeInitiate(MSA_CODE);
