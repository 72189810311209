/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const TRAINING_LANG: {
  TRAINING_HOME_BREADCRUMB: MvnCodeFE;
  TRAINING_REQUIRE_CERTIFICATE: MvnCodeFE;
  TRAINING_DIALOG_WARNING_SKIP_ALLOWED_ONCE_DESC: MvnCodeFE;
  GO_TO_TRAINING_BTN: MvnCodeFE;
  GET_CERTIFICATE_LATER_BTN: MvnCodeFE;
  MANAGE_CERTIFICATE_BREADCRUMB: MvnCodeFE;
  TRAINING_CERTIFICATE_DOWNLOAD_REQUESTED: MvnCodeFE;
  TRAINING_CERTIFICATE_DOWNLOAD_REQUEST_FAILED: MvnCodeFE;
  TRAINING_CERTIFICATE_REQUEST_FAILED: MvnCodeFE;
  TRAINING_CERTIFICATE_REQUESTED: MvnCodeFE;
  TRAINING_CERTIFICATE_VALIDATE_FAILED: MvnCodeFE;
  TRAINING_CERTIFICATE_VALIDATE_ERROR: MvnCodeFE;
  TRAINING_VIEW_BTN_LIST: MvnCodeFE;
  TRAINING_ANSWER_VIEW_CORRECT: MvnCodeFE;
  TRAINING_ANSWER_VIEW_INCORRECT: MvnCodeFE;
  TRAINING_ANSWER_VIEW_REQUEST_BTN_CERTIFICATE: MvnCodeFE;
  ADD_SECTION_TITLE: MvnCodeFE;
  ADD_LABEL_TITLE: MvnCodeFE;
  ADD_PLACEHOLDER_TITLE: MvnCodeFE;
  ADD_LABEL_CERTI_TITLE: MvnCodeFE;
  ADD_PLACEHOLDER_CERTI_TITLE: MvnCodeFE;
  ADD_LABEL_VERSION: MvnCodeFE;
  ADD_PLACEHOLDER_VERSION: MvnCodeFE;
  ADD_SUCC: MvnCodeFE;
  ADD_FAIL: MvnCodeFE;
  LIST_COL_CONTENT: MvnCodeFE;
  LIST_COL_VERSION: MvnCodeFE;
  LIST_COL_ENABLED: MvnCodeFE;
  LIST_BTN_ADD: MvnCodeFE;
  VIEW_LABEL_TITLE: MvnCodeFE;
  VIEW_PLACEHOLDER_TITLE: MvnCodeFE;
  VIEW_LABEL_CERTI_TITLE: MvnCodeFE;
  VIEW_PLACEHOLDER_CERTI_TITLE: MvnCodeFE;
  VIEW_LABEL_VERSION: MvnCodeFE;
  VIEW_PLACEHOLDER_VERSION: MvnCodeFE;
  VIEW_LABEL_ACTIVATED: MvnCodeFE;
  VIEW_UPDATE_SUCC: MvnCodeFE;
  VIEW_UPDATE_FAIL: MvnCodeFE;
  VIEW_BTN_PAGE_LINK: MvnCodeFE;
  VIEW_BTN_QUESTION_LINK: MvnCodeFE;
  VIEW_SECTION_TITLE: MvnCodeFE;
  PAGE_ADD_SECTION_TITLE: MvnCodeFE;
  PAGE_ADD_LABEL_TITLE: MvnCodeFE;
  PAGE_ADD_PLACEHOLDER_TITLE: MvnCodeFE;
  PAGE_ADD_LABEL_SEQ: MvnCodeFE;
  PAGE_ADD_PLACEHOLDER_SEQ: MvnCodeFE;
  PAGE_ADD_LABEL_IMG: MvnCodeFE;
  PAGE_ADD_TEXT_IMG: MvnCodeFE;
  PAGE_ADD_SUCC: MvnCodeFE;
  PAGE_ADD_FAIL: MvnCodeFE;
  PAGE_LIST_COL_TITLE: MvnCodeFE;
  PAGE_LIST_COL_URL: MvnCodeFE;
  PAGE_LIST_COL_SEQ: MvnCodeFE;
  PAGE_LIST_COL_ENABLED: MvnCodeFE;
  PAGE_LIST_BTN_BACK_TO_DETAIL_LINK: MvnCodeFE;
  PAGE_LIST_ADD_BTN_PAGE_LINK: MvnCodeFE;
  PAGE_VIEW_LABEL_TITLE: MvnCodeFE;
  PAGE_VIEW_PLACEHOLDER_TITLE: MvnCodeFE;
  PAGE_VIEW_LABEL_SEQ: MvnCodeFE;
  PAGE_VIEW_PLACEHOLDER_SEQ: MvnCodeFE;
  PAGE_VIEW_LABEL_IMG: MvnCodeFE;
  PAGE_VIEW_TEXT_IMG: MvnCodeFE;
  PAGE_VIEW_LABEL_ACTIVATED: MvnCodeFE;
  PAGE_VIEW_MODIFY_SUCC: MvnCodeFE;
  PAGE_VIEW_MODIFY_FAIL: MvnCodeFE;
  PAGE_VIEW_SECTION_TITLE: MvnCodeFE;
  QUESTION_ADD_SECTION_TITLE: MvnCodeFE;
  QUESTION_ADD_LABEL_CONTENT: MvnCodeFE;
  QUESTION_ADD_PLACEHOLDER_CONTENT: MvnCodeFE;
  QUESTION_ADD_LABEL_SEQ: MvnCodeFE;
  QUESTION_ADD_PLACEHOLDER_SEQ: MvnCodeFE;
  QUESTION_ADD_SUCC: MvnCodeFE;
  QUESTION_ADD_FAIL: MvnCodeFE;
  QUESTION_LIST_COL_CONTENT: MvnCodeFE;
  QUESTION_LIST_COL_SEQ: MvnCodeFE;
  QUESTION_LIST_BTN_BACK_TO_DETAIL_LINK: MvnCodeFE;
  QUESTION_LIST_BTN_ADD: MvnCodeFE;
  QUESTION_LIST_SECTION_TITLE: MvnCodeFE;
  QUESTION_VIEW_LABEL_CONTENT: MvnCodeFE;
  QUESTION_VIEW_PLACEHOLDER_CONTENT: MvnCodeFE;
  QUESTION_VIEW_LABEL_SEQ: MvnCodeFE;
  QUESTION_VIEW_PLACEHOLDER_SEQ: MvnCodeFE;
  QUESTION_VIEW_MODIFY_SUCC: MvnCodeFE;
  QUESTION_VIEW_MODIFY_FAIL: MvnCodeFE;
  QUESTION_VIEW_BTN_ANSWER_LIST_LINK: MvnCodeFE;
  QUESTION_VIEW_SECTION_TITLE: MvnCodeFE;
  ANSWER_ADD_SECTION_TITLE: MvnCodeFE;
  ANSWER_ADD_LABEL_CONTENT: MvnCodeFE;
  ANSWER_ADD_PLACEHOLDER_CONTENT: MvnCodeFE;
  ANSWER_ADD_LABEL_SEQ: MvnCodeFE;
  ANSWER_ADD_PLACEHOLDER_SEQ: MvnCodeFE;
  ANSWER_ADD_LABEL_IS_ANSWER: MvnCodeFE;
  ANSWER_ADD_SUCC: MvnCodeFE;
  ANSWER_ADD_FAIL: MvnCodeFE;
  ANSWER_LIST_COL_CONTENT: MvnCodeFE;
  ANSWER_LIST_COL_SEQ: MvnCodeFE;
  ANSWER_LIST_COL_IS_ANSWER: MvnCodeFE;
  ANSWER_LIST_BTN_BACK_TO_DETAIL_LINK: MvnCodeFE;
  ANSWER_LIST_BTN_ADD_LINK: MvnCodeFE;
  ANSWER_VIEW_LABEL_CONTENT: MvnCodeFE;
  ANSWER_VIEW_PLACEHOLDER_CONTENT: MvnCodeFE;
  ANSWER_VIEW_LABEL_SEQ: MvnCodeFE;
  ANSWER_VIEW_PLACEHOLDER_SEQ: MvnCodeFE;
  ANSWER_VIEW_LABEL_IS_ANSWER: MvnCodeFE;
  ANSWER_VIEW_MODIFY_SUCC: MvnCodeFE;
  ANSWER_VIEW_MODIFY_FAIL: MvnCodeFE;
  ANSWER_VIEW_SECTION_TITLE: MvnCodeFE;
} = {
  TRAINING_HOME_BREADCRUMB: {
    ko: "E-Training을 확인하고 인증서를 발급 받을 수 있습니다.",
    en: "Conduct E-Training and download certificate.",
  },
  TRAINING_REQUIRE_CERTIFICATE: {
    ko: `E-Training에 대한 Certificate가 만료되어 재수료가 필요합니다.
    아래의 [{button}] 버튼을 클릭하여 재수료를 진행해 주세요.`,
    en: `The certificate for E-Training has expired.
    Please click the [{button}] button below to retake E-Training.`,
  },
  TRAINING_DIALOG_WARNING_SKIP_ALLOWED_ONCE_DESC: {
    ko: "*[{skipForNow}]는 1회만 가능합니다.",
    en: "*[{skipForNow}] can only be used once.",
  },
  GO_TO_TRAINING_BTN: {
    ko: "E-Training 진행하기",
    en: "Proceed to E-Training",
  },
  GET_CERTIFICATE_LATER_BTN: {
    ko: "나중에 하기",
    en: "Skip for now",
  },
  MANAGE_CERTIFICATE_BREADCRUMB: {
    ko: "임상시험 스터디 구성원의 E-Training 인증서 목록을 확인할 수 있습니다.",
    en: "Inquire E-Training certificates of subjects.",
  },
  TRAINING_CERTIFICATE_DOWNLOAD_REQUESTED: {
    ko: "Training certificate 다운로드가 요청되었습니다.",
    en: "Training certificate download has been requested.",
  },
  TRAINING_CERTIFICATE_DOWNLOAD_REQUEST_FAILED: {
    ko: "Training certificate 다운로드 요청에 실패하였습니다. ({message})",
    en: "Failed to download Training certificate. ({message})",
  },
  TRAINING_CERTIFICATE_REQUEST_FAILED: {
    ko: "Training certificate 발급 요청에 실패하였습니다. ({message})",
    en: "Failed to issue Training certificate. ({message})",
  },
  TRAINING_CERTIFICATE_REQUESTED: {
    ko: "Training certificate를 발급하였습니다.",
    en: "Training certificate has been issued.",
  },
  TRAINING_CERTIFICATE_VALIDATE_FAILED: {
    ko: "{roleName}의 E-Training을 수료하지 않았습니다. E-Training을 먼저 진행하여 주세요.",
    en: "E-Training of {roleName} is not yet completed. Please proceed to complete E-Training.",
  },
  TRAINING_CERTIFICATE_VALIDATE_ERROR: {
    ko: "일시적으로 study에 접근할 수 없는 상태입니다. 잠시 후 다시 시도해주십시오. ({message})",
    en: "Access to the study is temporarily unavailable. Please try again later. ({message})",
  },
  TRAINING_VIEW_BTN_LIST: {
    ko: "Training List",
    en: "Training List",
  },
  TRAINING_ANSWER_VIEW_CORRECT: {
    ko: "정답입니다. 아래 '인증서 받기' 버튼을 클릭해 인증서를 신청하세요.",
    en: "Correct answer. Click the 'Receive certificate' button for the certificate.",
  },
  TRAINING_ANSWER_VIEW_INCORRECT: {
    ko: "정답이 아닙니다. 다시 선택해주세요.",
    en: "Incorrect answer. Please select again.",
  },
  TRAINING_ANSWER_VIEW_REQUEST_BTN_CERTIFICATE: {
    ko: "인증서 받기",
    en: "Receive certificate",
  },
  ADD_SECTION_TITLE: {
    ko: "E-Training 추가",
    en: "Add E-Training",
  },
  ADD_LABEL_TITLE: {
    ko: "Title",
    en: "Title",
  },
  ADD_PLACEHOLDER_TITLE: {
    ko: "E-Training title을 입력해주세요.",
    en: "Enter E-Training title.",
  },
  ADD_LABEL_CERTI_TITLE: {
    ko: "Certificate Title",
    en: "Certificate Title",
  },
  ADD_PLACEHOLDER_CERTI_TITLE: {
    ko: "E-Training certificate title을 입력해주세요.",
    en: "Enter E-Training certificate title.",
  },
  ADD_LABEL_VERSION: {
    ko: "Version",
    en: "Version",
  },
  ADD_PLACEHOLDER_VERSION: {
    ko: "E-Training version을 입력해주세요.",
    en: "Enter E-Training version.",
  },
  ADD_SUCC: {
    ko: "신규 E-Training 등록에 성공하였습니다.",
    en: "A new E-Training has beed registered.",
  },
  ADD_FAIL: {
    ko: "신규 E-Training 등록에 실패하였습니다. ({error})",
    en: "Failed to register a E-Training. ({error})",
  },
  LIST_COL_CONTENT: {
    ko: "Content",
    en: "Content",
  },
  LIST_COL_VERSION: {
    ko: "Version",
    en: "Version",
  },
  LIST_COL_ENABLED: {
    ko: "활성여부",
    en: "Activated",
  },
  LIST_BTN_ADD: {
    ko: "Add E-Training",
    en: "Add E-Training",
  },
  VIEW_LABEL_TITLE: {
    ko: "Title",
    en: "Title",
  },
  VIEW_PLACEHOLDER_TITLE: {
    ko: "Title을 입력해 주세요.",
    en: "Enter title.",
  },
  VIEW_LABEL_CERTI_TITLE: {
    ko: "Certificate Title",
    en: "Certificate Title",
  },
  VIEW_PLACEHOLDER_CERTI_TITLE: {
    ko: "Certificate Title을 입력해 주세요.",
    en: "Enter certificate title.",
  },
  VIEW_LABEL_VERSION: {
    ko: "Version",
    en: "Version",
  },
  VIEW_PLACEHOLDER_VERSION: {
    ko: "Version을 입력해 주세요.",
    en: "Enter Version.",
  },
  VIEW_LABEL_ACTIVATED: {
    ko: "활성여부",
    en: "Activated",
  },
  VIEW_UPDATE_SUCC: {
    ko: "E-Training 갱신에 성공하였습니다.",
    en: "The E-Training has been modified successfully.",
  },
  VIEW_UPDATE_FAIL: {
    ko: "E-Training 갱신에 실패하였습니다. ({error})",
    en: "Failed to modify a E-Training. ({error})",
  },
  VIEW_BTN_PAGE_LINK: {
    ko: "Page List",
    en: "Page List",
  },
  VIEW_BTN_QUESTION_LINK: {
    ko: "Question List",
    en: "Question List",
  },
  VIEW_SECTION_TITLE: {
    ko: "E-Training",
    en: "E-Training",
  },
  PAGE_ADD_SECTION_TITLE: {
    ko: "E-Training Page 추가",
    en: "Add E-Training Page",
  },
  PAGE_ADD_LABEL_TITLE: {
    ko: "Title",
    en: "Title",
  },
  PAGE_ADD_PLACEHOLDER_TITLE: {
    ko: "Page 제목을 입력해주세요.",
    en: "Enter Page Title.",
  },
  PAGE_ADD_LABEL_SEQ: {
    ko: "Sequence",
    en: "Sequence",
  },
  PAGE_ADD_PLACEHOLDER_SEQ: {
    ko: "page sequence를 입력해주세요.",
    en: "Enter page sequence.",
  },
  PAGE_ADD_LABEL_IMG: {
    ko: "Image 파일",
    en: "Image File",
  },
  PAGE_ADD_TEXT_IMG: {
    ko: "Page Image 등록",
    en: "Register Page Image",
  },
  PAGE_ADD_SUCC: {
    ko: "신규 E-Training page 등록에 성공하였습니다.",
    en: "A new E-Training page has beed registered.",
  },
  PAGE_ADD_FAIL: {
    ko: "신규 E-Training page 등록에 실패하였습니다. ({error})",
    en: "Failed to register a E-Training page. ({error})",
  },
  PAGE_LIST_COL_TITLE: {
    ko: "Title",
    en: "Title",
  },
  PAGE_LIST_COL_URL: {
    ko: "URL",
    en: "URL",
  },
  PAGE_LIST_COL_SEQ: {
    ko: "Sequence",
    en: "Sequence",
  },
  PAGE_LIST_COL_ENABLED: {
    ko: "활성여부",
    en: "Activated",
  },
  PAGE_LIST_BTN_BACK_TO_DETAIL_LINK: {
    ko: "Back to E-Training Detail",
    en: "Back to E-Training Detail",
  },
  PAGE_LIST_ADD_BTN_PAGE_LINK: {
    ko: "Add Page",
    en: "Add Page",
  },
  PAGE_VIEW_LABEL_TITLE: {
    ko: "Title",
    en: "Title",
  },
  PAGE_VIEW_PLACEHOLDER_TITLE: {
    ko: "Page 제목을 입력해 주세요.",
    en: "Enter page title.",
  },
  PAGE_VIEW_LABEL_SEQ: {
    ko: "Sequence",
    en: "Sequence",
  },
  PAGE_VIEW_PLACEHOLDER_SEQ: {
    ko: "Sequence을 입력해 주세요.",
    en: "Enter Sequence.",
  },
  PAGE_VIEW_LABEL_IMG: {
    ko: "Image 파일",
    en: "Image File",
  },
  PAGE_VIEW_TEXT_IMG: {
    ko: "Page Image 등록",
    en: "Register Page Image",
  },
  PAGE_VIEW_LABEL_ACTIVATED: {
    ko: "활성여부",
    en: "Activated",
  },
  PAGE_VIEW_MODIFY_SUCC: {
    ko: "E-Training page를 수정하였습니다.",
    en: "The E-Training page has been modified successfully.",
  },
  PAGE_VIEW_MODIFY_FAIL: {
    ko: "E-Training page 수정에 실패하였습니다. ({error})",
    en: "Failed to modify a E-Training page. ({error})",
  },
  PAGE_VIEW_SECTION_TITLE: {
    ko: "E-Training",
    en: "E-Training",
  },
  QUESTION_ADD_SECTION_TITLE: {
    ko: "E-Training Question 추가",
    en: "Add E-Training Question",
  },
  QUESTION_ADD_LABEL_CONTENT: {
    ko: "Content",
    en: "Content",
  },
  QUESTION_ADD_PLACEHOLDER_CONTENT: {
    ko: "Question 내용을 입력해주세요.",
    en: "Enter question content.",
  },
  QUESTION_ADD_LABEL_SEQ: {
    ko: "Sequence",
    en: "Sequence",
  },
  QUESTION_ADD_PLACEHOLDER_SEQ: {
    ko: "Question sequence를 입력해주세요.",
    en: "Enter Question sequence.",
  },
  QUESTION_ADD_SUCC: {
    ko: "신규 E-Training question 등록에 성공하였습니다.",
    en: "A new E-Training question has beed registered.",
  },
  QUESTION_ADD_FAIL: {
    ko: "신규 E-Training question 등록에 실패하였습니다. ({error})",
    en: "Failed to register a E-Training question. ({error})",
  },
  QUESTION_LIST_COL_CONTENT: {
    ko: "Content",
    en: "Content",
  },
  QUESTION_LIST_COL_SEQ: {
    ko: "Sequence",
    en: "Sequence",
  },
  QUESTION_LIST_BTN_BACK_TO_DETAIL_LINK: {
    ko: "Back to E-Training Detail",
    en: "Back to E-Training Detail",
  },
  QUESTION_LIST_BTN_ADD: {
    ko: "Add Question",
    en: "Add Question",
  },
  QUESTION_LIST_SECTION_TITLE: {
    ko: "E-Training Question",
    en: "E-Training Question",
  },
  QUESTION_VIEW_LABEL_CONTENT: {
    ko: "Content",
    en: "Content",
  },
  QUESTION_VIEW_PLACEHOLDER_CONTENT: {
    ko: "Question 내용을 입력해 주세요.",
    en: "Enter question content.",
  },
  QUESTION_VIEW_LABEL_SEQ: {
    ko: "Sequence",
    en: "Sequence",
  },
  QUESTION_VIEW_PLACEHOLDER_SEQ: {
    ko: "Sequence를 입력해 주세요.",
    en: "Enter Sequence.",
  },
  QUESTION_VIEW_MODIFY_SUCC: {
    ko: "E-Training question을 수정하였습니다.",
    en: "The E-Training question has been modified successfully.",
  },
  QUESTION_VIEW_MODIFY_FAIL: {
    ko: "E-Training question 수정에 실패하였습니다. ({error})",
    en: "Failed to modify a E-Training question. ({error})",
  },
  QUESTION_VIEW_BTN_ANSWER_LIST_LINK: {
    ko: "Answer List",
    en: "Answer List",
  },
  QUESTION_VIEW_SECTION_TITLE: {
    ko: "E-Training Question",
    en: "E-Training Question",
  },
  ANSWER_ADD_SECTION_TITLE: {
    ko: "E-Training Answer 추가",
    en: "Add E-Training Answer",
  },
  ANSWER_ADD_LABEL_CONTENT: {
    ko: "Content",
    en: "Content",
  },
  ANSWER_ADD_PLACEHOLDER_CONTENT: {
    ko: "Answer 내용을 입력해주세요.",
    en: "Enter answer content.",
  },
  ANSWER_ADD_LABEL_SEQ: {
    ko: "Sequence",
    en: "Sequence",
  },
  ANSWER_ADD_PLACEHOLDER_SEQ: {
    ko: "Sequence를 입력해주세요.",
    en: "Enter Sequence.",
  },
  ANSWER_ADD_LABEL_IS_ANSWER: {
    ko: "정답여부",
    en: "Is Answer?",
  },
  ANSWER_ADD_SUCC: {
    ko: "신규 E-Training answer 등록에 성공하였습니다.",
    en: "A new E-Training answer has beed registered.",
  },
  ANSWER_ADD_FAIL: {
    ko: "신규 E-Training answer 등록에 실패하였습니다. ({error})",
    en: "Failed to register a E-Training answer. ({error})",
  },
  ANSWER_LIST_COL_CONTENT: {
    ko: "Content",
    en: "Content",
  },
  ANSWER_LIST_COL_SEQ: {
    ko: "Sequence",
    en: "Sequence",
  },
  ANSWER_LIST_COL_IS_ANSWER: {
    ko: "정답여부",
    en: "Is Answer",
  },
  ANSWER_LIST_BTN_BACK_TO_DETAIL_LINK: {
    ko: "Back to Answer Detail",
    en: "Back to Answer Detail",
  },
  ANSWER_LIST_BTN_ADD_LINK: {
    ko: "Add Answer",
    en: "Add Answer",
  },
  ANSWER_VIEW_LABEL_CONTENT: {
    ko: "Content",
    en: "Content",
  },
  ANSWER_VIEW_PLACEHOLDER_CONTENT: {
    ko: "Answer 내용을 입력해 주세요.",
    en: "Add answer content.",
  },
  ANSWER_VIEW_LABEL_SEQ: {
    ko: "Sequence",
    en: "Sequence",
  },
  ANSWER_VIEW_PLACEHOLDER_SEQ: {
    ko: "Sequence을 입력해 주세요.",
    en: "Add sequence.",
  },
  ANSWER_VIEW_LABEL_IS_ANSWER: {
    ko: "Sequence",
    en: "Sequence",
  },
  ANSWER_VIEW_MODIFY_SUCC: {
    ko: "E-Training answer를 수정하였습니다.",
    en: "The E-Training answer has been modified successfully.",
  },
  ANSWER_VIEW_MODIFY_FAIL: {
    ko: "E-Training answer 수정에 실패하였습니다. ({error})",
    en: "Failed to modify a E-Training answer. ({error})",
  },
  ANSWER_VIEW_SECTION_TITLE: {
    ko: "E-Training Answer",
    en: "E-Training Answer",
  },
};

MvnCodeInitiate(TRAINING_LANG);
