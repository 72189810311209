import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const ADMIN_RELEASE_CODE: {
  RELEASE_INVALID_DUPLICATE_SERVICE: MvnCodeBE;
} = {
  RELEASE_INVALID_DUPLICATE_SERVICE: {
    status: 409,
    ko: "이미 해당 서비스로 Release가 생성되었습니다. (service: {service})",
    en: "Release has already been created with that service. (service: {service})",
  },
};

MvnCodeInitiate(ADMIN_RELEASE_CODE);
