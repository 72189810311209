import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SSO_NOTIFICATION_MAIL_LANG: {
  SSO_NOTIFICATION_MAIL_VERIFY_CODE_TITLE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_VERIFY_CODE_CONTENT_001: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_VERIFY_CODE_CONTENT_002: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_VERIFY_CODE_TITLE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_VERIFY_CODE_CONTENT_001: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_VERIFY_CODE_CONTENT_VALID_DATE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_VERIFY_CODE_CONTENT_VALID_TIME: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_COMPLETE_TITLE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_COMPLETE_CONTENT: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_COMPLETE_TABLE_ITEM_LABEL: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_COMPLETE_GO_TO_MAVEN_CLINICAL_CLOUD: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_INIT_PASSWORD_VERIFY_CODE_TITLE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_INIT_PASSWORD_VERIFY_CODE_CONTENT: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_INIT_PASSWORD_VERIFY_CODE_EXPIRY_AT: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_MFA_VERIFY_CODE_EMAIL_TITLE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_MFA_VERIFY_CODE_EMAIL_CONTENT_001: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_MFA_VERIFY_CODE_EMAIL_CONTENT_002: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_SIGN_UP_COMPLETE_TITLE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_SIGN_UP_COMPLETE_CONTENT_001: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_SIGN_UP_COMPLETE_CONTENT_002: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_USER_ACCOUNT_LOCK_TITLE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_USER_ACCOUNT_LOCK_CONTENT: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_USER_ACCOUNT_LOCK_RESET_PASSWORD_LINK: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TITLE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TABLE_TITLE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TABLE_ITEM_LABEL_001: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TABLE_ITEM_LABEL_002: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TABLE_ITEM_LABEL_003: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TABLE_ITEM_LABEL_004: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_CONTENT_001: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_CONTENT_VALID_DATE: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_CONTENT_VALID_TIME: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_CONTENT_002: MvnCodeFE;
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_CONTENT_RESET_PASSWORD_LINK: MvnCodeFE;
} = {
  SSO_NOTIFICATION_MAIL_VERIFY_CODE_TITLE: {
    ko: "이메일을 인증하고 가입을 완료하세요",
    en: "Verify Your Email to Complete Sign Up",
  },
  SSO_NOTIFICATION_MAIL_VERIFY_CODE_CONTENT_001: {
    ko: "이메일 인증 번호는 다음과 같습니다.",
    en: "Your verification code is,",
  },
  SSO_NOTIFICATION_MAIL_VERIFY_CODE_CONTENT_002: {
    ko: "인증 번호를 입력하여 가입을 완료하세요.\n인증 번호의 유효기간은 다음과 같습니다.\n{expireAt}",
    en: "Please complete the sign-up by entering the verification code. This verification code will expire at {expireAt}",
  },
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_VERIFY_CODE_TITLE: {
    ko: "인증코드를 확인해 주세요.",
    en: "Please check your verification code.",
  },
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_VERIFY_CODE_CONTENT_001: {
    ko: "아래 인증 코드를 인증 창에 입력하여 비밀번호를 초기화하세요.\n문의 사항이 있는 경우, 하단 연락처로 문의해 주세요.",
    en: "Please enter the verification code below to reset the password.\nIf you have any questions, please contact us using the information provided below.",
  },
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_VERIFY_CODE_CONTENT_VALID_DATE: {
    ko: "유효기간 : {expireAt}",
    en: "Valid until : {expireAt}",
  },
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_VERIFY_CODE_CONTENT_VALID_TIME: {
    ko: "(30분 동안 유효)",
    en: "(Valid for 30 minutes)",
  },
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_COMPLETE_TITLE: {
    ko: "비밀번호가 변경되었습니다.",
    en: "Password has been changed.",
  },
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_COMPLETE_CONTENT: {
    ko: "회원님의 비밀번호 변경 내역을 안내해 드립니다.",
    en: "Here is the information regarding your password change.",
  },
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_COMPLETE_TABLE_ITEM_LABEL: {
    ko: "비밀번호 변경 일시",
    en: "Password Change\nDate and Time",
  },
  SSO_NOTIFICATION_MAIL_RESET_PASSWORD_COMPLETE_GO_TO_MAVEN_CLINICAL_CLOUD: {
    ko: "Maven Clinical Cloud 접속하기",
    en: "Go to Maven Clinical Cloud",
  },
  SSO_NOTIFICATION_MAIL_INIT_PASSWORD_VERIFY_CODE_TITLE: {
    ko: "인증코드를 확인해 주세요.",
    en: "Please check your verification code.",
  },
  SSO_NOTIFICATION_MAIL_INIT_PASSWORD_VERIFY_CODE_CONTENT: {
    ko: "아래 인증 코드를 인증창에 입력해 주시기 바랍니다.",
    en: "Please enter the verification code below into the verification field.",
  },
  SSO_NOTIFICATION_MAIL_INIT_PASSWORD_VERIFY_CODE_EXPIRY_AT: {
    ko: "유효 기간 : {expiryAt}",
    en: "expiration data : {expiryAt}",
  },
  SSO_NOTIFICATION_MAIL_MFA_VERIFY_CODE_EMAIL_TITLE: {
    ko: "이메일을 인증하고 로그인을 완료하세요.",
    en: "Verify your email to complete sign-in.",
  },
  SSO_NOTIFICATION_MAIL_MFA_VERIFY_CODE_EMAIL_CONTENT_001: {
    ko: "이메일 인증 번호는 다음과 같습니다.",
    en: "Your verification code is",
  },
  SSO_NOTIFICATION_MAIL_MFA_VERIFY_CODE_EMAIL_CONTENT_002: {
    ko: "인증 번호를 입력하여 로그인을 완료하세요.\n인증 번호의 유효기간은 다음과 같습니다.\n{expireAt}",
    en: "You can sign-in by entering the verification code.\nThis verification code will expire at {expireAt}",
  },
  SSO_NOTIFICATION_MAIL_SIGN_UP_COMPLETE_TITLE: {
    ko: "Maven Clinical Cloud를 선택해 주셔서 감사합니다",
    en: "Welcome to Maven Clinical Cloud",
  },
  SSO_NOTIFICATION_MAIL_SIGN_UP_COMPLETE_CONTENT_001: {
    ko: "Maven Clinical Cloud를 선택해주셔서 감사합니다.\n이제 Maven Clinical Cloud를 활용하여 임상 프로세스의 디지털화를 진행하세요.",
    en: "Thank you for joining Maven Clinical Cloud.\nNow it is time for digital transformation of your clinical trial process.",
  },
  SSO_NOTIFICATION_MAIL_SIGN_UP_COMPLETE_CONTENT_002: {
    ko: "아래 버튼을 눌러 Maven Clinical에 접속하세요.",
    en: "Click button below to start Maven Clinical Cloud.",
  },
  SSO_NOTIFICATION_MAIL_USER_ACCOUNT_LOCK_TITLE: {
    ko: "비밀번호 재설정",
    en: "Reset Password",
  },
  SSO_NOTIFICATION_MAIL_USER_ACCOUNT_LOCK_CONTENT: {
    ko: "비밀번호 5회 입력 실패로 계정이 잠겼습니다.\n하단의 ‘비밀번호 재설정’ 버튼을 눌러 비밀번호를 재설정해 주세요.",
    en: "Your account has been locked due to 5 failed password/login attempts.\nPlease click the ‘Reset Password’ button below to reset your password.",
  },
  SSO_NOTIFICATION_MAIL_USER_ACCOUNT_LOCK_RESET_PASSWORD_LINK: {
    ko: "비밀번호 재설정하기",
    en: "Reset Password",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TITLE: {
    ko: "새 기기에서 로그인이 시도되었습니다.\n이메일 인증을 완료해 주세요.",
    en: "Login attempted from a new device. \nPlease verify your email.",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TABLE_TITLE: {
    ko: "로그인 정보",
    en: "Login information",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TABLE_ITEM_LABEL_001: {
    ko: "Date",
    en: "Date",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TABLE_ITEM_LABEL_002: {
    ko: "Platform(OS)",
    en: "Platform(OS)",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TABLE_ITEM_LABEL_003: {
    ko: "Browser",
    en: "Browser",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_TABLE_ITEM_LABEL_004: {
    ko: "IP Address",
    en: "IP Address",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_CONTENT_001: {
    ko: "회원님의 계정이 새로운 기기(브라우저)에서 로그인 시도되었습니다.\n인증코드를 입력하여 이메일 인증을 완료해 주세요.",
    en: "Your account was logged in to from a new device(Browser).\nPlease enter the verification code to complete your sign-in.",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_CONTENT_VALID_DATE: {
    ko: "유효기간 : {expireAt}",
    en: "Valid until : {expireAt}",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_CONTENT_VALID_TIME: {
    ko: "(30분 동안 유효)",
    en: "(Valid for 30 minutes)",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_CONTENT_002: {
    ko: "직접 로그인한 것이 아니라면 비밀번호를 변경하세요.\n",
    en: "If this was not you, please reset your password.\n",
  },
  SSO_NOTIFICATION_MAIL_DEVICE_REGISTRATION_VERIFY_CODE_CONTENT_RESET_PASSWORD_LINK: {
    ko: "비밀번호 변경하기 >",
    en: "Reset Password >",
  },
};

MvnCodeInitiate(SSO_NOTIFICATION_MAIL_LANG);
