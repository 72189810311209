import React, { useState } from "react";
import { store } from "maven-lib/dist/states";
import { classNames, secondsToReadableString } from "maven-lib/dist/utils/misc";
import { useInterval } from "../../hooks/useInterval";
import dayjs from "dayjs";

interface TimerProps {
  awakeRequest: () => boolean;
  refreshRequest: () => boolean;
  busy: boolean;
  disabled: boolean;
  onTick: (secs: number) => void;
  defaultTitle?: string;
}

export function Timer(props: TimerProps) {
  const { awakeRequest, onTick, refreshRequest, busy, disabled } = props;
  const remainSeconds = () => dayjs(String(store.getState().session.expiryAt)).diff(dayjs(), "second");

  const [remainTime, setRemainTime] = useState(secondsToReadableString(remainSeconds()));

  useInterval(() => {
    const rs = remainSeconds();

    setRemainTime(secondsToReadableString(rs));

    if (rs <= 0) {
      awakeRequest();
    }

    onTick(rs);
  }, 1000);

  return (
    <>
      {remainTime && (
        <button
          className={classNames("timer", busy && "--busy", disabled && "--disabled")}
          title={props?.defaultTitle || "로그인 시간 연장하기"}
          onClick={() => {
            if (disabled) return;
            refreshRequest();
          }}
        >
          {remainTime}
        </button>
      )}
    </>
  );
}
