import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CASE_REPORT_EXPORT_CODE: {
  CASE_REPORT_EXPORT_NOT_FOUND: MvnCodeBE;
  CASE_REPORT_EXPORT_SPEC_NOT_FOUND: MvnCodeBE;
  CASE_REPORT_EXPORT_PAYLOAD_INVALID: MvnCodeBE;
  CASE_REPORT_EXPORT_PAYLOAD_KEY_MISSING: MvnCodeBE;
  CASE_REPORT_EXPORT_PAYLOAD_KEY_VALUE_MISSING: MvnCodeBE;
  CASE_REPORT_EXPORT_REQUEST_INVALID_RESOURCE_NOT_FOUND: MvnCodeBE;
  CASE_REPORT_EXPORT_HISTORY_DOWNLOAD_FILE_URL_EXPIRED: MvnCodeBE;
  CASE_REPORT_EXPORT_DOWNLOAD_REQUESTER_NOT_MATCH: MvnCodeBE;
  CASE_REPORT_EXPORT_MODIFY_INVALID_STATUS_NOT_DONE: MvnCodeBE;
  CASE_REPORT_EXPORT_VALIDATE_FAILED: MvnCodeBE;
} = {
  CASE_REPORT_EXPORT_NOT_FOUND: {
    status: 404,
    ko: "Case report export 정보가 존재하지 않습니다.",
    en: "Case report export entity does not exist.",
  },
  CASE_REPORT_EXPORT_SPEC_NOT_FOUND: {
    status: 404,
    ko: "Export spec 정보가 존재하지 않습니다.",
    en: "Export spec does not exist.",
  },
  CASE_REPORT_EXPORT_PAYLOAD_INVALID: {
    status: 400,
    ko: "요청 정보가 올바르지 않습니다.",
    en: "Requested information is not valid.",
  },
  CASE_REPORT_EXPORT_PAYLOAD_KEY_MISSING: {
    status: 400,
    ko: "요청 정보가 올바르지 않습니다. {key} 요소가 존재하지 않습니다.",
    en: "Requested information is not valid. {key} is missing.",
  },
  CASE_REPORT_EXPORT_PAYLOAD_KEY_VALUE_MISSING: {
    status: 400,
    ko: "{key} 값이 누락되었습니다.",
    en: "{key}'s value is missing.",
  },
  CASE_REPORT_EXPORT_REQUEST_INVALID_RESOURCE_NOT_FOUND: {
    status: 400,
    ko: "요청 정보가 존재하지 않습니다.",
    en: "Request resource does not exist.",
  },
  CASE_REPORT_EXPORT_HISTORY_DOWNLOAD_FILE_URL_EXPIRED: {
    status: 409,
    ko: "Export file 다운로드 URL이 만료되었습니다.",
    en: "Export file download url has expired.",
  },
  CASE_REPORT_EXPORT_DOWNLOAD_REQUESTER_NOT_MATCH: {
    status: 400,
    ko: "Export 요청자와 download 요청자가 일치하지 않습니다.",
    en: "Export download requestor do not match.",
  },
  CASE_REPORT_EXPORT_MODIFY_INVALID_STATUS_NOT_DONE: {
    status: 400,
    ko: "Export가 성공하였을 때만 Submitted, Submitted at을 설정할 수 있습니다.",
    en: "Submitted, Submitted at can be save only when export is successful.",
  },
  CASE_REPORT_EXPORT_VALIDATE_FAILED: {
    status: 400,
    ko: "Case Report Validate에 실패했습니다.",
    en: "Failed to validate case report.",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_EXPORT_CODE);
