import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const AUTH_MEMBER_CODE: {
  MEMBER_INVITE_SELF_NOT_ALLOWED: MvnCodeBE;
  MEMBER_INVITE_INVITEE_EXISTS: MvnCodeBE;
  MEMBER_INVITE_INVITATION_CANCELED: MvnCodeBE;
  MEMBER_INVITE_INVITATION_ACCEPTED_ALREADY: MvnCodeBE;
  MEMBER_INVITE_INVITATION_REJECTED_ALREADY: MvnCodeBE;
  MEMBER_INVITE_INVITATION_ACCEPT_DENIED: MvnCodeBE;
} = {
  MEMBER_INVITE_SELF_NOT_ALLOWED: {
    status: 400,
    ko: "현재 로그인 중인 계정을 Member로 초대할 수 없습니다.",
    en: "You cannot invite yourself as a member.",
  },
  MEMBER_INVITE_INVITEE_EXISTS: {
    status: 409,
    ko: "이미 초대된 사용자입니다.",
    en: "The user have already been invited.",
  },
  MEMBER_INVITE_INVITATION_CANCELED: {
    status: 409,
    ko: "취소된 초대입니다.",
    en: "The invitation have already been canceled.",
  },
  MEMBER_INVITE_INVITATION_ACCEPTED_ALREADY: {
    status: 409,
    ko: "이미 수락이 완료된 초대입니다.",
    en: "The invitation have already been accepted.",
  },
  MEMBER_INVITE_INVITATION_REJECTED_ALREADY: {
    status: 409,
    ko: "이미 거절이 완료된 초대입니다.",
    en: "The invitation have already been rejected.",
  },
  MEMBER_INVITE_INVITATION_ACCEPT_DENIED: {
    status: 403,
    ko: "'{email}'님에게 전송된 초대입니다. 현재 로그인된 계정을 확인해 주세요.",
    en: "The invitation was sent to '{email}'. Please check the account you are currently logged in to.",
  },
};

MvnCodeInitiate(AUTH_MEMBER_CODE);
