import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BUILDER_STUDY_TEMPLATE_PAYLOAD_CODE: {
  BUILDER_STUDY_TEMPLATE_PAYLOAD_DELETE_INVALID_USING: MvnCodeBE;
  BUILDER_STUDY_TEMPLATE_PAYLOAD_NOT_AUTHORIZED: MvnCodeBE;
} = {
  BUILDER_STUDY_TEMPLATE_PAYLOAD_DELETE_INVALID_USING: {
    status: 400,
    ko: "이미 사용중인 Study Template CRF version은 삭제할 수 없습니다. (id: {id})",
    en: "Study template CRF version that are already in use cannot deleted. (id: {id})",
  },
  BUILDER_STUDY_TEMPLATE_PAYLOAD_NOT_AUTHORIZED: {
    status: 403,
    ko: "해당 study template에 접근 권한이 없습니다. (studyTemplateId: {studyTemplateId})",
    en: "You do not have access to the template. (studyTemplateId: {studyTemplateId})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_TEMPLATE_PAYLOAD_CODE);
