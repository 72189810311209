import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_CLOSE_LANG: {
  CDMS_STUDY_CLOSE_LABEL_DB_LOCKED_DATE: MvnCodeFE;
} = {
  CDMS_STUDY_CLOSE_LABEL_DB_LOCKED_DATE: {
    ko: "DB 잠금 날짜",
    en: "DB Locked Date",
  },
};

MvnCodeInitiate(CDMS_STUDY_CLOSE_LANG);
