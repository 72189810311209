/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const ECOA_COMMON_LANG: {
  ECOA_COMMON_LANGUAGE_SETTINGS: MvnCodeFE;
  ECOA_COMMON_SURVEY_COMPLETED: MvnCodeFE;
} = {
  ECOA_COMMON_LANGUAGE_SETTINGS: {
    ko: "언어 설정",
    en: "Language Settings",
    fr: "Paramètres de langue",
    nl: "Taalinstellingen"
  },
  ECOA_COMMON_SURVEY_COMPLETED: {
    ko: "설문 종료",
    en: "Survey Completed",
    fr: "Enquête Terminée",
    nl: "Enquête Voltooid"
  }
};

MvnCodeInitiate(ECOA_COMMON_LANG);
