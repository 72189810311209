import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_IP_INFO: {
  STUDY_IP_INFO_NOT_FOUND: MvnCodeBE;
  STUDY_IP_INFO_NOT_AVAILABLE: MvnCodeBE;
  STUDY_IP_INFO_NAME_MISSING: MvnCodeBE;
  STUDY_IP_INFO_NAME_EXCEED_MAXLEN: MvnCodeBE;
  STUDY_IP_INFO_ID_MISSING: MvnCodeBE;
  STUDY_IP_INFO_ID_EXCEED_MAXLEN: MvnCodeBE;
  STUDY_IP_INFO_NAME_DUPLICATED: MvnCodeBE;
  STUDY_IP_INFO_ID_DUPLICATED: MvnCodeBE;
  STUDY_IP_INFO_WEIGHT_MISSING: MvnCodeBE;
  STUDY_IP_INFO_WEIGHT_INVALID: MvnCodeBE;
  STUDY_IP_INFO_NOT_CHANGED: MvnCodeBE;
  STUDY_IP_INFO_UNEXPECTED: MvnCodeBE;
} = {
  STUDY_IP_INFO_NOT_FOUND: {
    status: 404,
    ko: "Study IP 상세 정보가 존재하지 않습니다.",
    en: "Study IP information entity does not exist.",
  },
  STUDY_IP_INFO_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study IP 상세 정보가 올바르지 않습니다.",
    en: "Requested study IP information is not valid.",
  },
  STUDY_IP_INFO_NAME_MISSING: {
    status: 400,
    ko: "IP Label이 누락되었습니다.",
    en: "IP label is missing.",
  },
  STUDY_IP_INFO_NAME_EXCEED_MAXLEN: {
    status: 400,
    ko: "IP Label이 255자를 초과했습니다.",
    en: "IP label has exceeded 255 characters in length.",
  },
  STUDY_IP_INFO_ID_MISSING: {
    status: 400,
    ko: "IP ID가 누락되었습니다.",
    en: "IP ID is missing.",
  },
  STUDY_IP_INFO_ID_EXCEED_MAXLEN: {
    status: 400,
    ko: "IP ID가 255자를 초과했습니다.",
    en: "IP ID has exceeded 255 characters in length.",
  },
  STUDY_IP_INFO_NAME_DUPLICATED: {
    status: 400,
    ko: "요청하신 Study IP Label 정보가 중복존재합니다.",
    en: "Requested study IP label is duplicated.",
  },
  STUDY_IP_INFO_ID_DUPLICATED: {
    status: 400,
    ko: "요청하신 Study IP ID가 중복존재합니다.",
    en: "Requested study IP ID is duplicated.",
  },
  STUDY_IP_INFO_WEIGHT_MISSING: {
    status: 400,
    ko: "IP Weight이 누락되었습니다.",
    en: "IP weight is missing.",
  },
  STUDY_IP_INFO_WEIGHT_INVALID: {
    status: 400,
    ko: "IP Weight이 누락되었습니다.",
    en: "IP weight is invalid.",
  },
  STUDY_IP_INFO_NOT_CHANGED: {
    status: 400,
    ko: "요청하신 Study IP 상세 정보의 변경사항이 없습니다.",
    en: "No study IP information changed.",
  },
  STUDY_IP_INFO_UNEXPECTED: {
    status: 500,
    ko: "요청하신 Study IP 상세 정보가 올바르지 않습니다.",
    en: "Requested study IP information is not valid.",
  },
};

MvnCodeInitiate(STUDY_IP_INFO);
