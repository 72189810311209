import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const AUTH_USER_PASSWORD_CODE: {
  USER_CHANGE_PASSWORD_INVALID_USED_RECENTLY: MvnCodeBE;
  USER_CHANGE_PASSWORD_INVALID_SAME_EMAIL: MvnCodeBE;
  USER_CHANGE_PASSWORD_INVALID_CURRENT_PASSWORD: MvnCodeBE;
  USER_PASSWORD_NOT_MATCH_WITH_CURRENT: MvnCodeBE;
} = {
  USER_CHANGE_PASSWORD_INVALID_USED_RECENTLY: {
    status: 400,
    ko: "최근 3회 이내 사용한 비밀번호입니다. 다른 비밀번호를 입력해주세요.",
    en: "You have used the same password in last 3 times. Please enter a different password.",
  },
  USER_CHANGE_PASSWORD_INVALID_SAME_EMAIL: {
    status: 400,
    ko: "이메일과 동일한 비밀번호로 변경할 수 없습니다.",
    en: "You can not change with the same password as your email.",
  },
  USER_CHANGE_PASSWORD_INVALID_CURRENT_PASSWORD: {
    status: 400,
    ko: "현재 사용중인 비밀번호로는 변경할 수 없습니다.",
    en: "You can not change with your current password.",
  },
  USER_PASSWORD_NOT_MATCH_WITH_CURRENT: {
    status: 400,
    ko: "현재 비밀번호가 일치하지 않습니다.",
    en: "The current password does not match.",
  },
};

MvnCodeInitiate(AUTH_USER_PASSWORD_CODE);
