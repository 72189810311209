import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const ECOA_PRESCREENING_LANG: {
  ECOA_PRESCREENING_COVER_TITLE_DESC: MvnCodeFE;
} = {
  ECOA_PRESCREENING_COVER_TITLE_DESC: {
    ko: "아래 '{buttonDesc}' 버튼을 눌러 설문을 시작해주세요.",
    en: "Please click the '{buttonDesc}' button below to start the survey.",
  },
};

MvnCodeInitiate(ECOA_PRESCREENING_LANG);
