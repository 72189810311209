/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_SUBJECT_CODE: {
  STUDY_SUBJECT_NOT_FOUND: MvnCodeBE;
  STUDY_SUBJECT_CONTACT_NOT_FOUND: MvnCodeBE;
  STUDY_SUBJECT_SITE_NOT_FOUND: MvnCodeBE;
  STUDY_SUBJECT_WITH_ACCESS_CODE_NOT_FOUND: MvnCodeBE;
  STUDY_SUBJECT_NOT_AVAILABLE: MvnCodeBE;
  STUDY_SUBJECT_VISIT_EXCEED_MAXVAL: MvnCodeBE;
  STUDY_SUBJECT_ADD_INVALID_STUDY_ALLOCATION_LIMIT_EXCEED: MvnCodeBE;
  STUDY_SUBJECT_ADD_INVALID_SITE_ALLOCATION_LIMIT_EXCEED: MvnCodeBE;
  STUDY_SUBJECT_ADD_INVALID_INITIALABLE_EXIST: MvnCodeBE;
  STUDY_SUBJECT_ADD_SITE_CRF_NOT_FOUND: MvnCodeBE;
  STUDY_SUBJECT_CONTACT_ALREADY_REGISTERED: MvnCodeBE;
  STUDY_SUBJECT_EXPORT_FORMAT_NOT_AVAILABLE: MvnCodeBE;
  STUDY_SUBJECT_UNSIGNED_EXISTS: MvnCodeBE;
  STUDY_SUBJECT_RANDOM_NO_RETRIEVE_NOT_SUPPORT: MvnCodeBE;
  STUDY_SUBJECT_RANDOM_NO_NOT_FOUND: MvnCodeBE;
  STUDY_SUBJECT_RANDOM_NO_ALREADY_ASSIGNED: MvnCodeBE;
  STUDY_SUBJECT_BULK_ADD_REQUIRED_VALUE_MISSING: MvnCodeBE;
  STUDY_SUBJECT_VISITS_ADD_FAIL_VISITS_NOT_SAVED: MvnCodeBE;
  STUDY_SUBJECT_VISITS_REMOVE_FAIL_CYCLE_SAVED: MvnCodeBE;
} = {
  STUDY_SUBJECT_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study 대상자({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study subject({id}) could not be found.",
  },
  STUDY_SUBJECT_CONTACT_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study 대상자({id}) 전화번호 정보를 찾을 수 없습니다.",
    en: "Requested study subject({id}) contact could not be found.",
  },
  STUDY_SUBJECT_SITE_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study 대상자({id}) 소속기관 정보를 찾을 수 없습니다.",
    en: "Requested study subject({id}) site could not be found.",
  },
  STUDY_SUBJECT_WITH_ACCESS_CODE_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Access code({accessCode})로 Study 대상자를 찾을 수 없습니다.",
    en: "The Study subject could not be found with the requested access code({accessCode}).",
  },
  STUDY_SUBJECT_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study 대상자({id}) 정보가 올바르지 않습니다.",
    en: "Requested study subject({id}) is not valid.",
  },
  STUDY_SUBJECT_VISIT_EXCEED_MAXVAL: {
    status: 400,
    ko: "최대 Visit 개수를 초과했습니다.",
    en: "The maximum number of visits has been exceeded.",
  },
  STUDY_SUBJECT_ADD_INVALID_STUDY_ALLOCATION_LIMIT_EXCEED: {
    status: 400,
    ko: "등록할 수 있는 대상자 수를 초과하였습니다. 최대 {allocation}명까지 등록할 수 있습니다.",
    en: "You have exceeded the number of subjects. Up to {allocation} subjects can be registered.",
  },
  STUDY_SUBJECT_ADD_INVALID_SITE_ALLOCATION_LIMIT_EXCEED: {
    status: 400,
    ko: "기관에 등록할 수 있는 대상자 수가 초과되었습니다.",
    en: "The number of subject exceeds the limit.",
  },
  STUDY_SUBJECT_ADD_INVALID_INITIALABLE_EXIST: {
    status: 400,
    ko: "대상자 일괄 등록 진행 중에 신규 대상자를 추가할 수 없습니다.",
    en: "Subjects cannot be added while batch registration is in progress.",
  },
  STUDY_SUBJECT_ADD_SITE_CRF_NOT_FOUND: {
    status: 400,
    ko: "대상자에 적용할 수 있는 CRF version이 존재하지 않아 등록할 수 없습니다.",
    en: "There is no CRF version applicable to the subject.",
  },
  STUDY_SUBJECT_CONTACT_ALREADY_REGISTERED: {
    status: 409,
    ko: "대상자 전화번호가 중복인 경우 입력할 수 없습니다. 대상자 전화번호를 확인해주세요.",
    en: "Subject Contact Number is Duplicated. Please Check the Right Number.",
  },
  STUDY_SUBJECT_EXPORT_FORMAT_NOT_AVAILABLE: {
    status: 400,
    ko: "지원하지 않는 파일형식({format})입니다.",
    en: "The requested export format({format}) is not supported.",
  },
  STUDY_SUBJECT_UNSIGNED_EXISTS: {
    status: 400,
    ko: "서명되지 않은 시험대상자가 있습니다.",
    en: "Unsigned subject is exist",
  },
  STUDY_SUBJECT_RANDOM_NO_RETRIEVE_NOT_SUPPORT: {
    status: 400,
    ko: "해당 스터디에서는 Randomization No 회수 기능을 사용할 수 없습니다.",
    en: "The Randomization No. retrieval function is not available in this study.",
  },
  STUDY_SUBJECT_RANDOM_NO_NOT_FOUND: {
    status: 404,
    ko: "대상자에게 부여된 Randomization No가 없습니다.",
    en: "There is no Randomization No. assigned to the subject.",
  },
  STUDY_SUBJECT_RANDOM_NO_ALREADY_ASSIGNED: {
    status: 400,
    ko: "다음 Randomization No가 부여되었기 때문에 회수가 불가합니다.",
    en: "Unable to retrieve the following Randomization No. It has already been assigned.",
  },
  STUDY_SUBJECT_BULK_ADD_REQUIRED_VALUE_MISSING: {
    status: 400,
    ko: "필수값이 누락되었습니다. (label: {label})",
    en: "Required Item value is missing. (label: {label})",
  },
  STUDY_SUBJECT_VISITS_ADD_FAIL_VISITS_NOT_SAVED: {
    status: 409,
    ko: "저장되지 않은 Cycle 저장 후 새로운 Cycle을 추가할 수 있습니다.",
    en: "After saving the unsaved cycle, you can add a new cycle.",
  },
  STUDY_SUBJECT_VISITS_REMOVE_FAIL_CYCLE_SAVED: {
    status: 409,
    ko: "Save된 Cycle은 삭제할 수 없습니다.",
    en: "You cannot remove a saved cycle.",
  },
};

MvnCodeInitiate(STUDY_SUBJECT_CODE);
