import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_MEMBER_LANG: {
  AUTH_MEMBER_ORG_CREATE_HEADER: MvnCodeFE;
  AUTH_MEMBER_ORG_CREATE_CONFIRM: MvnCodeFE;
  AUTH_MEMBER_ORG_CREATE_ORGANIZATION_LABEL: MvnCodeFE;
  AUTH_MEMBER_ORG_CREATE_ORGANIZATION_PLACEHOLDER: MvnCodeFE;
  AUTH_MEMBER_ORG_CREATE_SUCC: MvnCodeFE;
  AUTH_MEMBER_ORG_CREATE_FAIL: MvnCodeFE;
  AUTH_MEMBER_INVITE_HEADER: MvnCodeFE;
  AUTH_MEMBER_INVITE_SEARCH_LABEL: MvnCodeFE;
  AUTH_MEMBER_INVITE_SEARCH_DESC: MvnCodeFE;
  AUTH_MEMBER_INVITE_SUCC: MvnCodeFE;
  AUTH_MEMBER_INVITE_FAIL: MvnCodeFE;
  AUTH_MEMBER_INVITATION_ACCEPT_CONFIRM: MvnCodeFE;
  AUTH_MEMBER_INVITATION_REJECT_CONFIRM: MvnCodeFE;
  AUTH_MEMBER_INVITATION_ACCEPT_SUCC: MvnCodeFE;
  AUTH_MEMBER_INVITATION_ACCEPT_FAIL: MvnCodeFE;
  AUTH_MEMBER_INVITATION_REJECT_SUCC: MvnCodeFE;
  AUTH_MEMBER_INVITATION_REJECT_FAIL: MvnCodeFE;
  AUTH_MEMBER_REMOVE_INVITATION_DESC: MvnCodeFE;
  AUTH_MEMBER_REMOVE_INVITATION_SUCC: MvnCodeFE;
  AUTH_MEMBER_REMOVE_INVITATION_FAIL: MvnCodeFE;
  AUTH_MEMBER_REMOVE_MEMBER_DESC: MvnCodeFE;
  AUTH_MEMBER_REMOVE_MEMBER_SUCC: MvnCodeFE;
  AUTH_MEMBER_REMOVE_MEMBER_FAIL: MvnCodeFE;
  AUTH_MEMBER_INVITATION_DESC: MvnCodeFE;
} = {
  AUTH_MEMBER_ORG_CREATE_HEADER: {
    ko: "Create an Organization",
    en: "Create an Organization",
  },
  AUTH_MEMBER_ORG_CREATE_CONFIRM: {
    ko: "'{name}' Organization을 생성하시겠습니까?",
    en: "Are you sure you want to create an organization, '{name}'?",
  },
  AUTH_MEMBER_ORG_CREATE_ORGANIZATION_LABEL: {
    ko: "Organization 이름",
    en: "Organization Name",
  },
  AUTH_MEMBER_ORG_CREATE_ORGANIZATION_PLACEHOLDER: {
    ko: "Organization 이름을 입력해 주세요.",
    en: "Please enter an organization name.",
  },
  AUTH_MEMBER_ORG_CREATE_SUCC: {
    ko: "Organization을 생성하였습니다.",
    en: "You have created a new organization successfully.",
  },
  AUTH_MEMBER_ORG_CREATE_FAIL: {
    ko: "Organization 생성에 실패하였습니다. ({message})",
    en: "You have failed to create a new organization. ({message})",
  },
  AUTH_MEMBER_INVITE_HEADER: {
    ko: "Invite Member",
    en: "Invite Member",
  },
  AUTH_MEMBER_INVITE_SEARCH_LABEL: {
    ko: "Email",
    en: "Email",
  },
  AUTH_MEMBER_INVITE_SEARCH_DESC: {
    ko: "Member로 초대할 사용자의 이메일 주소를 입력해 주세요.",
    en: "Please enter the email address to invite user as a member.",
  },
  AUTH_MEMBER_INVITE_SUCC: {
    ko: "사용자를 초대하였습니다.",
    en: "Member has been invited successfully.",
  },
  AUTH_MEMBER_INVITE_FAIL: {
    ko: "사용자 초대에 실패하였습니다. ({message})",
    en: "The member invitation request has been failed. ({message})",
  },
  AUTH_MEMBER_INVITATION_ACCEPT_CONFIRM: {
    ko: "Member 초대를 수락하시겠습니까?",
    en: "Are you sure you want to accept the invitation to a member?",
  },
  AUTH_MEMBER_INVITATION_REJECT_CONFIRM: {
    ko: "Member 초대를 거절하시겠습니까?",
    en: "Are you sure you want to reject the invitation to a member?",
  },
  AUTH_MEMBER_INVITATION_ACCEPT_SUCC: {
    ko: "Member 초대 수락이 완료되었습니다.",
    en: "Member invitation has been accepted successfully.",
  },
  AUTH_MEMBER_INVITATION_ACCEPT_FAIL: {
    ko: "Member 초대 수락에 실패하였습니다. ({message})",
    en: "The member invite acceptance request has been failed. ({message})",
  },
  AUTH_MEMBER_INVITATION_REJECT_SUCC: {
    ko: "Member 초대 거절이 완료되었습니다.",
    en: "Member invitation has been rejected successfully.",
  },
  AUTH_MEMBER_INVITATION_REJECT_FAIL: {
    ko: "Member 초대 거절에 실패하였습니다. ({message})",
    en: "The member invite rejection request has been failed. ({message})",
  },
  AUTH_MEMBER_REMOVE_INVITATION_DESC: {
    ko: "선택하신 Member의 초대를 취소하시겠습니까?",
    en: "Are you sure you want to cancel the invitation to a member?",
  },
  AUTH_MEMBER_REMOVE_INVITATION_SUCC: {
    ko: "Member 초대를 취소하였습니다.",
    en: "Member invitation has been removed successfully.",
  },
  AUTH_MEMBER_REMOVE_INVITATION_FAIL: {
    ko: "Member 초대 취소에 실패하였습니다. ({message})",
    en: "The member invitation cancellation request has been failed. ({message})",
  },
  AUTH_MEMBER_REMOVE_MEMBER_DESC: {
    ko: "선택하신 Member를 삭제하시겠습니까?",
    en: "Are you sure you want to remove a member?",
  },
  AUTH_MEMBER_REMOVE_MEMBER_SUCC: {
    ko: "Member를 삭제하였습니다.",
    en: "Member has been removed successfully.",
  },
  AUTH_MEMBER_REMOVE_MEMBER_FAIL: {
    ko: "Member 삭제에 실패하였습니다. ({message})",
    en: "The member removal request has been failed. ({message})",
  },
  AUTH_MEMBER_INVITATION_DESC: {
    ko: " You have an invitation invited by {invitation}",
    en: " You have an invitation invited by {invitation}",
  },
};

MvnCodeInitiate(AUTH_MEMBER_LANG);

export const AUTH_ORG_MEMBER_AUDIT_TRAIL_LANG: {
  AUTH_ORG_MEMBER_AUDIT_TRAIL_CREATED: MvnCodeFE;
  AUTH_ORG_MEMBER_AUDIT_TRAIL_UPDATED_REVIEWING: MvnCodeFE;
  AUTH_ORG_MEMBER_AUDIT_TRAIL_UPDATED_ACCEPTED: MvnCodeFE;
  AUTH_ORG_MEMBER_AUDIT_TRAIL_UPDATED_REJECTED: MvnCodeFE;
  AUTH_ORG_MEMBER_AUDIT_TRAIL_DELETED: MvnCodeFE;
} = {
  AUTH_ORG_MEMBER_AUDIT_TRAIL_CREATED: {
    ko: "Invited as {role}",
    en: "Invited as {role}",
  },
  AUTH_ORG_MEMBER_AUDIT_TRAIL_UPDATED_REVIEWING: {
    ko: "Reviewing",
    en: "Reviewing",
  },
  AUTH_ORG_MEMBER_AUDIT_TRAIL_UPDATED_ACCEPTED: {
    ko: "Invitation Accepted",
    en: "Invitation Accepted",
  },
  AUTH_ORG_MEMBER_AUDIT_TRAIL_UPDATED_REJECTED: {
    ko: "Invitation Denied",
    en: "Invitation Denied",
  },
  AUTH_ORG_MEMBER_AUDIT_TRAIL_DELETED: {
    ko: "Deleted",
    en: "Deleted",
  },
};

MvnCodeInitiate(AUTH_ORG_MEMBER_AUDIT_TRAIL_LANG);
