import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CASE_REPORT_VALIDATE_LANG: {
  SAFETY_CASE_REPORT_VALIDATE_BUTTON_TOOLTIP_SELECT_EXTENSION: MvnCodeFE;
  SAFETY_CASE_REPORT_VALIDATE_ALERT_MESSAGE: MvnCodeFE;
  SAFETY_CASE_REPORT_VALIDATE_SELECT_VALIDATOR_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_REPORT_VALIDATE_SELECT_VALIDATOR_DIALOG_DROPDOWN: MvnCodeFE;
  SAFETY_CASE_REPORT_VALIDATE_SUCC_DIALOG_HEADER: MvnCodeFE;
  SAFETY_CASE_REPORT_VALIDATE_SUCC_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_REPORT_VALIDATE_FAIL_REASON_DIALOG_HEADER: MvnCodeFE;
  SAFETY_CASE_REPORT_VALIDATE_FAIL: MvnCodeFE;
} = {
  SAFETY_CASE_REPORT_VALIDATE_BUTTON_TOOLTIP_SELECT_EXTENSION: {
    ko: "추가된 규제기관이 없습니다.",
    en: "There is no Extension Element for this case.",
  },
  SAFETY_CASE_REPORT_VALIDATE_ALERT_MESSAGE: {
    ko: "저장되지 않은 자료가 있습니다. Case Validate를 진행하시겠습니까?",
    en: "You have unsaved data. Do you want to validate the case?",
  },
  SAFETY_CASE_REPORT_VALIDATE_SELECT_VALIDATOR_DIALOG_DESCRIPTION: {
    ko: "Validate를 진행할 규제기관을 선택해주세요.\nCase에 추가된 규제기관만 선택 가능합니다.",
    en: "Please select a regulatory authority to validate.\nYou can only select a regulatory authority that have already been added.",
  },
  SAFETY_CASE_REPORT_VALIDATE_SELECT_VALIDATOR_DIALOG_DROPDOWN: {
    ko: "Regulatory Authority",
    en: "Regulatory Authority",
  },
  SAFETY_CASE_REPORT_VALIDATE_SUCC_DIALOG_HEADER: {
    ko: "Case Validate Completion",
    en: "Case Validate Completion",
  },
  SAFETY_CASE_REPORT_VALIDATE_SUCC_DIALOG_DESCRIPTION: {
    ko: "{validator}에 대한 Case Validation이 성공적으로 완료되었습니다.",
    en: "Validation for {validator} is completed successfully.",
  },
  SAFETY_CASE_REPORT_VALIDATE_FAIL_REASON_DIALOG_HEADER: {
    ko: "Case Validate Failure Report - {validator}",
    en: "Case Validate Failure Report - {validator}",
  },
  SAFETY_CASE_REPORT_VALIDATE_FAIL: {
    ko: "Case Validation에 실패했습니다. ({message})",
    en: "Failed to validate case. ({message})",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_VALIDATE_LANG);
