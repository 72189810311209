import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CASE_COMMENT_CODE: {
  SAFETY_CASE_COMMENT_INVALID_MSG_MAX_LENGTH: MvnCodeBE;
} = {
  SAFETY_CASE_COMMENT_INVALID_MSG_MAX_LENGTH: {
    status: 400,
    ko: "메세지의 길이는 최대 {length}자 입니다.",
    en: "Messages can be up to {length} characters long.",
  },
};

MvnCodeInitiate(SAFETY_CASE_COMMENT_CODE);
