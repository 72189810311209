import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_WORKSPACE_FOLDER_LANG: {
  SAFETY_WORKSPACE_CREATE_FOLDER_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_CREATE_FOLDER_DIALOG_NAME_PLACEHOLDER: MvnCodeFE;
  SAFETY_WORKSPACE_CREATE_FOLDER_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_CREATE_FOLDER_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_EDIT_FOLDER_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_EDIT_FOLDER_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_EDIT_FOLDER_FAIL: MvnCodeFE;
  SAFETY_WORKSPACE_FOLDER_EXPLORER_DIALOG_HEADER: MvnCodeFE;
  SAFETY_WORKSPACE_FOLDER_DIALOG_NAME_LABEL: MvnCodeFE;
  SAFETY_WORKSPACE_FOLDER_MOVE_SUCC: MvnCodeFE;
  SAFETY_WORKSPACE_FOLDER_MOVE_FAIL: MvnCodeFE;
} = {
  SAFETY_WORKSPACE_CREATE_FOLDER_DIALOG_HEADER: {
    ko: "Create New Folder",
    en: "Create New Folder",
  },
  SAFETY_WORKSPACE_CREATE_FOLDER_DIALOG_NAME_PLACEHOLDER: {
    ko: "폴더 이름을 입력하세요",
    en: "Enter folder name",
  },
  SAFETY_WORKSPACE_CREATE_FOLDER_SUCC: {
    ko: "폴더 등록에 성공했습니다.",
    en: "You have created a folder successfully.",
  },
  SAFETY_WORKSPACE_CREATE_FOLDER_FAIL: {
    ko: "폴더 등록에 실패했습니다. ({message})",
    en: "Failed to create a folder. ({message})",
  },
  SAFETY_WORKSPACE_EDIT_FOLDER_DIALOG_HEADER: {
    ko: "Edit Folder",
    en: "Edit Folder",
  },
  SAFETY_WORKSPACE_EDIT_FOLDER_SUCC: {
    ko: "폴더를 수정하였습니다.",
    en: "Item(s) have been edited successfully.",
  },
  SAFETY_WORKSPACE_EDIT_FOLDER_FAIL: {
    ko: "폴더 수정에 실패했습니다. ({error})",
    en: "Failed to edited item(s). ({error})",
  },
  SAFETY_WORKSPACE_FOLDER_EXPLORER_DIALOG_HEADER: {
    ko: "Move Item",
    en: "Move Item",
  },
  SAFETY_WORKSPACE_FOLDER_DIALOG_NAME_LABEL: {
    ko: "Folder Name",
    en: "Folder Name",
  },
  SAFETY_WORKSPACE_FOLDER_MOVE_SUCC: {
    ko: "폴더/케이스를 이동하였습니다.",
    en: "Items have been moved successfully.",
  },
  SAFETY_WORKSPACE_FOLDER_MOVE_FAIL: {
    ko: "폴더/케이스 이동에 실패했습니다. ({error})",
    en: "Failed to move items. ({error})",
  },
};

MvnCodeInitiate(SAFETY_WORKSPACE_FOLDER_LANG);
