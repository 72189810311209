import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_IP_SCHEDULE: {
  STUDY_IP_SCHEDULE_NOT_CHANGED: MvnCodeBE;
  STUDY_IP_SCHEDULE_NOT_FOUND: MvnCodeBE;
  STUDY_IP_SCHEDULE_NOT_AVAILABLE: MvnCodeBE;
  STUDY_IP_SCHEDULE_DETAIL_NOT_FOUND: MvnCodeBE;
  STUDY_IP_SCHEDULE_DETAIL_NOT_AVAILABLE: MvnCodeBE;
  STUDY_IP_SCHEDULE_DETAIL_DUPLICATED: MvnCodeBE;
  STUDY_IP_SCHEDULE_DETAIL_UNEXPECTED: MvnCodeBE;
  STUDY_IP_SCHEDULE_DETAIL_VISIT_MISSING: MvnCodeBE;
  STUDY_IP_SCHEDULE_DETAIL_VISIT_INVALID: MvnCodeBE;
  STUDY_IP_SCHEDULE_DETAIL_RANDOM_GROUP_MISSING: MvnCodeBE;
  STUDY_IP_SCHEDULE_DETAIL_RANDOM_GROUP_INVALID: MvnCodeBE;
  STUDY_IP_SCHEDULE_DETAIL_DRUG_MISSING: MvnCodeBE;
  STUDY_IP_SCHEDULE_DETAIL_DRUG_INVALID: MvnCodeBE;
} = {
  STUDY_IP_SCHEDULE_NOT_CHANGED: {
    status: 400,
    ko: "요청하신 Study IP Schedule 상세 정보의 변경사항이 없습니다.",
    en: "No study IP schedule changed.",
  },
  STUDY_IP_SCHEDULE_NOT_FOUND: {
    status: 404,
    ko: "Study IP Schedule 정보가 존재하지 않습니다.",
    en: "Study IP schedule does not exist.",
  },
  STUDY_IP_SCHEDULE_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study IP Schedule 정보가 올바르지 않습니다.",
    en: "Requested study IP schedule is not valid.",
  },
  STUDY_IP_SCHEDULE_DETAIL_NOT_FOUND: {
    status: 404,
    ko: "Study IP Schedule Detail 정보가 존재하지 않습니다.",
    en: "Study IP schedule detail does not exist.",
  },
  STUDY_IP_SCHEDULE_DETAIL_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study IP Schedule Detail 상세 정보가 올바르지 않습니다.",
    en: "Requested study IP schedule detail is not valid.",
  },
  STUDY_IP_SCHEDULE_DETAIL_DUPLICATED: {
    status: 400,
    ko: "요청하신 Study IP Schedule Detail이 중복 존재합니다.",
    en: "Requested study IP schedule Detail is duplicated.",
  },
  STUDY_IP_SCHEDULE_DETAIL_UNEXPECTED: {
    status: 500,
    ko: "요청하신 Study IP Schedule 상세 정보가 올바르지 않습니다.",
    en: "Requested study IP schedule detail is not valid.",
  },
  STUDY_IP_SCHEDULE_DETAIL_VISIT_MISSING: {
    status: 400,
    ko: "IP Schedule Visit이 누락되었습니다.",
    en: "IP schedule visit is missing.",
  },
  STUDY_IP_SCHEDULE_DETAIL_VISIT_INVALID: {
    status: 400,
    ko: "IP Schedule Visit이 올바르지 않습니다.",
    en: "IP schedule visit is invalid.",
  },
  STUDY_IP_SCHEDULE_DETAIL_RANDOM_GROUP_MISSING: {
    status: 400,
    ko: "IP Schedule Random Group이 누락되었습니다.",
    en: "IP schedule random group is missing.",
  },
  STUDY_IP_SCHEDULE_DETAIL_RANDOM_GROUP_INVALID: {
    status: 400,
    ko: "IP Schedule Random Group이 올바르지 않습니다.",
    en: "IP schedule random group is invalid.",
  },
  STUDY_IP_SCHEDULE_DETAIL_DRUG_MISSING: {
    status: 400,
    ko: "IP Schedule Drug가 누락되었습니다.",
    en: "IP schedule drug is missing.",
  },
  STUDY_IP_SCHEDULE_DETAIL_DRUG_INVALID: {
    status: 400,
    ko: "IP Schedule Drug가 올바르지 않습니다.",
    en: "IP schedule drug is invalid.",
  },
};

MvnCodeInitiate(STUDY_IP_SCHEDULE);
