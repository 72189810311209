import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BILLING_SUBSCRIPTION_CODE: {
  BILLING_SUBSCRIPTION_GROUP_DUPLICATED: MvnCodeBE;
  BILLING_SUBSCRIPTION_TRIAL_DUPLICATED: MvnCodeBE;
  BILLING_SUBSCRIPTION_UNABLE_PLAN_NONRENEWABLE: MvnCodeBE;
  BILLING_SUBSCRIPTION_NOT_ADMIN_DENIED: MvnCodeBE;
  BILLING_SUBSCRIPTION_NOT_ADMIN_DENIED_UNSUBSCRIBE: MvnCodeBE;
  BILLING_SUBSCRIPTION_ADD_LICENSE_PERMISSION_DENIED: MvnCodeBE;
  BILLING_SUBSCRIPTION_ADD_LICENSE_NOT_MEMBER: MvnCodeBE;
  BILLING_SUBSCRIPTION_ADD_LICENSE_DUPLICATE_MEMBER: MvnCodeBE;
  BILLING_SUBSCRIPTION_REMOVE_LICENSE_SUSPENDED: MvnCodeBE;
  BILLING_SUBSCRIPTION_REMOVE_LICENSE_ADMIN_NOT_ALLOWED: MvnCodeBE;
  BILLING_SUBSCRIPTION_RESUME_LICENSE_RESUMED: MvnCodeBE;
  BILLING_SUBSCRIPTION_RESUME_LICENSE_NOT_VALID: MvnCodeBE;
  BILLING_SUBSCRIPTION_RESUME_MEMBER_NOT_VALID: MvnCodeBE;
  BILLING_SUBSCRIPTION_UNSUBSCRIBED_ALREADY: MvnCodeBE;
  BILLING_SUBSCRIPTION_RESUBSCRIBED_ALREADY: MvnCodeBE;
  BILLING_SUBSCRIPTION_INVENTORY_ADD_LICENSE_STATUS_INVALID: MvnCodeBE;
  BILLING_SUBSCRIPTION_PG_FAILED: MvnCodeBE;
  BILLING_SUBSCRIPTION_INVALID_BUNDLE_ORGANIZATION: MvnCodeBE;
} = {
  BILLING_SUBSCRIPTION_GROUP_DUPLICATED: {
    status: 409,
    ko: "이미 구독중인 Plan이 있습니다.",
    en: "You have subscribed plan already.",
  },
  BILLING_SUBSCRIPTION_TRIAL_DUPLICATED: {
    status: 409,
    ko: "이미 Free Trial Plan을 이용하셨습니다. Plan을 구독해 주세요.",
    en: "You have used free trial plan already. Please subscribe a plan.",
  },
  BILLING_SUBSCRIPTION_UNABLE_PLAN_NONRENEWABLE: {
    status: 409,
    ko: "재구독 불가능한 Plan입니다. 다른 Plan을 구독해 주세요.",
    en: "This plan cannot be resubscribed. Please subscribe to another plan.",
  },
  BILLING_SUBSCRIPTION_NOT_ADMIN_DENIED: {
    status: 403,
    ko: "Plan 구독은 Admin 계정에서 할 수 있습니다.",
    en: "You can subscribe the plan on the admin account.",
  },
  BILLING_SUBSCRIPTION_NOT_ADMIN_DENIED_UNSUBSCRIBE: {
    status: 403,
    ko: "Plan 구독 및 해지는 Admin 계정에서 할 수 있습니다.",
    en: "You can subscribe or unsubscribe the plan on the admin account.",
  },
  BILLING_SUBSCRIPTION_ADD_LICENSE_PERMISSION_DENIED: {
    status: 403,
    ko: "License 추가는 Admin 계정에서 할 수 있습니다.",
    en: "You can add license on the admin account.",
  },
  BILLING_SUBSCRIPTION_ADD_LICENSE_NOT_MEMBER: {
    status: 400,
    ko: "Organization에 추가된 사용자에게 License를 부여할 수 있습니다.",
    en: "You can grant licenses to users belong to the organization.",
  },
  BILLING_SUBSCRIPTION_ADD_LICENSE_DUPLICATE_MEMBER: {
    status: 409,
    ko: "이미 License가 부여된 사용자입니다.",
    en: "You have already granted license to user.",
  },
  BILLING_SUBSCRIPTION_REMOVE_LICENSE_SUSPENDED: {
    status: 409,
    ko: "이미 License가 삭제된 사용자입니다.",
    en: "You have already removed license from user.",
  },
  BILLING_SUBSCRIPTION_REMOVE_LICENSE_ADMIN_NOT_ALLOWED: {
    status: 400,
    ko: "Admin의 License는 삭제할 수 없습니다.",
    en: "You cannot remove the admin user's license.",
  },
  BILLING_SUBSCRIPTION_RESUME_LICENSE_RESUMED: {
    status: 409,
    ko: "이미 사용자의 License가 재개되었습니다.",
    en: "You have already resumed license of user.",
  },
  BILLING_SUBSCRIPTION_RESUME_LICENSE_NOT_VALID: {
    status: 409,
    ko: "유효하지 않은 License입니다.",
    en: "License is not valid.",
  },
  BILLING_SUBSCRIPTION_RESUME_MEMBER_NOT_VALID: {
    status: 409,
    ko: "유효하지 않은 Member입니다.",
    en: "Member status is not valid.",
  },
  BILLING_SUBSCRIPTION_UNSUBSCRIBED_ALREADY: {
    status: 409,
    ko: "이미 구독해지 요청이 처리되었습니다.",
    en: "You have already requested to unsubscribe.",
  },
  BILLING_SUBSCRIPTION_RESUBSCRIBED_ALREADY: {
    status: 409,
    ko: "이미 구독재개 요청이 처리되었습니다.",
    en: "You have already requested to resubscribe.",
  },
  BILLING_SUBSCRIPTION_INVENTORY_ADD_LICENSE_STATUS_INVALID: {
    status: 409,
    ko: "Subscription 상태 정보가 올바르지 않습니다. ({code})",
    en: "Subscription status information is not valid. ({code})",
  },
  BILLING_SUBSCRIPTION_PG_FAILED: {
    status: 500,
    ko: "PG 결제에 실패하였습니다. ({message})",
    en: "Failed to payment. ({message})",
  },
  BILLING_SUBSCRIPTION_INVALID_BUNDLE_ORGANIZATION: {
    status: 400,
    ko: "해당 Plan을 구독할 수 없는 organization입니다.",
    en: "This organization cannot subscribe to this plan.",
  },
};

MvnCodeInitiate(BILLING_SUBSCRIPTION_CODE);
