import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CERTIFICATE_CODE: {
  CERTIFICATE_NOT_PERMITTED: MvnCodeBE;
} = {
  CERTIFICATE_NOT_PERMITTED: {
    status: 403,
    ko: "요청하신 인증서를 열람할 권한이 없습니다.",
    en: "Not authorized to browse certificate",
  },
};

MvnCodeInitiate(STUDY_CERTIFICATE_CODE);
