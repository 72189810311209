import React from "react";
import { FxApiRequest, UseRequestProps } from "@coralblack/flax";
import { ContextualMenu, IDragOptions, ResponsiveMode } from "@fluentui/react";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { hasOwnProperty } from "maven-lib/dist/consts/edc/lib";
import { classNames } from "maven-lib/dist/utils/misc";
import { CrButton, CrButtonColor } from "./CrButton";
import { CrRequestButton } from "./CrRequestButton";
import { nl2br } from "../helper/nl2br";
import { IconName } from "../interface/Icon";

export interface CrDialogProps<TR, TE, TRR, TRE> {
  className?: string;
  contentClassName?: string;
  header?: string;
  title?: string;
  warningText?: React.ReactNode;
  visibility: boolean;
  confirmRef?: React.RefObject<HTMLElement>;
  onConfirm:
    | (() => void)
    | {
        api: () => FxApiRequest<TR, TE, TRR, TRE>;
        delegate?: UseRequestProps<TR, TE, TRR, TRE>;
        confirm?: {
          confirmMessage: string;
          confirmText: string;
          cancelText: string;
        };
      };
  onCancel?: () => void;
  onMiscRender?: () => React.ReactChild;
  onMisc?: () => void;
  onDismiss?: () => void;
  confirm: string;
  confirmColor: CrButtonColor;
  cancel: string;
  misc?: string;
  miscColor: CrButtonColor;
  miscDisabled?: boolean;
  miscIcon?: IconName;
  children: React.ReactNode;
  confirmIcon?: IconName;
  confirmDisabled?: boolean;
  confirmLoading?: boolean;
  confirmVisibility?: boolean;
  isDraggable?: boolean;
  size?: "WIDER" | "WIDE" | "BASE" | "NARROW" | "AUTO";
  maxWidth: string;
  isHeaderSticky?: boolean;
  isFooterSticky?: boolean;
  textSize?: "SMALL" | "MEDIUM" | "LARGE";
  onLoadingStatusChanged?: (busy: boolean) => void;
  responsiveMode?: ResponsiveMode;
}

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};

export function CrDialog<TR, TE, TRR, TRE>(props: CrDialogProps<TR, TE, TRR, TRE>) {
  const {
    contentClassName,
    className,
    header,
    title,
    warningText,
    visibility,
    onConfirm,
    onCancel,
    onMiscRender,
    onMisc,
    onDismiss,
    confirmRef,
    confirm,
    confirmColor,
    cancel,
    misc,
    miscColor,
    miscIcon,
    miscDisabled,
    children,
    confirmIcon,
    confirmDisabled,
    confirmLoading,
    confirmVisibility,
    isDraggable,
    size,
    maxWidth,
    isHeaderSticky,
    isFooterSticky,
    textSize,
    onLoadingStatusChanged,
    responsiveMode,
  } = props;

  return (
    <Dialog
      maxWidth={maxWidth}
      hidden={!visibility}
      onDismiss={onDismiss}
      responsiveMode={responsiveMode}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: (
          <>
            {header || process.env.REACT_APP_NAME}
            {title && <p className="ms-Dialog-header-sub">{nl2br(title)}</p>}
          </>
        ),
        className: `${contentClassName || ""} ${size && `--size-${size.toLowerCase()}`} ${
          textSize && `--text-size-${textSize.toLowerCase()}`
        }`.trim(),
      }}
      modalProps={{
        dragOptions: isDraggable ? dragOptions : undefined,
        className: classNames("cr-dialog", isHeaderSticky && "header-sticky", isFooterSticky && "footer-sticky", className),
        isBlocking: true,
      }}
    >
      {warningText}
      {children}
      <DialogFooter>
        {onMiscRender && <div className="misc-btn">{onMiscRender()}</div>}
        {onMisc && !onMiscRender && (miscIcon || misc) && (
          <CrButton className="misc-btn" icon={miscIcon} color={miscColor} text={misc} onClick={() => onMisc()} disabled={miscDisabled} />
        )}
        {onConfirm && confirmVisibility && !hasOwnProperty(onConfirm, "api") && (
          <CrButton
            disabled={confirmDisabled || confirmLoading}
            elementRef={confirmRef}
            color={confirmColor}
            text={confirm}
            icon={confirmIcon}
            loading={confirmLoading}
            onClick={() => onConfirm()}
          />
        )}
        {onConfirm && confirmVisibility && hasOwnProperty(onConfirm, "api") && (
          <CrRequestButton
            elementRef={confirmRef}
            disabled={confirmDisabled || confirmLoading}
            color={confirmColor}
            text={confirm}
            icon={confirmIcon}
            loading={confirmLoading}
            api={onConfirm.api}
            delegate={onConfirm.delegate}
            confirm={onConfirm.confirm}
            onLoadingStatusChanged={onLoadingStatusChanged}
          />
        )}
        {onCancel && <CrButton text={cancel} onClick={() => onCancel()} />}
      </DialogFooter>
    </Dialog>
  );
}

CrDialog.defaultProps = {
  visibility: false,
  confirmVisibility: true,
  confirm: "OK",
  confirmColor: "primary",
  cancel: "Cancel",
  miscColor: "primary",
  maxWidth: "none",
};
