import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_PREFILLED_FORM_CODE: {
  PREFILLED_FORM_INVALID_NAME_MAX_LENGTH: MvnCodeBE;
  PREFILLED_FORM_INVALID_NAME_DUPLICATED: MvnCodeBE;
  PREFILLED_FORM_INVALID_PARENT_PRESET_NOT_EXIST: MvnCodeBE;
} = {
  PREFILLED_FORM_INVALID_NAME_MAX_LENGTH: {
    status: 400,
    ko: "이름은 최대 {length}자까지 입력할 수 있습니다.",
    en: "Name can be up to {length} characters.",
  },
  PREFILLED_FORM_INVALID_NAME_DUPLICATED: {
    status: 409,
    ko: "이미 사용되고 있는 prefiiled form name입니다. (name: {name})",
    en: "Prefilled form name is duplicated. (name: {name})",
  },
  PREFILLED_FORM_INVALID_PARENT_PRESET_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 preset입니다. (id: {id})",
    en: "The requested preset does not exist. (id: {id})",
  },
};

MvnCodeInitiate(SAFETY_PREFILLED_FORM_CODE);
