/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const UAT_LANG: {
  UAT_BREADCRUMB: MvnCodeFE;
  UAT_DIALOG_TITLE: MvnCodeFE;
  UAT_PAGE_BREADCRUMB_LABEL: MvnCodeFE;
  UAT_PAGE_URL_LABEL: MvnCodeFE;
  UAT_CATEGORY_LABEL: MvnCodeFE;
  UAT_CATEGORY_PLACEHOLDER: MvnCodeFE;
  UAT_TITLE_LABEL: MvnCodeFE;
  UAT_TITLE_PLACEHOLDER: MvnCodeFE;
  UAT_CONTENT_LABEL: MvnCodeFE;
  UAT_CONTENT_PLACEHOLDER: MvnCodeFE;
  UAT_CAPTURED_LABEL: MvnCodeFE;
  UAT_FILE_UPLOAD_FAILED: MvnCodeFE;
  UAT_OPENED: MvnCodeFE;
  UAT_CLOSED: MvnCodeFE;
  UAT_ADDED: MvnCodeFE;
  UAT_ADD_FAILED: MvnCodeFE;
  UAT_UPDATE_SUCCESS: MvnCodeFE;
  UAT_UPDATE_FAIL: MvnCodeFE;
  UAT_CLOSE_CONFIRM: MvnCodeFE;
  UAT_CLOSE_DIALOG_TITLE: MvnCodeFE;
  UAT_OPEN_FAIL: MvnCodeFE;
  UAT_CLOSE_FAIL: MvnCodeFE;
  UAT_NOT_OPENED_ALERT: MvnCodeFE;
  UAT_MIGRATE_CONFIRM: MvnCodeFE;
  UAT_MIGRATE_SUCC: MvnCodeFE;
  UAT_MIGRATE_FAIL: MvnCodeFE;
  REPORT_REQUEST_SUCC: MvnCodeFE;
  REPORT_REQUEST_FAIL: MvnCodeFE;
  BUTTON_REQUEST_UAT_REPORT: MvnCodeFE;
  BUTTON_HISTORY: MvnCodeFE;
  BUTTON_REQUEST_UAT_OPEN: MvnCodeFE;
  BUTTON_REQUEST_UAT_CLOSE: MvnCodeFE;
  COMMENT_EDIT_TOOLTIP: MvnCodeFE;
  COMMENT_AUDIT_TRAIL_TOOLTIP: MvnCodeFE;
  COMMENT_TABLE_COLUMN_CONTENT: MvnCodeFE;
  COMMENT_TABLE_COLUMN_USER: MvnCodeFE;
  COMMENT_TABLE_COLUMN_DATE: MvnCodeFE;
  COMMENT_INPUT_PLACEHOLDER: MvnCodeFE;
  COMMENT_EDIT_SUCC: MvnCodeFE;
  COMMENT_EDIT_FAIL: MvnCodeFE;
  COMMENT_REGISTER_SUCC: MvnCodeFE;
  COMMENT_REGISTER_FAIL: MvnCodeFE;
} = {
  UAT_BREADCRUMB: {
    ko: "Study에 등록된 UAT 목록을 확인하고 관리할 수 있습니다.",
    en: "Inquire and download UAT report.",
  },
  UAT_DIALOG_TITLE: {
    ko: "UAT 의견 제출",
    en: "UAT Item",
  },
  UAT_PAGE_BREADCRUMB_LABEL: {
    ko: "페이지 경로",
    en: "Location",
  },
  UAT_PAGE_URL_LABEL: {
    ko: "페이지 URL",
    en: "URL",
  },
  UAT_CATEGORY_LABEL: {
    ko: "UAT 카테고리",
    en: "UAT Category",
  },
  UAT_CATEGORY_PLACEHOLDER: {
    ko: "[UAT 카테고리]",
    en: "[UAT Category]",
  },
  UAT_TITLE_LABEL: {
    ko: "UAT 의견 제목",
    en: "UAT Title",
  },
  UAT_TITLE_PLACEHOLDER: {
    ko: "UAT 의견 제목을 입력해 주세요.",
    en: "Please enter the UAT title.",
  },
  UAT_CONTENT_LABEL: {
    ko: "UAT 의견 내용",
    en: "UAT Content",
  },
  UAT_CONTENT_PLACEHOLDER: {
    ko: "UAT 의견을 입력해 주세요.",
    en: "Please enter your UAT content.",
  },
  UAT_CAPTURED_LABEL: {
    ko: "Captured Image",
    en: "Captured Image",
  },
  UAT_FILE_UPLOAD_FAILED: {
    ko: "파일 업로드에 실패하였습니다. 네트워크 상태를 확인해 주세요.",
    en: "Failed to upload file. Please check the network connection and try again.", // 동시에 2개 파일 업로드 가능?
  },
  UAT_OPENED: {
    ko: "UAT가 시작되었습니다.",
    en: "UAT is open.",
  },
  UAT_CLOSED: {
    ko: "UAT가 종료되었습니다.",
    en: "UAT is closed.",
  },
  UAT_ADDED: {
    ko: "UAT 등록에 성공했습니다.",
    en: "New UAT has been added successfully.",
  },
  UAT_ADD_FAILED: {
    ko: "UAT 등록에 실패하였습니다. ({message})",
    en: "Failed to add UAT item. ({message})",
  },
  UAT_UPDATE_SUCCESS: {
    ko: "성공적으로 Status({status}) 정보를 갱신하였습니다.",
    en: "Status({status}) update was successful.",
  },
  UAT_UPDATE_FAIL: {
    ko: "Status 갱신에 실패하였습니다. ({error})",
    en: "Status update has failed. ({error})",
  },
  UAT_CLOSE_CONFIRM: {
    ko: "지금 UAT를 종료하면 완료/취소 상태가 아닌 항목들은 모두 자동 취소됩니다.\n자동 취소된 항목들은 다음 UAT로 이관될 수 있습니다. 이 UAT를 종료하시겠습니까?",
    en: "If you close the UAT now,\nitems that are not currently completed and/or canceled will be automatically canceled.\nAutomatically canceled items will be migratable to the next UAT.\nAre you sure you want to close this UAT?",
  },
  UAT_CLOSE_DIALOG_TITLE: {
    ko: "{number}차 UAT 종료를 위해 비밀번호를 입력해주세요.",
    en: "Please enter your password to close No. {number} UAT. ",
  },
  UAT_OPEN_FAIL: {
    ko: "UAT 시작에 실패했습니다. ({error})",
    en: "Failed to open UAT. ({error})",
  },
  UAT_CLOSE_FAIL: {
    ko: "UAT 종료에 실패했습니다. ({error})",
    en: "Failed to close UAT. ({error})",
  },
  UAT_NOT_OPENED_ALERT: {
    ko: "UAT가 아직 시작되지 않았습니다. UAT를 사용하려면 UAT를 열어주세요.",
    en: "UAT has not been open yet. To use UAT, please open it first.",
  },
  UAT_MIGRATE_CONFIRM: {
    ko: "이 항목을 다음 UAT로 이관하시겠습니까?",
    en: "Are you sure you want to migrate this item to the next UAT?",
  },
  UAT_MIGRATE_SUCC: {
    ko: "{number}차 UAT로 이관되었습니다.",
    en: "The item has been migrated to No. {number} UAT.",
  },
  UAT_MIGRATE_FAIL: {
    ko: "이 UAT 항목은 현재 이관할 수 없습니다. ({error})",
    en: "The UAT item is currently not migratable. ({error})",
  },
  REPORT_REQUEST_SUCC: {
    ko: "UAT Report 생성이 요청되었습니다.",
    en: "UAT report has requested successfully.",
  },
  REPORT_REQUEST_FAIL: {
    ko: "UAT Report 생성 요청에 실패하였습니다. ({message})",
    en: "Failed to request UAT report. ({message})",
  },
  BUTTON_REQUEST_UAT_REPORT: {
    ko: "UAT Report 요청",
    en: "Request UAT Report",
  },
  BUTTON_HISTORY: {
    ko: "History",
    en: "History",
  },
  BUTTON_REQUEST_UAT_OPEN: {
    ko: "Open",
    en: "Open",
  },
  BUTTON_REQUEST_UAT_CLOSE: {
    ko: "Close",
    en: "Close",
  },
  COMMENT_EDIT_TOOLTIP: {
    ko: "댓글 수정",
    en: "Edit",
  },
  COMMENT_AUDIT_TRAIL_TOOLTIP: {
    ko: "댓글 수정 내역",
    en: "Audit Trail",
  },
  COMMENT_TABLE_COLUMN_CONTENT: {
    ko: "내용",
    en: "content",
  },
  COMMENT_TABLE_COLUMN_USER: {
    ko: "사용자",
    en: "User",
  },
  COMMENT_TABLE_COLUMN_DATE: {
    ko: "날짜",
    en: "Date",
  },
  COMMENT_INPUT_PLACEHOLDER: {
    ko: "댓글을 입력해 주세요.",
    en: "Please enter the comment.",
  },
  COMMENT_EDIT_SUCC: {
    ko: "댓글 수정에 성공하였습니다.",
    en: "Comment has been edited successfully.",
  },
  COMMENT_EDIT_FAIL: {
    ko: "댓글 수정에 실패하였습니다. ({message})",
    en: "Comment editing has been failed. ({message})",
  },
  COMMENT_REGISTER_SUCC: {
    ko: "댓글 등록에 성공하였습니다.",
    en: "Comment has been registered successfully.",
  },
  COMMENT_REGISTER_FAIL: {
    ko: "댓글 등록에 실패하였습니다. ({message})",
    en: "Comment registration has been failed. ({message})",
  },
};

MvnCodeInitiate(UAT_LANG);
