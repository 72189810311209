import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_USER_LANG: {
  SAFETY_USER_EDIT_SUCC: MvnCodeFE;
  SAFETY_USER_EDIT_FAIL: MvnCodeFE;
  USER_TOKEN_REFRESH_SUCC: MvnCodeFE;
  USER_TOKEN_REFRESH_FAIL: MvnCodeFE;
} = {
  SAFETY_USER_EDIT_SUCC: {
    ko: "사용자 정보가 성공적으로 수정되었습니다.",
    en: "User info has been modified successfully.",
  },
  SAFETY_USER_EDIT_FAIL: {
    ko: "사용자 정보 수정에 실패했습니다. ({message})",
    en: "The user info modification request has been failed. ({message})",
  },
  USER_TOKEN_REFRESH_SUCC: {
    ko: "세션 유효시간이 연장되었습니다.",
    en: "Your login session has extended.",
  },
  USER_TOKEN_REFRESH_FAIL: {
    ko: "세션 유효시간 연장에 실패하였습니다.",
    en: "Failed to extend login session. ({error})",
  },
};

MvnCodeInitiate(SAFETY_USER_LANG);
