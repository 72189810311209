import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_USER_DEVICE_LANG: {
  AUTH_USER_DEVICE_TAB_TITLE: MvnCodeFE;
  AUTH_USER_DEVICE_LIST_COLUMN_REGISTRATION_DATE: MvnCodeFE;
  AUTH_USER_DEVICE_LIST_COLUMN_IP_ADDRESS: MvnCodeFE;
  AUTH_USER_DEVICE_LIST_COLUMN_BROWSER: MvnCodeFE;
  AUTH_USER_DEVICE_LIST_COLUMN_PLATFORM: MvnCodeFE;
  AUTH_USER_DEVICE_LIST_COLUMN_MANAGEMENT: MvnCodeFE;
  AUTH_USER_DEVICE_DELETE_DIALOG_TITLE: MvnCodeFE;
  AUTH_USER_DEVICE_DELETE_DIALOG_DESCRIPTION: MvnCodeFE;
  AUTH_USER_DEVICE_DELETE_SUCCESS: MvnCodeFE;
  AUTH_USER_DEVICE_DELETE_FAIL: MvnCodeFE;
} = {
  AUTH_USER_DEVICE_TAB_TITLE: {
    ko: "등록한 기기 목록",
    en: "Registered devices",
  },
  AUTH_USER_DEVICE_LIST_COLUMN_REGISTRATION_DATE: {
    ko: "Registration Date",
    en: "Registration Date",
  },
  AUTH_USER_DEVICE_LIST_COLUMN_IP_ADDRESS: {
    ko: "IP Address",
    en: "IP Address",
  },
  AUTH_USER_DEVICE_LIST_COLUMN_BROWSER: {
    ko: "Browser",
    en: "Browser",
  },
  AUTH_USER_DEVICE_LIST_COLUMN_PLATFORM: {
    ko: "Platform(OS)",
    en: "Platform(OS)",
  },
  AUTH_USER_DEVICE_LIST_COLUMN_MANAGEMENT: {
    ko: "Management",
    en: "Management",
  },
  AUTH_USER_DEVICE_DELETE_DIALOG_TITLE: {
    ko: "Delete Device",
    en: "Delete Device",
  },
  AUTH_USER_DEVICE_DELETE_DIALOG_DESCRIPTION: {
    ko: "선택한 기기를 삭제하시겠습니까?",
    en: "Delete selected devices?",
  },
  AUTH_USER_DEVICE_DELETE_SUCCESS: {
    ko: "선택한 기기를 삭제했습니다.",
    en: "Device has been deleted successfully.",
  },
  AUTH_USER_DEVICE_DELETE_FAIL: {
    ko: "선택한 기기 삭제에 실패했습니다. ({message})",
    en: "Failed to delete a device. ({message})",
  },
};

MvnCodeInitiate(AUTH_USER_DEVICE_LANG);
