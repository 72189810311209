import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SSO_REQUEST_TOKEN_CODE: {
  SSO_REQUEST_TOKEN_NOT_FOUND: MvnCodeBE;
  SSO_REQUEST_TOKEN_CREATE_INVALID: MvnCodeBE;
  SSO_REQUEST_TOKEN_CREATE_INVALID_USER_LOCKED_BY_INVALID_PASSWORD: MvnCodeBE;
  SSO_REQUEST_TOKEN_CREATE_INVALID_PASSWORD: MvnCodeBE;
  SSO_REQUEST_TOKEN_VERIFY_INVALID_EXPIRED: MvnCodeBE;
  SSO_REQUEST_TOKEN_VERIFY_INVALID_NOT_ACTIVE: MvnCodeBE;
  SSO_REQUEST_TOKEN_VERIFY_INVALID: MvnCodeBE;
  SSO_REQUEST_TOKEN_VERIFY_INVALID_TYPE: MvnCodeBE;
  SSO_REQUEST_TOKEN_VERIFY_INVALID_USER: MvnCodeBE;
} = {
  SSO_REQUEST_TOKEN_NOT_FOUND: {
    status: 404,
    ko: "Request Token({id}) 정보를 찾을 수 없습니다.",
    en: "Request Token({id}) could not be found.",
  },
  SSO_REQUEST_TOKEN_CREATE_INVALID: {
    status: 400,
    ko: "이메일 혹은 비밀번호를 잘못 입력하셨습니다.",
    en: "The email address or password you entered is invalid.",
  },
  SSO_REQUEST_TOKEN_CREATE_INVALID_USER_LOCKED_BY_INVALID_PASSWORD: {
    code: "USER-LOCKED",
    status: 400,
    ko: "비밀번호 {maxFailureCount}회 입력 실패로 비밀번호 재설정이 필요합니다. 이메일을 확인해 주세요.",
    en: "A password reset is required due to {maxFailureCount} failed password attempts. Please check your email.",
  },
  SSO_REQUEST_TOKEN_CREATE_INVALID_PASSWORD: {
    code: "INVALID-PASSWORD",
    status: 400,
    ko: "이메일 또는 비밀번호가 올바르지 않습니다. ({failureCount} / {maxFailureCount})",
    en: "The email or password is invalid.  ({failureCount} / {maxFailureCount})",
  },
  SSO_REQUEST_TOKEN_VERIFY_INVALID: {
    code: "CREDENTIAL-INVALID",
    status: 400,
    ko: "Request Token 형식이 유효하지 않습니다",
    en: "Invalid request token information.",
  },
  SSO_REQUEST_TOKEN_VERIFY_INVALID_NOT_ACTIVE: {
    code: "CREDENTIAL-INVALID",
    status: 400,
    ko: "토큰이 비활성화 상태입니다.",
    en: "Token inactive.",
  },
  SSO_REQUEST_TOKEN_VERIFY_INVALID_EXPIRED: {
    code: "CREDENTIAL-INVALID",
    status: 400,
    ko: "Request Token이 만료되었습니다.",
    en: "Your request token has expired.",
  },
  SSO_REQUEST_TOKEN_VERIFY_INVALID_TYPE: {
    status: 400,
    ko: "타입이 일치하지 않습니다.",
    en: "Types do not match.",
  },
  SSO_REQUEST_TOKEN_VERIFY_INVALID_USER: {
    status: 400,
    ko: "유저가 일치하지 않습니다. ",
    en: "User does not match.",
  },
};

MvnCodeInitiate(SSO_REQUEST_TOKEN_CODE);
