import React from "react";
import { LoLang } from "maven-lib/dist/consts";
import { useDispatch } from "react-redux";
import { useRequest } from "@coralblack/flax";
import { StudyUser, User } from "maven-lib/dist/dto/entity";
import { StudyUserRolePriv } from "maven-lib/dist/dto/study";
import { authApi } from "maven-lib/dist/states/apis/auth";
import { sessionSlice } from "maven-lib/dist/states/reducers/session";
import { AuthTokenManager } from "./partial/TokenManager";
import { CrContextualMenu, CrContextualMenuItem } from "../components/base/CrContextualMenu";
import { useRoute } from "../hooks/useRoute";

export type HeaderHelpButtonProp =
  | false
  | {
      title: string;
      url: string;
    };

interface HeaderProps {
  currentUser?: User;
  currentStudyUser?: StudyUser;
  currentRole: StudyUserRolePriv | undefined;
  hamburgerToggleState: () => void;
  drawerToggleState: Array<any>; // [boolean, Dispatch<boolean>];
  childrenNavi?: React.ReactNode;
  languageChangeable: boolean;
  localeLanguage: { lolang: LoLang };
  enableProfile: boolean;
  enableLoginHistory: boolean;
  hideHamburger?: boolean;
  helpButton?: HeaderHelpButtonProp;
  dropdownItems?: CrContextualMenuItem[];
}

enum MvnLanguageText {
  EN = "English",
  KO = "한국어",
}
export const LOCALE_LANG_KEY = "LoLang";

function languageFullName(localStorageValue: LoLang) {
  switch (localStorageValue) {
    case "EN":
      return MvnLanguageText.EN;
    case "KO":
    default:
      return MvnLanguageText.KO;
  }
}

export default function Header(props: HeaderProps) {
  const { pushPath, history } = useRoute();
  const {
    enableProfile,
    enableLoginHistory,
    currentUser,
    currentStudyUser,
    currentRole,
    hamburgerToggleState,
    childrenNavi,
    languageChangeable,
    localeLanguage,
    hideHamburger,
    helpButton,
  } = props;
  const dispatch = useDispatch();

  function changeLocaleLanguage(language: LoLang): void {
    localStorage.setItem(LOCALE_LANG_KEY, language);
    history.go(0);
  }

  const { request: authTokenDeleteRequest } = useRequest(
    { ...authApi.deleteAuthToken },
    {
      done: () => dispatch(sessionSlice.actions.signOut()),
    }
  );

  const dropdownItems: CrContextualMenuItem[] = [];

  if (enableProfile) {
    dropdownItems.push({
      type: "normal",
      text: "Profile",
      icon: "EditContact",
      onClick: () =>
        process.env.REACT_APP_FEATURE_SSO_ENABLED === "true"
          ? window.open(`${process.env.REACT_APP_AUTH_FE_URL}/profile`)
          : process.env.REACT_APP_CDMS_HOST
          ? (document.location.href = `${process.env.REACT_APP_CDMS_HOST}/my/profile`)
          : pushPath("/my/profile"),
    });
  }

  if (enableLoginHistory) {
    dropdownItems.push({
      type: "normal",
      text: process.env.REACT_APP_FEATURE_SSO_ENABLED === "true" ? "Account History" : "Login History",
      icon: "History",
      onClick: () =>
        process.env.REACT_APP_FEATURE_SSO_ENABLED === "true"
          ? window.open(`${process.env.REACT_APP_AUTH_FE_URL}/user-auth-logs`)
          : process.env.REACT_APP_CDMS_HOST
          ? (document.location.href = `${process.env.REACT_APP_CDMS_HOST}/login-histories`)
          : pushPath("/my/login-histories"),
    });
  }

  if (props.dropdownItems?.length) {
    dropdownItems.push(...props.dropdownItems);
  }

  if (dropdownItems.length) {
    dropdownItems.push({ type: "divider" });
  }

  dropdownItems.push({ type: "normal", text: "Logout", icon: "SignOut", onClick: () => authTokenDeleteRequest() });

  const languageDropdownItems: CrContextualMenuItem[] = [
    {
      type: "normal",
      text: MvnLanguageText.EN,
      onClick: () => {
        changeLocaleLanguage(LoLang.EN);
      },
    },
    { type: "divider" },
    {
      type: "normal",
      text: MvnLanguageText.KO,
      onClick: () => {
        changeLocaleLanguage(LoLang.KO);
      },
    },
  ];

  return (
    <>
      <div className="app-header-wrapper">
        <div className="header">
          {hideHamburger !== true && (
            <button className="hamburger" onClick={hamburgerToggleState}>
              <i className="notranslate material-icons">menu</i>
            </button>
          )}
          <strong className="logo">{process.env.REACT_APP_NAME}</strong>
        </div>
        <div className="navi">
          <ul className="navi-wrapper">
            {languageChangeable && (
              <li className="nav dropdown change-language">
                <CrContextualMenu items={languageDropdownItems}>
                  <i className="notranslate material-icons">language</i> {languageFullName(localeLanguage.lolang)}
                </CrContextualMenu>
              </li>
            )}
            {helpButton !== false && (
              <li className={`nav help ${!currentUser ? "--no-user" : ""}`.trim()}>
                <a
                  href={
                    helpButton?.url || (localeLanguage.lolang === "KO" ? "https://mvn.do/guides/cdms" : "https://mvn.do/guides/cdms/en")
                  }
                  target="_blank"
                  rel="noreferrer"
                  title={helpButton?.title || "Maven CDMS User Guide"}
                >
                  <i className="notranslate material-icons-outlined">help_outline</i>
                </a>
              </li>
            )}
            {!!currentUser && (
              <>
                <li className="nav">
                  <AuthTokenManager localeLanguage={localeLanguage} />
                </li>
                <li className="nav profile dropdown">
                  <CrContextualMenu items={dropdownItems}>
                    <i className="notranslate material-icons-outlined">person</i> {currentRole && <>[{currentRole.name}] </>}
                    <span className="user-name-or-email">{currentStudyUser?.name || currentUser?.name || currentUser?.email}</span>
                  </CrContextualMenu>
                </li>
              </>
            )}
          </ul>
          {!!childrenNavi && childrenNavi}
        </div>
      </div>
    </>
  );
}

Header.defaultProps = {
  languageChangeable: true,
  enableProfile: true,
  enableLoginHistory: true,
};
