import React from "react";
import styled from "@emotion/styled";
import { FontIcon } from "@fluentui/react/lib/components/Icon/FontIcon";
import { classNames } from "maven-lib/dist/utils/misc";
import { IconName, IconPackageName } from "../interface/Icon";

interface CrIconProps {
  icon: IconName | IconPackageName;
  ariaLabel?: string;
  className?: string;
}

const StyledMaterial = styled.i`
  display: inline-block;
  font-style: normal;

  &.--material {
    font-family: "Material Icons Outlined";
  }

  &.--material-symbol {
    font-family: "Material Symbols Outlined";
  }
`;

const renderFontIcon = (icon: IconName | IconPackageName, ariaLabel?: string, className?: string) => (
  <FontIcon
    iconName={typeof icon === "string" ? icon : icon.name}
    aria-label={ariaLabel}
    className={classNames("cr-icon", className, "--fluent", `--icon-${typeof icon === "string" ? icon : icon.name}`)}
  />
);

const renderStyledMaterial = (icon: IconPackageName, iconType: string, className?: string) => (
  <StyledMaterial className={classNames("cr-icon", `--${iconType}`, "notranslate", `--icon-${icon.name}`, className)}>
    {icon.name}
  </StyledMaterial>
);

export function CrIcon({ icon, ariaLabel, className }: CrIconProps) {
  if (typeof icon === "string" || icon.package === "FLUENT") {
    return renderFontIcon(icon, ariaLabel, className);
  }

  if (typeof icon === "object") {
    const iconType = icon.package === "MATERIAL" ? "material" : "material-symbol";

    return renderStyledMaterial(icon, iconType, className);
  }

  return <></>;
}
