import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const RESOURCE_S3_RESOURCE_CODE: {
  RESOURCE_S3_RESOURCE_COPIED_LIVE_BUCKET_ALREADY: MvnCodeBE;
} = {
  RESOURCE_S3_RESOURCE_COPIED_LIVE_BUCKET_ALREADY: {
    status: 409,
    ko: "요청하신 Resource는 이미 Live Bucket으로 복사가 완료되었습니다.",
    en: "The requested resource has already been copied to live bucket.",
  },
};

MvnCodeInitiate(RESOURCE_S3_RESOURCE_CODE);
