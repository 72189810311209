import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CRF_EXTENSION: {
  SAFETY_CRF_EXTENSION_NOTIFICATION_REGIONAL_ELEMENTS_INCLUDED: MvnCodeFE;
  SAFETY_CRF_EXTENSION_NOTIFICATION_REGIONAL_ELEMENTS_REMOVED: MvnCodeFE;
  SAFETY_CRF_EXTENSION_WARNING_HIDE_EXTENSION_DIALOG_DESCRIPTION_001: MvnCodeFE;
  SAFETY_CRF_EXTENSION_WARNING_HIDE_EXTENSION_DIALOG_DESCRIPTION_002: MvnCodeFE;
} = {
  SAFETY_CRF_EXTENSION_NOTIFICATION_REGIONAL_ELEMENTS_INCLUDED: {
    ko: "{extension} 항목이 추가되었습니다.",
    en: "{extension} regional elements are included.",
  },
  SAFETY_CRF_EXTENSION_NOTIFICATION_REGIONAL_ELEMENTS_REMOVED: {
    ko: "{extension} 항목이 제거되었습니다.",
    en: "{extension} regional elements are removed.",
  },
  SAFETY_CRF_EXTENSION_WARNING_HIDE_EXTENSION_DIALOG_DESCRIPTION_001: {
    ko: "{extension} 항목을 제거하시겠습니까?",
    en: "Are you sure you want to remove {extension} regional elements?",
  },
  SAFETY_CRF_EXTENSION_WARNING_HIDE_EXTENSION_DIALOG_DESCRIPTION_002: {
    ko: "{page} 페이지가 닫힙니다.",
    en: "{page} page will be closed.",
  },
};

MvnCodeInitiate(SAFETY_CRF_EXTENSION);
