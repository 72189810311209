import React, { RefObject, useState } from "react";
import { useRequest } from "@coralblack/flax";
import { IsUserPasswordValid } from "maven-lib/dist/consts/validator";
import { UserEmailVerifyCodeType } from "maven-lib/dist/dto/entity/userEmailVerifyCode";
import { userEmailVerificationApi } from "maven-lib/dist/states/apis/userEmailVerification";
import { userPasswordApi } from "maven-lib/dist/states/apis/userPassword";
import { ApiErrorResponse } from "maven-lib/dist/typings/api";
import { CrDialog } from "../../../../components/base/CrDialog";
import { CrInput } from "../../../../components/base/CrInput";
import { CrInputGroup } from "../../../../components/base/CrInputGroup";
import { CrRequestButton } from "../../../../components/base/CrRequestButton";

export interface InitEmailVerifyPasswordDialogMessageProps {
  dialogTitle: {
    default: string;
    codeSent: string;
    enterPassword: string;
  };

  button: {
    confirm: string;
    cancel: string;
    verify: string;
    requestVerificationCode: string;
  };

  label: {
    email: string;
    verificationCode: string;
    passwordNew: string;
    passwordConfirm: string;
  };

  placeholder: {
    email: string;
    verificationCode: string;
    passwordNew: string;
    passwordConfirm: string;
  };

  response: {
    verificationCodeSent: string;
    verificationCodeSendError: (error: ApiErrorResponse) => string;
    verificationCodeConfirmed: string;
    verificationCodeTryLimitExceed: string;
    verificationCodeNotMatched: string;
    passwordUpdateError: (error: ApiErrorResponse) => string;
  };

  alert: {
    passwordValidate: string;
    passwordNotMatched: string;
  };

  code: {
    verificationCodeTryLimitExceed: string;
  };
}

interface InitEmailVerifyPasswordDialogProps {
  messages: InitEmailVerifyPasswordDialogMessageProps;
  visibility: boolean;
  email: RefObject<HTMLInputElement>;
  emailVal: string;
  passwordUpdatedEventHandler: (password: string) => void;
  cancelEventHandler: () => void;
  header?: string;
}

export function InitEmailVerifyPasswordDialog(props: InitEmailVerifyPasswordDialogProps) {
  const { messages, visibility, email, emailVal, passwordUpdatedEventHandler, cancelEventHandler, header } = props;
  const [dialogTitle, setDialogTitle] = useState(messages.dialogTitle.default);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isVerifyCodeDisabled, setIsVerifyCodeDisabled] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const { request: updateUserEmailVerifyCode } = useRequest(
    { ...userPasswordApi.resetPasswordWithVerificationCode },
    {
      success: () => passwordUpdatedEventHandler(newPassword),
      error: (error) => messages.response.passwordUpdateError(error),
    }
  );

  return (
    <>
      <CrDialog
        header={header}
        visibility={visibility}
        title={dialogTitle}
        onConfirm={() =>
          updateUserEmailVerifyCode({
            data: { type: UserEmailVerifyCodeType.PasswordInit, email, verifyCode: verificationCode, newPassword },
          })
        }
        onCancel={() => {
          setNewPassword("");
          setConfirmPassword("");
          cancelEventHandler();
        }}
        confirmDisabled={!email || !isPasswordMatch || !isPasswordValid}
        confirm={messages.button.confirm}
        cancel={messages.button.cancel}
        size="WIDE"
      >
        <CrInputGroup spacing={7}>
          <CrInputGroup spacing={5}>
            <CrInput label={messages.label.email} value={emailVal} disabled={true}></CrInput>
            {!isEmailSent && (
              <CrRequestButton
                disabled={isEmailSent}
                color="primary"
                api={{
                  ...userEmailVerificationApi.createUserEmailVerificationCode,
                  data: () => ({
                    type: UserEmailVerifyCodeType.PasswordInit,
                    email,
                  }),
                }}
                delegate={{
                  success: () => {
                    setIsEmailSent(true);
                    setIsVerifyCodeDisabled(false);
                    setDialogTitle(messages.dialogTitle.codeSent);

                    return messages.response.verificationCodeSent;
                  },
                  error: (error) => messages.response.verificationCodeSendError(error),
                }}
              >
                {messages.button.requestVerificationCode}
              </CrRequestButton>
            )}
          </CrInputGroup>
          {isEmailSent && !isEmailVerified && (
            <>
              <CrInputGroup spacing={5}>
                <CrInput
                  label={messages.label.verificationCode}
                  placeholder={messages.placeholder.verificationCode}
                  value={verificationCode}
                  maxLength={8}
                  onChange={(_, v) => {
                    setVerificationCode(v);
                  }}
                  onPaste={(ev) => {
                    ev.persist();
                    ev.preventDefault();

                    const val = String(ev.clipboardData.getData("Text")).replace(/\D+/g, "").slice(0, 8);

                    setVerificationCode(val);
                  }}
                  disabled={isVerifyCodeDisabled}
                />
                <CrRequestButton
                  className="dialog-btn"
                  color="primary"
                  api={{
                    ...userEmailVerificationApi.verifyUserEmailVerificationCode,
                    query: {
                      type: UserEmailVerifyCodeType.PasswordInit,
                      email: emailVal,
                      verify_code: verificationCode,
                    },
                  }}
                  delegate={{
                    success: () => {
                      setIsEmailSent(true);
                      setIsEmailVerified(true);
                      setIsVerifyCodeDisabled(true);
                      setDialogTitle(messages.dialogTitle.enterPassword);

                      return messages.response.verificationCodeConfirmed;
                    },
                    error: (error) => {
                      if (error?.code?.length > 0) {
                        if (error.code === messages.code.verificationCodeTryLimitExceed) {
                          setIsEmailSent(false);
                          return messages.response.verificationCodeTryLimitExceed;
                        }
                      }
                      return messages.response.verificationCodeNotMatched;
                    },
                  }}
                >
                  {messages.button.verify}
                </CrRequestButton>
              </CrInputGroup>
            </>
          )}
          {isEmailVerified && (
            <>
              <CrInput
                label={messages.label.passwordNew}
                type="password"
                errorMessage={newPassword && !isPasswordValid ? messages.alert.passwordValidate : ""}
                placeholder={messages.placeholder.passwordNew}
                autoComplete="off"
                onValueChange={(val) => {
                  setIsPasswordValid(IsUserPasswordValid(val));
                  setIsPasswordMatch(val === confirmPassword);
                  setNewPassword(val);
                }}
              />
              <CrInput
                label={messages.label.passwordConfirm}
                type="password"
                errorMessage={confirmPassword && !isPasswordMatch ? messages.alert.passwordNotMatched : ""}
                placeholder={messages.placeholder.passwordConfirm}
                autoComplete="off"
                onValueChange={(val) => {
                  setIsPasswordMatch(val === newPassword);
                  setConfirmPassword(val);
                }}
              />
            </>
          )}
        </CrInputGroup>
      </CrDialog>
    </>
  );
}
