import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_SUBJECT_LANG: {
  CDMS_STUDY_SWITCH_PRESUBJECT_TO_SUBJECT_BTN: MvnCodeFE;
  CDMS_STUDY_SWITCH_PRESUBJECT_TO_SUBJECT_SUCC: MvnCodeFE;
  CDMS_STUDY_SWITCH_PRESUBJECT_TO_SUBJECT_FAIL: MvnCodeFE;
  CDMS_SUBJECT_BULK_ADD_DIALOG_DESC: MvnCodeFE;
  CDMS_SUBJECT_BULK_ADD_DIALOG_INVALID_COUNTS_DESC: MvnCodeFE;
  CDMS_SUBJECT_CONTACT_NOT_EXIST: MvnCodeFE;
} = {
  CDMS_STUDY_SWITCH_PRESUBJECT_TO_SUBJECT_BTN: {
    ko: "Add to Subject",
    en: "Add to Subject",
  },
  CDMS_STUDY_SWITCH_PRESUBJECT_TO_SUBJECT_SUCC: {
    ko: "Subject 전환에 성공하였습니다.",
    en: "Subject successfully added.",
  },
  CDMS_STUDY_SWITCH_PRESUBJECT_TO_SUBJECT_FAIL: {
    ko: "Subject 전환에 실패하였습니다. ({message})",
    en: "Failed to add subject. ({message})",
  },
  CDMS_SUBJECT_BULK_ADD_DIALOG_DESC: {
    ko: "Subject는 한번에 최대 {count}명까지 일괄 등록 가능합니다.",
    en: "Subjects can be registered up to {count} at a time. ",
  },
  CDMS_SUBJECT_BULK_ADD_DIALOG_INVALID_COUNTS_DESC: {
    ko: "{count}명 이하로 입력해 주세요.",
    en: "Please enter the number of subjects less than {count}.",
  },
  CDMS_SUBJECT_CONTACT_NOT_EXIST: {
    ko: "Subject 전화번호가 설정되지 않았습니다. 전화번호 설정 후 다시 시도해 주십시오.",
    en: "Subject phone number has not been entered. Please try again after setting the phone number.",
  },
};

MvnCodeInitiate(CDMS_STUDY_SUBJECT_LANG);
