/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_DASHBOARD_CODE: {
  NOT_FOUND: MvnCodeBE;
} = {
  NOT_FOUND: {
    status: 400,
    ko: "요청하신 Study에서 Dashboard 정보를 찾을 수 없습니다.",
    en: "Requested dashboard information could not be found in study.",
  },
};

MvnCodeInitiate(STUDY_DASHBOARD_CODE);
