/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_DOCUMENT_COMMENT_CODE: {
  TMF_STUDY_DOCUMENT_COMMENT_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_DOCUMENT_COMMENT_ID_INVALID: MvnCodeBE;
  TMF_STUDY_DOCUMENT_COMMENT_CONTENT_MISSING: MvnCodeBE;
  TMF_STUDY_DOCUMENT_COMMENT_CONTENT_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_DOCUMENT_COMMENT_CONTENT_TOO_LONG: MvnCodeBE;
} = {
  TMF_STUDY_DOCUMENT_COMMENT_ID_NOT_FOUND: {
    code: "TMF_STUDY_DOCUMENT_COMMENT_ID_NOT_FOUND",
    status: 400,
    ko: "studyDocumentCommentId({id})가 존재하지 않습니다.",
    en: "studyDocumentCommentId({id}) does not exists."
  },
  TMF_STUDY_DOCUMENT_COMMENT_ID_INVALID: {
    code: "TMF_STUDY_DOCUMENT_COMMENT_ID_INVALID",
    status: 400,
    ko: "TMF Document comment id({id})가 잘못되었습니다.",
    en: "TMF Document comment id({id}) is invalid."
  },
  TMF_STUDY_DOCUMENT_COMMENT_CONTENT_MISSING: {
    code: "TMF_STUDY_DOCUMENT_COMMENT_CONTENT_MISSING",
    status: 400,
    ko: "Document Comment가 입력되지 않았습니다.",
    en: "Document Comment is missing."
  },
  TMF_STUDY_DOCUMENT_COMMENT_CONTENT_TOO_SHORT: {
    code: "TMF_STUDY_DOCUMENT_COMMENT_CONTENT_TOO_SHORT",
    status: 400,
    ko: "Document Comment의 길이는 최소 {length}자 이상입니다.",
    en: "Document comment must be at least {length} characters long.",
  },
  TMF_STUDY_DOCUMENT_COMMENT_CONTENT_TOO_LONG: {
    code: "TMF_STUDY_DOCUMENT_COMMENT_CONTENT_TOO_LONG",
    status: 400,
    ko: "Document Comment의 길이는 최대 {length}자 입니다.",
    en: "Document comment can be up to {length} characters long.",
  },
};

MvnCodeInitiate(TMF_STUDY_DOCUMENT_COMMENT_CODE);
