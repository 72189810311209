import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_WORKSTATION_LANG: {
  SAFETY_WORKSTATION_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
} = {
  SAFETY_WORKSTATION_PAGE_TITLE_DESCRIPTION: {
    ko: "진행 중인 작업들을 확인하고 관리할 수 있습니다.",
    en: "Manage assigned report",
  },
};

MvnCodeInitiate(SAFETY_WORKSTATION_LANG);
