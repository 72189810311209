/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CRAWLER_CRAWLING_CODE: {
  CRAWLER_CRAWLING_CODE_NON_CRAWLERABLE_CONTENT: MvnCodeBE;
  CRAWLER_CRAWLING_CODE_PAGE_NOT_FOUND: MvnCodeBE;
  CRAWLER_CRAWLING_CODE_TIMEOUT_EXCEEDED: MvnCodeBE;
} = {
  CRAWLER_CRAWLING_CODE_NON_CRAWLERABLE_CONTENT: {
    status: 400,
    ko: "Non crawlerable content. ({contentType})",
    en: "Non crawlerable content. ({contentType})",
  },
  CRAWLER_CRAWLING_CODE_PAGE_NOT_FOUND: {
    status: 400,
    ko: "Non crawlerable content. (404)",
    en: "Non crawlerable content. (404)",
  },
  CRAWLER_CRAWLING_CODE_TIMEOUT_EXCEEDED: {
    status: 504,
    ko: "Crawling response wait timeout({timeout}) exceeded.",
    en: "Crawling response wait timeout({timeout}) exceeded.",
  },
};

MvnCodeInitiate(CRAWLER_CRAWLING_CODE);
