import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_LANG: {
  BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_USER_INFO_LABEL: MvnCodeFE;
  BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_EMAIL_LABEL: MvnCodeFE;
  BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_EMAIL_PLACEHOLDER: MvnCodeFE;
  BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_PASSWORD_LABEL: MvnCodeFE;
  BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_PASSWORD_PLACEHOLDER: MvnCodeFE;
} = {
  BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_USER_INFO_LABEL: {
    ko: "사용자 정보",
    en: "User Information",
  },
  BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_EMAIL_LABEL: {
    ko: "이메일",
    en: "Email",
  },
  BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_EMAIL_PLACEHOLDER: {
    ko: "이메일 주소를 입력해 주세요.",
    en: "Enter your email address.",
  },
  BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_PASSWORD_LABEL: {
    ko: "비밀번호",
    en: "Password",
  },
  BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_PASSWORD_PLACEHOLDER: {
    ko: "비밀번호를 입력해 주세요.",
    en: "Enter your password.",
  },
};

MvnCodeInitiate(BUILDER_GENERATE_REQUEST_TOKEN_DIALOG_LANG);
