import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_WORKFLOW_CODE: {
  SAFETY_WORKFLOW_INVALID_WORKFLOW_STEP: MvnCodeBE;
  SAFETY_WORKFLOW_INVALID_SEND_BACK_REQUEST_UNAUTHORIZED: MvnCodeBE;
  SAFETY_WORKFLOW_INCLUDE_UNASSIGNED_WORKFLOW: MvnCodeBE;
  SAFETY_WORKFLOW_INVALID_SEND_BACK_ASSIGNEE_UNAUTHORIZED: MvnCodeBE;
} = {
  SAFETY_WORKFLOW_INVALID_WORKFLOW_STEP: {
    status: 400,
    ko: "올바르지 않은 요청입니다. Workflow status가 {status}인 경우 {requestStatus}에 대해 요청할 수 없습니다.",
    en: "Invalid request. You cannot request {requestStatus} when the workflow status is {status}.",
  },
  SAFETY_WORKFLOW_INVALID_SEND_BACK_REQUEST_UNAUTHORIZED: {
    status: 403,
    ko: "올바르지 않은 요청입니다. Workflow를 send back을 하기 위한 권한이 없습니다.",
    en: "Invalid request. You do not have permission to send back the workflow.",
  },
  SAFETY_WORKFLOW_INCLUDE_UNASSIGNED_WORKFLOW: {
    status: 400,
    ko: "올바르지 않은 요청입니다. 할당되지 않은 Workflow가 포함되어 있습니다.",
    en: "Invalid request. Contains unassigned workflows.",
  },
  SAFETY_WORKFLOW_INVALID_SEND_BACK_ASSIGNEE_UNAUTHORIZED: {
    status: 403,
    ko: "Send back을 요청할 수 없습니다. {assignee}가 해당 업무를 처리할 수 있는 권한이 없습니다. Case Supervisor에게 문의하여 Workflow를 취소하세요.",
    en: "You cannot request send back. {assignee} does not have permission to handle the task. Contact the Case Supervisor to cancel the workflow.",
  },
};

MvnCodeInitiate(SAFETY_WORKFLOW_CODE);
