import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const TMF_STUDY_REPORT_LANG: {
  ENTER_REPORT_NAME_PLACEHOLDER: MvnCodeFE;
  NUMBER_NOUN: MvnCodeFE;
  NAME_NOUN: MvnCodeFE;
  PUBLISH_NOUN: MvnCodeFE;
  CREATOR_NOUN: MvnCodeFE;
  CREATED_DATE_NOUN: MvnCodeFE;
  DOWNLOAD_NOUN: MvnCodeFE;

  REPORT_CREATE_NOUN: MvnCodeFE;
  REPORT_EDIT_NOUN: MvnCodeFE;
  REPORT_COPY_NOUN: MvnCodeFE;

  REPORT_INFO_NOUN: MvnCodeFE;
  REPORT_NAME_NOUN: MvnCodeFE;
  REPORT_NAME_PLACEHOLDER: MvnCodeFE;
  REPORT_PUBLISH_NOUN: MvnCodeFE;
  REPORT_PUBLISH_PLACEHOLDER: MvnCodeFE;
  REPORT_SHARED_USER_NOUN: MvnCodeFE;
  REPORT_SHARED_USER_PLACEHOLDER: MvnCodeFE;

  REPORT_ATTR_NOUN: MvnCodeFE;
  DOCUMENT_NAME_NOUN: MvnCodeFE;
  DOCUMENT_NAME_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_STATUS_NOUN: MvnCodeFE;
  DOCUMENT_STATUS_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_DATE_NOUN: MvnCodeFE;
  DOCUMENT_UPLOADER_NOUN: MvnCodeFE;
  DOCUMENT_UPLOADER_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_UPLOAD_DATE_NOUN: MvnCodeFE;
  DOCUMENT_UPLOAD_DATE_FROM_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_UPLOAD_DATE_TO_PLACEHOLDER: MvnCodeFE;

  TMF_LOCATION_NOUN: MvnCodeFE;
  ZONE_NOUN: MvnCodeFE;
  ZONE_PLACEHOLDER: MvnCodeFE;
  SECTION_NOUN: MvnCodeFE;
  SECTION_PLACEHOLDER: MvnCodeFE;
  ARTIFACT_NOUN: MvnCodeFE;
  ARTIFACT_PLACEHOLDER: MvnCodeFE;

  REPORT_DOWNLOAD_NOUN: MvnCodeFE;
  CREATE_NOUN: MvnCodeFE;
  EDIT_NOUN: MvnCodeFE;
  DELETE_NOUN: MvnCodeFE;
  COPY_NOUN: MvnCodeFE;
  SHARE_NOUN: MvnCodeFE;
  CANCEL_NOUN: MvnCodeFE;
  SAVE_NOUN: MvnCodeFE;

  REPORT_CREATE_SUCCESS_TOAST: MvnCodeFE;
  REPORT_CREATE_FAIL_TOAST: MvnCodeFE;

  REPORT_DOWNLOAD_SUCCESS_TOAST: MvnCodeFE;
  REPORT_DOWNLOAD_FAIL_TOAST: MvnCodeFE;

  REPORT_LIST_LOAD_FAIL_TOAST: MvnCodeFE;
  REPORT_LOAD_FAIL_TOAST: MvnCodeFE;

  REPORT_UPDATE_SUCCESS_TOAST: MvnCodeFE;
  REPORT_UPDATE_FAIL_TOAST: MvnCodeFE;

  NO_REPORT_EMPTY_VIEW: MvnCodeFE;

  SHAREABLE_USER_LOAD_FAIL_TOAST: MvnCodeFE;

  REPORT_REMOVE_TITLE: MvnCodeFE;
  REPORT_REMOVE_PROMPT: MvnCodeFE;
  REPORT_REMOVE_PROMPT2: MvnCodeFE;

  REPORT_DOCUMENT_LIST_LOAD_FAIL_TOAST: MvnCodeFE;
} = {
  ENTER_REPORT_NAME_PLACEHOLDER: {
    ko: "Report 이름을 입력해 주세요.",
    en: "Please enter the report name.",
  },

  NUMBER_NOUN: {
    ko: "No",
    en: "No",
  },
  NAME_NOUN: {
    ko: "Report Name",
    en: "Report Name",
  },
  PUBLISH_NOUN: {
    ko: "Publish",
    en: "Publish",
  },
  CREATOR_NOUN: {
    ko: "Creator",
    en: "Creator",
  },
  CREATED_DATE_NOUN: {
    ko: "Date created",
    en: "Date created",
  },
  DOWNLOAD_NOUN: {
    ko: "Download",
    en: "Download",
  },

  REPORT_DOWNLOAD_NOUN: {
    ko: "Report 다운로드",
    en: "Report Download",
  },
  REPORT_CREATE_NOUN: {
    ko: "Create Report",
    en: "Create Report",
  },
  REPORT_EDIT_NOUN: {
    ko: "Edit Report",
    en: "Edit Report",
  },
  REPORT_COPY_NOUN: {
    ko: "Copy Report",
    en: "Copy Report",
  },

  REPORT_INFO_NOUN: {
    ko: "Report Information",
    en: "Report Information",
  },
  REPORT_NAME_NOUN: {
    ko: "Report Name",
    en: "Report Name",
  },
  REPORT_NAME_PLACEHOLDER: {
    ko: "Report 이름을 입력해 주세요.",
    en: "Please enter report name.",
  },
  REPORT_PUBLISH_NOUN: {
    ko: "Publish",
    en: "Publish",
  },
  REPORT_PUBLISH_PLACEHOLDER: {
    ko: "Report 공개 여부를 선택해 주세요.",
    en: "Please select share option.",
  },

  REPORT_SHARED_USER_NOUN: {
    ko: "Shared user",
    en: "Shared user",
  },
  REPORT_SHARED_USER_PLACEHOLDER: {
    ko: "공유할 사용자를 선택해 주세요.",
    en: "Please select users to share with.",
  },

  REPORT_ATTR_NOUN: {
    ko: "Document Attribution",
    en: "Document Attribution",
  },
  DOCUMENT_NAME_NOUN: {
    ko: "Document Name",
    en: "Document Name",
  },
  DOCUMENT_NAME_PLACEHOLDER: {
    ko: "문서 이름을 입력해 주세요.",
    en: "Please enter document name",
  },
  DOCUMENT_STATUS_NOUN: {
    ko: "Document Status",
    en: "Document Status",
  },
  DOCUMENT_STATUS_PLACEHOLDER: {
    ko: "문서 상태를 선택해 주세요.",
    en: "Please select document status.",
  },
  DOCUMENT_DATE_NOUN: {
    ko: "Document Date",
    en: "Document Date",
  },
  DOCUMENT_UPLOADER_NOUN: {
    ko: "Uploader",
    en: "Uploader",
  },
  DOCUMENT_UPLOADER_PLACEHOLDER: {
    ko: "업로더를 선택해 주세요.",
    en: "Please select uploader",
  },
  DOCUMENT_UPLOAD_DATE_NOUN: {
    ko: "Created at",
    en: "Created at",
  },
  DOCUMENT_UPLOAD_DATE_FROM_PLACEHOLDER: {
    ko: "업로드 시작일",
    en: "Start date",
  },
  DOCUMENT_UPLOAD_DATE_TO_PLACEHOLDER: {
    ko: "업로드 종료일",
    en: "End date",
  },

  TMF_LOCATION_NOUN: {
    ko: "TMF Location",
    en: "TMF Location",
  },
  ZONE_NOUN: {
    ko: "Zone",
    en: "Zone",
  },
  ZONE_PLACEHOLDER: {
    ko: "Zone을 선택해 주세요.",
    en: "Please select zone.",
  },
  SECTION_NOUN: {
    ko: "Section",
    en: "Section",
  },
  SECTION_PLACEHOLDER: {
    ko: "Section을 선택해 주세요.",
    en: "Please select section.",
  },
  ARTIFACT_NOUN: {
    ko: "Artifact",
    en: "Artifact",
  },
  ARTIFACT_PLACEHOLDER: {
    ko: "Artifact을 선택해 주세요.",
    en: "Please select artifact.",
  },

  CREATE_NOUN: {
    ko: "생성",
    en: "Create",
  },
  EDIT_NOUN: {
    ko: "수정",
    en: "Edit",
  },
  DELETE_NOUN: {
    ko: "삭제",
    en: "Delete",
  },
  COPY_NOUN: {
    ko: "복사",
    en: "Copy",
  },
  SHARE_NOUN: {
    ko: "공유",
    en: "Share",
  },
  CANCEL_NOUN: {
    ko: "취소",
    en: "Cancel",
  },
  SAVE_NOUN: {
    ko: "저장",
    en: "Save",
  },

  REPORT_CREATE_SUCCESS_TOAST: {
    ko: "Report 생성에 성공했습니다.",
    en: "The report created successfully.",
  },
  REPORT_CREATE_FAIL_TOAST: {
    ko: "Report 생성에 실패했습니다. ({message})",
    en: "Failed to create the report. ({message})",
  },

  REPORT_DOWNLOAD_SUCCESS_TOAST: {
    ko: "Report 다운로드에 성공했습니다.",
    en: "The report downloaded successfully.",
  },
  REPORT_DOWNLOAD_FAIL_TOAST: {
    ko: "Report 다운로드에 실패했습니다. ({message})",
    en: "Failed to download the report. ({message})",
  },

  REPORT_LIST_LOAD_FAIL_TOAST: {
    ko: "Report List 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Report List. ({message})",
  },
  REPORT_LOAD_FAIL_TOAST: {
    ko: "Report 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Report. ({message})",
  },
  REPORT_UPDATE_SUCCESS_TOAST: {
    ko: "Report 수정에 성공했습니다.",
    en: "The report updated successfully.",
  },
  REPORT_UPDATE_FAIL_TOAST: {
    ko: "Report 수정에 실패했습니다. ({message})",
    en: "Failed to update the report. ({message})",
  },

  NO_REPORT_EMPTY_VIEW: {
    ko: "리포트를 먼저 생성해주세요.",
    en: "Please create a report",
  },

  SHAREABLE_USER_LOAD_FAIL_TOAST: {
    ko: "Report 공유 가능한 유저 리스트 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Report shareable user data. ({message})",
  },

  REPORT_REMOVE_TITLE: {
    ko: "TMF Report Delete",
    en: "TMF Report Delete",
  },
  REPORT_REMOVE_PROMPT: {
    ko: "TMF Report를 삭제하시겠습니까?",
    en: "Do you want to delete the report?",
  },
  REPORT_REMOVE_PROMPT2: {
    ko: "(삭제된 Report는 복구가 불가능 합니다.)",
    en: "The deleted report cannot be restored.",
  },

  REPORT_DOCUMENT_LIST_LOAD_FAIL_TOAST: {
    ko: "Document List 불러오기에 실패했습니다. ({message})",
    en: "Failed to load Document List. ({message})",
  },
};

MvnCodeInitiate(TMF_STUDY_REPORT_LANG);
