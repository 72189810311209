import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CASE_CODE: {
  SAFETY_CASE_CREATE_INVALID_REQUIRED_ITEM_VALUE_MISSING: MvnCodeBE;
  SAFETY_CASE_CREATE_INVALID_DUPLICATE_IDENTIFIER: MvnCodeBE;
  SAFETY_CASE_CREATE_INVALID_DUPLICATE_CASE: MvnCodeBE;
  SAFETY_CASE_COPY_INVALID_WORKFLOW_STATUS: MvnCodeBE;
} = {
  SAFETY_CASE_CREATE_INVALID_REQUIRED_ITEM_VALUE_MISSING: {
    status: 400,
    ko: "{label} 항목이 입력되지 않았습니다.",
    en: "The {label} item input is missing.",
  },
  SAFETY_CASE_CREATE_INVALID_DUPLICATE_IDENTIFIER: {
    status: 400,
    ko: "이미 존재하는 고유식별 관리자번호입니다.",
    en: "Case identifier already exists.",
  },
  SAFETY_CASE_CREATE_INVALID_DUPLICATE_CASE: {
    status: 409,
    ko: "중복된 case가 존재합니다.",
    en: "Duplicate case(s) exist.",
  },
  SAFETY_CASE_COPY_INVALID_WORKFLOW_STATUS: {
    status: 400,
    ko: "{status} 상태의 case는 복사할 수 없습니다.",
    en: "Case in the {status} state cannot be copied.",
  },
};

MvnCodeInitiate(SAFETY_CASE_CODE);
