import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_CASE_LANG: {
  SAFETY_CREATE_CASE: MvnCodeFE;
  SAFETY_CASE_ADD_BREADCRUMB: MvnCodeFE;
  SAFETY_CASE_ADD_CRF_SELECT_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_ADD_SUCC: MvnCodeFE;
  SAFETY_CASE_ADD_FAIL: MvnCodeFE;
  SAFETY_CASE_SAVE_SUCC: MvnCodeFE;
  SAFETY_CASE_SAVE_FAIL: MvnCodeFE;
  SAFETY_CASE_CRF_VIEW_FAIL: MvnCodeFE;
  SAFETY_CASE_IDENTIFIER_AUTO_GENERATE_INPUT_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_ADD_CRF_DROPDOWN_PLACEHOLDER: MvnCodeFE;
  SAFETY_CASE_ADD_CRF_CHANGE_WARNING: MvnCodeFE;
  SAFETY_CASE_VIEW_NOT_AUTHORIZED: MvnCodeFE;
  SAFETY_CASE_COPY_DIALOG_HEADER: MvnCodeFE;
  SAFETY_CASE_COPY_DIALOG_DESC_CASE_IDENTIFICATION_REQUIRED: MvnCodeFE;
  SAFETY_CASE_COPY_DIALOG_CASE_IDENTICATION_ITEM_LABEL: MvnCodeFE;
  SAFETY_CASE_COPY_TOOLTIP_SELECT_SINGLE_CASE: MvnCodeFE;
  SAFETY_CASE_COPY_SUCC: MvnCodeFE;
  SAFETY_CASE_COPY_FAIL: MvnCodeFE;
  SAFETY_CASE_COPY_FAIL_IDENTIFICATION_DUPLICATED: MvnCodeFE;
  SAFETY_CREATE_CASE_DIALOG_TITLE: MvnCodeFE;
  SAFETY_CASE_SIMILAR_CASE_CHECK_DIALOG_TITLE: MvnCodeFE;
  SAFETY_CASE_SIMILAR_CASE_CHECK_DESCRIPTION: MvnCodeFE;
  SAFETY_CASE_SIMILAR_CASE_CHECK_LIST_DESCRIPTION_001: MvnCodeFE;
  SAFETY_CASE_SIMILAR_CASE_CHECK_LIST_DESCRIPTION_002: MvnCodeFE;
} = {
  SAFETY_CREATE_CASE: {
    ko: "Case 생성",
    en: "Create Case",
  },
  SAFETY_CASE_ADD_BREADCRUMB: {
    ko: "등록된 Case를 확인하고 관리할 수 있습니다.",
    en: "Create and manage cases",
  },
  SAFETY_CASE_ADD_CRF_SELECT_DIALOG_DESCRIPTION: {
    ko: "생성할 Case Report Form을 선택하세요.",
    en: "Select the Case Report Form to be created.",
  },
  SAFETY_CASE_ADD_SUCC: {
    ko: "CASE가 성공적으로 추가되었습니다.",
    en: "Case has been created successfully.",
  },
  SAFETY_CASE_ADD_FAIL: {
    ko: "CASE 추가에 실패했습니다. ({message})",
    en: "Failed to create case. ({message})",
  },
  SAFETY_CASE_SAVE_SUCC: {
    ko: "CASE가 성공적으로 저장되었습니다.",
    en: "Case has been saved successfully.",
  },
  SAFETY_CASE_SAVE_FAIL: {
    ko: "CASE 저장에 실패했습니다. ({message})",
    en: "Failed to save case. ({message})",
  },
  SAFETY_CASE_CRF_VIEW_FAIL: {
    ko: "Report 정보를 불러오는데 실패했습니다. ({message})",
    en: "Failed to load report information. ({message})",
  },
  SAFETY_CASE_IDENTIFIER_AUTO_GENERATE_INPUT_DESCRIPTION: {
    ko: "고유식별 보고자관리번호는 Case 생성 시 자동 부여됩니다.",
    en: "Case identifier is automatically assigned when creating a case.",
  },
  SAFETY_CASE_ADD_CRF_DROPDOWN_PLACEHOLDER: {
    ko: "Report를 선택해주세요.",
    en: "Please choose a report.",
  },
  SAFETY_CASE_ADD_CRF_CHANGE_WARNING: {
    ko: "Report Form을 변경하였습니다. 작성 중인 내용이 삭제되었습니다.",
    en: "The Report Form has been changed. The contents has been deleted.",
  },
  SAFETY_CASE_VIEW_NOT_AUTHORIZED: {
    ko: "Case를 보기 위해서는 {privileges} 권한이 필요합니다.",
    en: "You need {privileges} privilege(s) to view case details.",
  },
  SAFETY_CASE_COPY_DIALOG_HEADER: {
    ko: "선택한 Case를 복사하시겠습니까?",
    en: "Are you sure you want to copy a selected case?",
  },
  SAFETY_CASE_COPY_DIALOG_DESC_CASE_IDENTIFICATION_REQUIRED: {
    ko: "{caseIdentification}의 새로운 고유식별 보고자관리번호를 입력해주세요.",
    en: "Please enter a new unique case identification of {caseIdentification}.",
  },
  SAFETY_CASE_COPY_DIALOG_CASE_IDENTICATION_ITEM_LABEL: {
    ko: "고유식별 보고자관리번호",
    en: "Unique Case Identification",
  },
  SAFETY_CASE_COPY_TOOLTIP_SELECT_SINGLE_CASE: {
    ko: "1개의 Case를 선택해주세요.",
    en: "Please select a single case.",
  },
  SAFETY_CASE_COPY_SUCC: {
    ko: "Case가 성공적으로 복사되었습니다.",
    en: "Case has been copied successfully.",
  },
  SAFETY_CASE_COPY_FAIL: {
    ko: "Case 복사에 실패했습니다. ({message})",
    en: "Failed to copy case. ({message})",
  },
  SAFETY_CASE_COPY_FAIL_IDENTIFICATION_DUPLICATED: {
    ko: "이미 사용 중인 고유식별 보고자관리번호입니다.",
    en: "This unique case identification is already used.",
  },
  SAFETY_CREATE_CASE_DIALOG_TITLE: {
    ko: "Case 생성",
    en: "Create New Case",
  },
  SAFETY_CASE_SIMILAR_CASE_CHECK_DIALOG_TITLE: {
    ko: "유사 Case 검사",
    en: "Similar Case Check",
  },
  SAFETY_CASE_SIMILAR_CASE_CHECK_DESCRIPTION: {
    ko: "유사한 Case가 있습니다. 확인 후 Case 생성 여부를 결정해주세요.",
    en: "There are similar cases. Please check below before creating new case.",
  },
  SAFETY_CASE_SIMILAR_CASE_CHECK_LIST_DESCRIPTION_001: {
    ko: "새로운 Case 가 필요한 경우, 'Create Case' 버튼을 선택해주세요.",
    en: "Select 'New Case' if you need new case.",
  },
  SAFETY_CASE_SIMILAR_CASE_CHECK_LIST_DESCRIPTION_002: {
    ko: "취소를 원하는 경우 'Cancel' 버튼을 선택해주세요.",
    en: "Select 'Cancel' if you want to cancel.",
  },
};

MvnCodeInitiate(SAFETY_CASE_LANG);
