import { getLanguage } from "@lib/cdms-lib/lib";
import { UserDeviceFlax } from "@maven-rest/sso/UserDevice.flax";
import { useEffect } from "react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { useRequest } from "@coralblack/flax";
import { getPrivacyPolicyUrl } from "../layout/Auth";
import { useRoute } from "@maven-surface/hooks";
import { AUTH_USER_LANG } from "@maven-msg/lang";
import { MvnMessage } from "@maven-msg/lib/Message";

const F = 8;
const S = 10;
const O = 2;
const X = 10;

const frames = [
  `${S - X}px -${S + O}px 0 ${S}px,
  ${S + O}px -${S + O}px 0 ${S}px,
  ${S + O}px -${S - X}px 0 ${S}px,
  -${F + O}px ${F + O}px 0 ${F}px`,
  `${S + O}px -${S + O}px 0 ${S}px,
  ${S + O}px -${S + O}px 0 ${S}px,
  ${S + O}px ${S - O}px 0 ${S}px,
  -${F + O}px ${F + O}px 0 ${F}px`,
  `${S + O}px ${S - O}px 0 ${S}px,
  ${S + O}px ${S - O}px 0 ${S}px,
  ${S + O}px ${S - O}px 0 ${S}px,
  -${F + O}px ${F + O}px 0 ${F}px`,
  `-${F}px ${S - O}px 0 ${S}px,
  -${F}px ${S - O}px 0 ${S}px,
  -${F}px ${S - O}px 0 ${S}px,
  -${F + O}px ${F + O}px 0 ${F}px`,
  `-${F}px -${S + O}px 0 ${S}px,
  -${F}px -${S + O}px 0 ${S}px,
  -${F}px -${S + O}px 0 ${S}px,
  -${F + O}px ${F + O}px 0 ${F}px`,
  `${S - X}px -${S + O}px 0 ${S}px,
  ${S + O}px -${S + O}px 0 ${S}px,
  ${S + O}px -${S + O}px 0 ${S}px,
  -${F + O}px ${F + O}px 0 ${F}px`,
  `${S - X}px -${S + O}px 0 ${S}px,
  ${S + O}px -${S + O}px 0 ${S}px,
  ${S + O}px -${S - X}px 0 ${S}px,
  -${F + O}px ${F + O}px 0 ${F}px`,
];

const keyframesAnimation = keyframes`
  ${frames.map(
    (frame, frameIdx) => `
      ${Math.floor((frameIdx / frames.length) * 100)}% {
        box-shadow: ${frame};
      }
    `
  )}
  100% {
    box-shadow: ${frames[6]};
  }
`;

export const StyledLogo = styled.div(
  () => css`
    display: inline-block;
    position: relative;
    width: 0;
    height: 0;
    color: #1193c8;
    box-shadow: ${S - X}px -${S + O}px 0 ${S}px, ${S + O}px -${S + O}px 0 ${S}px, ${S + O}px -${S - X}px 0 ${S}px,
      -${F + O}px ${F + O}px 0 ${F}px;
    animation: ${keyframesAnimation} 1.5s infinite;
  `
);

const StyledFlexBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const StyledWrapper = styled(StyledFlexBox)`
  height: 100vh;
  background: #f4f6f9;
`;

const StyledLoader = styled(StyledFlexBox)`
  flex-grow: 1;
  gap: 32px;
  font-size: 18px;
  font-weight: 500;
  color: #343a3f;
`;

const StyledFooter = styled(StyledFlexBox)`
  padding: 10px 10px 12px;
  gap: 6px;

  @media screen and (min-width: 768px) {
    gap: 20px;
    flex-direction: row;
  }
`;

const StyledFooterText = styled.span`
  display: block;
  text-align: center;
  font-size: 13px;
  color: #a2a9b0;
  line-height: 140%;

  @media screen and (min-width: 768px) {
    display: inline;
  }
`;

const StyledLink = styled.a`
  font-size: 13px;
  font-weight: 500;
  color: #259ac5;
`;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export function PasswordLoading() {
  const { pushPath } = useRoute();
  const query = useQuery();
  const verificationToken = query.get("verification");
  const currentYear = new Date().getFullYear();
  const lang = getLanguage();

  const { request: notifyUnauthorizedDeviceRequest } = useRequest(
    { ...UserDeviceFlax.notifyMyUnauthorizedDevice({ token: verificationToken }) },
    {
      success: (resp) => {
        pushPath("/auth/password/find");
      },
      error: (error) => {
        return error;
      },
    }
  );

  useEffect(() => {
    notifyUnauthorizedDeviceRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledWrapper>
      <StyledLoader>
        <StyledLogo />
        <p>{MvnMessage(AUTH_USER_LANG.USER_REDIRECT_CHANGE_PASSWORD_LOADING)}</p>
      </StyledLoader>
      <StyledFooter>
        <StyledLink href={getPrivacyPolicyUrl(lang)} target="_blank">
          Privacy Policy
        </StyledLink>
        <StyledFooterText>
          <StyledFooterText>COPYRIGHT &copy; 2020-{currentYear} </StyledFooterText>
          <StyledFooterText>
            <StyledLink href="https://www.jnpmedi.com" target="_blank">
              JNPMEDI
            </StyledLink>
            &nbsp;ALL RIGHTS RESERVED.
          </StyledFooterText>
        </StyledFooterText>
      </StyledFooter>
    </StyledWrapper>
  );
}
