import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_DATA_EXPORT_CODE: {
  STUDY_DATA_EXPORT_SPEC_NOT_FOUND: MvnCodeBE;
  STUDY_DATA_EXPORT_SPEC_DISTINCT_NOT_SUPPORT: MvnCodeBE;
  STUDY_DATA_EXPORT_SPEC_OUTPUT_NOT_FOUND: MvnCodeBE;
  STUDY_DATA_EXPORT_REQUEST_INVALID_RESOURCE_NOT_FOUND: MvnCodeBE;
  STUDY_DATA_EXPORT_REQUEST_INVALID_OUTPUT_NOT_SUPPORT: MvnCodeBE;
  STUDY_DATA_EXPORT_REQUEST_DENIED_BY_PRIV: MvnCodeBE;
  STUDY_DATA_EXPORT_HISTORY_DOWNLOAD_URL_EXPIRED: MvnCodeBE;
  STUDY_DATA_EXPORT_REQUEST_DENIED_BY_DOWNLOAD_DISABLED: MvnCodeBE;
} = {
  STUDY_DATA_EXPORT_SPEC_NOT_FOUND: {
    status: 404,
    ko: "Study Data Export 정보가 존재하지 않습니다.",
    en: "Study data export entity does not exist.",
  },
  STUDY_DATA_EXPORT_SPEC_DISTINCT_NOT_SUPPORT: {
    status: 400,
    ko: "현재 지원되지않는 distinct 유형입니다.",
    en: "The configured distinct type is not supported currently.",
  },
  STUDY_DATA_EXPORT_SPEC_OUTPUT_NOT_FOUND: {
    status: 400,
    ko: "요청된 확장자와 일치하는 Study Data Export 변환 정보가 존재하지 않습니다.",
    en: "No Study Data Export conversion information matching the requested extension exists.",
  },
  STUDY_DATA_EXPORT_REQUEST_INVALID_RESOURCE_NOT_FOUND: {
    status: 400,
    ko: "요청 정보가 존재하지 않습니다.",
    en: "Request resource does not exist.",
  },
  STUDY_DATA_EXPORT_REQUEST_INVALID_OUTPUT_NOT_SUPPORT: {
    status: 400,
    ko: "현재 지원되지 않는 Output 유형입니다.",
    en: "The configured Output type is not supported currently.",
  },
  STUDY_DATA_EXPORT_REQUEST_DENIED_BY_PRIV: {
    status: 400,
    ko: "요청하신 정보에 대하여 권한이 없습니다.",
    en: "Not authorized to the requested information.",
  },
  STUDY_DATA_EXPORT_HISTORY_DOWNLOAD_URL_EXPIRED: {
    status: 409,
    ko: "Study Data Export 다운로드 URL이 만료되었습니다.",
    en: "Study Data Export download URL has expired.",
  },
  STUDY_DATA_EXPORT_REQUEST_DENIED_BY_DOWNLOAD_DISABLED: {
    status: 400,
    ko: "요청하신 정보는 다운로드가 비활성화 되어 있습니다.",
    en: "Request resource download is disabled.",
  },
};

MvnCodeInitiate(STUDY_DATA_EXPORT_CODE);
