import React, { FormEvent, useRef, useState } from "react";
import { Callout, DirectionalHint } from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";
import { arrayify, classNames } from "maven-lib/dist/utils/misc";
import { CrIcon } from "./CrIcon";
import { IconName } from "../interface/Icon";

export type CrInputThickness = "slim" | "normal" | "thick";

export interface CrInputProps extends Omit<React.AllHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>, "readOnly"> {
  autoFocus?: boolean;
  autoComplete?: string;
  placeholder?: string;
  className?: string;
  label?: string;
  labelTooltip?: string | string[];
  required?: boolean;
  prefix?: string;
  suffix?: string;
  description?: string;
  prefixedValue?: boolean;
  suffixedValue?: boolean;
  defaultValue?: string;
  value?: string;
  multiline?: boolean | { rows?: number; autoAdjustHeight?: boolean; disableResize?: boolean | "HORIZONTAL" | "VERTICAL" };
  disabled?: boolean;
  readonly?: boolean;
  readonlyFaded?: boolean;
  type?: "password" | "text" | "number";
  maxLength?: number;
  thickness?: CrInputThickness;
  iconName?: IconName | "VALID" | "INVALID" | "WARNING";
  errorMessage?: string;
  onValueChange?: (val: string) => void;
  onFocus?: (ev?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onEnterKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, val?: any) => void;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyUp?: (ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, val?: any) => void;
  onKeyPress?: (ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, val?: any) => void;
  onChange?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, val?: string | undefined) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export function CrInput(props: CrInputProps) {
  const {
    autoFocus,
    autoComplete,
    className,
    label,
    labelTooltip,
    multiline,
    prefix,
    suffix,
    prefixedValue,
    suffixedValue,
    description,
    placeholder,
    disabled,
    readonly,
    required,
    type,
    defaultValue,
    value,
    errorMessage,
    thickness,
    iconName,
    onValueChange,
    onFocus,
    onEnterKeyDown,
    onPaste,
    onChange,
    onKeyUp,
    onKeyPress,
    onBlur,
    onClick,
    onKeyDown,
    maxLength,
    readonlyFaded,
  } = props;

  const tooltipRef = useRef(null);
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const [tooltipCalloutVisibility, setTooltipCalloutVisibility] = useState(false);

  const disableResize =
    multiline && typeof multiline === "object" && typeof multiline.disableResize === "boolean" && multiline.disableResize;
  const disabledResizeDirection =
    multiline && typeof multiline === "object" && typeof multiline.disableResize !== "boolean" && multiline.disableResize;

  return (
    <TextField
      autoFocus={autoFocus}
      className={classNames(
        "cr-input",
        className,
        {
          "resize-disabled": disableResize,
          "state--faded": readonlyFaded,
          "state--valid": iconName === "VALID",
          "state--invalid": iconName === "INVALID",
          "state--warning": iconName === "WARNING",
        },
        `thickness--${String(thickness || "normal").toLowerCase()}`,
        !!disabledResizeDirection && `resize-${disabledResizeDirection.toLowerCase()}-disabled`
      )}
      label={label}
      onRenderLabel={
        labelTooltip
          ? (props, defaultRender) => {
              return (
                <>
                  {(tooltipVisibility || tooltipCalloutVisibility) && (
                    <Callout
                      className={classNames("mv-item-description-tooltip-callout", `attr--tooltip-text-${arrayify(labelTooltip).length}`)}
                      target={tooltipRef?.current}
                      doNotLayer={false}
                      directionalHint={DirectionalHint.topCenter}
                      onMouseMove={() => setTooltipCalloutVisibility(true)}
                      onMouseLeave={() => setTimeout(() => setTooltipCalloutVisibility(false), 50)}
                    >
                      {((tooltipTexts) => tooltipTexts.map((text, i) => <p key={i}>{text}</p>))(arrayify(labelTooltip))}
                    </Callout>
                  )}
                  <div className="attr--with-tooltip">
                    {defaultRender!(props)}
                    <span
                      ref={tooltipRef}
                      className="attr--tooltip-icon"
                      onMouseMove={() => setTooltipVisibility(true)}
                      onMouseLeave={() => setTimeout(() => setTooltipVisibility(false), 50)}
                    >
                      <CrIcon icon={"AlertSolid"} />
                    </span>
                  </div>
                </>
              );
            }
          : undefined
      }
      prefix={prefix}
      suffix={suffix}
      maxLength={maxLength}
      description={description}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      readOnly={readonly}
      multiline={!!multiline}
      rows={!!multiline ? (typeof multiline === "object" ? multiline.rows || 9 : 9) : undefined}
      autoAdjustHeight={!!multiline && (typeof multiline === "object" ? multiline.autoAdjustHeight : multiline)}
      type={type}
      iconProps={
        iconName
          ? {
              iconName:
                iconName === "VALID" ? "CheckMark" : iconName === "INVALID" ? "Cancel" : iconName === "WARNING" ? "Warning" : iconName,
            }
          : undefined
      }
      errorMessage={errorMessage}
      onChange={(_, val) => {
        if (onChange) onChange(_, val);

        return (
          onValueChange &&
          onValueChange(val ? `${prefix && prefixedValue ? prefix : ""}${val}${suffix && suffixedValue ? suffix : ""}` : "")
        );
      }}
      onKeyDown={(e) => {
        e.key === "Enter" && onEnterKeyDown && onEnterKeyDown(e, e.currentTarget.value);
        onKeyDown && onKeyDown(e);
      }}
      onPaste={onPaste}
      onKeyUp={onKeyUp}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      autoComplete={autoComplete}
    />
  );
}

CrInput.defaultProps = {
  type: "text",
};
