/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_SUBJECT_BLIND_ACTION_CODE: {
  STUDY_SUBJECT_BLIND_NOT_FOUND: MvnCodeBE;
  STUDY_SUBJECT_BLIND_ID_MISSING: MvnCodeBE;
  STUDY_SUBJECT_BLIND_ALREADY_BLINDED: MvnCodeBE;
  STUDY_SUBJECT_BLIND_ALREADY_UNBLINDED: MvnCodeBE;
  STUDY_SUBJECT_BLIND_ALREADY_UNBLIND_REQUESTED: MvnCodeBE;
  STUDY_SUBJECT_BLIND_ONLY_UNBLIND_REQUEST: MvnCodeBE;
  STUDY_SUBJECT_BLIND_ONLY_UNBLIND: MvnCodeBE;
  STUDY_SUBJECT_BLIND_MAIL_RESERVED_ALREADY: MvnCodeBE;
  STUDY_SUBJECT_BLIND_MAIL_SENT_ALREADY: MvnCodeBE;
} = {
  STUDY_SUBJECT_BLIND_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study Subject Action({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study subject action could({id}) not be found.",
  },
  STUDY_SUBJECT_BLIND_ID_MISSING: {
    status: 400,
    ko: "Study Subject Action id가 누락되었습니다.",
    en: "study subject action id is missing.",
  },
  STUDY_SUBJECT_BLIND_ALREADY_BLINDED: {
    status: 400,
    ko: "요청하신 Study Subject({subjectNo})는 이미 눈가림 되어있습니다.",
    en: "Requested study subject({subjectNo}) is already blinded.",
  },
  STUDY_SUBJECT_BLIND_ALREADY_UNBLINDED: {
    status: 400,
    ko: "요청하신 Study Subject({subjectNo})는 이미 눈가림 해제 되어있습니다.",
    en: "Requested study subject({subjectNo}) is already unblinded.",
  },
  STUDY_SUBJECT_BLIND_ALREADY_UNBLIND_REQUESTED: {
    status: 400,
    ko: "요청하신 Study Subject({subjectNo})는 이미 눈가림 해제 요청된 상태입니다.",
    en: "Requested study subject({subjectNo}) has already completed the unblind request."
  },
  STUDY_SUBJECT_BLIND_ONLY_UNBLIND_REQUEST: {
    status: 400,
    ko: "Unblind Request 메일은 Unblind Request Action에 한하여 전송할 수 있습니다.",
    en: "Unblind Request mail can only be transmitted by the Unblind Request Action."
  },
  STUDY_SUBJECT_BLIND_ONLY_UNBLIND: {
    status: 400,
    ko: "Unblind 메일은 Unblind Approve/Force Action에 한하여 전송할 수 있습니다.",
    en: "Unblind mail can only be transmitted by the Unblind Approve/Force Action."
  },
  STUDY_SUBJECT_BLIND_MAIL_RESERVED_ALREADY: {
    status: 409,
    ko: "이메일 발송이 예약되어있습니다.",
    en: "The email sending is already scheduled.",
  },
  STUDY_SUBJECT_BLIND_MAIL_SENT_ALREADY: {
    status: 400,
    ko: "메일이 이미 전송되었습니다.",
    en: "Mail has already been sent.",
  },
};

//

MvnCodeInitiate(STUDY_SUBJECT_BLIND_ACTION_CODE);
