import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_PAYLOAD_DOMAIN_LANG: {
  BUILDER_STUDY_PAYLOAD_DOMAIN_NO_ITEM_DESC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_SAVE_PROPERTY_DESC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_SAVE_PROPERTY_EXPORT_ITEM_SEQ_TYPED_DESC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_SAVE_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_SAVE_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_DOMAIN_TITLE_DESCRIPTION: MvnCodeFE;
} = {
  BUILDER_STUDY_PAYLOAD_DOMAIN_TITLE_DESCRIPTION: {
    ko: "Study domain을 관리할 수 있습니다.",
    en: "Manage your study domain.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_NO_ITEM_DESC: {
    ko: "There is no available domain",
    en: "There is no available domain",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_SAVE_PROPERTY_DESC: {
    ko: "Select domain to set property.",
    en: "Select domain to set property.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_SAVE_PROPERTY_EXPORT_ITEM_SEQ_TYPED_DESC: {
    ko: "Enter the sequence item id",
    en: "Enter the sequence item id",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_SAVE_SUCC: {
    ko: "Domain 정보를 저장했습니다.",
    en: "You have saved domains successfully.",
  },
  BUILDER_STUDY_PAYLOAD_DOMAIN_SAVE_FAIL: {
    ko: "Domain 정보 저장에 실패하였습니다. ({message})",
    en: "Failed to save domains. ({message})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_DOMAIN_LANG);
