import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CASE_REPORT_VALIDATE_CODE: {
  SAFETY_CASE_REPORT_VALIDATE_ERROR_ITEM_NOT_AVAILABLE: MvnCodeBE;
  SAFETY_CASE_REPORT_VALIDATE_ERROR_REQUIRED_VALUE_MISSING: MvnCodeBE;
  SAFETY_CASE_REPORT_VALIDATE_ERROR_INVALID_VALUE: MvnCodeBE;
} = {
  SAFETY_CASE_REPORT_VALIDATE_ERROR_ITEM_NOT_AVAILABLE: {
    status: 400,
    ko: "입력 불가 항목",
    en: "Unavailable item",
  },
  SAFETY_CASE_REPORT_VALIDATE_ERROR_REQUIRED_VALUE_MISSING: {
    status: 400,
    ko: "필수값 누락",
    en: "Missing required item value",
  },
  SAFETY_CASE_REPORT_VALIDATE_ERROR_INVALID_VALUE: {
    status: 400,
    ko: "유효하지 않은 값",
    en: "Invalid item value",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_VALIDATE_CODE);
