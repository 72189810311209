import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const MAILING_TEMPLATE_LANG: {
  MAILING_TEMPLATE_FOOTER_V1_EMAIL: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V1_PHONE: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V1_PHONE_NUMBER: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V1_QUESTIONS: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V2_COMPANY: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V2_BRN: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V2_CEO: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V2_CS: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V2_EMAIL: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V2_ADDRESS_HQ: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V2_ADDRESS_SEOUL: MvnCodeFE;
  MAILING_TEMPLATE_FOOTER_V2_NO_REPLY: MvnCodeFE;
} = {
  MAILING_TEMPLATE_FOOTER_V1_EMAIL: {
    ko: "이메일",
    en: "E-mail",
  },
  MAILING_TEMPLATE_FOOTER_V1_PHONE: {
    ko: "대표 번호",
    en: "Phone",
  },
  MAILING_TEMPLATE_FOOTER_V1_PHONE_NUMBER: {
    ko: "1668-0774",
    en: "+1(888) 471-0774 (North America) \n+82 1668-0774 (Korea & Other Country)",
  },
  MAILING_TEMPLATE_FOOTER_V1_QUESTIONS: {
    ko: "본 메일은 발신 전용입니다. 궁금하신 사항은 아래 연락처를 통해 문의 부탁드립니다.",
    en: "This email is for sending only. If you have any questions, please contact us using the information below.",
  },
  MAILING_TEMPLATE_FOOTER_V2_COMPANY: {
    ko: "(주)제이앤피메디",
    en: "JNPMEDI Inc.",
  },
  MAILING_TEMPLATE_FOOTER_V2_BRN: {
    ko: "사업자등록번호",
    en: "Registration No",
  },
  MAILING_TEMPLATE_FOOTER_V2_CEO: {
    ko: "대표자: 정권호",
    en: "CEO: Kwunho Jeong",
  },
  MAILING_TEMPLATE_FOOTER_V2_CS: {
    ko: "고객센터: 1668-0774 (10:00 ~ 19:00 / 토,일,공휴일 휴무)",
    en: "Help Desk: +82-1668-0774 (Mon-Fri 10:00 ~ 19:00 KST)",
  },
  MAILING_TEMPLATE_FOOTER_V2_EMAIL: {
    ko: "이메일",
    en: "Email",
  },
  MAILING_TEMPLATE_FOOTER_V2_ADDRESS_HQ: {
    ko: "송도 본사) 인천시 연수구 컨벤시아대로 165 포스코타워 31층",
    en: "Headquater) 31st Fl. Posco Tower, 165, Convensia-daero, Yeonsu-gu, Incheon, Korea",
  },
  MAILING_TEMPLATE_FOOTER_V2_ADDRESS_SEOUL: {
    ko: "서울 오피스) 서울시 중구 명동10길 52 신한익스페이스 2층",
    en: "Seoul Branch) 2nd Fl. Shinhan Expace, 52, Myeongdong 10-gil, Jung-gu, Seoul, Korea",
  },
  MAILING_TEMPLATE_FOOTER_V2_NO_REPLY: {
    ko: "※ 본 메일은 발신 전용으로 회신되지 않습니다. 궁금하신 사항은 고객센터에 문의해 주세요.",
    en: "※ Please do not reply to this message. If you have any questions, contact our Help Desk.",
  },
};

MvnCodeInitiate(MAILING_TEMPLATE_LANG);
