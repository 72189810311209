import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_DW_CODE: {
  HISTORY_REQUEST_INVALID: MvnCodeBE;
  HISTORY_REQUEST_INVALID_BY_ONLY_ITEM_LABEL: MvnCodeBE;
  HISTORY_REQUEST_DENIED_BY_PRIV: MvnCodeBE;
  HISTORY_NOT_EXISTS: MvnCodeBE;
  HISTORY_TYPE_INVALID: MvnCodeBE;
  HISTORY_DOWNLOAD_DENIED_BY_PRIV: MvnCodeBE;
  HISTORY_DOWNLOAD_URL_EXPIRED: MvnCodeBE;
  REQUEST_INVALID_FORMAT_NOT_SUPPORT: MvnCodeBE;
  REQUEST_INVALID_DOMAIN_EMPTY: MvnCodeBE;
  REQUEST_INVALID_DOMAIN_IPM_CANNOT_DOWNLOAD: MvnCodeBE;
  REQUEST_INVALID_ECOA_EXTRACT_MODE_NOT_SUPPORT: MvnCodeBE;
} = {
  HISTORY_REQUEST_INVALID: {
    status: 400,
    ko: "요청하신 Study Data Warehouse History 정보가 올바르지 않습니다.",
    en: "Requested Study Data Warehouse History is invalid.",
  },
  HISTORY_REQUEST_INVALID_BY_ONLY_ITEM_LABEL: {
    status: 400,
    ko: "[아이템코드 Label 정보 포함] 옵션과 [아이템 Label 정보만 포함] 옵션은 함께 선택될 수 없습니다.",
    en: "[Include item code label] option and [Only item label] option cannot be selected together.",
  },
  HISTORY_REQUEST_DENIED_BY_PRIV: {
    status: 403,
    ko: "요청하신 정보의 Download 권한이 없습니다.",
    en: "No access granted to download privilege(s).",
  },
  HISTORY_NOT_EXISTS: {
    status: 400,
    ko: "Study Data Warehouse History가 존재하지 않습니다.",
    en: "Study Data Warehouse History does not exist.",
  },
  HISTORY_TYPE_INVALID: {
    status: 400,
    ko: "Study Data Warehouse Type이 유효하지 않습니다.",
    en: "Study Data Warehouse Type is invalid.",
  },
  HISTORY_DOWNLOAD_DENIED_BY_PRIV: {
    status: 403,
    ko: "다운로드 권한이 없는 데이터가 포함되어 있습니다. 권한을 확인해 주세요.",
    en: "It contains data that you do not have permission to download. Please check your privilege.",
  },
  HISTORY_DOWNLOAD_URL_EXPIRED: {
    status: 409,
    ko: "Study Data Warehouse 다운로드 URL이 만료되었습니다.",
    en: "Study Data Warehouse download URL has expired.",
  },
  REQUEST_INVALID_FORMAT_NOT_SUPPORT: {
    status: 400,
    ko: "지원하지 않는 파일형식({format})입니다.",
    en: "The requested export format({format}) is not supported.",
  },
  REQUEST_INVALID_DOMAIN_EMPTY: {
    status: 400,
    ko: "Domain 정보가 누락되었습니다.",
    en: "Domain is missing.",
  },
  REQUEST_INVALID_DOMAIN_IPM_CANNOT_DOWNLOAD: {
    status: 400,
    ko: "IPM Domain 데이터는 다운로드받을 수 없습니다.",
    en: "IPM Domain data cannot be downloaded",
  },
  REQUEST_INVALID_ECOA_EXTRACT_MODE_NOT_SUPPORT: {
    status: 400,
    ko: "지원하지 않는 eCOA 추출모드({extractMode})입니다.",
    en: "The requested eCOA extraction mode({extractMode}) is not supported.",
  },
};

MvnCodeInitiate(STUDY_DW_CODE);
