/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_NOTICE_CODE: {
  STUDY_NOTICE_NOT_VALID: MvnCodeBE;
  STUDY_NOTICE_NOT_FOUND: MvnCodeBE;
  STUDY_NOTICE_ATTACHMENT_TYPE_NOT_VALID: MvnCodeBE;
} = {
  STUDY_NOTICE_NOT_VALID: {
    status: 400,
    ko: "요청하신 Study Notice 정보가 올바르지 않습니다.",
    en: "Requested Study Notice is invalid.",
  },
  STUDY_NOTICE_NOT_FOUND: {
    status: 404,
    ko: "Study Notice 정보가 존재하지 않습니다.",
    en: "Study Notice does not exist.",
  },
  STUDY_NOTICE_ATTACHMENT_TYPE_NOT_VALID: {
    status: 400,
    ko: "첨부파일이 지원되지 않는 파일형식입니다.",
    en: "The requested attachment file format is not supported.",
  },
};

MvnCodeInitiate(STUDY_NOTICE_CODE);
