/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_USER_CODE: {
  TMF_STUDY_USER_INVITE_NOT_EXIST: MvnCodeBE;
  TMF_STUDY_USER_INVITE_RESEND_WAIT_TIME: MvnCodeBE;

  TMF_STUDY_USER_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_USER_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_USER_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_USER_EMAIL_MISSING: MvnCodeBE;
  TMF_STUDY_USER_EMAIL_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_USER_EMAIL_TOO_LONG: MvnCodeBE;
  TMF_STUDY_USER_EMAIL_INVALID: MvnCodeBE;
  TMF_STUDY_USER_EMAIL_DUPLICATED: MvnCodeBE;

  TMF_STUDY_USER_ROLE_MISSING: MvnCodeBE;

  TMF_STUDY_USER_MANAGER_ROLE_ONE_REQUIRED: MvnCodeBE;

  TMF_STUDY_USER_NOT_EXIST: MvnCodeBE;
  TMF_STUDY_USER_INVALID: MvnCodeBE;
  TMF_STUDY_USER_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_USER_SITE_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_SITE_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_SITE_ID_DUPLICATED: MvnCodeBE;
  TMF_STUDY_ROLE_ID_NOT_FOUND: MvnCodeBE;
} = {

  TMF_STUDY_USER_INVITE_NOT_EXIST: {
    code: "TMF_STUDY_USER_INVITE_NOT_EXIST",
    status: 404,
    ko: "Study({studyId}) User({email}) Invitation가 존재하지 않습니다.",
    en: "Study({studyId}) User({email}) Invitation dose not exists.",
  },
  TMF_STUDY_USER_INVITE_RESEND_WAIT_TIME: {
    code: "TMF_STUDY_USER_INVITE_RESEND_WAIT_TIME",
    status: 404,
    ko: "초대 재전송은 5분 주기로 가능합니다.",
    en: "The invitation can be resent every 5 minutes.",
  },

  TMF_STUDY_USER_NAME_MISSING: {
    code: "TMF_STUDY_USER_NAME_MISSING",
    status: 400,
    ko: "Study User 이름이 입력되지 않았습니다.",
    en: "Study User Name is missing."
  },
  TMF_STUDY_USER_NAME_TOO_SHORT: {
    code: "TMF_STUDY_USER_NAME_TOO_SHORT",
    status: 400,
    ko: "Study User 이름의 길이는 최소 {length}자 이상입니다.",
    en: "Study User name must be at least {length} characters long.",
  },
  TMF_STUDY_USER_NAME_TOO_LONG: {
    code: "TMF_STUDY_USER_NAME_TOO_LONG",
    status: 400,
    ko: "Study User 이름의 길이는 최대 {length}자 입니다.",
    en: "Study User name can be up to {length} characters long.",
  },
  TMF_STUDY_USER_EMAIL_MISSING: {
    code: "TMF_STUDY_USER_EMAIL_MISSING",
    status: 400,
    ko: "Study User Email이 입력되지 않았습니다.",
    en: "Study User Email is missing."
  },
  TMF_STUDY_USER_EMAIL_TOO_SHORT: {
    code: "TMF_STUDY_USER_EMAIL_TOO_SHORT",
    status: 400,
    ko: "Study User Email이 길이는 최소 {length}자 이상입니다.",
    en: "Study User Email must be at least {length} characters long.",
  },
  TMF_STUDY_USER_EMAIL_TOO_LONG: {
    code: "TMF_STUDY_USER_EMAIL_TOO_LONG",
    status: 400,
    ko: "Study User Email이 길이는 최대 {length}자 입니다.",
    en: "Study User Email can be up to {length} characters long.",
  },
  TMF_STUDY_USER_EMAIL_INVALID: {
    code: "TMF_STUDY_USER_EMAIL_INVALID",
    status: 400,
    ko: "Email({email})이 잘못되었습니다.",
    en: "Email({email}) is invalid.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_USER_EMAIL_DUPLICATED: {
    code: "TMF_STUDY_USER_EMAIL_DUPLICATED",
    status: 409,
    ko: "중복된 Email({email})입니다.",
    en: "Email({email}) is duplicated.", // TODO: 번역 확인 필요
  },

  TMF_STUDY_USER_ROLE_MISSING: {
    code: "TMF_STUDY_USER_ROLE_MISSING",
    status: 400,
    ko: "Study User Role이 입력되지 않았습니다.",
    en: "Study User Role is missing."
  },

  TMF_STUDY_USER_MANAGER_ROLE_ONE_REQUIRED: {
    code: "TMF_STUDY_USER_MANAGER_ROLE_ONE_REQUIRED",
    status: 400,
    ko: "TMF 매니저는 최소 1명이상 존재해야 합니다.",
    en: "There should be at least one TMF manager.", // TODO: 번역 확인 필요
  },

  TMF_STUDY_USER_NOT_EXIST: {
    code: "TMF_STUDY_USER_NOT_EXIST",
    status: 400,
    ko: "Study User({id})가 존재하지 않습니다.",
    en: "Study User({id}) dose not exists.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_USER_INVALID: {
    code: "TMF_STUDY_USER_INVALID",
    status: 400,
    ko: "Study User({id})가 유효하지 않습니다..",
    en: "Study User({id}) is invalid.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_USER_ID_NOT_FOUND: {
    code: "TMF_STUDY_USER_ID_NOT_FOUND",
    status: 400,
    ko: "studyUserId({id})가 존재하지 않습니다.",
    en: "studyUserId({id}) does not exists.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_USER_SITE_ID_NOT_FOUND: {
    code: "TMF_STUDY_USER_SITE_ID_NOT_FOUND",
    status: 400,
    ko: "studyUserSiteId({id})가 존재하지 않습니다.",
    en: "studyUserSiteId({id}) does not exists.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_SITE_ID_NOT_FOUND: {
    code: "TMF_STUDY_SITE_ID_NOT_FOUND",
    status: 400,
    ko: "studySiteId({id})가 존재하지 않습니다.",
    en: "studySiteId({id}) does not exists.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_SITE_ID_DUPLICATED: {
    code: "TMF_STUDY_SITE_ID_DUPLICATED",
    status: 409,
    ko: "중복된 studySiteId({studySiteId})입니다.",
    en: "studySiteId({studySiteId}) is duplicated.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_ROLE_ID_NOT_FOUND: {
    code: "TMF_STUDY_ROLE_ID_NOT_FOUND",
    status: 400,
    ko: "studyRoleId({id})가 존재하지 않습니다.",
    en: "studyRoleId({id}) does not exists.", // TODO: 번역 확인 필요
  },
};

MvnCodeInitiate(TMF_STUDY_USER_CODE);
