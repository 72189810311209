import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_PAYLOAD_LAB_TEST_LANG: {
  BUILDER_STUDY_PAYLOAD_LAB_TEST_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
} = {
  BUILDER_STUDY_PAYLOAD_LAB_TEST_PAGE_TITLE_DESCRIPTION: {
    ko: "CRF Draft 내에 아이템에 연결된 Lab Tests을 확인할 수 있습니다.",
    en: "Check your lab tests mapped to items within this CRF draft.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_LAB_TEST_LANG);
