import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_PURCHASE_LANG: {
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_SUCC: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_FAIL: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_HEADER: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_DESC: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_DESC_CURRENT_REMAIN_POINT_LABEL: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_DESC_CURRENT_RESET_DAY_LABEL: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_NOT_PERMITTED_DESC: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_NOT_PERMITTED_DESC_OWNER_LABEL: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_CONFIRM_DIALOG_HEADER: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_CONFIRM_DIALOG_DESC_PURCHASE_POINTS_LABEL: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_CONFIRM_DIALOG_DESC_POINTS_AFTER_PURCHASE_LABEL: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_CONFIRM_DIALOG_DESC_ESTIMATED_PRICE_LABEL: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_PURCHASE_BTN: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_QUANTITY_INPUT_LABEL: MvnCodeFE;
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_INVALID_MISSING_POINTS: MvnCodeFE;
} = {
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_SUCC: {
    ko: "포인트가 성공적으로 구매되었습니다.",
    en: "Points have been purchased successfully.",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_FAIL: {
    ko: "포인트 구매에 실패하였습니다. ({message})",
    en: "Failed to purchase points. ({message})",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_HEADER: {
    ko: "Purchase Points",
    en: "Purchase Points",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_DESC: {
    ko: "특정 형식으로 변환하여 export를 진행하면 포인트가 소진됩니다.\n 소진하지 못한 모든 포인트는 매달 구독이 시작되는 시점에 초기화되므로 이전에 모두 소진해야합니다.",
    en: "Points will be used when exporting cases in certain formats.\n Remaining points will be reset when your subscription plan starts every month.",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_DESC_CURRENT_REMAIN_POINT_LABEL: {
    ko: "현재 남은 포인트: ",
    en: "Currently remaining: ",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_DESC_CURRENT_RESET_DAY_LABEL: {
    ko: "유효 기간: ",
    en: "Reset day: ",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_NOT_PERMITTED_DESC: {
    ko: "포인트를 구매하려면 Organization owner에게 요청하여 진행하여주시기 바랍니다.",
    en: "Contact owner of your organization to purchase additional points.",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_DIALOG_NOT_PERMITTED_DESC_OWNER_LABEL: {
    ko: "Organization owner: ",
    en: "Organization owner: ",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_CONFIRM_DIALOG_HEADER: {
    ko: "Are you sure you want to purchase?",
    en: "Are you sure you want to purchase?",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_CONFIRM_DIALOG_DESC_PURCHASE_POINTS_LABEL: {
    ko: "구매하는 포인트: ",
    en: "Number of points to purchase: ",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_CONFIRM_DIALOG_DESC_POINTS_AFTER_PURCHASE_LABEL: {
    ko: "구매 후 보유 포인트: ",
    en: "Total points after purchase: ",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_CONFIRM_DIALOG_DESC_ESTIMATED_PRICE_LABEL: {
    ko: "결제 예정 금액: ",
    en: "Estimated price: ",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_PURCHASE_BTN: {
    ko: "Purchase Points",
    en: "Purchase Points",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_QUANTITY_INPUT_LABEL: {
    ko: "Number of Points to Purchase",
    en: "Number of Points to Purchase",
  },
  AUTH_PURCHASE_SAFETY_EXPORT_POINTS_INVALID_MISSING_POINTS: {
    ko: "구매하고자 하는 포인트의 개수를 입력하세요.",
    en: "Number of points to purchase is missing.",
  },
};

MvnCodeInitiate(AUTH_PURCHASE_LANG);
