import {
  CreateEmailVerificationCodeRequest,
  CreateUserVerificationCodeForDeviceRegistrationRequest,
  CreateUserVerificationCodeForMFARequest,
  CreateUserVerificationCodeForPasswordInitRequest,
  CreateUserVerificationCodeForPasswordInitResponse,
  CreateUserVerificationCodeForPasswordResetRequest,
  CreateUserVerificationCodeForSignUpRequest,
  CreateUserVerificationCodeResponse,
  VerifyUserVerificationCodeForPasswordInitRequest,
  VerifyUserVerificationCodeForPasswordInitResponse,
  VerifyUserVerificationCodeRequest,
  VerifyUserVerificationCodeResponse,
} from "./UserVerificationCode.dto";
import { UserVerificationCodeUrl } from "./UserVerificationCode.url";
import { restReq } from "../common/cyan.types";
import { FxApiRequestData, FxRequestData } from "../common/flax.types";

export class UserVerificationCodeFlax {
  public static createUserVerificationCodeForSignUp(
    data: FxRequestData<CreateUserVerificationCodeForSignUpRequest>
  ): FxApiRequestData<CreateUserVerificationCodeResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserVerificationCodeUrl.createUserVerificationCodeForSignUp, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static createUserVerificationCodeForPasswordReset(
    data: FxRequestData<CreateUserVerificationCodeForPasswordResetRequest>
  ): FxApiRequestData<CreateUserVerificationCodeResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserVerificationCodeUrl.createUserVerificationCodeForPasswordReset, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static createUserVerificationCodeForPasswordInit(
    data: FxRequestData<CreateUserVerificationCodeForPasswordInitRequest>
  ): FxApiRequestData<CreateUserVerificationCodeForPasswordInitResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserVerificationCodeUrl.createUserVerificationCodeForPasswordInit, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  /** @todo verifyUserVerificationCode 의존하도록 개선 필요 (2024.06.22)  */
  public static verifyUserVerificationCodeForPasswordInit(
    data: FxRequestData<VerifyUserVerificationCodeForPasswordInitRequest>
  ): FxApiRequestData<VerifyUserVerificationCodeForPasswordInitResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserVerificationCodeUrl.verifyUserVerificationCodeForPasswordInit, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static createUserVerificationCodeForDeviceRegistration(
    data: FxRequestData<CreateUserVerificationCodeForDeviceRegistrationRequest>
  ): FxApiRequestData<CreateUserVerificationCodeResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserVerificationCodeUrl.createUserVerificationCodeForDeviceRegistration, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static createUserVerificationCodeForMFA(
    data: FxRequestData<CreateUserVerificationCodeForMFARequest>
  ): FxApiRequestData<CreateUserVerificationCodeResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserVerificationCodeUrl.createUserVerificationCodeForMFA, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static createEmailVerificationCode(
    data: FxRequestData<CreateEmailVerificationCodeRequest>
  ): FxApiRequestData<CreateUserVerificationCodeResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserVerificationCodeUrl.createEmailVerificationCode, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static verifyUserVerificationCode(
    data: FxRequestData<VerifyUserVerificationCodeRequest>
  ): FxApiRequestData<VerifyUserVerificationCodeResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserVerificationCodeUrl.verifyVerificationCode, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
