import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_IP_RETURN: {
  STUDY_IP_RETURN_NOT_FOUND: MvnCodeBE;
  STUDY_IP_RETURN_NOT_AVAILABLE: MvnCodeBE;
  STUDY_IP_RETURN_UNEXPECTED: MvnCodeBE;
  STUDY_IP_RETURN_DENIED_BY_PRIV: MvnCodeBE;
  STUDY_IP_RETURN_HISTORY_NOT_FOUND: MvnCodeBE;
} = {
  STUDY_IP_RETURN_NOT_FOUND: {
    status: 404,
    ko: "Study IP 반납 정보가 존재하지 않습니다.",
    en: "Study IP return entity does not exist.",
  },
  STUDY_IP_RETURN_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study IP 반납 정보가 올바르지 않습니다.",
    en: "Requested study IP return is not valid.",
  },
  STUDY_IP_RETURN_UNEXPECTED: {
    status: 500,
    ko: "요청하신 Study IP 반납 정보가 올바르지 않습니다.",
    en: "Requested study IP return is not valid.",
  },
  STUDY_IP_RETURN_DENIED_BY_PRIV: {
    status: 400,
    ko: "권한이 없는 IP 반납입니다.",
    en: "IP returned due to no access right.",
  },
  STUDY_IP_RETURN_HISTORY_NOT_FOUND: {
    status: 400,
    ko: "반납내역이 존재하지 않습니다.",
    en: "Return history is not found.",
  },
};

MvnCodeInitiate(STUDY_IP_RETURN);
