import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_PAYLOAD_PAGE_LANG: {
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_DIALOG_HEADER: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_DIALOG_IDENTIFIER_LABEL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_SAVE_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_SAVE_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_EDIT_DIALOG_HEADER: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_RESTORE_DIALOG_HEADER: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_EDIT_DIALOG_IDENTIFIER_LABEL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_EDIT_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_EDIT_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_INVALID_VISIT_CODE: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_DELETE_DIALOG_CONFIRM_MESSAGE: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PAGE_DELETE_DIALOG_CONFIRM_DESC_MESSAGE: MvnCodeFE;
} = {
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_DIALOG_HEADER: {
    ko: "Add Page",
    en: "Add Page",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_DIALOG_IDENTIFIER_LABEL: {
    ko: "Identifier",
    en: "Identifier",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_SUCC: {
    ko: "Page를 생성하였습니다.",
    en: "You have created a new page successfully.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_ADD_FAIL: {
    ko: "page 생성에 실패하였습니다. ({message})",
    en: "Failed to create a new page. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SAVE_SUCC: {
    ko: "Page를 저장하였습니다.",
    en: "You have saved page successfully.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_SAVE_FAIL: {
    ko: "page 저장에 실패하였습니다. ({message})",
    en: "Failed to save page. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_EDIT_DIALOG_HEADER: {
    ko: "Edit Page",
    en: "Edit Page",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_RESTORE_DIALOG_HEADER: {
    ko: "Restore Page",
    en: "Restore Page",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_EDIT_DIALOG_IDENTIFIER_LABEL: {
    ko: "Identifier",
    en: "Identifier",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_EDIT_SUCC: {
    ko: "Page 정보를 수정하였습니다.",
    en: "You have modified a page info successfully.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_EDIT_FAIL: {
    ko: "page 정보 수정에 실패하였습니다. ({message})",
    en: "Failed to modify a page info. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_INVALID_VISIT_CODE: {
    ko: "Visit Code가 유효하지 않습니다.",
    en: "Visit code is invalid.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_TITLE_DESCRIPTION: {
    ko: "Study page를 생성할 수 있습니다.",
    en: "Build your study page.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_DELETE_DIALOG_CONFIRM_MESSAGE: {
    ko: "이 페이지에서 사용 중인 항목은 유효하지 않게 됩니다.",
    en: "Items being used from this page will become invalid.",
  },
  BUILDER_STUDY_PAYLOAD_PAGE_DELETE_DIALOG_CONFIRM_DESC_MESSAGE: {
    ko: "이 페이지 ({pageIdentifier})를 삭제하시겠습니까?",
    en: "Are you sure you want to delete this page ({pageIdentifier})?",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_PAGE_LANG);
