import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CAPTURE_CODE: {
  NO_CHANGES: MvnCodeBE;
  TRIGGER_ECOA_ACCESS_CODE_ASSIGNED_ALREADY: MvnCodeBE;
  INVALID_NOT_NULLABLE_ITEM_VALUE: MvnCodeBE;
  CAPTURE_FILE_FORMAT_NOT_ALLOWED: MvnCodeBE;
  CAPTURE_FILE_UPLOAD_FAILED: MvnCodeBE;
  CAPTURE_CRF_NOT_AVAILABLE: MvnCodeBE;
} = {
  NO_CHANGES: {
    status: 400,
    ko: "변경사항이 없습니다.",
    en: "There are no changes.",
  },
  TRIGGER_ECOA_ACCESS_CODE_ASSIGNED_ALREADY: {
    status: 409,
    ko: "eCOA Access Code가 이미 할당되어 있습니다.",
    en: "eCOA access code is assigned already.",
  },
  INVALID_NOT_NULLABLE_ITEM_VALUE: {
    status: 400,
    ko: "{itemLabel} 항목이 미입력 되었습니다.",
    en: "The {itemLabel} item input is missing.",
  },
  CAPTURE_FILE_FORMAT_NOT_ALLOWED: {
    status: 400,
    ko: "선택한 파일은 업로드를 지원하지 않는 형식입니다.",
    en: "The file format is not supported.",
  },
  CAPTURE_FILE_UPLOAD_FAILED: {
    status: 400,
    ko: "파일업로드에 실패하였습니다.",
    en: "File failed to upload.",
  },
  CAPTURE_CRF_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Page에 Data를 Capture 할 수 없습니다.",
    en: "The requested data cannot be captured to the specified CRF Page",
  },
};

MvnCodeInitiate(STUDY_CAPTURE_CODE);
