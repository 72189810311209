/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const QUERY_LANG: {
  STUDY_QUERY_BREADCRUMB: MvnCodeFE;
  STUDY_QUERY_RESPONSE_DIALOG_HEADER: MvnCodeFE;
  STUDY_QUERY_RESPONSE_INPUT_PLACEHOLDER: MvnCodeFE;
  STUDY_QUERY_MESSAGE_INPUT_PLACEHOLDER: MvnCodeFE;
  STUDY_QUERY_ANSWER_SUCC: MvnCodeFE;
  STUDY_QUERY_ANSWER_FAIL: MvnCodeFE;
  STUDY_QUERY_BULK_ANSWER_FAIL: MvnCodeFE;
  STUDY_QUERY_BULK_CLOSE_FAIL: MvnCodeFE;
  STUDY_QUERY_RECLAIM_DIALOG_HEADER: MvnCodeFE;
  STUDY_QUERY_CLOSE_DIALOG_HEADER: MvnCodeFE;
  STUDY_CLOSE_QUERY_SUCC: MvnCodeFE;
  STUDY_CLOSE_QUERY_FAIL: MvnCodeFE;
  DOWNLOAD_TEMPLATE_BTN: MvnCodeFE;
  MANUAL_QUERY_ADDED: MvnCodeFE;
  MANUAL_QUERY_ADD_FAILED: MvnCodeFE;
  QUERY_UPLOAD_DIALOG_LABEL_TASK_NAME: MvnCodeFE;
  QUERY_UPLOAD_DIALOG_LABEL_DESCRIPTION: MvnCodeFE;
  QUERY_UPLOAD_DAILOG_LABEL_FILE: MvnCodeFE;
  QUERY_UPLOAD_LIST_APPLY_FAIL: MvnCodeFE;
  QUERY_UPLOAD_LIST_APPLY_SUCC: MvnCodeFE;
  QUERY_UPLOAD_APPLY_BTN: MvnCodeFE;
  QUERY_UPLOAD_BTN: MvnCodeFE;
  QUERY_UPLOAD_DIALOG_HEADER: MvnCodeFE;
  STUDY_QUERY_DOWNLOAD_FAIL: MvnCodeFE;
  STUDY_QUERY_MIGRATION_SUCC: MvnCodeFE;
  STUDY_QUERY_MIGRATION_FAIL: MvnCodeFE;
  STUDY_QUERY_CLAIM_SUCC: MvnCodeFE;
  STUDY_QUERY_CLAIM_FAIL: MvnCodeFE;
  STUDY_QUERY_SOLVE_DIALOG_HEADER: MvnCodeFE;
  STUDY_QUERY_SOLVE_SUCC: MvnCodeFE;
  STUDY_QUERY_SOLVE_FAIL: MvnCodeFE;
  STUDY_QUERY_OPEN_QUERY_DIALOG_LABEL: MvnCodeFE;
  STUDY_QUERY_OPEN_QUERY_TOOLTIP: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_DOMAIN_MISSING: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_CRF_PAGE_NOT_EXIST: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_CRF_PAGE_DISABLED: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_CRF_PAGE_LOCKED: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_CRF_PAGE_SEQ_MISSING: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_SUBJECT_NO_MISSING: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_VISIT_CODE_MISSING: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_ITEM_SEQ_MISSING: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_ITEM_SEQ_INVALID: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_ITEM_ID_MISSING: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_STATUS_MISSING: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_STATUS_INVALID: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_MSG_MISSING: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_SUBJECT_NOT_EXIST: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_SUBJECT_SITE_ACTANT_NOT_AUTHORIZED: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_VISIT_NOT_EXIST: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_ITEM_NOT_EXIST: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_ITEM_NOT_QUERABLE: MvnCodeFE;
  STUDY_QUERY_VALIDATE_ERROR_NON_ACTIVATE_VISIT: MvnCodeFE;
  VALIDATE_RESULT_BTN: MvnCodeFE;
  QUETRY_TAB_LABEL: MvnCodeFE;
} = {
  STUDY_QUERY_BREADCRUMB: {
    ko: "발행된 쿼리를 확인할 수 있습니다.",
    en: "Manage and inquire queries.",
  },
  STUDY_QUERY_RESPONSE_DIALOG_HEADER: {
    ko: "쿼리 응답",
    en: "Query Response",
  },
  STUDY_QUERY_RESPONSE_INPUT_PLACEHOLDER: {
    ko: "쿼리 응답 내용을 입력해주세요.",
    en: "Please enter the content of the query message.",
  },
  STUDY_QUERY_MESSAGE_INPUT_PLACEHOLDER: {
    ko: "쿼리 내용을 입력해주세요.",
    en: "Please enter your query message.",
  },
  STUDY_QUERY_ANSWER_SUCC: {
    ko: "쿼리에 응답하였습니다.",
    en: "The query has been answered.",
  },
  STUDY_QUERY_ANSWER_FAIL: {
    ko: "쿼리에 응답에 실패하였습니다. ({error})",
    en: "Query response has been failed. ({error})",
  },
  STUDY_QUERY_BULK_ANSWER_FAIL: {
    ko: "일괄 응답에 실패하였습니다. ({error})",
    en: "Batch query response has been failed. ({error})",
  },
  STUDY_QUERY_BULK_CLOSE_FAIL: {
    ko: "일괄 종료에 실패하였습니다. ({error})",
    en: "Batch Query close has been failed. ({error})",
  },
  STUDY_QUERY_RECLAIM_DIALOG_HEADER: {
    ko: "쿼리 재발행",
    en: "Reclaim Query",
  },
  STUDY_QUERY_CLOSE_DIALOG_HEADER: {
    ko: "쿼리 종료",
    en: "Close Query",
  },
  STUDY_CLOSE_QUERY_SUCC: {
    ko: "쿼리를 성공적으로 종료하였습니다.",
    en: "The query has been closed successfully.",
  },
  STUDY_CLOSE_QUERY_FAIL: {
    ko: "쿼리 종료에 실패하였습니다. ({error})",
    en: "Query close has been failed. ({error})",
  },
  DOWNLOAD_TEMPLATE_BTN: {
    ko: "템플릿 다운로드",
    en: "Download Template",
  },
  MANUAL_QUERY_ADDED: {
    ko: "쿼리를 등록하였습니다.",
    en: "A new query has been registered.",
  },
  MANUAL_QUERY_ADD_FAILED: {
    ko: "쿼리 등록에 실패하였습니다. ({message})",
    en: "Query registration has been failed. ({message})",
  },
  STUDY_QUERY_DOWNLOAD_FAIL: {
    ko: "쿼리 다운로드에 실패했습니다. ({error})",
    en: "Query download has been failed. ({error})",
  },
  STUDY_QUERY_MIGRATION_SUCC: {
    ko: "요청이 성공했습니다.",
    en: "The request was successful.",
  },
  STUDY_QUERY_MIGRATION_FAIL: {
    ko: "요청이 실패했습니다. ({error})",
    en: "The request has been failed. ({error})",
  },
  STUDY_QUERY_SOLVE_DIALOG_HEADER: {
    ko: "쿼리 해결",
    en: "Solve Query",
  },
  STUDY_QUERY_SOLVE_SUCC: {
    ko: "쿼리를 해결하여 정상종료하였습니다.",
    en: "The query has been solved.",
  },
  STUDY_QUERY_SOLVE_FAIL: {
    ko: "쿼리 정상종료에 실패했습니다. ({error})",
    en: "Query resolve has been failed. ({error}).",
  },
  STUDY_QUERY_CLAIM_SUCC: {
    ko: "쿼리를 재발행 하였습니다.",
    en: "The query has been reclaimed successfully.",
  },
  STUDY_QUERY_CLAIM_FAIL: {
    ko: "쿼리 재발행에 실패하였습니다. ({error})",
    en: "Query reclaim has been failed. ({error})",
  },
  STUDY_QUERY_OPEN_QUERY_DIALOG_LABEL: {
    ko: "쿼리 발행",
    en: "Open Query",
  },
  STUDY_QUERY_OPEN_QUERY_TOOLTIP: {
    ko: "쿼리 발행",
    en: "Open Query",
  },
  STUDY_QUERY_VALIDATE_ERROR_DOMAIN_MISSING: {
    ko: "DomainId 입력 누락",
    en: "Missing domainId",
  },
  STUDY_QUERY_VALIDATE_ERROR_CRF_PAGE_NOT_EXIST: {
    ko: "존재하지 않는 CRF Page ({crfPageId})",
    en: "Non-existent CRF Page ({crfPageId})",
  },
  STUDY_QUERY_VALIDATE_ERROR_CRF_PAGE_DISABLED: {
    ko: "활성화되지 않은 CRF Page ({crfPageId})",
    en: "Non-activated CRF Page ({crfPageId})",
  },
  STUDY_QUERY_VALIDATE_ERROR_CRF_PAGE_LOCKED: {
    ko: "Lock 처리된 CRF Page ({crfPageId})",
    en: "Locked CRF Page ({crfPageId})",
  },
  STUDY_QUERY_VALIDATE_ERROR_CRF_PAGE_SEQ_MISSING: {
    ko: "CRF Page Seq 입력 누락",
    en: "검수 필요",
  },
  STUDY_QUERY_VALIDATE_ERROR_SUBJECT_NO_MISSING: {
    ko: "Subject 입력 누락",
    en: "Missing SubjectNo",
  },
  STUDY_QUERY_VALIDATE_ERROR_VISIT_CODE_MISSING: {
    ko: "Visit Code 입력 누락",
    en: "Missing VisitCode",
  },
  STUDY_QUERY_VALIDATE_ERROR_ITEM_SEQ_MISSING: {
    ko: "ItemSeq 입력 누락",
    en: "Missing ItemSeq",
  },
  STUDY_QUERY_VALIDATE_ERROR_ITEM_SEQ_INVALID: {
    ko: "비정상적인 ItemSeq ({itemSeq})",
    en: "Invalid ItemSeq ({itemSeq})",
  },
  STUDY_QUERY_VALIDATE_ERROR_ITEM_ID_MISSING: {
    ko: "Item Id 입력 누락",
    en: "Missing ItemId",
  },
  STUDY_QUERY_VALIDATE_ERROR_STATUS_MISSING: {
    ko: "Status 입력 누락",
    en: "Missing Status",
  },
  STUDY_QUERY_VALIDATE_ERROR_STATUS_INVALID: {
    ko: "비정상적인 Status ({status})",
    en: "Invalid Status ({status})",
  },
  STUDY_QUERY_VALIDATE_ERROR_MSG_MISSING: {
    ko: "Message 입력 누락",
    en: "Missing Message",
  },
  STUDY_QUERY_VALIDATE_ERROR_SUBJECT_NOT_EXIST: {
    ko: "존재하지 않는 Subject ({subjectNo})",
    en: "Non-existent Subject ({subjectNo})",
  },
  STUDY_QUERY_VALIDATE_ERROR_SUBJECT_SITE_ACTANT_NOT_AUTHORIZED: {
    ko: "해당 Subject({subjectNo}) 실시기관에 대한 권한 없음",
    en: "Unauthorized Access to Subject({subjectNo}) site",
  },
  STUDY_QUERY_VALIDATE_ERROR_VISIT_NOT_EXIST: {
    ko: "존재하지 않는 Visit Code ({visitCode})",
    en: "Non-existent Visit Code ({visitCode})",
  },
  STUDY_QUERY_VALIDATE_ERROR_ITEM_NOT_EXIST: {
    ko: "존재하지 않는 Item ({itemId})",
    en: "Non-existent Item ({itemId})",
  },
  STUDY_QUERY_VALIDATE_ERROR_ITEM_NOT_QUERABLE: {
    ko: "쿼리 불가능한 Item ({itemId})",
    en: "Non-queryable Item ({itemId})",
  },
  STUDY_QUERY_VALIDATE_ERROR_NON_ACTIVATE_VISIT: {
    ko: "활성화되지 않은 Visit에 관련된 Item ({visitCode})",
    en: "Non-activated Visit Item  ({visitCode})",
  },
  QUERY_UPLOAD_DIALOG_HEADER: {
    ko: "쿼리 업로드",
    en: "Upload Query",
  },
  QUERY_UPLOAD_DIALOG_LABEL_TASK_NAME: {
    ko: "작업명",
    en: "Task Name",
  },
  QUERY_UPLOAD_DIALOG_LABEL_DESCRIPTION: {
    ko: "설명",
    en: "Description",
  },
  QUERY_UPLOAD_DAILOG_LABEL_FILE: {
    ko: "파일",
    en: "File",
  },
  QUERY_UPLOAD_LIST_APPLY_FAIL: {
    ko: "쿼리 발행에 실패하였습니다. ({message})",
    en: "Query open has been failed. ({message})",
  },
  QUERY_UPLOAD_LIST_APPLY_SUCC: {
    ko: "쿼리 발행 요청에 성공하였습니다.",
    en: "Query open has been requested successfully.",
  },
  QUERY_UPLOAD_APPLY_BTN: {
    ko: "Apply All",
    en: "Apply All",
  },
  VALIDATE_RESULT_BTN: {
    ko: "Validate Result",
    en: "Validate Result",
  },
  QUERY_UPLOAD_BTN: {
    ko: "쿼리 업로드",
    en: "Upload Query",
  },
  QUETRY_TAB_LABEL: {
    ko: "쿼리",
    en: "Query",
  },
};

MvnCodeInitiate(QUERY_LANG);
