import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const DOCS_DOCUMENT_TRAINING_RECORD_LANG: {
  DOWNLOAD_DOCUMENT_TRAINING_SUCC: MvnCodeFE;
  DOWNLOAD_DOCUMENT_TRAINING_FAIL: MvnCodeFE;
} = {
  DOWNLOAD_DOCUMENT_TRAINING_SUCC: {
    ko: "Training Record 다운로드가 시작되었습니다.",
    en: "Training Record download has been started successfully.",
  },
  DOWNLOAD_DOCUMENT_TRAINING_FAIL: {
    ko: "Training Record 다운로드에 실패하였습니다. ({message})",
    en: "Failed to download the training records. ({message})",
  },
};

MvnCodeInitiate(DOCS_DOCUMENT_TRAINING_RECORD_LANG);
