import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_IP_DELIVERY: {
  STUDY_IP_DELIVERY_NOT_FOUND: MvnCodeBE;
  STUDY_IP_DELIVERY_NOT_AVAILABLE: MvnCodeBE;
  STUDY_IP_DELIVERY_ALREADY_DELIVERY_STARTED: MvnCodeBE;
  STUDY_IP_DELIVERY_ALREADY_DELIVERY_CONFIRMED: MvnCodeBE;
  STUDY_IP_DELIVERY_LOT_NO_MISSING: MvnCodeBE;
  STUDY_IP_DELIVERY_EXPIRED_AT_MISSING: MvnCodeBE;
  STUDY_IP_DELIVERY_EXPIRED_AT_INVALID: MvnCodeBE;
  STUDY_IP_DELIVERY_UNEXPECTED: MvnCodeBE;
  STUDY_IP_DELIVERY_IP_INFO_NOT_FOUND: MvnCodeBE;
  STUDY_IP_DELIVERY_IP_CATEGORY_NOT_FOUND: MvnCodeBE;
  STUDY_IP_DELIVERY_IP_CATEGORY_AMOUNT_LESS_TAHN_ZERO: MvnCodeBE;
  STUDY_IP_DELIVERY_IP_CATEGORY_WEIGHT_INVALID: MvnCodeBE;
  STUDY_IP_DELIVERY_IP_CATEGORY_CODE_NOT_ENOUGH: MvnCodeBE;
} = {
  STUDY_IP_DELIVERY_NOT_FOUND: {
    status: 404,
    ko: "Study IP 배송 정보가 존재하지 않습니다.",
    en: "Study IP delivery entity does not exist.",
  },
  STUDY_IP_DELIVERY_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study IP 배송 정보가 올바르지 않습니다.",
    en: "Requested study IP delivery is not valid.",
  },
  STUDY_IP_DELIVERY_ALREADY_DELIVERY_STARTED: {
    status: 404,
    ko: "Study IP 배송이 이미 시작되었습니다.",
    en: "Study IP delivery has already started.",
  },
  STUDY_IP_DELIVERY_ALREADY_DELIVERY_CONFIRMED: {
    status: 404,
    ko: "Study IP 배송이 이미 확인되었습니다.",
    en: "Study IP delivery has already confirmed.",
  },
  STUDY_IP_DELIVERY_LOT_NO_MISSING: {
    status: 400,
    ko: "Study IP Lot No. 정보가 누락되었습니다.",
    en: "Study IP lot no is missing.",
  },
  STUDY_IP_DELIVERY_EXPIRED_AT_MISSING: {
    status: 400,
    ko: "Study IP Expired Date 정보가 누락되었습니다.",
    en: "Study IP expired date is missing.",
  },
  STUDY_IP_DELIVERY_EXPIRED_AT_INVALID: {
    status: 400,
    ko: "Study IP Expired Date 정보가 잘못되었습니다.",
    en: "Study IP expired date is invalid.",
  },
  STUDY_IP_DELIVERY_UNEXPECTED: {
    status: 500,
    ko: "요청하신 Study IP 배송 정보가 올바르지 않습니다.",
    en: "Requested study IP delivery is not valid.",
  },
  STUDY_IP_DELIVERY_IP_INFO_NOT_FOUND: {
    status: 400,
    ko: "Study IP 배송 요청에 IP 정보가 존재하지 않습니다.",
    en: "Study IP request information does not exist.",
  },
  STUDY_IP_DELIVERY_IP_CATEGORY_NOT_FOUND: {
    status: 400,
    ko: "Study IP 배송 요청에 IP Category 정보가 존재하지 않습니다.",
    en: "Study IP Category request information does not exist.",
  },
  STUDY_IP_DELIVERY_IP_CATEGORY_AMOUNT_LESS_TAHN_ZERO: {
    status: 400,
    ko: "Study IP 배송 요청 amount 가 0 미만입니다.",
    en: "Study IP request amount is less than 0.",
  },
  STUDY_IP_DELIVERY_IP_CATEGORY_WEIGHT_INVALID: {
    status: 400,
    ko: "Study IP 배송 요청 amount 가 {weight} 배수여야합니다.",
    en: "Study IP request amount should be a multiple of {weight}.",
  },
  STUDY_IP_DELIVERY_IP_CATEGORY_CODE_NOT_ENOUGH: {
    status: 400,
    ko: "배송할 수 있는 Study IP Category({category})의 IP 개수가 부족합니다.",
    en: "Not enough IP for Study IP Category({category}) to deliver.",
  },
};

MvnCodeInitiate(STUDY_IP_DELIVERY);
