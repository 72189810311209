import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_PAYLOAD_LANG: {
  BUILDER_STUDY_PAYLOAD_DIALOG_VERSION_LABEL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_DIALOG_TITLE_LABEL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_ADD_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_ADD_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_EDIT_DIALOG_HEADER: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_EDIT_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_EDIT_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_DELETE_DIALOG_HEADER: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_DELETE_DIALOG_DESCRIPTION: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_DELETE_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_DELETE_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_VALIDATE_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_VALIDATE_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_IMPORT_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_IMPORT_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_EXPORT_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_EXPORT_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_WRITABILITY_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_WRITABILITY_DIALOG_HEADER: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_VISIBILITY_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_VISIBILITY_DIALOG_HEADER: MvnCodeFE;
  BUILDER_STUDY_DRAFT_PAYLOAD_EMPTY: MvnCodeFE;
  BUILDER_STUDY_PUBLISHED_PAYLOAD_EMPTY: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_ADD: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PUBLISH_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PUBLISH_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_OVERWRITE_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_OVERWRITE_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PUSH_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_PUSH_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_HOME_TITLE_DESCRIPTION: MvnCodeFE;
} = {
  BUILDER_STUDY_PAYLOAD_DIALOG_VERSION_LABEL: {
    ko: "Draft Version",
    en: "Draft Version",
  },
  BUILDER_STUDY_PAYLOAD_DIALOG_TITLE_LABEL: {
    ko: "Title",
    en: "Title",
  },
  BUILDER_STUDY_PAYLOAD_ADD_SUCC: {
    ko: "{type}을(를) 생성하였습니다.",
    en: "You have created a new {type} successfully.",
  },
  BUILDER_STUDY_PAYLOAD_ADD_FAIL: {
    ko: "{type} 생성에 실패하였습니다. ({message})",
    en: "Failed to create a new {type}. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_EDIT_DIALOG_HEADER: {
    ko: "Edit {type}",
    en: "Edit {type}",
  },
  BUILDER_STUDY_PAYLOAD_EDIT_SUCC: {
    ko: "{type}을(를) 수정하였습니다.",
    en: "You have edited {type} successfully.",
  },
  BUILDER_STUDY_PAYLOAD_EDIT_FAIL: {
    ko: "{type} 수정에 실패하였습니다. ({message})",
    en: "Failed to edit {type}. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_DELETE_DIALOG_HEADER: {
    ko: "{type} 삭제",
    en: "Delete {type}",
  },
  BUILDER_STUDY_PAYLOAD_DELETE_DIALOG_DESCRIPTION: {
    ko: "삭제된 {type}는 되돌릴 수 없습니다.\n삭제하시겠습니까?",
    en: "You are about to delete this {type} permanently.\nYou can’t undo this action.\nAre you sure you want to delete this {type}?",
  },
  BUILDER_STUDY_PAYLOAD_DELETE_SUCC: {
    ko: "{type}을(를) 삭제하였습니다.",
    en: "You have deleted {type} successfully.",
  },
  BUILDER_STUDY_PAYLOAD_DELETE_FAIL: {
    ko: "{type} 삭제에 실패하였습니다. ({message})",
    en: "Failed to delete {type}. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_VALIDATE_SUCC: {
    ko: "CRF version 유효성 검사에 성공하였습니다.",
    en: "You have validated CRF version successfully.",
  },
  BUILDER_STUDY_PAYLOAD_VALIDATE_FAIL: {
    ko: "CRF version 유효성 검사에 실패하였습니다. ({message})",
    en: "Failed to validate CRF version. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_IMPORT_SUCC: {
    ko: "{type} Import에 성공하였습니다.",
    en: "You have imported {type} successfully.",
  },
  BUILDER_STUDY_PAYLOAD_IMPORT_FAIL: {
    ko: "{type} Import에 실패하였습니다. ({message})",
    en: "Failed to import {type}. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_EXPORT_SUCC: {
    ko: "Draft Export에 성공하였습니다.",
    en: "You have exported Draft successfully.",
  },
  BUILDER_STUDY_PAYLOAD_EXPORT_FAIL: {
    ko: "Draft Export에 실패하였습니다. ({message})",
    en: "Failed to export Draft. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_WRITABILITY_DIALOG_TITLE: {
    ko: "Writability를 통해 `Operand` 항목에 데이터를 입력할 수 있습니다.",
    en: "Writability allows `Operand` to enter data in this item.",
  },
  BUILDER_STUDY_PAYLOAD_WRITABILITY_DIALOG_HEADER: {
    ko: "Writability",
    en: "Writability",
  },
  BUILDER_STUDY_PAYLOAD_VISIBILITY_DIALOG_TITLE: {
    ko: "Visibility 통해 `Operand` 항목에 데이터를 입력할 수 있습니다.",
    en: "Visibility allows `Operand` to enter data in this item.",
  },
  BUILDER_STUDY_PAYLOAD_VISIBILITY_DIALOG_HEADER: {
    ko: "Visibility",
    en: "Visibility",
  },
  BUILDER_STUDY_DRAFT_PAYLOAD_EMPTY: {
    ko: "생성된 항목이 없습니다.",
    en: "No Draft yet.",
  },
  BUILDER_STUDY_PUBLISHED_PAYLOAD_EMPTY: {
    ko: "생성된 항목이 없습니다.",
    en: "No CRF version yet.",
  },
  BUILDER_STUDY_PAYLOAD_ADD: {
    ko: "Add Draft",
    en: "Add Draft",
  },
  BUILDER_STUDY_PAYLOAD_PUBLISH_SUCC: {
    ko: "Draft Publish에 성공하였습니다.",
    en: "You have publish a Draft successfully.",
  },
  BUILDER_STUDY_PAYLOAD_PUBLISH_FAIL: {
    ko: "Draft Publish에 실패하였습니다. ({message})",
    en: "Failed to publish a Draft. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_OVERWRITE_SUCC: {
    ko: "CRF version Overwrite에 성공하였습니다.",
    en: "You have overwrite a CRF version successfully.",
  },
  BUILDER_STUDY_PAYLOAD_OVERWRITE_FAIL: {
    ko: "CRF version Overwrite에 실패하였습니다. ({message})",
    en: "Failed to overwrite a CRF version. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_PUSH_SUCC: {
    ko: "CRF version Push에 성공하였습니다.",
    en: "You have push a CRF version successfully.",
  },
  BUILDER_STUDY_PAYLOAD_PUSH_FAIL: {
    ko: "CRF version Push에 실패하였습니다. ({message})",
    en: "Failed to push a CRF version. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_HOME_TITLE_DESCRIPTION: {
    ko: "CRF Draft 정보를 관리하고 Publish 할 수 있습니다.",
    en: "Manage your Draft info and publish it to CRF Version.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_LANG);
