/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_CRF_BUILD_LANG: {
  CRF_BUILD_PAYLOAD_SAVE_SUCC: MvnCodeFE;
  CRF_BUILD_PAYLOAD_SAVE_FAIL: MvnCodeFE;
} = {
  CRF_BUILD_PAYLOAD_SAVE_SUCC: {
    ko: "CRF Payload가 저장되었습니다.",
    en: "CRF payload has been saved successfully.",
  },
  CRF_BUILD_PAYLOAD_SAVE_FAIL: {
    ko: "CRF Payload 저장에 실패했습니다. ({message})",
    en: "CRF payload save request has been failed. ({message})",
  },
};

MvnCodeInitiate(BUILDER_CRF_BUILD_LANG);
