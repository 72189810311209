import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_UAT_LANG: {
  CDMS_UAT_CAPTURE_SCREEN_REGION: MvnCodeFE;
  CDMS_UAT_CAPTURE_FULL_SCREEN: MvnCodeFE;
  CDMS_UAT_CAPTURE_FULL_PAGE: MvnCodeFE;
} = {
  CDMS_UAT_CAPTURE_SCREEN_REGION: {
    ko: "직접 지정",
    en: "Capture a portion",
  },
  CDMS_UAT_CAPTURE_FULL_SCREEN: {
    ko: "전체 스크린",
    en: "Full Screen",
  },
  CDMS_UAT_CAPTURE_FULL_PAGE: {
    ko: "전체 페이지",
    en: "Full Page",
  },
};

MvnCodeInitiate(CDMS_STUDY_UAT_LANG);
