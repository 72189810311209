/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const SUBJECT_SMS_CODE: {
  SUBJECT_SMS_ECONSENT_TITLE_SPEC_NOT_INVALID: MvnCodeBE;
  SUBJECT_SMS_EXPORT_FORMAT_NOT_AVAILABLE: MvnCodeBE;
} = {
  SUBJECT_SMS_ECONSENT_TITLE_SPEC_NOT_INVALID: {
    status: 400,
    ko: "eConsent Title Spec이 올바르지 않습니다.",
    en: "Title Spec of eConsent is not valid.",
  },
  SUBJECT_SMS_EXPORT_FORMAT_NOT_AVAILABLE: {
    status: 400,
    ko: "지원하지 않는 파일형식({format})입니다.",
    en: "The requested export format({format}) is not supported.",
  },
};

MvnCodeInitiate(SUBJECT_SMS_CODE);
