import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BILLING_INVENTORY_CODE: {
  BILLING_INVENTORY_USE_NOT_AVAILABLE: MvnCodeBE;
  BILLING_INVENTORY_CANCEL_NOT_AVAILABLE: MvnCodeBE;
} = {
  BILLING_INVENTORY_USE_NOT_AVAILABLE: {
    status: 409,
    ko: "사용 가능한 Inventory가 없습니다.",
    en: "There is no available inventory.",
  },
  BILLING_INVENTORY_CANCEL_NOT_AVAILABLE: {
    status: 409,
    ko: "이미 사용 취소된 항목입니다.",
    en: "The requested item is already canceled.",
  },
};

MvnCodeInitiate(BILLING_INVENTORY_CODE);
