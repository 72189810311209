/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_ROLE_CODE: {

  TMF_STUDY_ROLE_ID_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_ROLE_ID_INVALID: MvnCodeBE;
  TMF_STUDY_ROLE_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_ROLE_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_ROLE_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_ROLE_NAME_DUPLICATED: MvnCodeBE;
  TMF_STUDY_ROLE_DESC_TOO_LONG: MvnCodeBE;
  TMF_STUDY_ROLE_NOT_ALLOW_DESTROYED: MvnCodeBE;

  TMF_STUDY_ROLE_PRIV_TYPE_MISSING: MvnCodeBE;
  TMF_STUDY_ROLE_PRIV_TYPE_INVALID: MvnCodeBE;
  TMF_STUDY_ROLE_PRIV_ENABLED_INVALID: MvnCodeBE;
  TMF_STUDY_ROLE_PRIV_DUPLICATED: MvnCodeBE;

  TMF_STUDY_ROLE_PRIV_TMF_MANAGER_ADMIN_PRIV_SHOULD_BE_CHECKED: MvnCodeBE;
  TMF_STUDY_ROLE_PRIV_REG_INSPECTOR_PRIV_ONLY_READ: MvnCodeBE;

} = {
  TMF_STUDY_ROLE_ID_NOT_FOUND: {
    code: "TMF_STUDY_ROLE_ID_NOT_FOUND",
    status: 400,
    ko: "studyRoleId({id})가 존재하지 않습니다.",
    en: "studyRoleId({id}) does not exists."
  },
  TMF_STUDY_ROLE_ID_INVALID: {
    code: "TMF_STUDY_ROLE_ID_INVALID",
    status: 400,
    ko: "TMF Role id({id})가 잘못되었습니다.",
    en: "TMF Role id({id}) is invalid."
  },
  TMF_STUDY_ROLE_NAME_MISSING: {
    code: "TMF_STUDY_ROLE_NAME_MISSING",
    status: 400,
    ko: "TMF Role 이름이 입력되지 않았습니다.",
    en: "TMF Role Name is missing."
  },
  TMF_STUDY_ROLE_NAME_TOO_SHORT: {
    code: "TMF_STUDY_ROLE_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Role 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Role name must be at least {length} characters long.",
  },
  TMF_STUDY_ROLE_NAME_TOO_LONG: {
    code: "TMF_STUDY_ROLE_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Role 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Role name can be up to {length} characters long.",
  },
  TMF_STUDY_ROLE_NAME_DUPLICATED: {
    code: "TMF_STUDY_ROLE_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Role 이름({name})이 중복되었습니다.",
    en: "TMF Role name({name}) is duplicated.",
  },
  TMF_STUDY_ROLE_DESC_TOO_LONG: {
    code: "TMF_STUDY_ROLE_DESC_TOO_LONG",
    status: 400,
    ko: "TMF Role 설명의 길이는 최대 {length}자 입니다.",
    en: "TMF Role description can be up to {length} characters long.",
  },
  TMF_STUDY_ROLE_NOT_ALLOW_DESTROYED: {
    code: "TMF_STUDY_ROLE_NOT_ALLOW_DESTROYED",
    status: 400,
    ko: "TMF Role을 삭제할 수 없습니다.",
    en: "TMF Role can not be deleted.",
  },

  TMF_STUDY_ROLE_PRIV_TYPE_MISSING: {
    code: "TMF_STUDY_ROLE_PRIV_TYPE_MISSING",
    status: 400,
    ko: "TMF Privilege Type이 입력되지 않았습니다.",
    en: "TMF Privelege Type is missing."
  },
  TMF_STUDY_ROLE_PRIV_TYPE_INVALID: {
    code: "TMF_STUDY_ROLE_PRIV_TYPE_INVALID",
    status: 400,
    ko: "TMF Privilege Type({priv})이 유효하지 않습니다.",
    en: "TMF Privilege Type({priv}) is invalid.",
  },
  TMF_STUDY_ROLE_PRIV_ENABLED_INVALID: {
    code: "TMF_STUDY_ROLE_PRIV_ENABLED_INVALID",
    status: 400,
    ko: "TMF Privilege 활성화 여부가 잘못되었습니다.",
    en: "TMF Privilege active status is invalid.",
  },
  TMF_STUDY_ROLE_PRIV_DUPLICATED: {
    code: "TMF_STUDY_ROLE_PRIV_DUPLICATED",
    status: 409,
    ko: "TMF Role({name}) Privilege가({priv}) 중복되었습니다.",
    en: "TMF Role({name}) Privilege({priv}) is duplicated.",
  },

  TMF_STUDY_ROLE_PRIV_TMF_MANAGER_ADMIN_PRIV_SHOULD_BE_CHECKED: {
    code: "TMF_STUDY_ROLE_PRIV_TMF_MANAGER_ADMIN_PRIV_SHOULD_BE_CHECKED",
    status: 400,
    ko: "TMF Manager의 Admin read/write 권한은 제거할 수 없습니다.",
    en: "TMF Manager admin read/write permissions cannot removed",
  },
  TMF_STUDY_ROLE_PRIV_REG_INSPECTOR_PRIV_ONLY_READ: {
    code: "TMF_STUDY_ROLE_PRIV_REG_INSPECTOR_PRIV_ONLY_READ",
    status: 400,
    ko: "Regulatory Inspector 는 조회 권한만 지정할 수 있습니다.",
    en: "Regulatory Inspector can only have read privileges.",
  },

};

MvnCodeInitiate(TMF_STUDY_ROLE_CODE);
