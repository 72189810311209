import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_USER_AUTH_LOG_LANG: {
  AUTH_USER_AUTH_LOG_TAB_TITLE: MvnCodeFE;
  AUTH_USER_AUTH_LOG_LIST_COLUMN_ACTION: MvnCodeFE;
  AUTH_USER_AUTH_LOG_LIST_COLUMN_SERVICE: MvnCodeFE;
  AUTH_USER_AUTH_LOG_LIST_COLUMN_IP_ADDRESS: MvnCodeFE;
  AUTH_USER_AUTH_LOG_LIST_COLUMN_BROWSER: MvnCodeFE;
  AUTH_USER_AUTH_LOG_LIST_COLUMN_PLATFORM: MvnCodeFE;
  AUTH_USER_AUTH_LOG_LIST_COLUMN_TIMESTAMP: MvnCodeFE;
  AUTH_USER_AUTH_LOG_FILE_DOWNLOAD_SUCCESS: MvnCodeFE;
  AUTH_USER_AUTH_LOG_FILE_DOWNLOAD_FAIL: MvnCodeFE;
} = {
  AUTH_USER_AUTH_LOG_TAB_TITLE: {
    ko: "로그인 목록",
    en: "Sign in history",
  },
  AUTH_USER_AUTH_LOG_LIST_COLUMN_ACTION: {
    ko: "Action",
    en: "Action",
  },
  AUTH_USER_AUTH_LOG_LIST_COLUMN_SERVICE: {
    ko: "Service",
    en: "Service",
  },
  AUTH_USER_AUTH_LOG_LIST_COLUMN_TIMESTAMP: {
    ko: "Timestamp",
    en: "Timestamp",
  },
  AUTH_USER_AUTH_LOG_LIST_COLUMN_IP_ADDRESS: {
    ko: "IP Address",
    en: "IP Address",
  },
  AUTH_USER_AUTH_LOG_LIST_COLUMN_BROWSER: {
    ko: "Browser",
    en: "Browser",
  },
  AUTH_USER_AUTH_LOG_LIST_COLUMN_PLATFORM: {
    ko: "Platform(OS)",
    en: "Platform(OS)",
  },
  AUTH_USER_AUTH_LOG_FILE_DOWNLOAD_SUCCESS: {
    ko: "문서 다운로드가 시작되었습니다.",
    en: "The download has started.",
  },
  AUTH_USER_AUTH_LOG_FILE_DOWNLOAD_FAIL: {
    ko: "문서 다운로드에 실패했습니다.",
    en: "The download has failed.",
  },
};

MvnCodeInitiate(AUTH_USER_AUTH_LOG_LANG);
