/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_USER_CODE: {
  ADDITION_MAIL_SENT_ALREADY: MvnCodeBE;
  STUDY_USER_EMAIL_NOT_FOUND: MvnCodeBE;
  STUDY_USER_NOT_FOUND: MvnCodeBE;
  STUDY_USER_NOT_AVAILABLE: MvnCodeBE;
  STUDY_USER_ALREADY_EXISTS: MvnCodeBE;
  STUDY_USER_ACCOUNT_INACTIVATED: MvnCodeBE;
  STUDY_USER_ACCOUNT_TERMINATED: MvnCodeBE;
  STUDY_USER_ACTIVATE_DATE_INVALID: MvnCodeBE;
  STUDY_USER_DATE_MIGRATE_FAILED: MvnCodeBE;
  STUDY_USER_API_KEY_NOT_AVAILABLE: MvnCodeBE;
} = {
  ADDITION_MAIL_SENT_ALREADY: {
    status: 400,
    ko: "메일이 이미 전송된 유저입니다.",
    en: "Mail has already been sent to this user.",
  },
  STUDY_USER_EMAIL_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study User Email 정보를 찾을 수 없습니다.",
    en: "Requested study user email could not be found.",
  },
  STUDY_USER_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study User({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study user({id}) could not be found.",
  },
  STUDY_USER_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study User({id}) 정보가 올바르지 않습니다.",
    en: "Requested study user({id}) is not valid.",
  },
  STUDY_USER_ALREADY_EXISTS: {
    status: 400,
    ko: "이미 등록된 Study User 입니다.",
    en: "The study user is already registered.",
  },
  STUDY_USER_ACCOUNT_INACTIVATED: {
    status: 401,
    ko: "study 사용자 계정이 비활성화 상태입니다.",
    en: "study user account has inactivated.",
  },
  STUDY_USER_ACCOUNT_TERMINATED: {
    status: 401,
    ko: "study 사용자 계정이 이미 만료되었습니다.",
    en: "study user account has been terminated already.",
  },
  STUDY_USER_ACTIVATE_DATE_INVALID: {
    status: 400,
    ko: "study 사용자 계정 만료 일자({terminateAt})가 활성화 일자({activateAt})와 같거나, 보다 이전입니다.",
    en: "study user account termination date({terminateAt}) is less than or equal to the activation date({activateAt})",
  },
  STUDY_USER_DATE_MIGRATE_FAILED: {
    status: 400,
    ko: "migration 가능한 대상자가 0명입니다.",
    en: "Failed to migrate study user activation date, available row is 0",
  },
  STUDY_USER_API_KEY_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study API Key({id}) 정보가 올바르지 않습니다.",
    en: "Requested study API Key({id}) is not valid.",
  },
};

MvnCodeInitiate(STUDY_USER_CODE);
