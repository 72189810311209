import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const COUPON_CODE: {
  COUPON_ENTITY_DUPLICATED: MvnCodeBE;
} = {
  COUPON_ENTITY_DUPLICATED: {
    status: 409,
    ko: "쿠폰 코드가 이미 존재합니다.",
    en: "Coupon code already exists.",
  },
};

MvnCodeInitiate(COUPON_CODE);
