import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const ADMIN_NOTICE_CODE: {
  NOTICE_INVALID_START_DATE_TO_DUE_DATE: MvnCodeBE;
} = {
  NOTICE_INVALID_START_DATE_TO_DUE_DATE: {
    status: 400,
    ko: "종료 날짜는 시작 날짜 이후여야 합니다.",
    en: "Due date must be later than start date.",
  },
};

MvnCodeInitiate(ADMIN_NOTICE_CODE);
