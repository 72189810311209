import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const VDR_ROOM_LANG: {
  // Room List
  ROOM_PAGE_SUBTITLE: MvnCodeFE;
  ROOM_INVITATION_DESCRIPTION: MvnCodeFE;
  ROOM_LIST_EMPTY_TITLE: MvnCodeFE;
  ROOM_LIST_EMPTY_DESCRIPTION: MvnCodeFE;

  // Room List Dialog:
  ADD_ROOM_TITLE: MvnCodeFE;
  ROOM_NAME_TITLE: MvnCodeFE;
  COMPANY_NAME_TITLE: MvnCodeFE;
  ROOM_NAME_INPUT_DESCRIPTION: MvnCodeFE;
  COMPANY_NAME_INPUT_DESCRIPTION: MvnCodeFE;

  // Toast
  ROOM_INVITATION_FETCH_FAIL: MvnCodeFE;
  ROOM_INVITATION_ACCEPT_SUCCESS: MvnCodeFE;
  ROOM_INVITATION_ACCEPT_FAIL: MvnCodeFE;
  ROOM_INVITATION_REJECT_SUCCESS: MvnCodeFE;
  ROOM_INVITATION_REJECT_FAIL: MvnCodeFE;
  ROOM_CREATE_SUCCESS: MvnCodeFE;
  ROOM_CREATE_FAIL: MvnCodeFE;
} = {
  // Room List
  ROOM_PAGE_SUBTITLE: {
    ko: "가상 데이터 룸을 만들고 원하는 룸으로 진입할 수 있습니다.",
    en: "Create a virtual data room and get directed to your room.",
  },
  ROOM_INVITATION_DESCRIPTION: {
    ko: "{count} 개의 룸에 초대받았습니다.",
    en: "You are invited to {count} rooms.",
  },
  ROOM_LIST_EMPTY_TITLE: {
    ko: "룸이 없습니다.",
    en: "No rooms.",
  },
  ROOM_LIST_EMPTY_DESCRIPTION: {
    ko: "먼저 룸을 생성해 주세요.",
    en: "Please create a room first.",
  },

  // Room List Dialog:
  ADD_ROOM_TITLE: {
    ko: "룸 생성",
    en: "Create Room",
  },
  ROOM_NAME_TITLE: {
    ko: "Room 이름",
    en: "Room Name",
  },
  COMPANY_NAME_TITLE: {
    ko: "회사 이름",
    en: "Company Name",
  },
  ROOM_NAME_INPUT_DESCRIPTION: {
    ko: "룸 이름 입력",
    en: "Enter room name",
  },
  COMPANY_NAME_INPUT_DESCRIPTION: {
    ko: "회사 이름 입력",
    en: "Enter company name",
  },

  // Toast
  ROOM_INVITATION_FETCH_FAIL: {
    ko: "룸 초대 데이터 불러오기에 실패했습니다.",
    en: "Failed to load room invitation data.",
  },
  ROOM_INVITATION_ACCEPT_SUCCESS: {
    ko: "룸 초대를 수락하였습니다.",
    en: "Invitation to room has been accepted.",
  },
  ROOM_INVITATION_ACCEPT_FAIL: {
    ko: "룸 초대를 수락에 실패하였습니다.",
    en: "Invitation to room has been accepted.",
  },
  ROOM_INVITATION_REJECT_SUCCESS: {
    ko: "룸 초대를 거절하였습니다.",
    en: "Invitation to room has been declined.",
  },
  ROOM_INVITATION_REJECT_FAIL: {
    ko: "룸 초대를 거절에 실패하였습니다.",
    en: "Invitation to room has been declined.",
  },
  ROOM_CREATE_SUCCESS: {
    ko: "룸을 생성하였습니다.",
    en: " You have created a room.",
  },
  ROOM_CREATE_FAIL: {
    ko: "룸 생성에 실패하였습니다.",
    en: "Failed to create a room.",
  },
};

MvnCodeInitiate(VDR_ROOM_LANG);
