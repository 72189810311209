import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const DOCS_TEMPLATE_LANG: {
  TEMPLATE_TITLE_LABEL: MvnCodeFE;
  TEMPLATE_TITLE_PLACEHOLDER: MvnCodeFE;
  TEMPLATE_VERSION_LABEL: MvnCodeFE;
  TEMPLATE_VERSION_PLACEHOLDER: MvnCodeFE;
  TEMPLATE_PAYLOAD_LABEL: MvnCodeFE;
  TEMPLATE_PAYLOAD_PLACEHOLDER: MvnCodeFE;
  TEMPLATE_CREATE_SUCC: MvnCodeFE;
  TEMPLATE_CREATE_FAIL: MvnCodeFE;
  TEMPLATE_PATCH_SUCC: MvnCodeFE;
  TEMPLATE_PATCH_FAIL: MvnCodeFE;
} = {
  TEMPLATE_TITLE_LABEL: {
    ko: "Title",
    en: "Title",
  },
  TEMPLATE_TITLE_PLACEHOLDER: {
    ko: "문서 템플릿 제목을 입력해 주세요.",
    en: "Please enter the template title.",
  },
  TEMPLATE_VERSION_LABEL: {
    ko: "Version",
    en: "Version",
  },
  TEMPLATE_VERSION_PLACEHOLDER: {
    ko: "1.0",
    en: "1.0",
  },
  TEMPLATE_PAYLOAD_LABEL: {
    ko: "Payload",
    en: "Payload",
  },
  TEMPLATE_PAYLOAD_PLACEHOLDER: {
    ko: "문서 템플릿 Payload를 입력해 주세요.",
    en: "Please enter the template payload.",
  },
  TEMPLATE_CREATE_SUCC: {
    ko: "템플릿 등록에 성공했습니다.",
    en: "Document template has been created successfully.",
  },
  TEMPLATE_CREATE_FAIL: {
    ko: "템플릿 등록에 실패했습니다. ({message})",
    en: "Document template creation request failed. ({message})",
  },
  TEMPLATE_PATCH_SUCC: {
    ko: "템플릿 수정에 성공했습니다.",
    en: "Document template has been modified successfully.",
  },
  TEMPLATE_PATCH_FAIL: {
    ko: "템플릿 수정에 실패했습니다. ({message})",
    en: "Document template modification request failed. ({message})",
  },
};

MvnCodeInitiate(DOCS_TEMPLATE_LANG);
