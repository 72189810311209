import React from "react";

export function nl2br(content?: string): React.ReactNode {
  return String(typeof content === "number" ? String(content) : content || "")
    .split("\n")
    .map((c, ci) => (
      <React.Fragment key={ci}>
        {ci > 0 && <br />}
        {c}
      </React.Fragment>
    ));
}
