import {
  AwakeAuthTokenRequest,
  AwakeAuthTokenRequestForTest,
  AwakeAuthTokenResponse,
  AwakeAuthTokenResponseForTest,
  CreateAuthTokenRequest,
  CreateAuthTokenRequestForTest,
  CreateAuthTokenResponse,
  CreateAuthTokenResponseForTest,
  DeleteAuthTokenRequest,
  DeleteAuthTokenRequestForTest,
  DeleteAuthTokenResponse,
  DeleteAuthTokenResponseForTest,
  RefreshAuthTokenRequest,
  RefreshAuthTokenRequestForTest,
  RefreshAuthTokenResponseForTest,
} from "./AuthToken.dto";
import { AuthTokenUrl } from "./AuthToken.url";
import { restReq } from "../common/cyan.types";
import { FxApiRequestData, FxRequestData } from "../common/flax.types";

export class AuthTokenFlax {
  public static createAuthToken(data: FxRequestData<CreateAuthTokenRequest>): FxApiRequestData<CreateAuthTokenResponse> {
    return {
      ...restReq(process.env.REACT_APP_CDMS_BE_URL, AuthTokenUrl.createAuthToken, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static awakeAuthToken(data: FxRequestData<AwakeAuthTokenRequest>): FxApiRequestData<AwakeAuthTokenResponse> {
    return {
      ...restReq(process.env.REACT_APP_CDMS_BE_URL, AuthTokenUrl.awakeAuthToken, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static refreshAuthToken(data: FxRequestData<RefreshAuthTokenRequest>): FxApiRequestData<CreateAuthTokenResponse> {
    return {
      ...restReq(process.env.REACT_APP_CDMS_BE_URL, AuthTokenUrl.refreshAuthToken, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static deleteAuthToken(data: FxRequestData<DeleteAuthTokenRequest>): FxApiRequestData<DeleteAuthTokenResponse> {
    return {
      ...restReq(process.env.REACT_APP_CDMS_BE_URL, AuthTokenUrl.deleteAuthToken, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  // For Test
  public static createAuthTokenForTest(
    data: FxRequestData<CreateAuthTokenRequestForTest>
  ): FxApiRequestData<CreateAuthTokenResponseForTest> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, AuthTokenUrl.createAuthToken, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static awakeAuthTokenForTest(data: FxRequestData<AwakeAuthTokenRequestForTest>): FxApiRequestData<AwakeAuthTokenResponseForTest> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, AuthTokenUrl.awakeAuthToken, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static refreshAuthTokenForTest(
    data: FxRequestData<RefreshAuthTokenRequestForTest>
  ): FxApiRequestData<RefreshAuthTokenResponseForTest> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, AuthTokenUrl.refreshAuthToken, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static deleteAuthTokenForTest(
    data: FxRequestData<DeleteAuthTokenRequestForTest>
  ): FxApiRequestData<DeleteAuthTokenResponseForTest> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, AuthTokenUrl.deleteAuthToken, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
