import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const DOCS_DOCUMENT_SIGN_LANG: {
  DOCUMENT_SIGN_EMAIL_VERIFY_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_VERIFY_DESCRIPTION: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_REGISTER_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_REGISTER_DESCRIPTION: MvnCodeFE;
  DOCUMENT_SIGN_IDENTITY_VERIFY_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_IDENTITY_VERIFY_DESCRIPTION: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_CODE_PLACEHOLDER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_REQUEST_VERIFY_CODE: MvnCodeFE;
  DOCUMENT_SIGN_PROCEED_VERIFY: MvnCodeFE;
  DOCUMENT_SIGN_FETCH_FAIL: MvnCodeFE;
  DOCUMENT_SIGN_NOT_SIGNED: MvnCodeFE;
  DOCUMENT_SIGN_RESEND_REQUEST: MvnCodeFE;
  DOCUMENT_SIGN_RESEND_REQUEST_SUCC: MvnCodeFE;
  DOCUMENT_SIGN_RESEND_REQUEST_FAIL: MvnCodeFE;
  DOCUMENT_SIGN_RESEND_REQUEST_FAIL_ONE_HOUR: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_BEGAN: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_REQUESTED: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_REMIND: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_DONE: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_DECLINED: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_CANCELED: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_CANCEL_REASON: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_TITLE: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_REQUESTOR: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_SIGNER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_EXPIRY: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_PROCEED_SIGN: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_PROCEED_SIGN_ALTERNATIVE_URL_GUIDANCE: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_BEGAN_SUBJECT: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_BEGAN_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_BEGAN_BODY: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_TURN_SUBJECT: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_TURN_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_TURN_BODY: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_REMIND_SUBJECT: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_REMIND_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_REMIND_BODY: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_CANCEL_SUBJECT: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_CANCEL_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_CANCEL_BODY: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_DECLINE_SUBJECT: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_DECLINE_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_DECLINE_BODY: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_DONE_SUBJECT: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_DONE_HEADER: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_DONE_BODY: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_VIEW_DOCUMENT: MvnCodeFE;
  DOCUMENT_SIGN_EMAIL_VIEW_DOCUMENT_ALTERNATIVE_URL_GUIDANCE: MvnCodeFE;
  DOCUMENT_SIGN_SUBSCRIPTION_EXPIRY_CANCEL_REASON: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_BEGAN: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_REQUESTED: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_REMIND: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_DONE: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_DECLINED: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_CANCELED: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_CANCEL_REASON: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_TITLE: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_REQUESTOR: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_SIGNER: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_EXPIRY: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_PROCEED_SIGN: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_PROCEED_SIGN_ALTERNATIVE_URL_GUIDANCE: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_BEGAN_SUBJECT: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_BEGAN_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_BEGAN_BODY: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_TURN_SUBJECT: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_TURN_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_TURN_BODY: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_REMIND_SUBJECT: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_REMIND_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_REMIND_BODY: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_CANCEL_SUBJECT: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_CANCEL_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_CANCEL_BODY: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_DECLINE_SUBJECT: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_DECLINE_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_DECLINE_BODY: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_DONE_SUBJECT: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_DONE_HEADER: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_DONE_BODY: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_VIEW_DOCUMENT: MvnCodeFE;
  DOCUMENT_ECONSENT_EMAIL_VIEW_DOCUMENT_ALTERNATIVE_URL_GUIDANCE: MvnCodeFE;
  DOCUMENT_ECONSENT_SMS_TURN_TITLE: MvnCodeFE;
  DOCUMENT_ECONSENT_SMS_TURN_TITLE_REMIND: MvnCodeFE;
  DOCUMENT_ECONSENT_SMS_TURN_CONTENT_SIGNER: MvnCodeFE;
  DOCUMENT_ECONSENT_SMS_TURN_CONTENT_INVESTIGATOR: MvnCodeFE;
} = {
  DOCUMENT_SIGN_EMAIL_VERIFY_HEADER: {
    ko: "이메일 인증",
    en: "Email Verification",
  },
  DOCUMENT_SIGN_EMAIL_VERIFY_DESCRIPTION: {
    ko: "문서를 열람하기 위해서 이메일 인증이 필요합니다.",
    en: "Please verify your email address to view the document.",
  },
  DOCUMENT_SIGN_EMAIL_REGISTER_HEADER: {
    ko: "이메일 인증",
    en: "Email Verification",
  },
  DOCUMENT_SIGN_EMAIL_REGISTER_DESCRIPTION: {
    ko: "서명 완료된 문서를 받기 위한 이메일 주소를 입력해 주세요.",
    en: "Please enter your email address to receive the signed document.",
  },
  DOCUMENT_SIGN_IDENTITY_VERIFY_HEADER: {
    ko: "본인 인증",
    en: "Identity Verification",
  },
  DOCUMENT_SIGN_IDENTITY_VERIFY_DESCRIPTION: {
    ko: "문서를 열람하기 위해서 본인 인증이 필요합니다.",
    en: "Please verify your personal identity to view the document.",
  },
  DOCUMENT_SIGN_EMAIL_PLACEHOLDER: {
    ko: "이메일주소를 입력해 주세요.",
    en: "Please enter your email address.",
  },
  DOCUMENT_SIGN_CODE_PLACEHOLDER: {
    ko: "인증코드를 입력해 주세요.",
    en: "Please enter verification code.",
  },
  DOCUMENT_SIGN_EMAIL_REQUEST_VERIFY_CODE: {
    ko: "인증코드 요청",
    en: "Request Verification Code",
  },
  DOCUMENT_SIGN_PROCEED_VERIFY: {
    ko: "인증하기",
    en: "Verify",
  },
  DOCUMENT_SIGN_FETCH_FAIL: {
    ko: "서명 문서를 초기화하지 못했습니다.\n({message})",
    en: "Failed to initialize a document.\n({message})",
  },
  DOCUMENT_SIGN_NOT_SIGNED: {
    ko: "미서명",
    en: "Not Signed",
  },
  DOCUMENT_SIGN_RESEND_REQUEST: {
    ko: "서명 재요청하기",
    en: "Resend Request",
  },
  DOCUMENT_SIGN_RESEND_REQUEST_SUCC: {
    ko: "문서 서명 재요청에 성공했습니다.",
    en: "Document Signature request has been resent successfully.",
  },
  DOCUMENT_SIGN_RESEND_REQUEST_FAIL: {
    ko: "문서 서명 재요청에 실패했습니다.",
    en: "Document Signature request resent has been failed.",
  },
  DOCUMENT_SIGN_RESEND_REQUEST_FAIL_ONE_HOUR: {
    ko: "아직 서명을 재요청할 수 없습니다. 요청한 지 60분이 지난 후에 다시 시도하십시오.",
    en: "You cannot resend signature at this time. Please wait for 60 minutes to pass before trying again.",
  },
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_BEGAN: {
    ko: "서명시작",
    en: "Signing Started",
  },
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_REQUESTED: {
    ko: "서명요청",
    en: "Signature Requested",
  },
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_REMIND: {
    ko: "서명재요청",
    en: "Signature Request Resent",
  },
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_DONE: {
    ko: "서명완료",
    en: "Signature Completed",
  },
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_DECLINED: {
    ko: "서명거절",
    en: "Signature Declined",
  },
  DOCUMENT_SIGN_EMAIL_TITLE_HEADER_CANCELED: {
    ko: "서명요청 취소",
    en: "Signature Request Canceled",
  },
  DOCUMENT_SIGN_EMAIL_CANCEL_REASON: {
    ko: "취소 사유",
    en: "Cancellation Reason",
  },
  DOCUMENT_SIGN_EMAIL_TITLE: {
    ko: "문서 이름",
    en: "Title",
  },
  DOCUMENT_SIGN_EMAIL_REQUESTOR: {
    ko: "서명 요청자",
    en: "Requestor",
  },
  DOCUMENT_SIGN_EMAIL_SIGNER: {
    ko: "서명자",
    en: "Signee",
  },
  DOCUMENT_SIGN_EMAIL_EXPIRY: {
    ko: "유효기간",
    en: "Expired at",
  },
  DOCUMENT_SIGN_EMAIL_PROCEED_SIGN: {
    ko: "서명하기",
    en: "Proceed to E-Sign",
  },
  DOCUMENT_SIGN_EMAIL_PROCEED_SIGN_ALTERNATIVE_URL_GUIDANCE: {
    ko: "[서명하기] 버튼을 클릭할 수 없으신가요?\n아래 주소를 복사해서 브라우저 주소창에 붙여넣어 주세요.",
    en: "[E-Sign] Can't click the “Proceed to E-Sign” button?\nCopy and paste the link below into your browser.",
  },
  DOCUMENT_SIGN_EMAIL_BEGAN_SUBJECT: {
    ko: "{title}의 서명이 시작되었습니다.",
    en: "{title} Signing has been started.",
  },
  DOCUMENT_SIGN_EMAIL_BEGAN_HEADER: {
    ko: "서명이 시작되었습니다.",
    en: "Signing has been started.",
  },
  DOCUMENT_SIGN_EMAIL_BEGAN_BODY: {
    ko: "첫번째 서명자에게 서명 요청 메일이 전송되었습니다.",
    en: "The signature request email has been sent to the first signee.",
  },
  DOCUMENT_SIGN_EMAIL_TURN_SUBJECT: {
    ko: "{title}의 서명을 진행해 주세요.",
    en: "{title} Please proceed to sign.",
  },
  DOCUMENT_SIGN_EMAIL_TURN_HEADER: {
    ko: "서명을 진행해 주세요.",
    en: "Please proceed to sign.",
  },
  DOCUMENT_SIGN_EMAIL_TURN_BODY: {
    ko: "서명 정보를 확인해 주세요. 아래의 [서명하기] 버튼을 누르면 서명을 진행할 문서로 이동합니다.",
    en: "Please confirm your signature information. Click the [Proceed to E-Sign] button below to move to the document to be signed.",
  },
  DOCUMENT_SIGN_EMAIL_REMIND_SUBJECT: {
    ko: "{title}의 서명을 진행해 주세요.",
    en: "{title} Please proceed to sign.",
  },
  DOCUMENT_SIGN_EMAIL_REMIND_HEADER: {
    ko: "서명을 진행해 주세요.",
    en: "Please proceed to sign.",
  },
  DOCUMENT_SIGN_EMAIL_REMIND_BODY: {
    ko: "서명 정보를 확인해 주세요. 아래의 [서명하기] 버튼을 누르면 서명을 진행할 문서로 이동합니다.",
    en: "Please confirm your signature information. Click the [Proceed to E-Sign] button below to move to the document to be signed.",
  },
  DOCUMENT_SIGN_EMAIL_CANCEL_SUBJECT: {
    ko: "{title}의 서명 요청이 취소되었습니다.",
    en: "{title} Signature request has been canceled.",
  },
  DOCUMENT_SIGN_EMAIL_CANCEL_HEADER: {
    ko: "서명 요청이 취소되었습니다.",
    en: "The signature request has been canceled.",
  },
  DOCUMENT_SIGN_EMAIL_CANCEL_BODY: {
    ko: "서명 요청이 취소되어 해당 문서에 서명을 진행할 수 없습니다.",
    en: "The signature request has been canceled. You may not proceed to sign on this document.",
  },
  DOCUMENT_SIGN_EMAIL_DECLINE_SUBJECT: {
    ko: "{title}의 서명이 거절되었습니다.",
    en: "{title} Signature request has been declined.",
  },
  DOCUMENT_SIGN_EMAIL_DECLINE_HEADER: {
    ko: "서명이 거절되었습니다.",
    en: "The signature has been declined.",
  },
  DOCUMENT_SIGN_EMAIL_DECLINE_BODY: {
    ko: "서명이 거절되어 해당 문서에 서명을 진행할 수 없습니다.",
    en: "The signature has been declined. You may not proceed to sign on this document.",
  },
  DOCUMENT_SIGN_EMAIL_DONE_SUBJECT: {
    ko: "{title}의 서명이 완료되었습니다.",
    en: "{title} Signature has been completed.",
  },
  DOCUMENT_SIGN_EMAIL_DONE_HEADER: {
    ko: "서명이 완료되었습니다.",
    en: "The signature has been completed.",
  },
  DOCUMENT_SIGN_EMAIL_DONE_BODY: {
    ko: "서명이 완료된 문서는 [문서 확인하기] 버튼을 누르시면 확인 및 다운로드 가능합니다.",
    en: "Click the [View Signed Document] button to check and download the signed document.",
  },
  DOCUMENT_SIGN_EMAIL_VIEW_DOCUMENT: {
    ko: "문서 확인하기",
    en: "View Signed Document",
  },
  DOCUMENT_SIGN_EMAIL_VIEW_DOCUMENT_ALTERNATIVE_URL_GUIDANCE: {
    ko: "[문서 확인하기] 버튼을 클릭할 수 없으신가요?\n아래 주소를 복사해서 브라우저 주소창에 붙여넣어 주세요.",
    en: "[View Document] Can't click the “View Signed Document” button?\nCopy and paste the link below into your browser. ",
  },
  DOCUMENT_SIGN_SUBSCRIPTION_EXPIRY_CANCEL_REASON: {
    ko: "요청자의 구독이 해지됐습니다.",
    en: "The requestor's subscription has been expired.",
  },
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_BEGAN: {
    ko: "전자 동의 시작",
    en: "eConsent Started",
  },
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_REQUESTED: {
    ko: "전자 동의 요청",
    en: "eConsent Requested",
  },
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_REMIND: {
    ko: "전자 동의 재요청",
    en: "eConsent Request Resent",
  },
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_DONE: {
    ko: "전자 동의 완료",
    en: "eConsent Completed",
  },
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_DECLINED: {
    ko: "전자 동의 거절",
    en: "eConsent Declined",
  },
  DOCUMENT_ECONSENT_EMAIL_TITLE_HEADER_CANCELED: {
    ko: "전자 동의 요청 취소",
    en: "eConsent Request Canceled",
  },
  DOCUMENT_ECONSENT_EMAIL_CANCEL_REASON: {
    ko: "취소 사유",
    en: "Cancellation Reason",
  },
  DOCUMENT_ECONSENT_EMAIL_TITLE: {
    ko: "문서 이름",
    en: "Title",
  },
  DOCUMENT_ECONSENT_EMAIL_REQUESTOR: {
    ko: "서명 요청자",
    en: "Requestor",
  },
  DOCUMENT_ECONSENT_EMAIL_SIGNER: {
    ko: "서명자",
    en: "Signee",
  },
  DOCUMENT_ECONSENT_EMAIL_EXPIRY: {
    ko: "유효기간",
    en: "Expired at",
  },
  DOCUMENT_ECONSENT_EMAIL_PROCEED_SIGN: {
    ko: "서명하기",
    en: "Proceed to Consent",
  },
  DOCUMENT_ECONSENT_EMAIL_PROCEED_SIGN_ALTERNATIVE_URL_GUIDANCE: {
    ko: "[서명하기] 버튼을 클릭할 수 없으신가요?\n아래 주소를 복사해서 브라우저 주소창에 붙여넣어 주세요.",
    en: "[eConsent] Can't click the “Proceed to Consent” button?\nCopy and paste the link below into your browser.",
  },
  DOCUMENT_ECONSENT_EMAIL_BEGAN_SUBJECT: {
    ko: "{title}의 전자 동의가 시작되었습니다.",
    en: "{title} eConsent has been started.",
  },
  DOCUMENT_ECONSENT_EMAIL_BEGAN_HEADER: {
    ko: "전자 동의가 시작되었습니다.",
    en: "eConsent has been started.",
  },
  DOCUMENT_ECONSENT_EMAIL_BEGAN_BODY: {
    ko: "첫번째 서명자에게 전자 동의 요청 메일이 전송되었습니다.",
    en: "The eConsent request email has been sent to the first signee.",
  },
  DOCUMENT_ECONSENT_EMAIL_TURN_SUBJECT: {
    ko: "{title}의 전자 동의를 진행해 주세요.",
    en: "{title} Please proceed to sign.",
  },
  DOCUMENT_ECONSENT_EMAIL_TURN_HEADER: {
    ko: "전자 동의를 진행해 주세요.",
    en: "Please proceed to consent.",
  },
  DOCUMENT_ECONSENT_EMAIL_TURN_BODY: {
    ko: "전자 동의 정보를 확인해 주세요. 아래의 [서명 하기] 버튼을 누르면 전자 동의를 진행할 문서로 이동합니다.",
    en: "Please confirm your eConsent information. Click the [Proceed to Consent] button below to move to the eConsent document.",
  },
  DOCUMENT_ECONSENT_EMAIL_REMIND_SUBJECT: {
    ko: "{title}의 전자 동의를 진행해 주세요.",
    en: "{title} Please proceed to sign.",
  },
  DOCUMENT_ECONSENT_EMAIL_REMIND_HEADER: {
    ko: "전자 동의를 진행해 주세요.",
    en: "Please proceed to consent.",
  },
  DOCUMENT_ECONSENT_EMAIL_REMIND_BODY: {
    ko: "전자 동의 정보를 확인해 주세요. 아래의 [서명 하기] 버튼을 누르면 전자 동의를 진행할 문서로 이동합니다.",
    en: "Please confirm your eConsent information. Click the [Proceed to Consent] button below to move to the eConsent document.",
  },
  DOCUMENT_ECONSENT_EMAIL_CANCEL_SUBJECT: {
    ko: "{title}의 전자 동의 요청이 취소되었습니다.",
    en: "{title} eConsent request has been canceled.",
  },
  DOCUMENT_ECONSENT_EMAIL_CANCEL_HEADER: {
    ko: "전자 동의가 취소되었습니다.",
    en: "The eConsent request has been canceled.",
  },
  DOCUMENT_ECONSENT_EMAIL_CANCEL_BODY: {
    ko: "전자 동의 요청이 취소되어 해당 문서에 서명을 진행할 수 없습니다.",
    en: "The eConsent request has been canceled. You may not proceed to sign on this document.",
  },
  DOCUMENT_ECONSENT_EMAIL_DECLINE_SUBJECT: {
    ko: "{title}의 전자 동의가 거절되었습니다.",
    en: "{title} eConsent request has been declined.",
  },
  DOCUMENT_ECONSENT_EMAIL_DECLINE_HEADER: {
    ko: "전자 동의가 거절되었습니다.",
    en: "The eConsent has been declined.",
  },
  DOCUMENT_ECONSENT_EMAIL_DECLINE_BODY: {
    ko: "전자 동의가 거절되어 해당 문서에 전자 동의를 진행할 수 없습니다.",
    en: "The eConsent has been declined. You may not proceed to sign on this document.",
  },
  DOCUMENT_ECONSENT_EMAIL_DONE_SUBJECT: {
    ko: "{title}의 전자 동의가 완료되었습니다.",
    en: "{title} eConsent has been completed.",
  },
  DOCUMENT_ECONSENT_EMAIL_DONE_HEADER: {
    ko: "전자 동의가 완료되었습니다.",
    en: "The eConsent has been completed.",
  },
  DOCUMENT_ECONSENT_EMAIL_DONE_BODY: {
    ko: "서명이 완료된 문서는 [문서 확인하기] 버튼을 누르시면 확인 및 다운로드 가능합니다.",
    en: "Click the [View eConsent Document] button to check and download the signed document.",
  },
  DOCUMENT_ECONSENT_EMAIL_VIEW_DOCUMENT: {
    ko: "문서 확인하기",
    en: "View eConsent Document",
  },
  DOCUMENT_ECONSENT_EMAIL_VIEW_DOCUMENT_ALTERNATIVE_URL_GUIDANCE: {
    ko: "[문서 확인하기] 버튼을 클릭할 수 없으신가요?\n아래 주소를 복사해서 브라우저 주소창에 붙여넣어 주세요.",
    en: "[View eConsent Document] Can't click the “View eConsent Document” button?\nCopy and paste the link below into your browser. ",
  },
  DOCUMENT_ECONSENT_SMS_TURN_TITLE: {
    ko: "[{name}] 전자동의 요청",
    en: "",
  },
  DOCUMENT_ECONSENT_SMS_TURN_TITLE_REMIND: {
    ko: "[{name}] 전자동의 재요청",
    en: "",
  },
  DOCUMENT_ECONSENT_SMS_TURN_CONTENT_SIGNER: {
    ko: "{title} 전자동의 정보를 확인해 주세요. 아래의 링크를 누르면 전자동의 문서로 이동합니다.\n\n▶ 전자동의 문서에 서명하기 {url}\n\n{content}",
    // NOTE: 현재 ko외의 다른언어는 사용되지 않습니다.
    en: "",
  },
  DOCUMENT_ECONSENT_SMS_TURN_CONTENT_INVESTIGATOR: {
    ko: "{title} 전자동의 정보를 확인해 주세요. 아래의 링크를 누르면 전자동의 문서로 이동합니다.\n\n▶ 전자동의 문서에 서명하기 {url}\n\n■ 문서 이름\n{fileName}\n\n■ 요청자\n{requesterName} ({requesterEmail})\n\n■ 서명자\n1. {signerName} ({signerEmail})\n2. {investigatorName} ({investigatorEmail})\n\n■ 유효기간\n~ {time}",
    // NOTE: 현재 ko만 지원중
    en: "",
  },
};

MvnCodeInitiate(DOCS_DOCUMENT_SIGN_LANG);
