import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_WORKSPACE_ITEM_CODE: {
  SAFETY_WORKSPACE_MODIFY_INVALID_SAME_DESTINATION: MvnCodeBE;
  SAFETY_WORKSPACE_ITEM_FOLDER_MODIFY_INVALID_DUPLICATED_NAME: MvnCodeBE;
  SAFETY_WORKSPACE_ITEM_FOLDER_MODIFY_INVALID_CHILDREN_DESTINATION: MvnCodeBE;
  SAFETY_WORKSPACE_ITEM_FOLDER_MODIFY_INVALID_NAME_MAX_LENGTH_EXCEED: MvnCodeBE;
  SAFETY_WORKSPACE_ITEM_FOLDER_DELETE_INVALID_NOT_EMPTY: MvnCodeBE;
  SAFETY_WORKSPACE_ITEM_CASE_DELETE_INVALID_OPTS_DISABLED: MvnCodeBE;
  SAFETY_WORKSPACE_ITEM_CASE_DELETE_INVALID_CASE_STATUS: MvnCodeBE;
  SAFETY_WORKSPACE_ITEM_CASE_DELETE_INVALID_REPORT_LOCKED: MvnCodeBE;
} = {
  SAFETY_WORKSPACE_MODIFY_INVALID_SAME_DESTINATION: {
    status: 400,
    ko: "이전과 동일한 위치로는 이동할 수 없습니다.",
    en: "You cannot move to the same location as before.",
  },
  SAFETY_WORKSPACE_ITEM_FOLDER_MODIFY_INVALID_DUPLICATED_NAME: {
    status: 400,
    ko: "폴더 이름이 중복되었습니다.",
    en: "Folder name is duplicated.",
  },
  SAFETY_WORKSPACE_ITEM_FOLDER_MODIFY_INVALID_CHILDREN_DESTINATION: {
    status: 400,
    ko: "지정한 폴더의 하위 폴더로는 이동시킬 수 없습니다.",
    en: "You cannot move to children of a folder.",
  },
  SAFETY_WORKSPACE_ITEM_FOLDER_MODIFY_INVALID_NAME_MAX_LENGTH_EXCEED: {
    status: 400,
    ko: "폴더명은 50자 이내로 입력가능합니다.",
    en: "Folder name must be less than 50 characters long.",
  },
  SAFETY_WORKSPACE_ITEM_FOLDER_DELETE_INVALID_NOT_EMPTY: {
    status: 400,
    ko: "비어있는 폴더만 삭제할 수 있습니다.",
    en: "Only empty folder can be deleted.",
  },
  SAFETY_WORKSPACE_ITEM_CASE_DELETE_INVALID_OPTS_DISABLED: {
    status: 400,
    ko: "Case Delete 기능이 활성화되지 않았습니다.",
    en: "Case Delete is not active.",
  },
  SAFETY_WORKSPACE_ITEM_CASE_DELETE_INVALID_CASE_STATUS: {
    status: 400,
    ko: "Draft 또는 Ready For Submission 상태의 case만 삭제할 수 있습니다.",
    en: "Only Draft or Ready For Submission case can be deleted.",
  },
  SAFETY_WORKSPACE_ITEM_CASE_DELETE_INVALID_REPORT_LOCKED: {
    status: 400,
    ko: "잠긴 case는 삭제할 수 없습니다.",
    en: "Locked case cannot be deleted.",
  },
};

MvnCodeInitiate(SAFETY_WORKSPACE_ITEM_CODE);
