import { MvnCode, MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_AS2_ACCOUNT_LANG: {
  SAFETY_AS2_ACCOUNT_ADD_SUCC: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_ADD_FAIL: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_EDIT_SUCC: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_EDIT_FAIL: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_IDENTIFIER_IN_USE: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_ACTIVATE_SUCC: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_ACTIVATE_FAIL: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_DEACTIVATE_SUCC: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_DEACTIVATE_FAIL: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_ACTIVATE_DIALOG: MvnCode;
  SAFETY_AS2_ACCOUNT_DEACTIVATE_DIALOG_001: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_DEACTIVATE_DIALOG_002: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_DEACTIVATE_FAILED_DIALOG_001: MvnCodeFE;
  SAFETY_AS2_ACCOUNT_DEACTIVATE_FAILED_DIALOG_002: MvnCodeFE;
} = {
  SAFETY_AS2_ACCOUNT_ADD_SUCC: {
    ko: "Gateway Account가 성공적으로 추가되었습니다.",
    en: "Gateway Account has been created successfully.",
  },
  SAFETY_AS2_ACCOUNT_ADD_FAIL: {
    ko: "Gateway Account 추가에 실패했습니다. ({message})",
    en: "Failed to create Gateway Account. ({message})",
  },
  SAFETY_AS2_ACCOUNT_EDIT_SUCC: {
    ko: "Gateway Account가 성공적으로 수정되었습니다.",
    en: "Gateway Account has been modified successfully.",
  },
  SAFETY_AS2_ACCOUNT_EDIT_FAIL: {
    ko: "Gateway Account 수정에 실패했습니다. ({message})",
    en: "Failed to modify Gateway Account. ({message})",
  },
  SAFETY_AS2_ACCOUNT_IDENTIFIER_IN_USE: {
    ko: "이미 사용되고 있는 Account Identifier입니다.",
    en: "Identifier is already in use.",
  },
  SAFETY_AS2_ACCOUNT_ACTIVATE_SUCC: {
    ko: "Gateway Account가 활성화되었습니다.",
    en: "Gateway Account has been activated successfully.",
  },
  SAFETY_AS2_ACCOUNT_ACTIVATE_FAIL: {
    ko: "Gateway Account 활성화에 실패했습니다. ({message})",
    en: "Failed to activate Gateway Account. ({message})",
  },
  SAFETY_AS2_ACCOUNT_DEACTIVATE_SUCC: {
    ko: "Gateway Account가 비활성화되었습니다.",
    en: "Gateway Account has been deactivated successfully.",
  },
  SAFETY_AS2_ACCOUNT_DEACTIVATE_FAIL: {
    ko: "Gateway Account 비활성화에 실패했습니다. ({message})",
    en: "Failed to deactivate Gateway Account. ({message})",
  },
  SAFETY_AS2_ACCOUNT_ACTIVATE_DIALOG: {
    ko: "Gateway Account를 활성화 하시겠습니까?",
    en: "Are you sure you want to activate Gateway Account?",
  },
  SAFETY_AS2_ACCOUNT_DEACTIVATE_DIALOG_001: {
    ko: "Gateawy Account를 비활성화 하시겠습니까?",
    en: "Are you sure you want to deactivate Gatweay Account?",
  },
  SAFETY_AS2_ACCOUNT_DEACTIVATE_DIALOG_002: {
    ko: "Gateway Account가 비활성화되면 Account 정보를 사용하고 있는 Gateway에 영향을 끼칠 수 있습니다.",
    en: "If Gateway Account is deactivated, this may affect the Gateway that is using the account information.",
  },
  SAFETY_AS2_ACCOUNT_DEACTIVATE_FAILED_DIALOG_001: {
    ko: "비활성화할 수 없습니다.",
    en: "Deactivation is not available.",
  },
  SAFETY_AS2_ACCOUNT_DEACTIVATE_FAILED_DIALOG_002: {
    ko: "Gateway Account가 다른 Gateway Connection와 연결되어 있습니다. 연결 해제 후 비활성화할 수 있습니다.",
    en: "Gateway Account is linked with Gateway Connection. Deactivation is available after disconnecting.",
  },
};

MvnCodeInitiate(SAFETY_AS2_ACCOUNT_LANG);
