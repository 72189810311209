import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const SUBJECT_SMS_LANG: {
  SUBJECT_SMS_ECONSENT_URL_TITLE: MvnCodeFE;
  SUBJECT_SMS_ECONSENT_URL_CONTENT: MvnCodeFE;
  SUBJECT_SMS_ECONSENT_URL_CONTENT_NOT_EXIST: MvnCodeFE;
  SUBJECT_SMS_ECOA_ACCESS_CODE_TITLE: MvnCodeFE;
  SUBJECT_SMS_ECOA_ACCESS_CODE_CONTENT: MvnCodeFE;
  SUBJECT_SMS_ECOA_AUTHENTICATE_CODE_TITLE: MvnCodeFE;
  SUBJECT_SMS_ECOA_AUTHENTICATE_CODE_CONTENT: MvnCodeFE;
  SUBJECT_SMS_HISTORY_DOWNLOAD_FAIL: MvnCodeFE;
} = {
  SUBJECT_SMS_ECONSENT_URL_TITLE: {
    ko: "eConsent URL 발송",
    en: "eConsent URL sent",
  },
  SUBJECT_SMS_ECONSENT_URL_CONTENT: {
    ko: "eConsent에 접속하여 동의 서명을 진행해주세요. {eConsentUrl}",
    en: "Please access to the eConsent and sign the consent. {eConsentUrl}",
  },
  SUBJECT_SMS_ECONSENT_URL_CONTENT_NOT_EXIST: {
    ko: "발송된 내용이 없습니다.",
    en: "Content does not exist.",
  },
  SUBJECT_SMS_ECOA_ACCESS_CODE_TITLE: {
    ko: "eCOA Code 및 URL 발송",
    en: "Send eCOA code and URL",
  },
  SUBJECT_SMS_ECOA_ACCESS_CODE_CONTENT: {
    ko: "eCOA 인증번호 [{ecoaAccessCode}]를 {ecoaUrl} 에서 입력 후 본인인증 진행해주세요.",
    en: "Please type in the eCOA authentication code [{ecoaAccessCode}] at {ecoaUrl} after self identification.",
  },
  SUBJECT_SMS_ECOA_AUTHENTICATE_CODE_TITLE: {
    ko: "eCOA 인증번호 발송",
    en: "Send eCOA authentication code",
  },
  SUBJECT_SMS_ECOA_AUTHENTICATE_CODE_CONTENT: {
    ko: "[{authenticateCode}] 인증번호를 Maven eCOA에 입력해주세요.",
    en: "Please type in the authentication code [{authenticateCode}] in the Maven eCOA.",
  },
  SUBJECT_SMS_HISTORY_DOWNLOAD_FAIL: {
    ko: "대상자 SMS 발송 내역 다운로드에 실패하였습니다.",
    en: "Subject SMS History download has been failed. ({error})",
  },
};

MvnCodeInitiate(SUBJECT_SMS_LANG);
