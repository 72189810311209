import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CASE_REPORT_IMPORT_VALIDATE_CODE: {
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_DATA_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_WORKSPACE_CRF_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_IMPORT_SPEC_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_CRITERIA_ROW_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_COLUMN_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_IDENTIFIER_DUPLICATED: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_ATTRIBUTE_MISSING: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_SEQ_VALUE_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPEATABLE_NUMBER_FORMAT_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_CASE_REPORT_MISSING: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_IS_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_CONTENT_DUPLICATED: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_MISSING_ATTRIBUTE: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_PAGE_ID_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_SECTION_ID_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_ITEM_ID_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_MAPPING_VALUE_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_DATE_VALUE_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_DATE_RANGE_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_RELATED_TEXT_ITEM_INVALID_FORMAT: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_DICTIONARY_VERSION_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_DICTIONARY_VERSION_MISSING: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_DICTIONARY_CODE_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPEATABLE_ITEM_DUPLICATED: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_IMPORT_DESTINATION_CASE_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_TAG_NOT_EXIST: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_RELATED_ITEM_UUID_NOT_FOUND: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_RELATED_ITEM_UUID_DUPLICATE: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_DICTIONARY_VERSION_DUPLICATE: MvnCodeBE;
} = {
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_DATA_NOT_EXIST: {
    status: 400,
    ko: "유효한 데이터가 존재하지 않는 파일입니다.",
    en: "No valid data in file.",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_WORKSPACE_CRF_NOT_EXIST: {
    status: 404,
    ko: "Workspace CRF 미존재 (crfId: {crfId})",
    en: "Workspace CRF does not exist (crfId: {crfId})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_IMPORT_SPEC_NOT_EXIST: {
    status: 400,
    ko: "Case report import spec 미존재 ({identifier})",
    en: "Case report import spec does not exist ({identifier})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_CRITERIA_ROW_NOT_EXIST: {
    status: 400,
    ko: "Import 기준 Row 미존재",
    en: "Import criteria row does not exist.",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_COLUMN_NOT_EXIST: {
    status: 400,
    ko: "Report 식별 컬럼 미존재",
    en: "Report identity column does not exist.",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_TAG_NOT_EXIST: {
    status: 400,
    ko: "Report 식별 태그 미존재",
    en: "Report identity tag does not exist.",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_IDENTIFIER_DUPLICATED: {
    status: 400,
    ko: "Import 파일 내 Report 중복 ({caseIdentifier}#{sequence})",
    en: "Duplicate Report in Import file (({caseIdentifier}#{sequence}))",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_ATTRIBUTE_MISSING: {
    status: 400,
    ko: "Report 일부 요소 누락 ({attribute})",
    en: "Missing report attribute ({attribute})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_SEQ_VALUE_INVALID: {
    status: 400,
    ko: "유효하지 않은 Report sequence 값",
    en: "Invalid Report sequence value",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPEATABLE_NUMBER_FORMAT_INVALID: {
    status: 400,
    ko: "순서정렬 양식 오류 [{format}]",
    en: "Invalid Item sequence format [{format}]",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_CASE_REPORT_MISSING: {
    status: 400,
    ko: "{caseReportIdentifiers} 누락",
    en: "Missing {caseReportIdentifiers}",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_IS_EXIST: {
    status: 409,
    ko: "Report 존재 ({caseIdentifier}#{sequence})",
    en: "Same Case Report is already exist ({caseIdentifier}#{sequence})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPORT_CONTENT_DUPLICATED: {
    status: 409,
    ko: "중복 Content의 Report 존재 ({caseIdentifier}#{sequence} - patient name: {patientName}, product name: {productName}, adverse event: {adverseEvent})",
    en: "Existence of Report of Duplicate Content ({caseIdentifier}#{sequence} - patient name: {patientName}, product name: {productName}, adverse event: {adverseEvent})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_MISSING_ATTRIBUTE: {
    status: 400,
    ko: "일부 설정 값 누락으로 인한 검증 진행 불가",
    en: "Values cannot be verified because some setting values are missing",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_PAGE_ID_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 Page ID ({pageId})",
    en: "Non-existent Page ID ({pageId})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_SECTION_ID_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 Section ID ({sectionId})",
    en: "Non-existent Section ID ({sectionId})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_ITEM_ID_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 Item ID ({itemId})",
    en: "Non-existent Item ID ({itemId})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_MAPPING_VALUE_NOT_EXIST: {
    status: 400,
    ko: "정의되지 않은 Mapping 값 ({value})",
    en: "Undefined Mapping Value ({value})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_DATE_VALUE_INVALID: {
    status: 400,
    ko: "범위를 초과한 날짜 ({range})",
    en: "Date out of range ({range})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_DATE_RANGE_INVALID: {
    status: 400,
    ko: "범위를 초과한 날짜 (itemId: {itemId}, range: {range})",
    en: "Date out of range (itemId: {itemId}, range: {range})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_RELATED_TEXT_ITEM_INVALID_FORMAT: {
    status: 400,
    ko: "잘못된 형식 ({value})",
    en: "Invalid format ({value})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_DICTIONARY_VERSION_INVALID: {
    status: 404,
    ko: "유효하지 않은 dictionary version (dictionary: {type}, version: {version})",
    en: "Invalid dictionary version (dictionary: {type}, version: {version})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_DICTIONARY_VERSION_MISSING: {
    status: 404,
    ko: "{dictionary} 버전 입력 누락",
    en: "Missing {dictionary} version",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_DICTIONARY_CODE_INVALID: {
    status: 400,
    ko: "{version} 내 존재하지 않는 {codeLabel}({code})",
    en: "{code} missing in {version} version",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_IMPORT_DESTINATION_CASE_NOT_EXIST: {
    status: 400,
    ko: "{caseReport}를 import 하고자 하는 위치에 Case({case})가 존재하지 않습니다.",
    en: "Case({case}) does not exist where you want to import {caseReport}",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPEATABLE_ITEM_DUPLICATED: {
    status: 400,
    ko: "Cell 내의 '{prefix}' 중복",
    en: "Duplicate '{prefix}' in cell",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_RELATED_ITEM_UUID_NOT_FOUND: {
    status: 400,
    ko: "Related item uuid 누락 (itemId: {itemId})",
    en: "Missing related item uuid (itemId: {itemId})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_RELATED_ITEM_UUID_DUPLICATE: {
    status: 400,
    ko: "Related item uuid 중복 (uuid: {uuid})",
    en: "Duplicate related item uuid (uuid: {uuid})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_DICTIONARY_VERSION_DUPLICATE: {
    status: 400,
    ko: "Dictionary version 중복 (dictionary: {type})",
    en: "Duplicate dictionary version (dictionary: {type})",
  },
};

export const SAFETY_CASE_REPORT_IMPORT_VALIDATE_EXCEL_CODE: {
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_VALUE_FORMAT_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_NULL_FLAVOR_FORMAT_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_DATE_FORMAT_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REQUIRED_ITEM_VALUE_MISSING: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_ITEM_NOT_AVAILABLE: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPEATABLE_ITEM_REF_NOT_FOUND: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_PARENT_ITEM_NOT_FOUND: MvnCodeBE;
} = {
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_VALUE_FORMAT_INVALID: {
    status: 400,
    ko: "올바르지 않은 데이터 입력 형식 ({value})",
    en: "Invalid data entry format ({value})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_DATE_FORMAT_INVALID: {
    status: 400,
    ko: "잘못된 날짜 형식 ({value})",
    en: "Invalid date format ({value})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_NULL_FLAVOR_FORMAT_INVALID: {
    status: 400,
    ko: "허용하지 않는 Null Flavor ({value})",
    en: "Not allowed Null Flavor ({value})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REQUIRED_ITEM_VALUE_MISSING: {
    status: 400,
    ko: "필수값 누락 (label: {itemLabel})",
    en: "Missing required item value (label: {itemLabel})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_ITEM_NOT_AVAILABLE: {
    status: 400,
    ko: "입력 불가 항목",
    en: "Unavailable item",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REPEATABLE_ITEM_REF_NOT_FOUND: {
    status: 400,
    ko: "존재하지 않는 참조 값 (Name: {itemId}, RefId : [{prefix}])",
    en: "Reference value does not exist (Name: {itemId}, RefId: [{prefix}])",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_PARENT_ITEM_NOT_FOUND: {
    status: 400,
    ko: "{parentSheetName}에 존재하지 않는 Case ({caseIdentifierAndSequence})",
    en: "Case ({caseIdentifierAndSequence}) does not exist in {parentSheetName}",
  },
};

export const SAFETY_CASE_REPORT_IMPORT_VALIDATE_XML_CODE: {
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_VALUE_FORMAT_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_NULL_FLAVOR_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_DATE_FORMAT_INVALID: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REQUIRED_ITEM_VALUE_MISSING: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_ITEM_NOT_AVAILABLE: MvnCodeBE;
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_RELATED_ITEM_NOT_FOUND: MvnCodeBE;
} = {
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_VALUE_FORMAT_INVALID: {
    status: 400,
    ko: "올바르지 않은 데이터 입력 형식 (itemId: {itemId}, value: {value})",
    en: "Invalid data entry format (itemId: {itemId}, value: {value})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_DATE_FORMAT_INVALID: {
    status: 400,
    ko: "잘못된 날짜 형식 (itemId: {itemId}, value: {value})",
    en: "Invalid date format (itemId: {itemId}, value: {value})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_BIND_TO_NULL_FLAVOR_INVALID: {
    status: 400,
    ko: "허용하지 않는 Null Flavor (itemId: {itemId}, value: {value})",
    en: "Not allowed Null Flavor (itemId: {itemId}, value: {value})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_REQUIRED_ITEM_VALUE_MISSING: {
    status: 400,
    ko: "필수값 누락 (itemId: {itemId})",
    en: "Missing required item value (itemId: {itemId})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_ITEM_NOT_AVAILABLE: {
    status: 400,
    ko: "입력 불가 항목 (itemId: {itemId})",
    en: "Unavailable item (itemId: {itemId})",
  },
  SAFETY_CASE_REPORT_IMPORT_VALIDATE_ERROR_RELATED_ITEM_NOT_FOUND: {
    status: 400,
    ko: "존재하지 않는 참조 값 (itemId: {itemId})",
    en: "Reference value does not exist (itemId: {itemId})",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_IMPORT_VALIDATE_CODE);
MvnCodeInitiate(SAFETY_CASE_REPORT_IMPORT_VALIDATE_EXCEL_CODE);
MvnCodeInitiate(SAFETY_CASE_REPORT_IMPORT_VALIDATE_XML_CODE);
