import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CASE_REPORT_CODE: {
  CASE_REPORT_NOT_FOUND: MvnCodeBE;
  CASE_REPORT_NOT_AVAILABLE: MvnCodeBE;
  CASE_REPORT_REQUIRED_VALUE_MISSING: MvnCodeBE;
  CASE_REPORT_ITEM_NOT_CAPTURABLE: MvnCodeBE;
  CASE_REPORT_ITEM_NOT_AVAILABLE: MvnCodeBE;
  CASE_REPORT_ITEM_INVALID_NOT_ALLOWED_FORMAT: MvnCodeBE;
  CASE_REPORT_ITEM_INVALID_NOT_ALLOWED_CHARACTER: MvnCodeBE;
  CASE_REPORT_ITEM_INVALID_NOT_MODIFIED: MvnCodeBE;
  CASE_REPORT_AUTO_ITEM_INVALID_NOT_MATCHED_VALUE: MvnCodeBE;
  CASE_REPORT_AUTO_ITEM_INVALID_MATCHED_NOT_EXIST: MvnCodeBE;
  CASE_REPORT_RELATED_TEXT_ITEM_INVALID_NOT_MATCHED_VALUE: MvnCodeBE;
  CASE_REPORT_RELATED_TEXT_ITEM_INVALID_STRIKED_VALUE: MvnCodeBE;
  CASE_REPORT_DATE_ITEM_INVALID_DATE_RANGE: MvnCodeBE;
  CASE_REPORT_CREATE_INVALID_WORKFLOW_NOT_COMPLETED: MvnCodeBE;
  CASE_REPORT_HIDDEN_ITEM_INVALID_NOT_MATCHED_CONDITION: MvnCodeBE;
  CASE_REPORT_CREATE_INVALID_CASE_LOCKED: MvnCodeBE;
  CASE_REPORT_CREATE_INVALID_DUPLICATED_CONTENT: MvnCodeBE;
} = {
  CASE_REPORT_NOT_FOUND: {
    status: 404,
    ko: "Case report({id}) 정보가 존재하지 않습니다.",
    en: "Case report({id}) does not exist.",
  },
  CASE_REPORT_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Case report({id}) 정보가 올바르지 않습니다.",
    en: "Requested case report({id}) is not valid.",
  },
  CASE_REPORT_REQUIRED_VALUE_MISSING: {
    status: 400,
    ko: "필수값이 누락되었습니다. (label: {label})",
    en: "Required Item value is missing. (label: {label})",
  },
  CASE_REPORT_ITEM_NOT_CAPTURABLE: {
    status: 400,
    ko: "[{itemSeq}] {itemId}의 값이 capture 될 수 없는 조건입니다.",
    en: "[{itemSeq}] {itemId} value does not match capture conditions.",
  },
  CASE_REPORT_ITEM_NOT_AVAILABLE: {
    status: 400,
    ko: "[{itemSeq}] {itemId}는 입력할 수 없는 항목입니다.",
    en: "[{itemSeq}] {itemId} cannot be entered.",
  },
  CASE_REPORT_ITEM_INVALID_NOT_ALLOWED_FORMAT: {
    status: 400,
    ko: "허용하지 않는 데이터 형식입니다. ([{itemSeq}] {itemId})",
    en: "Not allowed data format. ([{itemSeq}] {itemId})",
  },
  CASE_REPORT_ITEM_INVALID_NOT_ALLOWED_CHARACTER: {
    status: 400,
    ko: "허용하지 않는 문자 형식입니다.",
    en: "Not allowed character format.",
  },
  CASE_REPORT_ITEM_INVALID_NOT_MODIFIED: {
    status: 400,
    ko: "[{itemSeq}] {itemId}는 수정할 수 없는 항목입니다.",
    en: "[{itemSeq}] {itemId} cannot be modified.",
  },
  CASE_REPORT_AUTO_ITEM_INVALID_NOT_MATCHED_VALUE: {
    status: 400,
    ko: "{itemId}의 value가 매칭되는 bind item의 값과 일치하지 않습니다.",
    en: "{itemId} does not have a same value with a bind item.",
  },
  CASE_REPORT_AUTO_ITEM_INVALID_MATCHED_NOT_EXIST: {
    status: 400,
    ko: "{itemId}에 match 되는 bind item이 존재하지 않습니다.",
    en: "{itemId} does not match with any bind item.",
  },
  CASE_REPORT_RELATED_TEXT_ITEM_INVALID_NOT_MATCHED_VALUE: {
    status: 400,
    ko: "[{itemSeq}] {itemId}의 value가 참조하는 item의 값과 일치하지 않습니다.",
    en: "[{itemSeq}] {itemId} does not have a same value with a related item.",
  },
  CASE_REPORT_RELATED_TEXT_ITEM_INVALID_STRIKED_VALUE: {
    status: 400,
    ko: "[{itemSeq}] {itemId}의 value가 참조하는 item의 값이 유효하지 않습니다.",
    en: "[{itemSeq}] related item of {itemId} is invalid.",
  },
  CASE_REPORT_DATE_ITEM_INVALID_DATE_RANGE: {
    status: 400,
    ko: "[{itemSeq}] {itemId}의 value가 범위를 초과하였습니다. (range: {range})",
    en: "[{itemSeq}] {itemId} is out of range. (range: {range})",
  },
  CASE_REPORT_CREATE_INVALID_WORKFLOW_NOT_COMPLETED: {
    status: 403,
    ko: "Case report({id})의 Workflow가 완료되지 않았습니다.",
    en: "Workflow of case report({id}) is not completed.",
  },
  CASE_REPORT_HIDDEN_ITEM_INVALID_NOT_MATCHED_CONDITION: {
    status: 400,
    ko: "[{itemSeq}] {itemId}의 값이 조건과 맞지 않습니다.",
    en: "[{itemSeq}] {itemId} value does not match condition.",
  },
  CASE_REPORT_CREATE_INVALID_CASE_LOCKED: {
    status: 400,
    ko: "Case가 잠겨 있어 report를 생성할 수 없습니다.",
    en: "Cannot create report of locked case.",
  },
  CASE_REPORT_CREATE_INVALID_DUPLICATED_CONTENT: {
    status: 409,
    ko: "중복된 Content의 Report가 존재합니다.",
    en: "Existence of Report of duplicate content.",
  },
};

MvnCodeInitiate(SAFETY_CASE_REPORT_CODE);
