import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_STATUS_LANG: {
  STUDY_STATUS_DASHBOARD_PLACEHOLDER_ALL_SITE: MvnCodeFE;
  STUDY_STATUS_DASHBOARD_DROPDOWN_ENROLL: MvnCodeFE;
  STUDY_STATUS_DASHBOARD_DROPDOWN_SCREENING: MvnCodeFE;
  STUDY_STATUS_DASHBOARD_DROPDOWN_SCREENING_FAILED: MvnCodeFE;
  STUDY_STATUS_DASHBOARD_DROPDOWN_ONGOING: MvnCodeFE;
  STUDY_STATUS_DASHBOARD_DROPDOWN_DROPPED_OUT: MvnCodeFE;
  STUDY_STATUS_DASHBOARD_DROPDOWN_COMPLETED: MvnCodeFE;
} = {
  STUDY_STATUS_DASHBOARD_PLACEHOLDER_ALL_SITE: {
    ko: "전체 기관",
    en: "All Sites",
  },
  STUDY_STATUS_DASHBOARD_DROPDOWN_ENROLL: {
    ko: "등록",
    en: "Enrolled",
  },
  STUDY_STATUS_DASHBOARD_DROPDOWN_SCREENING: {
    ko: "스크리닝",
    en: "Screening",
  },
  STUDY_STATUS_DASHBOARD_DROPDOWN_SCREENING_FAILED: {
    ko: "스크리닝 탈락",
    en: "Screening Failed",
  },
  STUDY_STATUS_DASHBOARD_DROPDOWN_ONGOING: {
    ko: "진행 중",
    en: "Ongoing",
  },
  STUDY_STATUS_DASHBOARD_DROPDOWN_DROPPED_OUT: {
    ko: "중도탈락",
    en: "Dropped out",
  },
  STUDY_STATUS_DASHBOARD_DROPDOWN_COMPLETED: {
    ko: "완료",
    en: "Completed",
  },
};

MvnCodeInitiate(STUDY_STATUS_LANG);
