/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const USER_LANG: {
  USER_INFO: MvnCodeFE;
  USER_INFO_PLACEHOLDER: MvnCodeFE;
  AFFILIATION: MvnCodeFE;
  EMAIL: MvnCodeFE;
  NAME: MvnCodeFE;
  PHONE: MvnCodeFE;
  LANG: MvnCodeFE;
  TIMEZONE: MvnCodeFE;
  ACTIVATE_DATE: MvnCodeFE;
  ACTIVATE_HOUR: MvnCodeFE;
  TERMINATE_DATE: MvnCodeFE;
  TERMINATE_HOUR: MvnCodeFE;
  TABLE_COLUMN_CREATED_AT: MvnCodeFE;
  TABLE_MAIL_SEND: MvnCodeFE;
  TABLE_ACTIVE: MvnCodeFE;
  USER_ROLE: MvnCodeFE;
  ROLE: MvnCodeFE;
  USER_INFO_UPDATE_SUCC: MvnCodeFE;
  USER_INFO_UPDATE_FAIL: MvnCodeFE;
  USER_ROLE_UPDATE_SUCC: MvnCodeFE;
  USER_ROLE_UPDATE_FAIL: MvnCodeFE;
  BTN_USER_INFO_UPDATE: MvnCodeFE;
  BTN_USER_ROLE_UPDATE: MvnCodeFE;
  ADD_USER: MvnCodeFE;
  FORM_AFFILIATION_PLACEHOLDER: MvnCodeFE;
  FORM_EMAIL_PLACEHOLDER: MvnCodeFE;
  FORM_EMAIL_SEND_CHECKBOX: MvnCodeFE;
  FORM_EMAIL_DESC: MvnCodeFE;
  FORM_PASSWORD_PLACEHOLDER: MvnCodeFE;
  FORM_PASSWORD_DESC: MvnCodeFE;
  FORM_NAME_PLACEHOLDER: MvnCodeFE;
  FORM_PHONE_PLACEHOLDER: MvnCodeFE;
  ADD_USER_SUCC: MvnCodeFE;
  ADD_USER_FAIL: MvnCodeFE;
  NOTIFY_USER_CREATED_SUCC: MvnCodeFE;
  NOTIFY_USER_CREATED_FAIL: MvnCodeFE;
  NOTIFY_USER_CREATED_CONFIRM: MvnCodeFE;
  RE_NOTIFY_USER_CREATED_DIALOG_TITLE: MvnCodeFE;
  DELETE_SUCC: MvnCodeFE;
  DELETE_FAIL: MvnCodeFE;
  DELETE_POPUP_TITLE: MvnCodeFE;
  MAIL_INIT_PASSWORD_NOT_EXIST: MvnCodeFE;
  ESIGN_WARNING_REQUIRE_AUTHENTICATION: MvnCodeFE;
  ESIGN_WARNING_CHECK_WRITTEN_CORRECTLY: MvnCodeFE;
  ESIGN_WARNING_LEGAL_EFFECT_PREFIX: MvnCodeFE;
  ESIGN_WARNING_LEGAL_EFFECT_KEYWORD: MvnCodeFE;
  ESIGN_WARNING_LEGAL_EFFECT_SUFFIX: MvnCodeFE;
  ESIGN_WARNING_INFORM_FAILURE_COUNT: MvnCodeFE;
  ESIGN_WARNING_DO_NOT_SAVE: MvnCodeFE;
  USER_RESTORE_SUCC: MvnCodeFE;
  USER_RESTORE_FAIL: MvnCodeFE;
  USER_RESTORE_POPUP_TITLE: MvnCodeFE;
} = {
  USER_INFO: {
    ko: "사용자 정보",
    en: "User Infomation",
  },
  USER_INFO_PLACEHOLDER: {
    ko: "사용자 정보를 찾을 수 없습니다.",
    en: "User Infomation is missing",
  },
  AFFILIATION: {
    ko: "소속",
    en: "Affiliation",
  },
  EMAIL: {
    ko: "이메일",
    en: "E-Mail",
  },
  NAME: {
    ko: "이름",
    en: "Name",
  },
  PHONE: {
    ko: "전화번호",
    en: "Phone",
  },
  LANG: {
    ko: "사용언어",
    en: "Language",
  },
  ACTIVATE_DATE: {
    ko: "계정 사용 시작 일자",
    en: "Activate Date",
  },
  ACTIVATE_HOUR: {
    ko: "시작 시간",
    en: "Activate Hour",
  },
  TIMEZONE: {
    ko: "Time Zone",
    en: "Time Zone",
  },
  TERMINATE_DATE: {
    ko: "계정 사용 종료 일자",
    en: "Terminate Date",
  },
  TERMINATE_HOUR: {
    ko: "종료 시간",
    en: "Activate Hour",
  },
  TABLE_COLUMN_CREATED_AT: {
    ko: "생성 시각",
    en: "Created at",
  },
  TABLE_MAIL_SEND: {
    ko: "메일 전송",
    en: "Send Mail",
  },
  TABLE_ACTIVE: {
    ko: "활성여부",
    en: "Active",
  },
  ROLE: {
    ko: "Role (System / CDMS)",
    en: "Role (System / CDMS)",
  },
  USER_ROLE: {
    ko: "User Role (System / CDMS)",
    en: "User Role (System / CDMS)",
  },
  USER_INFO_UPDATE_SUCC: {
    ko: "성공적으로 User({email}) 정보를 갱신하였습니다.",
    en: "User Information has been updated successfully. ({email})",
  },
  USER_INFO_UPDATE_FAIL: {
    ko: "User 정보 갱신에 실패하였습니다. ({message})",
    en: "Failed to update User Information. ({message})",
  },
  USER_ROLE_UPDATE_SUCC: {
    ko: "성공적으로 User({email}) System/CDMS 권한을 갱신하였습니다.",
    en: "User System/CDMS privilege has been updated successfully. ({email})",
  },
  USER_ROLE_UPDATE_FAIL: {
    ko: "User System/CDMS 권한 갱신에 실패하였습니다. ({message})",
    en: "Failed to update user System/CDMS privilege. ({message})",
  },
  BTN_USER_INFO_UPDATE: {
    ko: "User Info Update",
    en: "User Info Update",
  },
  BTN_USER_ROLE_UPDATE: {
    ko: "User Role(System / CDMS) Update",
    en: "User Role(System / CDMS) Update",
  },
  ADD_USER: {
    ko: "사용자 추가",
    en: "Add New User",
  },
  FORM_AFFILIATION_PLACEHOLDER: {
    ko: "소속을 선택해 주세요.",
    en: "Please choose user's affiliation.",
  },
  FORM_EMAIL_PLACEHOLDER: {
    ko: "사용자 이메일 주소를 입력해 주세요.",
    en: "Please enter user's email address.",
  },
  FORM_EMAIL_SEND_CHECKBOX: {
    ko: "알림 이메일 발송",
    en: "Send notification email",
  },
  FORM_EMAIL_DESC: {
    ko: "이메일 주소는 로그인 정보로 사용됩니다.",
    en: "Email address will be used as user's login information.",
  },
  FORM_PASSWORD_PLACEHOLDER: {
    ko: "사용자의 임시 비밀번호를 입력해 주세요.",
    en: "Please enter user's temporary password.",
  },
  FORM_PASSWORD_DESC: {
    ko: "임시 비밀번호는 최초 로그인시에만 사용되며 반드시 변경해야 합니다.",
    en: "The temporary password is used only for the first time and must be changed.",
  },
  FORM_NAME_PLACEHOLDER: {
    ko: "사용자 이름을 입력해 주세요.",
    en: "Please enter user's name.",
  },
  FORM_PHONE_PLACEHOLDER: {
    ko: "사용자 연락처를 입력해 주세요.",
    en: "Please enter user's phone number.",
  },
  ADD_USER_SUCC: {
    ko: "신규 사용자({user})를 등록하였습니다.",
    en: "New user has been registered successfully. ({user})",
  },
  ADD_USER_FAIL: {
    ko: "신규 사용자 등록에 실패하였습니다. ({message})",
    en: "Failed to add new user. ({message})",
  },
  NOTIFY_USER_CREATED_SUCC: {
    ko: "메일 전송에 성공하였습니다.",
    en: "Mail has been sent successfully.",
  },
  NOTIFY_USER_CREATED_FAIL: {
    ko: "메일 보내기에 실패했습니다. ({message})",
    en: "Failed to send the mail. ({message})",
  },
  NOTIFY_USER_CREATED_CONFIRM: {
    ko: "메일을 전송하시겠습니까?",
    en: "Do you want to send the mail?",
  },
  RE_NOTIFY_USER_CREATED_DIALOG_TITLE: {
    ko: "이미 메일을 전송한 사용자입니다. 전송하시겠습니까?",
    en: "You have already sent the mail to this user. Do you want to send again?",
  },
  DELETE_SUCC: {
    ko: "선택한 사용자를 삭제하였습니다.",
    en: "The user has been deleted successfully.",
  },
  DELETE_FAIL: {
    ko: "사용자 삭제에 실패하였습니다.",
    en: "Failed to delete the user.",
  },
  DELETE_POPUP_TITLE: {
    ko: "선택된 사용자 삭제를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to delete the user.",
  },
  MAIL_INIT_PASSWORD_NOT_EXIST: {
    ko: "임시 비밀번호가 설정되지 않았습니다. 위의 사이트에서 이메일 입력 후 로그인을 시도하면 이메일 인증 절차가 진행됩니다.",
    en: "A temporary password has not been created. Please verify your email and continue the registration process.",
  },
  ESIGN_WARNING_REQUIRE_AUTHENTICATION: {
    ko: "아래의 내용을 확인해 주시고, 화면 하단에 사용자 아이디와 비밀번호를 입력해 주십시오.",
    en: "Confirm the following statement, then enter your ID and password.",
  },
  ESIGN_WARNING_CHECK_WRITTEN_CORRECTLY: {
    ko: "1. 본인은 본 내용이 빠짐없이 정확하게 기재되었음을 확인합니다.",
    en: "1. Confirm that the content includes the entire information correctly.",
  },
  ESIGN_WARNING_LEGAL_EFFECT_PREFIX: {
    ko: "2. 본 전자 서명은 ",
    en: "2. Hereby accept that this e-sign has ",
  },
  ESIGN_WARNING_LEGAL_EFFECT_KEYWORD: {
    ko: "본인의 수기서명과 동등한 법적 구속력을 가지고 있음",
    en: "equivalent legal weight as handwritten signatures",
  },
  ESIGN_WARNING_LEGAL_EFFECT_SUFFIX: {
    ko: "을 수락합니다.",
    en: ".",
  },
  ESIGN_WARNING_INFORM_FAILURE_COUNT: {
    ko: "3. 5번 접속 실패 시 자동 로그아웃되며 계정이 차단됩니다.",
    en: "3. Account will be locked after 5 failed passcode attempts.",
  },
  ESIGN_WARNING_DO_NOT_SAVE: {
    ko: "* 개인정보 보호를 위해 공용 PC에서는 ID/Password를 저장하지 마시기 바랍니다.",
    en: "* For security purposes, do not save ID/password on public PCs.",
  },
  USER_RESTORE_SUCC: {
    ko: "선택한 사용자를 복구하였습니다.",
    en: "The user has been restored.",
  },
  USER_RESTORE_FAIL: {
    ko: "사용자 계정 복구에 실패하였습니다. ({message})",
    en: "Failed to restore the user. ({message})",
  },
  USER_RESTORE_POPUP_TITLE: {
    ko: "선택된 사용자 복구를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to restore the user.",
  },
};

MvnCodeInitiate(USER_LANG);
