/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const UAT_CODE: {
  COMMENT_TOO_LONG: MvnCodeBE;
  COMMENT_INVALID: MvnCodeBE;
  COMMENT_NOT_FOUND: MvnCodeBE;
  COMMENT_USER_NOT_MATCH: MvnCodeBE;
  COMMENT_NOT_EXISTS: MvnCodeBE;
  STUDY_UAT_OPENED_ALREADY: MvnCodeBE;
  STUDY_UAT_NOT_OPENED: MvnCodeBE;
  STUDY_UAT_NOT_EXIST: MvnCodeBE;
  STUDY_UAT_NOT_MIGRATABLE: MvnCodeBE;
  STUDY_UAT_NOT_CLOSED: MvnCodeBE;
} = {
  COMMENT_TOO_LONG: {
    status: 400,
    ko: "Comment는 {length}자 이하로 입력해 주세요.",
    en: "Comment is too long. Please enter a comment with {length} characters or less.",
  },
  COMMENT_INVALID: {
    status: 400,
    ko: "UAT comment가 유효하지 않습니다.",
    en: "Requested study UAT comment is not valid.",
  },
  COMMENT_NOT_FOUND: {
    status: 404,
    ko: "UAT comment가 존재하지 않습니다.",
    en: "Requested study UAT comment could not be found.",
  },
  COMMENT_USER_NOT_MATCH: {
    status: 403,
    ko: "해당 코멘트의 수정 권한이 없습니다.",
    en: "No access granted to edit this comment.",
  },
  COMMENT_NOT_EXISTS: {
    status: 400,
    ko: "UAT comment 정보가 존재하지 않습니다.",
    en: "UAT comment does not exist.",
  },
  STUDY_UAT_OPENED_ALREADY: {
    status: 409,
    ko: "UAT가 이미 시작되었습니다.",
    en: "UAT has been already open.",
  },
  STUDY_UAT_NOT_OPENED: {
    status: 409,
    ko: "UAT가 시작되지 않았습니다.",
    en: "UAT has not been open.",
  },
  STUDY_UAT_NOT_EXIST: {
    status: 409,
    ko: "등록된 UAT 의견이 없습니다.",
    en: "There is no UAT item submitted.",
  },
  STUDY_UAT_NOT_MIGRATABLE: {
    status: 409,
    ko: "다음 차수로 이관할 수 없는 UAT입니다.",
    en: "The item is not migratable to the next UAT sequence.",
  },
  STUDY_UAT_NOT_CLOSED: {
    status: 409,
    ko: "UAT가 종료되지 않았습니다.",
    en: "UAT has not been closed.",
  },
};

MvnCodeInitiate(UAT_CODE);
