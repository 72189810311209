import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CAPTURE_STATUS_LANG: {
  CAPTURE_STATUS_BY_CRF_LIST_DOWNLOAD_FAIL: MvnCodeFE;
  CAPTURE_STATUS_BY_VISIT_LIST_DOWNLOAD_FAIL: MvnCodeFE;
  CAPTURE_STATUS_BY_SUBJECT_LIST_DOWNLOAD_FAIL: MvnCodeFE;
  CAPTURE_STATUS_BY_SITE_LIST_DOWNLOAD_FAIL: MvnCodeFE;
  CDMS_CAPTURE_STATUS_BULK_TITLE: MvnCodeFE;
  CDMS_CAPTURE_STATUS_BULK_DESC: MvnCodeFE;
  CDMS_CAPTURE_STATUS_BULK_PROCESSING_DESC: MvnCodeFE;
} = {
  CAPTURE_STATUS_BY_CRF_LIST_DOWNLOAD_FAIL: {
    ko: "CRF 페이지 별 상태 목록 다운로드에 실패했습니다. ({message})",
    en: "CRFs status by CRF list download has been failed. ({message})",
  },
  CAPTURE_STATUS_BY_VISIT_LIST_DOWNLOAD_FAIL: {
    ko: "Visit 별 CRF 상태 목록 다운로드에 실패했습니다. ({message})",
    en: "CRFs status by Visit list download has been failed. ({message})",
  },
  CAPTURE_STATUS_BY_SUBJECT_LIST_DOWNLOAD_FAIL: {
    ko: "대상자 별 CRF 상태 목록 다운로드에 실패했습니다. ({message})",
    en: "CRFs status by Subject list download has been failed. ({message})",
  },
  CAPTURE_STATUS_BY_SITE_LIST_DOWNLOAD_FAIL: {
    ko: "기관별 CRF 상태 목록 다운로드에 실패했습니다. ({message})",
    en: "CRFs status by Site list download has been failed. ({message})",
  },
  CDMS_CAPTURE_STATUS_BULK_TITLE: {
    ko: "안내",
    en: "Information",
  },
  CDMS_CAPTURE_STATUS_BULK_DESC: {
    ko: "일괄 처리를 진행합니다.\n확인을 누르시면 진행현황 페이지로 이동합니다.",
    en: "The batch job will be running.\nPlease click Confirm to be directed to the history page.",
  },
  CDMS_CAPTURE_STATUS_BULK_PROCESSING_DESC: {
    ko: "현재 진행중인 일괄 처리건이 있습니다.\n기존 요청건 완료 후 추가 요청건이 진행됩니다.\n확인을 누르시면 진행현황 페이지로 이동합니다.",
    en: "There is a batch job running.\nAdditional request will be running after the current one.\nPlease click Confirm to be directed to the history page. ",
  },
};

MvnCodeInitiate(STUDY_CAPTURE_STATUS_LANG);
