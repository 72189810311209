import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CRF_CODE: {
  SAFETY_CRF_CREATE_INVALID_CRF_DUPLICATED: MvnCodeBE;
  SAFETY_CRF_INVALID_PAYLOAD_MISSING: MvnCodeBE;
  SAFETY_CRF_INVALID_PAYLOAD_URL: MvnCodeBE;
  SAFETY_CRF_INVALID_UNEXTENDABLE_CRF: MvnCodeBE;
  SAFETY_CRF_INVALID_ADD_ORGANIZATION_DENIED: MvnCodeBE;
  SAFETY_CRF_INVALID_EXTENDED_EXTENSION_CRF_CANNOT_BE_REMOVED: MvnCodeBE;
  SAFETY_CRF_INVALID_EXTENDED_ENGINE_VERSION_PAYLOAD: MvnCodeBE;
  SAFETY_CRF_INVALID_ALREADY_ACTIVATE_CRF: MvnCodeBE;
  SAFETY_CRF_INVALID_ALREADY_DEACTIVATE_CRF: MvnCodeBE;
} = {
  SAFETY_CRF_CREATE_INVALID_CRF_DUPLICATED: {
    status: 409,
    ko: "이미 존재하는 Report 입니다. (name: ({name}), type: ({type}), version: ({version}))",
    en: "The report already exists. (name: ({name}), type: ({type}), version: ({version}))",
  },
  SAFETY_CRF_INVALID_PAYLOAD_MISSING: {
    status: 400,
    ko: "CRF Payload 정보가 누락되었습니다.",
    en: "CRF Payload information is missing.",
  },
  SAFETY_CRF_INVALID_PAYLOAD_URL: {
    status: 400,
    ko: "요청하신 CRF payload URL 정보가 올바르지 않습니다.",
    en: "Requested CRF payload URL is not valid.",
  },
  SAFETY_CRF_INVALID_UNEXTENDABLE_CRF: {
    status: 400,
    ko: "요청하신 CRF에는 extension 항목을 추가할 수 없습니다. ",
    en: "Requested CRF cannot add extension elements.",
  },
  SAFETY_CRF_INVALID_ADD_ORGANIZATION_DENIED: {
    status: 400,
    ko: "category가 custom인 CRF에만 organization을 할당할 수 있습니다.",
    en: "CRF with custom category can only assign organizations.",
  },
  SAFETY_CRF_INVALID_EXTENDED_EXTENSION_CRF_CANNOT_BE_REMOVED: {
    status: 400,
    ko: "이미 extend된 extension CRF를 삭제할 수 없습니다.",
    en: "Cannot delete extension CRF that has already been extended.",
  },
  SAFETY_CRF_INVALID_EXTENDED_ENGINE_VERSION_PAYLOAD: {
    status: 400,
    ko: "CRF engine version이 {version}인 CRF에는 extension CRF를 extend할 수 없습니다.",
    en: "CRF engine version {version} cannot extend extension CRF.",
  },
  SAFETY_CRF_INVALID_ALREADY_ACTIVATE_CRF: {
    status: 409,
    ko: "이미 활성화 되어있는 CRF입니다.",
    en: "The CRF is already activated.",
  },
  SAFETY_CRF_INVALID_ALREADY_DEACTIVATE_CRF: {
    status: 409,
    ko: "이미 비활성화 되어있는 CRF입니다.",
    en: "The CRF is already deactivated.",
  },
};

MvnCodeInitiate(SAFETY_CRF_CODE);
