import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CRF_LANG: {
  STUDY_CRF_SDV_BREADCRUMB: MvnCodeFE;
  STUDY_CRF_FREEZING_BREADCRUMB: MvnCodeFE;
  STUDY_CRF_ESIGN_BREADCRUMB: MvnCodeFE;
  STUDY_CRF_LOCKING_BREADCRUMB: MvnCodeFE;
  STUDY_CRF_DOWNLOAD_DISABLED: MvnCodeFE;
  STUDY_CRF_LABEL_CRF_VERSION: MvnCodeFE;
  STUDY_CRF_PLACEHOLDER_CRF_VERSION: MvnCodeFE;
  STUDY_CRF_LABEL_CRF_EFFECTIVE_DATE: MvnCodeFE;
  STUDY_CRF_PLACEHOLDER_CRF_EFFECTIVE_DATE: MvnCodeFE;
  STUDY_CRF_LABEL_EDC_VERSION: MvnCodeFE;
  STUDY_CRF_PLACEHOLDER_EDC_VERSION: MvnCodeFE;
  STUDY_CRF_LABEL_PAYLOAD: MvnCodeFE;
  STUDY_CRF_LABEL_PAYLOAD_URL: MvnCodeFE;
  STUDY_CRF_PLACEHOLDER_PAYLOAD_URL: MvnCodeFE;
  STUDY_CRF_STUDY_UPDATE: MvnCodeFE;
  STUDY_CRF_ADD_TITLE: MvnCodeFE;
  STUDY_CRF_ADD_BTN_ADD_CRF: MvnCodeFE;
  STUDY_CRF_ADD_SUCC: MvnCodeFE;
  STUDY_CRF_ADD_FAIL: MvnCodeFE;
  STUDY_CRF_MODIFY_TITLE: MvnCodeFE;
  STUDY_CRF_MODIFY_SUCC: MvnCodeFE;
  STUDY_CRF_MODIFY_FAIL: MvnCodeFE;
  STUDY_CRF_BTN_BACK_TO_CRF_LIST: MvnCodeFE;
  STUDY_CRF_ESIGN_REASON: MvnCodeFE;
  STUDY_CRF_ESIGN_REASON_PLACEHOLDER: MvnCodeFE;
  STUDY_CRF_ESIGN_CANCEL_REASON: MvnCodeFE;
  STUDY_CRF_ESIGN_CANCEL_REASON_PLACEHOLDER: MvnCodeFE;
} = {
  STUDY_CRF_SDV_BREADCRUMB: {
    ko: "등록된 대상자/사이트/Visit/CRF 별 일괄 SDV 처리를 진행하거나 조회할 수 있습니다.",
    en: "Proceed or inquire batch SDV for each registerd Subject/Site/Visit/CRF.",
  },
  STUDY_CRF_FREEZING_BREADCRUMB: {
    ko: "등록된 대상자/사이트/Visit/CRF 별 일괄 Freezing 처리를 진행하거나 조회할 수 있습니다.",
    en: "Proceed or inquire batch freezing for each registerd Subject/Site/Visit/CRF.",
  },
  STUDY_CRF_ESIGN_BREADCRUMB: {
    ko: "등록된 대상자/사이트/Visit/CRF 별 일괄 E-Sign을 진행하거나 조회할 수 있습니다.",
    en: "Proceed or inquire batch E-Sign for each registerd Subject/Site/Visit/CRF.",
  },
  STUDY_CRF_LOCKING_BREADCRUMB: {
    ko: "등록된 대상자/사이트/Visit/CRF 별 일괄 Locking 처리를 진행하거나 조회할 수 있습니다.",
    en: "Proceed or inquire batch locking for each registerd Subject/Site/Visit/CRF.",
  },
  STUDY_CRF_DOWNLOAD_DISABLED: {
    ko: "Blank CRF 다운로드 비활성화",
    en: "Blank CRF Download Disabled",
  },
  STUDY_CRF_LABEL_CRF_VERSION: {
    ko: "CRF Version",
    en: "CRF Version",
  },
  STUDY_CRF_PLACEHOLDER_CRF_VERSION: {
    ko: "CRF Version",
    en: "CRF Version",
  },
  STUDY_CRF_LABEL_EDC_VERSION: {
    ko: "EDC Version",
    en: "EDC Version",
  },
  STUDY_CRF_PLACEHOLDER_EDC_VERSION: {
    ko: "EDC Version",
    en: "EDC Version",
  },
  STUDY_CRF_LABEL_CRF_EFFECTIVE_DATE: {
    ko: "CRF Effective Date",
    en: "CRF Effective Date",
  },
  STUDY_CRF_PLACEHOLDER_CRF_EFFECTIVE_DATE: {
    ko: "CRF Effective Date",
    en: "CRF Effective Date",
  },
  STUDY_CRF_LABEL_PAYLOAD: {
    ko: "eCRF Payload",
    en: "eCRF Payload",
  },
  STUDY_CRF_LABEL_PAYLOAD_URL: {
    ko: "eCRF Payload Source URL",
    en: "eCRF Payload Source URL",
  },
  STUDY_CRF_PLACEHOLDER_PAYLOAD_URL: {
    ko: "http://...",
    en: "http://...",
  },
  STUDY_CRF_STUDY_UPDATE: {
    ko: "{studyProtocolNo}({studyName}) Study를 업데이트 하시겠습니까?",
    en: "Update {studyProtocolNo}({studyName}) study?",
  },
  STUDY_CRF_ADD_TITLE: {
    ko: "Study CRF 추가",
    en: "Add a Study CRF",
  },
  STUDY_CRF_ADD_BTN_ADD_CRF: {
    ko: "Add CRF",
    en: "Add CRF",
  },
  STUDY_CRF_ADD_SUCC: {
    ko: "성공적으로 Study CRF 정보를 추가하였습니다.",
    en: "The study CRF has been added successfully.",
  },
  STUDY_CRF_ADD_FAIL: {
    ko: "Study CRF 정보 추가에 실패하였습니다. ({message})",
    en: "Failed to add a Study CRF. ({message})",
  },
  STUDY_CRF_MODIFY_TITLE: {
    ko: "Study CRF 수정",
    en: "Modify a Study CRF",
  },
  STUDY_CRF_MODIFY_SUCC: {
    ko: "성공적으로 Study CRF 정보를 갱신하였습니다.",
    en: "The study CRF has been modified successfully.",
  },
  STUDY_CRF_MODIFY_FAIL: {
    ko: "Study CRF 정보 갱신에 실패하였습니다. ({message})",
    en: "Failed to modify a Study CRF. ({message})",
  },
  STUDY_CRF_BTN_BACK_TO_CRF_LIST: {
    ko: "Back to CRF List",
    en: "Back to CRF List",
  },
  STUDY_CRF_ESIGN_REASON: {
    ko: "서명 사유",
    en: "Reason for E-Sign",
  },
  STUDY_CRF_ESIGN_REASON_PLACEHOLDER: {
    ko: "전자서명 사유를 입력해주세요.",
    en: "Type in the reason for E-Sign.",
  },
  STUDY_CRF_ESIGN_CANCEL_REASON: {
    ko: "서명 취소 사유",
    en: "E-sign cancellation reason",
  },
  STUDY_CRF_ESIGN_CANCEL_REASON_PLACEHOLDER: {
    ko: "전자서명 취소 사유를 입력해주세요.",
    en: "Type in the reason for E-sign cancellation.",
  },
};

MvnCodeInitiate(STUDY_CRF_LANG);
