import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_QUERY_CODE: {
  STUDY_QUERY_OPEN_EXISTS: MvnCodeBE;
  STUDY_QUERY_INVALID_MSG_LENGTH: MvnCodeBE;
  CLOSABLE_NOT_EXIST: MvnCodeBE;
  ANSWER_NOT_AUTH: MvnCodeBE;
  ANSWER_IMPOSSIBLE: MvnCodeBE;
  CREATE_FAIL: MvnCodeBE;
} = {
  STUDY_QUERY_OPEN_EXISTS: {
    status: 400,
    ko: "종료되지 않은 쿼리가 있습니다.",
    en: "There is an open query.",
  },
  STUDY_QUERY_INVALID_MSG_LENGTH: {
    status: 400,
    ko: "쿼리 메시지의 길이는 최대 1024자 입니다.",
    en: "Query messages can be up to 1024 characters long.",
  },
  CLOSABLE_NOT_EXIST: {
    status: 403,
    ko: "선택한 쿼리에 대해 종료할 수 없습니다. 권한과 Role을 확인하세요.",
    en: "You cannot close the queries. Please check your role and privilege.",
  },
  ANSWER_NOT_AUTH: {
    status: 403,
    ko: "선택한 쿼리에 대해 응답할 수 없습니다. 권한과 Role을 확인하세요.",
    en: "You cannot answer the queries. Please check your role and privilege.",
  },
  ANSWER_IMPOSSIBLE: {
    status: 403,
    ko: "선택한 쿼리에 대해 응답할 수 없습니다. 응답 가능한 쿼리인지 확인하세요.",
    en: "You cannot answer the queries. Please check the queries are responsive.",
  },
  CREATE_FAIL: {
    status: 400,
    ko: "쿼리 업로드 요청에 실패하였습니다. 발행 가능한 쿼리인지 확인하세요.",
    en: "Query uploads have been failed. Please check if the queries are publishable.",
  },
};

MvnCodeInitiate(STUDY_QUERY_CODE);
