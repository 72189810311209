import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_AS2_CONNECTION_LANG: {
  SAFETY_AS2_CONNECTION_ADD_SUCC: MvnCodeFE;
  SAFETY_AS2_CONNECTION_ADD_FAIL: MvnCodeFE;
  SAFETY_AS2_CONNECTION_EDIT_SUCC: MvnCodeFE;
  SAFETY_AS2_CONNECTION_EDIT_FAIL: MvnCodeFE;
  SAFETY_AS2_CONNECTION_IDENTIFIER_IN_USE: MvnCodeFE;
  SAFETY_AS2_CONNECTION_ACCOUNTS_IN_USE: MvnCodeFE;
  SAFETY_AS2_CONNECTION_ACTIVATE_SUCC: MvnCodeFE;
  SAFETY_AS2_CONNECTION_ACTIVATE_FAIL: MvnCodeFE;
  SAFETY_AS2_CONNECTION_DEACTIVATE_SUCC: MvnCodeFE;
  SAFETY_AS2_CONNECTION_DEACTIVATE_FAIL: MvnCodeFE;
  SAFETY_AS2_CONNECTION_ACTIVATE_DIALOG: MvnCodeFE;
  SAFETY_AS2_CONNECTION_DEACTIVATE_DIALOG_001: MvnCodeFE;
  SAFETY_AS2_CONNECTION_DEACTIVATE_DIALOG_002: MvnCodeFE;
} = {
  SAFETY_AS2_CONNECTION_ADD_SUCC: {
    ko: "Gateway Connection이 성공적으로 추가되었습니다.",
    en: "Gateway Connection has been created successfully.",
  },
  SAFETY_AS2_CONNECTION_ADD_FAIL: {
    ko: "Gateway Connection 추가에 실패했습니다. ({message})",
    en: "Failed to create Gateway Connection. ({message})",
  },
  SAFETY_AS2_CONNECTION_EDIT_SUCC: {
    ko: "Gateway Connection이 성공적으로 수정되었습니다.",
    en: "Gateway Connection has been modified successfully.",
  },
  SAFETY_AS2_CONNECTION_EDIT_FAIL: {
    ko: "Gateway Connection 수정에 실패했습니다. ({message})",
    en: "Failed to modify Gateway Connection. ({message})",
  },
  SAFETY_AS2_CONNECTION_IDENTIFIER_IN_USE: {
    ko: "이미 사용되고 있는 Connection Identifier입니다.",
    en: "Identifier is already in use.",
  },
  SAFETY_AS2_CONNECTION_ACCOUNTS_IN_USE: {
    ko: "Host Account와 Partner Account가 동일하게 입력되어 있는 Connection이 있습니다.",
    en: "There is a Gateway Connection with same Host Account and Partner Account.",
  },
  SAFETY_AS2_CONNECTION_ACTIVATE_SUCC: {
    ko: "Gateway Connection이 활성화되었습니다.",
    en: "Gateway Connection has been activated successfully.",
  },
  SAFETY_AS2_CONNECTION_ACTIVATE_FAIL: {
    ko: "Gateway Connection 활성화에 실패했습니다. ({message})",
    en: "Failed to activate Gateway Connection. ({message})",
  },
  SAFETY_AS2_CONNECTION_DEACTIVATE_SUCC: {
    ko: "Gateway Connection이 비활성화되었습니다.",
    en: "Gateway Connection has been deactivated successfully.",
  },
  SAFETY_AS2_CONNECTION_DEACTIVATE_FAIL: {
    ko: "Gateway Connection 비활성화에 실패했습니다. ({message})",
    en: "Failed to deactivate Gateway Connection. ({message})",
  },
  SAFETY_AS2_CONNECTION_ACTIVATE_DIALOG: {
    ko: "Gateway Connection을 활성화 하시겠습니까?",
    en: "Are you sure you want to activate Gateway Connection?",
  },
  SAFETY_AS2_CONNECTION_DEACTIVATE_DIALOG_001: {
    ko: "Gateawy Connection을 비활성화 하시겠습니까?",
    en: "Are you sure you want to deactivate Gatweay Account?",
  },
  SAFETY_AS2_CONNECTION_DEACTIVATE_DIALOG_002: {
    ko: "Gateway Connection이 비활성화되면 Account 정보를 사용하고 있는 Gateway에 영향을 끼칠 수 있습니다.",
    en: "If Gateway Connection is deactivated, this may affect the Gateway that is using the account information.",
  },
};

MvnCodeInitiate(SAFETY_AS2_CONNECTION_LANG);
