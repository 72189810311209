import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_SETTINGS_LANG: {
  BUILDER_STUDY_SETTINGS_GENERAL_GROUP_NUMBER_TOOLTIP: MvnCodeFE;
  BUILDER_STUDY_PROPERTY_GENERAL_SAVE_FAIL: MvnCodeFE;
} = {
  BUILDER_STUDY_SETTINGS_GENERAL_GROUP_NUMBER_TOOLTIP: {
    ko: "번호 매김할 그룹을 설정합니다. (e.g. SITE로 설정할 경우, 기관이 다른 대상자는 숫자가 Start value부터 다시 부여됩니다.)",
    en: "Set the numbering group. (e.g. When set to SITE, subjects enrolled to a new site restart their numbering from the start value.)",
  },
  BUILDER_STUDY_PROPERTY_GENERAL_SAVE_FAIL: {
    ko: "저장에 실패했습니다.",
    en: "Failed to save the page.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_SETTINGS_LANG);
