import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_USER_LANG: {
  CDMS_STUDY_USER_ROLE_NOT_FOUND_ERROR: MvnCodeFE;
  CDMS_STUDY_USER_UNASSIGNED_ROLE_ACCESS_ERROR: MvnCodeFE;
} = {
  CDMS_STUDY_USER_ROLE_NOT_FOUND_ERROR: {
    ko: "존재하지 않는 Role로 접근하였습니다. Study List 화면으로 이동합니다. ({message})",
    en: "You have accessed the study with a role that does not exist. Returning to the study list. ({message})",
  },
  CDMS_STUDY_USER_UNASSIGNED_ROLE_ACCESS_ERROR: {
    ko: "부여받지 않은 Role로 접근하였습니다. Study List 화면으로 이동합니다. ({message})",
    en: "You have accessed the study with a role not assigned. Returning to the study list. ({message})",
  },
};

MvnCodeInitiate(CDMS_STUDY_USER_LANG);
