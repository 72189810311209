import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SSO_AUTH_TOKEN_CODE: {
  SSO_AUTH_TOKEN_CREATE_INVALID_ID_PASSWORD: MvnCodeBE;
  SSO_AUTH_TOKEN_CREATE_INVALID_USER_TYPE: MvnCodeBE;
  SSO_AUTH_TOKEN_CREATE_INVALID_INIT_PASSWORD_BY_EMAIL_VERIFICATION: MvnCodeBE;
  SSO_AUTH_TOKEN_CREATE_INVALID_INIT_PASSWORD: MvnCodeBE;
  SSO_AUTH_TOKEN_CREATE_INVALID_USER_LOCKED_BY_INVALID_PASSWORD: MvnCodeBE;
  SSO_AUTH_TOKEN_CREATE_INVALID_PASSWORD: MvnCodeBE;
  SSO_AUTH_TOKEN_CREATE_INVALID_PASSWORD_EXPIRED: MvnCodeBE;
  SSO_AUTH_TOKEN_CREATE_INVALID_DISABLE: MvnCodeBE;
  SSO_AUTH_TOKEN_CREATE_INVALID_DESTROYED: MvnCodeBE;
  SSO_AUTH_TOKEN_CREATE_INVALID_PREVIOUS_TOKEN_EXIST: MvnCodeBE;
  SSO_AUTH_TOKEN_VERIFY_INVALID: MvnCodeBE;
  SSO_AUTH_TOKEN_VERIFY_INVALID_NOT_ACTIVE: MvnCodeBE;
  SSO_AUTH_TOKEN_VERIFY_INVALID_EXPIRED: MvnCodeBE;
} = {
  SSO_AUTH_TOKEN_CREATE_INVALID_ID_PASSWORD: {
    status: 400,
    ko: "이메일 또는 비밀번호가 유효하지 않습니다.",
    en: "The email or password is invalid.",
  },
  SSO_AUTH_TOKEN_CREATE_INVALID_USER_TYPE: {
    status: 400,
    ko: "로그인 할 수 없는 사용자 유형입니다.",
    en: "The account is not allowed to sign in due to the user type.",
  },
  SSO_AUTH_TOKEN_CREATE_INVALID_INIT_PASSWORD_BY_EMAIL_VERIFICATION: {
    status: 400,
    ko: "초기 비밀번호가 설정되지 않았습니다. 비밀번호를 설정해주십시오.",
    en: "No initial password has been set. Please set your password.",
  },
  SSO_AUTH_TOKEN_CREATE_INVALID_INIT_PASSWORD: {
    status: 400,
    ko: "초기 비밀번호가 설정되지 않았습니다. 비밀번호를 설정해주십시오.",
    en: "No initial password has been set. Please set your password.",
  },
  SSO_AUTH_TOKEN_CREATE_INVALID_USER_LOCKED_BY_INVALID_PASSWORD: {
    code: "USER-LOCKED",
    status: 400,
    ko: "비밀번호 5회 입력 실패로 비밀번호 재설정이 필요합니다. 이메일을 확인해 주세요.",
    en: "Password reset required because Exceed Max Limit of Password validation. Please check your email.",
  },
  SSO_AUTH_TOKEN_CREATE_INVALID_PASSWORD: {
    code: "INVALID-PASSWORD",
    status: 400,
    ko: "이메일 또는 비밀번호가 올바르지 않습니다. ({failureCount} / {maxFailureCount})",
    en: "The email or password is invalid.  ({failureCount} / {maxFailureCount})",
  },
  SSO_AUTH_TOKEN_CREATE_INVALID_PASSWORD_EXPIRED: {
    code: "PASSWORD-CHANGE-CYCLE-EXCEED",
    status: 400,
    ko: "비밀번호 변경 이후 ({maxPasswordChangeCycle})일이 지났습니다. 비밀번호를 재설정해주십시오.",
    en: "({maxPasswordChangeCycle}) days have passed since last password change. Please reset your password.",
  },
  SSO_AUTH_TOKEN_CREATE_INVALID_DISABLE: {
    code: "USER-INVALID",
    status: 409,
    ko: "계정 정보가 유효하지 않습니다. 관리자에게 확인 바랍니다. (CODE: DI-01)",
    en: "Your account is not valid. Please contact system administrator. (CODE: DI-01)",
  },
  SSO_AUTH_TOKEN_CREATE_INVALID_DESTROYED: {
    status: 409,
    ko: "계정 정보가 유효하지 않습니다. 관리자에게 확인 바랍니다. (CODE: DE-02)",
    en: "Your account is not valid. Please contact system administrator. (CODE: DE-02)",
  },
  SSO_AUTH_TOKEN_CREATE_INVALID_PREVIOUS_TOKEN_EXIST: {
    status: 400,
    ko: "해당 계정으로 이미 로그인 한 사용자가 있습니다.",
    en: "This Account is already logged in.",
  },
  SSO_AUTH_TOKEN_VERIFY_INVALID: {
    code: "CREDENTIAL-INVALID",
    status: 400,
    ko: "로그인 혹은 세션 정보가 유효하지 않습니다",
    en: "Invalid login or session information.",
  },
  SSO_AUTH_TOKEN_VERIFY_INVALID_NOT_ACTIVE: {
    code: "CREDENTIAL-INVALID",
    status: 400,
    ko: "토큰이 비활성화 상태입니다.",
    en: "Token inactive.",
  },
  SSO_AUTH_TOKEN_VERIFY_INVALID_EXPIRED: {
    code: "CREDENTIAL-INVALID",
    status: 400,
    ko: "로그인 세션이 만료되었습니다.",
    en: "Your login session has expired.",
  },
};

MvnCodeInitiate(SSO_AUTH_TOKEN_CODE);
