import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_LAB_TEST_CODE: {
  NOTFOUND: MvnCodeBE;
} = {
  NOTFOUND: {
    status: 404,
    ko: "찾는 실험실 검사 항목이 없습니다.",
    en: "There is no lab test found.",
  },
};

MvnCodeInitiate(STUDY_LAB_TEST_CODE);
