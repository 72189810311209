import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const ECOA_ACCESS_TOKEN_CODE: {
  ECOA_ACCESS_TOKEN_NOT_FOUND: MvnCodeBE;
} = {
  ECOA_ACCESS_TOKEN_NOT_FOUND: {
    status: 400,
    ko: "유효한 엑세스 토큰이 없습니다.",
    en: "Access Token could not be found.",
  },
};

MvnCodeInitiate(ECOA_ACCESS_TOKEN_CODE);
