import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SSO_USER_LANG: {
  SSO_USER_INIT_PASSWORD_DIALOG_TITLE: MvnCodeFE;
  SSO_USER_CHANGE_EXPIRED_PASSWORD_DIALOG_TITLE: MvnCodeFE;
  SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_LABEL: MvnCodeFE;
  SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_DESC: MvnCodeFE;
  SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_WARN: MvnCodeFE;
  SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_PLACEHOLDER: MvnCodeFE;
  SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_LABEL: MvnCodeFE;
  SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_DESC: MvnCodeFE;
  SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_PLACEHOLDER: MvnCodeFE;
  SSO_USER_CHANGE_PASSWORD_FAIL: MvnCodeFE;
  SSO_USER_EMAIL_VERIFY_SUCCEED: MvnCodeFE;
  SSO_USER_EMAIL_VERIFY_FAIL: MvnCodeFE;
} = {
  SSO_USER_INIT_PASSWORD_DIALOG_TITLE: {
    ko: "초기 비밀번호 변경을 위해 새로운 비밀번호를 입력해주세요.",
    en: "Please enter a new password for initial setup.",
  },
  SSO_USER_CHANGE_EXPIRED_PASSWORD_DIALOG_TITLE: {
    ko: "이전 비밀번호 변경 후 {maxPasswordChangeCycle}일이 지났습니다. 새로운 비밀번호를 입력해주세요.",
    en: "{maxPasswordChangeCycle}days have passed since the last password reset. Please reset your password.",
  },
  SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_LABEL: {
    ko: "비밀번호",
    en: "Password",
  },
  SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_DESC: {
    ko: "비밀번호는 최소 8글자 이상, 1개 이상의 숫자, 영문, 특수문자를 포함해야 합니다.",
    en: "Password must contain at least 8 characters, 1 number, 1 English and 1 symbol.",
  },
  SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_WARN: {
    ko: "이메일과 동일한 비밀번호로 변경할 수 없습니다.",
    en: "You can not change with the same password as your email",
  },
  SSO_USER_CHANGE_PASSWORD_DIALOG_NEW_PASSWORD_PLACEHOLDER: {
    ko: "새로운 비밀번호를 입력해주세요.",
    en: "Please enter a new password.",
  },
  SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_LABEL: {
    ko: "비밀번호 확인",
    en: "Confirm Password",
  },
  SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_DESC: {
    ko: "비밀번호가 일치하지 않습니다.",
    en: "Password does not match.",
  },
  SSO_USER_CHANGE_PASSWORD_DIALOG_CONFIRM_PASSWORD_PLACEHOLDER: {
    ko: "새로운 비밀번호를 한번 더 입력해주세요.",
    en: "Please confirm a new password.",
  },
  SSO_USER_EMAIL_VERIFY_SUCCEED: {
    ko: "인증코드가 확인되었습니다. 비밀번호를 변경해 주세요.",
    en: "Verified. Please change password.",
  },
  SSO_USER_EMAIL_VERIFY_FAIL: {
    ko: "이메일 인증에 실패하였습니다. ({message})",
    en: "Email verification has been failed. ({message})",
  },
  SSO_USER_CHANGE_PASSWORD_FAIL: {
    ko: "비밀번호 변경을 실패했습니다. ({message})",
    en: "Failed to change password. ({message})",
  },
};

MvnCodeInitiate(SSO_USER_LANG);
