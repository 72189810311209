/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const DOCS_FOLDER_CODE: {
  DOCS_FOLDER_FILE_ACCESS_DENIED_IP_WHITELIST: MvnCodeBE;
  DOCS_FOLDER_FILE_VERSION_INVALID: MvnCodeBE;
  DOCS_FOLDER_FILES_COUNT_EXCEED: MvnCodeBE;
  DOCS_FOLDER_FILES_EXIST_CANNOT_REMOVE: MvnCodeBE;
  DOCS_FOLDER_SHARING_INHERITED: MvnCodeBE;
  DOCS_FOLDER_SHARING_EMAIL_INVALID: MvnCodeBE;
  DOCS_FOLDER_SHARING_EMAIL_MEMBER: MvnCodeBE;
  DOCS_FOLDER_SHARING_EMAIL_DUPLICATED: MvnCodeBE;
  DOCS_FOLDER_SHARING_ACCESS_DENIED_NOT_FOUND: MvnCodeBE;
  DOCS_FOLDER_SHARING_ACCESS_DENIED_MEMBER: MvnCodeBE;
  DOCS_FOLDER_SHARING_ACCESS_DENIED: MvnCodeBE;
  DOCS_FOLDER_SHARING_ACCESS_TOKEN_INVALID: MvnCodeBE;
  DOCS_FOLDER_SHARING_ACCESS_TOKEN_EXPIRED: MvnCodeBE;
  DOCS_FOLDER_SHARING_NOT_AUTHORIZED: MvnCodeBE;
  DOCS_FOLDER_SHARING_ACCESS_PERMISSION_DENIED: MvnCodeBE;
} = {
  DOCS_FOLDER_FILE_ACCESS_DENIED_IP_WHITELIST: {
    status: 403,
    ko: "해당 파일은 접속하신 장소({ip})에서 접근이 허용되지 않습니다.",
    en: "The requested file is not allowed access from the current location. ({ip})",
  },
  DOCS_FOLDER_FILE_VERSION_INVALID: {
    status: 400,
    ko: "버전이 올바르지 않습니다. 버전은 1.0 형태로 입력할 수 있습니다.",
    en: "The version is not valid. Version can be entered like 1.0.",
  },
  DOCS_FOLDER_FILES_COUNT_EXCEED: {
    status: 409,
    ko: "현재 폴더에서 최대 개수({cnt}개)의 항목을 이미 생성했습니다.",
    en: "You have already created maximum numbers({cnt}) of items in the current folder.",
  },
  DOCS_FOLDER_FILES_EXIST_CANNOT_REMOVE: {
    status: 409,
    ko: "하위 항목이 있는 폴더는 삭제할 수 없습니다.",
    en: "You cannot delete a folder that sub-items have been added.",
  },
  DOCS_FOLDER_SHARING_INHERITED: {
    status: 409,
    ko: "상위 리소스에서 이미 공유 설정이 되어있습니다.",
    en: "The specified resource already shared from parent resource.",
  },
  DOCS_FOLDER_SHARING_EMAIL_INVALID: {
    status: 400,
    ko: "공유할 이메일이 올바르지 않습니다. ({email})",
    en: "The specified email address is not valid. ({email})",
  },
  DOCS_FOLDER_SHARING_EMAIL_MEMBER: {
    status: 409,
    ko: "Organization Member는 추가할 수 없습니다. ({email})",
    en: "You cannot add a user which is a member of organization. ({email})",
  },
  DOCS_FOLDER_SHARING_EMAIL_DUPLICATED: {
    status: 400,
    ko: "이미 추가된 이메일입니다. ({email})",
    en: "The specified email address is registered already. ({email})",
  },
  DOCS_FOLDER_SHARING_ACCESS_DENIED_NOT_FOUND: {
    status: 401,
    ko: "접속 URL이 올바르지 않습니다.",
    en: "The access URL is not valid.",
  },
  DOCS_FOLDER_SHARING_ACCESS_DENIED_MEMBER: {
    status: 401,
    ko: "Organization Memeber로 초대된 사용자는 로그인 후 My Documents에서 파일을 열람할 수 있습니다.",
    en: "You can view shared files in My Documents after signing in.",
  },
  DOCS_FOLDER_SHARING_ACCESS_DENIED: {
    status: 401,
    ko: "인증 정보가 올바르지 않습니다. 접속 URL 및 권한을 다시 확인해 주세요.",
    en: "The access token is not valid. Please check the access URL and permission again.",
  },
  DOCS_FOLDER_SHARING_ACCESS_TOKEN_INVALID: {
    status: 400,
    ko: "인증 정보가 올바르지 않습니다.",
    en: "The requested access token is not valid.",
  },
  DOCS_FOLDER_SHARING_ACCESS_TOKEN_EXPIRED: {
    status: 409,
    ko: "인증 정보가 만료되었습니다.",
    en: "The access token has been expired.",
  },
  DOCS_FOLDER_SHARING_NOT_AUTHORIZED: {
    status: 403,
    ko: "사용 권한이 없습니다.\n({type})",
    en: "Access Denied.\n({type})",
  },
  DOCS_FOLDER_SHARING_ACCESS_PERMISSION_DENIED: {
    status: 403,
    ko: "접근 권한이 없는 항목입니다.",
    en: "You do not have permission to access the item.",
  },
};

MvnCodeInitiate(DOCS_FOLDER_CODE);
