import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const AUTH_USER_CODE: {
  USER_SIGNUP_RESTRICTED_IP: MvnCodeBE;
  USER_SIGNUP_RESTRICTED_DOMAIN: MvnCodeBE;
} = {
  USER_SIGNUP_RESTRICTED_IP: {
    status: 409,
    ko: "요청하신 IP({ip})에서는 사용이 제한되어 있습니다. [{env}]",
    en: "Access has restricted on the requested IP({ip}). [{env}]",
  },
  USER_SIGNUP_RESTRICTED_DOMAIN: {
    status: 409,
    ko: "입력하신 도메인({domain})은 회원가입이 제한되어 있습니다. [{env}]",
    en: "Sign up with specified domain({domain}) has restricted. [{env}]",
  },
};

MvnCodeInitiate(AUTH_USER_CODE);
