import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const TMF_STUDY_USER_LANG: {
  TMF_STUDY_USER_MAIL_INVITATION_TITLE: MvnCodeFE;
  TMF_STUDY_USER_MAIL_INVITATION_CONTENT_INVITED: MvnCodeFE;
  TMF_STUDY_USER_MAIL_INVITATION_CONTENT_JOIN: MvnCodeFE;
} = {
  TMF_STUDY_USER_MAIL_INVITATION_TITLE: {
    ko: "Maven TMF에 당신을 초대합니다",
    en: "You've been invited to Maven TMF",
  },
  TMF_STUDY_USER_MAIL_INVITATION_CONTENT_INVITED: {
    ko: "{name} ({email})님의 초대가 도착했습니다.",
    en: "{name} ({email}) has invited you.",
  },
  TMF_STUDY_USER_MAIL_INVITATION_CONTENT_JOIN: {
    ko: "Join 버튼을 클릭하여 Maven TMF를 사용할 수 있습니다.",
    en: "Please join the Maven TMF by clicking the Join button.",
  },
};

MvnCodeInitiate(TMF_STUDY_USER_LANG);
