/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_REPORT_CODE: {
  TMF_STUDY_REPORT_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_REPORT_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_REPORT_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_REPORT_NAME_DUPLICATED: MvnCodeBE;

  TMF_STUDY_REPORT_SHARING_SCOPE_MISSING: MvnCodeBE;
  TMF_STUDY_REPORT_SHARING_SCOPE_INVALID: MvnCodeBE;

  TMF_STUDY_REPORT_DOCUMENT_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_REPORT_DOCUMENT_NAME_TOO_LONG: MvnCodeBE;

  TMF_STUDY_REPORT_DOCUMENT_STATUS_INVALID: MvnCodeBE;

  TMF_STUDY_REPORT_DOCUMENT_CONVENTION_DATE_FROM_INVALID: MvnCodeBE;
  TMF_STUDY_REPORT_DOCUMENT_CONVENTION_DATE_TO_INVALID: MvnCodeBE;
  TMF_STUDY_REPORT_DOCUMENT_CONVENTION_DATE_FROM_LARGER: MvnCodeBE;

  TMF_STUDY_REPORT_DOCUMENT_RECENT_UPLOAD_DATE_FROM_INVALID: MvnCodeBE;
  TMF_STUDY_REPORT_DOCUMENT_RECENT_UPLOAD_DATE_TO_INVALID: MvnCodeBE;
  TMF_STUDY_REPORT_DOCUMENT_RECENT_UPLOAD_DATE_FROM_LARGER: MvnCodeBE;

  TMF_STUDY_REPORT_DOCUMENT_OWNER_ID_INVALID: MvnCodeBE;
  TMF_STUDY_REPORT_DOCUMENT_SHARING_USER_ID_INVALID: MvnCodeBE;
} = {
  TMF_STUDY_REPORT_NAME_MISSING: {
    code: "TMF_STUDY_REPORT_NAME_MISSING",
    status: 400,
    ko: "TMF Report 이름이 입력되지 않았습니다.",
    en: "TMF Report Name is missing."
  },
  TMF_STUDY_REPORT_NAME_TOO_SHORT: {
    code: "TMF_STUDY_REPORT_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Report 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Report name must be at least {length} characters long.",
  },
  TMF_STUDY_REPORT_NAME_TOO_LONG: {
    code: "TMF_STUDY_REPORT_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Report 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Report name can be up to {length} characters long.",
  },
  TMF_STUDY_REPORT_NAME_DUPLICATED: {
    code: "TMF_STUDY_REPORT_NAME_DUPLICATED",
    status: 409,
    ko: "TMF Report 이름({name})이 중복되었습니다.",
    en: "TMF Report name({name}) is duplicated.",
  },

  TMF_STUDY_REPORT_SHARING_SCOPE_MISSING: {
    code: "TMF_STUDY_REPORT_SHARING_SCOPE_MISSING",
    status: 400,
    ko: "TMF Report Sharing Scope이 누락되었습니다.",
    en: "TMF Report Sharing Scope is missing."
  },
  TMF_STUDY_REPORT_SHARING_SCOPE_INVALID: {
    code: "TMF_STUDY_REPORT_SHARING_SCOPE_INVALID",
    status: 400,
    ko: "TMF Document Sharing Cope({sharingScope})가 잘못되었습니다.",
    en: "TMF Document Sharing Cope({sharingScope}) is invalid."
  },

  TMF_STUDY_REPORT_DOCUMENT_NAME_TOO_SHORT: {
    code: "TMF_STUDY_REPORT_DOCUMENT_NAME_TOO_SHORT",
    status: 400,
    ko: "TMF Report Document 이름의 길이는 최소 {length}자 이상입니다.",
    en: "TMF Report Document name must be at least {length} characters long.",
  },
  TMF_STUDY_REPORT_DOCUMENT_NAME_TOO_LONG: {
    code: "TMF_STUDY_REPORT_DOCUMENT_NAME_TOO_LONG",
    status: 400,
    ko: "TMF Report Document 이름의 길이는 최대 {length}자 입니다.",
    en: "TMF Report Document name can be up to {length} characters long.",
  },

  TMF_STUDY_REPORT_DOCUMENT_STATUS_INVALID: {
    code: "TMF_STUDY_REPORT_DOCUMENT_STATUS_INVALID",
    status: 400,
    ko: "TMF Document Status({status})가 잘못되었습니다.",
    en: "TMF Document Status({status}) is invalid."
  },

  TMF_STUDY_REPORT_DOCUMENT_CONVENTION_DATE_FROM_INVALID: {
    code: "TMF_STUDY_REPORT_DOCUMENT_CONVENTION_DATE_FROM_INVALID",
    status: 400,
    ko: "TMF Document Date From ({date})가 잘못되었습니다.",
    en: "TMF Document Date From ({date}) is invalid."
  },
  TMF_STUDY_REPORT_DOCUMENT_CONVENTION_DATE_TO_INVALID: {
    code: "TMF_STUDY_REPORT_DOCUMENT_CONVENTION_DATE_TO_INVALID",
    status: 400,
    ko: "TMF Document Date To ({date})가 잘못되었습니다.",
    en: "TMF Document Date To ({date}) is invalid."
  },
  TMF_STUDY_REPORT_DOCUMENT_CONVENTION_DATE_FROM_LARGER: {
    code: "TMF_STUDY_REPORT_DOCUMENT_CONVENTION_DATE_FROM_LARGER",
    status: 400,
    ko: "TMF Document Date From ({fromDate})이 Date To ({toDate}) 보다 큽니다.",
    en: "TMF Document Date From ({fromDate}) is larger than Date To ({toDate})."
  },

  TMF_STUDY_REPORT_DOCUMENT_RECENT_UPLOAD_DATE_FROM_INVALID: {
    code: "TMF_STUDY_REPORT_DOCUMENT_RECENT_UPLOAD_DATE_FROM_INVALID",
    status: 400,
    ko: "TMF Document Create Date From ({date})가 잘못되었습니다.",
    en: "TMF Document Create Date From ({date}) is invalid."
  },
  TMF_STUDY_REPORT_DOCUMENT_RECENT_UPLOAD_DATE_TO_INVALID: {
    code: "TMF_STUDY_REPORT_DOCUMENT_RECENT_UPLOAD_DATE_TO_INVALID",
    status: 400,
    ko: "TMF Document Create Date To ({date})가 잘못되었습니다.",
    en: "TMF Document Create Date To ({date}) is invalid."
  },
  TMF_STUDY_REPORT_DOCUMENT_RECENT_UPLOAD_DATE_FROM_LARGER: {
    code: "TMF_STUDY_REPORT_DOCUMENT_RECENT_UPLOAD_DATE_FROM_LARGER",
    status: 400,
    ko: "TMF Document Create Date From ({fromDate})이 Date To ({toDate}) 보다 큽니다.",
    en: "TMF Document Create Date From ({fromDate}) is larger than Date To ({toDate})."
  },

  TMF_STUDY_REPORT_DOCUMENT_OWNER_ID_INVALID: {
    code: "TMF_STUDY_REPORT_DOCUMENT_OWNER_ID_INVALID",
    status: 400,
    ko: "TMF Document Owner({ownerId})가 잘못되었습니다.",
    en: "TMF Document Owner({ownerId}) is invalid."
  },
  TMF_STUDY_REPORT_DOCUMENT_SHARING_USER_ID_INVALID: {
    code: "TMF_STUDY_REPORT_DOCUMENT_SHARING_USER_ID_INVALID",
    status: 400,
    ko: "TMF Document Sharing User({sharingUserId})가 잘못되었습니다.",
    en: "TMF Document Sharing User({sharingUserId}) is invalid."
  },
};

MvnCodeInitiate(TMF_STUDY_REPORT_CODE);
