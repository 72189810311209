/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const TMF_STUDY_SITE_CODE: {
  TMF_STUDY_SITE_NOT_FOUND: MvnCodeBE;
  TMF_STUDY_SITE_ID_MISSING: MvnCodeBE;
  TMF_STUDY_SITE_EXIST: MvnCodeBE;
  TMF_STUDY_SITE_NOT_EXIST: MvnCodeBE;
  TMF_STUDY_SITE_DUPLICATED: MvnCodeBE;
  TMF_STUDY_SITE_NAME_MISSING: MvnCodeBE;
  TMF_STUDY_SITE_NAME_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_SITE_NAME_TOO_LONG: MvnCodeBE;
  TMF_STUDY_SITE_NAME_DUPLICATED: MvnCodeBE;
  TMF_STUDY_SITE_CODE_MISSING: MvnCodeBE;
  TMF_STUDY_SITE_CODE_INVALID: MvnCodeBE;
  TMF_STUDY_SITE_CODE_TOO_SHORT: MvnCodeBE;
  TMF_STUDY_SITE_CODE_TOO_LONG: MvnCodeBE;
  TMF_STUDY_SITE_CODE_DUPLICATED: MvnCodeBE;

  TMF_STUDY_SITE_REMOVE_INVALID_IN_USE: MvnCodeBE;
} = {
  TMF_STUDY_SITE_NOT_FOUND: {
    code: "TMF_STUDY_SITE_NOT_FOUND",
    status: 404,
    ko: "요청하신 Study site({id}) 정보를 찾을 수 없습니다.",
    en: "Requested study site({id}) could not be found.",
  },
  TMF_STUDY_SITE_ID_MISSING: {
    code: "TMF_STUDY_SITE_ID_MISSING",
    status: 400,
    ko: "studySiteId({id})가 입력되지 않았습니다.",
    en: "studySiteId({id}) is missing.",
  },
  TMF_STUDY_SITE_EXIST: {
    code: "TMF_STUDY_SITE_EXIST",
    status: 409,
    ko: "Study site({id})가 이미 존재합니다.",
    en: "Study site({id}) already exists.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_SITE_NOT_EXIST: {
    code: "TMF_STUDY_SITE_NOT_EXIST",
    status: 409,
    ko: "Study site({id})가 존재하지 않습니다.",
    en: "Study site({id}) dose not exists.", // TODO: 번역 확인 필요
  },

  TMF_STUDY_SITE_NAME_MISSING: {
    code: "TMF_STUDY_SITE_NAME_MISSING",
    status: 400,
    ko: "Study Site 이름이 입력되지 않았습니다.",
    en: "Study Site Name is missing."
  },
  TMF_STUDY_SITE_NAME_TOO_SHORT: {
    code: "TMF_STUDY_SITE_NAME_TOO_SHORT",
    status: 400,
    ko: "Study Site 이름의 길이는 최소 {length}자 이상입니다.",
    en: "Study Site name must be at least {length} characters long.",
  },
  TMF_STUDY_SITE_NAME_TOO_LONG: {
    code: "TMF_STUDY_SITE_NAME_TOO_LONG",
    status: 400,
    ko: "Study Site 이름의 길이는 최대 {length}자 입니다.",
    en: "Study Site name can be up to {length} characters long.",
  },

  TMF_STUDY_SITE_CODE_MISSING: {
    code: "TMF_STUDY_SITE_CODE_MISSING",
    status: 400,
    ko: "Study Site Code가 입력되지 않았습니다.",
    en: "Study Site Code is missing."
  },
  TMF_STUDY_SITE_CODE_INVALID: {
    code: "TMF_STUDY_SITE_CODE_INVALID",
    status: 400,
    ko: "Study Site Code({code})가 잘못되었습니다.",
    en: "Study Site Code({code}) is invalid."
  },
  TMF_STUDY_SITE_CODE_TOO_SHORT: {
    code: "TMF_STUDY_SITE_CODE_TOO_SHORT",
    status: 400,
    ko: "Study Site Code 길이는 최소 {length}자 이상입니다.",
    en: "Study Site Code must be at least {length} characters long.",
  },
  TMF_STUDY_SITE_CODE_TOO_LONG: {
    code: "TMF_STUDY_SITE_CODE_TOO_LONG",
    status: 400,
    ko: "Study Site Code 길이는 최대 {length}자 입니다.",
    en: "Study Site Code can be up to {length} characters long.",
  },

  TMF_STUDY_SITE_DUPLICATED: {
    code: "TMF_STUDY_SITE_DUPLICATED",
    status: 409,
    ko: "중복된 Study site({id})가 존재합니다.",
    en: "Study site({id}) is duplicated.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_SITE_CODE_DUPLICATED: {
    code: "TMF_STUDY_SITE_CODE_DUPLICATED",
    status: 409,
    ko: "중복된 Study site code({code})가 존재합니다.",
    en: "Study site code({code}) is duplicated.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_SITE_NAME_DUPLICATED: {
    code: "TMF_STUDY_SITE_NAME_DUPLICATED",
    status: 409,
    ko: "중복된 Study site 이름({name})가 존재합니다.",
    en: "Study site name({name}) is duplicated.", // TODO: 번역 확인 필요
  },
  TMF_STUDY_SITE_REMOVE_INVALID_IN_USE: {
    code: "TMF_STUDY_SITE_REMOVE_INVALID_IN_USE",
    status: 400,
    ko: "사용중인 Study Site는 삭제할 수 없습니다.",
    en: "Can not remove study site in use.",
  },
};

MvnCodeInitiate(TMF_STUDY_SITE_CODE);
