/* eslint-disable @typescript-eslint/no-explicit-any */

import { snakeCasedObject } from "./HttpRequest";

declare type ClassType<T> = {
  new (...args: any[]): T;
};

interface ParamBaseOptions<T = string | number | boolean | object> {
  required?: boolean;
  default?: any;
  invalid?: string | ((v: T) => string);
  missing?: string | (() => string);
  delimiter?: string;
  validate?: (v: T) => boolean | void;
}

interface ParamClassOptions extends ParamBaseOptions {
  type?: ClassType<any> | BigIntConstructor;
}

interface ParamEnumOptions<
  T extends {
    [key: string]: string | number;
  }
> extends ParamBaseOptions {
  type: "ENUM";
  enum: T;
}

export declare type ParamOptions<
  T extends {
    [key: string]: string | number;
  } = any
> = ParamClassOptions | ParamEnumOptions<T>;

export type ParamArgs = [string, ParamOptions];

export class HttpResponder {
  static withStatus: (code: any) => (binds: any) => HttpResponder;
}

export type RestUrl = ["POST" | "GET" | "PUT" | "DELETE" | "PATCH", string];

export const restReq = (url: string, path: RestUrl, data: { [key: string]: any }) => ({
  method: path[0],
  url: `${url}${path[1]}`,
  query: path[0] === "GET" ? snakeCasedObject(data, ",") : undefined,
  data: path[0] !== "GET" ? data : undefined,
});
