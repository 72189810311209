import { RestUrl } from "../common/cyan.types";

export class UserVerificationCodeUrl {
  public static createUserVerificationCodeForSignUp: RestUrl = ["POST", "/user/verification-codes/sign-up"];
  public static createUserVerificationCodeForPasswordReset: RestUrl = ["POST", "/user/verification-codes/password-reset"];
  public static createUserVerificationCodeForPasswordInit: RestUrl = ["POST", "/user/verification-codes/password-init"];
  /** @todo verifyVerificationCode 대체하도록 변경 필요 (2024.06.22) */
  public static verifyUserVerificationCodeForPasswordInit: RestUrl = ["POST", "/user/verification-codes/password-init/verify"];
  public static createUserVerificationCodeForDeviceRegistration: RestUrl = ["POST", "/user/verification-codes/device-registration"];
  public static createUserVerificationCodeForMFA: RestUrl = ["POST", "/user/mfa/verification-codes"];
  public static createEmailVerificationCode: RestUrl = ["POST", "/verification-codes/email-verify"];
  public static verifyVerificationCode: RestUrl = ["POST", "/verification-code/verify"];
}
