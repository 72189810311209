import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_ALWAYS_SERIOUS_LIST_LANG: {
  CDMS_STUDY_ASL_TAB_LABEL: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_SUCC: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_FAIL: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_NEW_UPLOAD_FILE_STATUS: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_RESULT_FAIL_REUPLOAD_FILE_REQUIRED: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_RESULT_FAIL_EXIST_FILE_REMAINED: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_FILE_INFO_TITLE: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_FILE_NOT_EXIST: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_INFO_LABEL_FILE_NAME: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_INFO_LABEL_CREATED_BY: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_INFO_LABEL_CREATED_AT: MvnCodeFE;
  CDMS_STUDY_ASL_UPLOAD_DIALOG_DESCRIPTION: MvnCodeFE;
  CDMS_STUDY_ASL_DELETE_SUCC: MvnCodeFE;
  CDMS_STUDY_ASL_DELETE_FAIL: MvnCodeFE;
  CDMS_STUDY_ASL_DELETE_DIALOG_DESCRIPTION: MvnCodeFE;
} = {
  CDMS_STUDY_ASL_TAB_LABEL: {
    ko: "ASL 업로드",
    en: "ASL Upload",
  },
  CDMS_STUDY_ASL_UPLOAD_SUCC: {
    ko: "파일이 업로드 되었습니다.",
    en: "File has been uploaded",
  },
  CDMS_STUDY_ASL_UPLOAD_FAIL: {
    ko: "파일 업로드에 실패하였습니다.({message})",
    en: "Failed to upload file. ({message})",
  },
  CDMS_STUDY_ASL_UPLOAD_NEW_UPLOAD_FILE_STATUS: {
    ko: "새 파일 업로드 상태",
    en: "File Upload Status",
  },
  CDMS_STUDY_ASL_UPLOAD_RESULT_FAIL_REUPLOAD_FILE_REQUIRED: {
    ko: "파일을 재업로드 하시기 바랍니다.",
    en: "Please re-upload the file.",
  },
  CDMS_STUDY_ASL_UPLOAD_RESULT_FAIL_EXIST_FILE_REMAINED: {
    ko: "기존 파일이 유지됩니다.",
    en: "The previous file will be preserved.",
  },
  CDMS_STUDY_ASL_UPLOAD_FILE_INFO_TITLE: {
    ko: "업로드 파일 정보",
    en: "Uploaded File Information",
  },
  CDMS_STUDY_ASL_UPLOAD_FILE_NOT_EXIST: {
    ko: "업로드 파일이 없습니다.",
    en: "No file has been uploaded.",
  },
  CDMS_STUDY_ASL_UPLOAD_INFO_LABEL_FILE_NAME: {
    ko: "File Name",
    en: "File Name",
  },
  CDMS_STUDY_ASL_UPLOAD_INFO_LABEL_CREATED_BY: {
    ko: "Created By",
    en: "Created By",
  },
  CDMS_STUDY_ASL_UPLOAD_INFO_LABEL_CREATED_AT: {
    ko: "Created At",
    en: "Created At",
  },
  CDMS_STUDY_ASL_UPLOAD_DIALOG_DESCRIPTION: {
    ko: "최대 {sizeInMb}MB의 Excel파일을 업로드할 수 있습니다.",
    en: "Excel files up to {sizeInMb}MB can be uploaded.",
  },
  CDMS_STUDY_ASL_DELETE_SUCC: {
    ko: "파일이 삭제되었습니다.",
    en: "The file has been deleted.",
  },
  CDMS_STUDY_ASL_DELETE_FAIL: {
    ko: "파일 삭제에 실패하였습니다.",
    en: "Failed to delete the file.",
  },
  CDMS_STUDY_ASL_DELETE_DIALOG_DESCRIPTION: {
    ko: "해당 파일이 영구 삭제됩니다.\n정말 삭제하시겠습니까?",
    en: "The file will be deleted and this is irreversible.\nContinue?",
  },
};

MvnCodeInitiate(CDMS_STUDY_ALWAYS_SERIOUS_LIST_LANG);
