import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_ROLE_UPLOAD_LANG: {
  BUILDER_STUDY_ROLE_UPLOAD_TEMPLATE_DOWNLOAD_BTN: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_TEMPLATE_DOWNLOAD_SUCC: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_TEMPLATE_DOWNLOAD_FAIL: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_EMPTY: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_SUCC: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_FAIL: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_CONFIRM_DIALOG_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_OVERWRITE_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_OVERWRITE_CONFIRM_DIALOG_DESC: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_FILE_DOWNLOAD_REQUEST_SUCC: MvnCodeFE;
  BUILDER_STUDY_ROLE_UPLOAD_FILE_DOWNLOAD_REQUEST_FAIL: MvnCodeFE;
} = {
  BUILDER_STUDY_ROLE_UPLOAD_TEMPLATE_DOWNLOAD_BTN: {
    ko: "Download Template",
    en: "Download Template",
  },
  BUILDER_STUDY_ROLE_UPLOAD_TEMPLATE_DOWNLOAD_SUCC: {
    ko: "Study Role Template 다운로드에 성공했습니다.",
    en: "You have downloaded study role template successfully.",
  },
  BUILDER_STUDY_ROLE_UPLOAD_TEMPLATE_DOWNLOAD_FAIL: {
    ko: "Study Role Template 다운로드에 실패했습니다. ({message})",
    en: "Failed to download study role template. ({message})",
  },
  BUILDER_STUDY_ROLE_UPLOAD_EMPTY: {
    ko: "업로드한 이력이 없습니다.",
    en: "No Upload yet.",
  },
  BUILDER_STUDY_ROLE_UPLOAD_SUCC: {
    ko: "Study Role 업로딩을 시작했습니다.",
    en: "Uploading has been started.",
  },
  BUILDER_STUDY_ROLE_UPLOAD_FAIL: {
    ko: "{fileName} 업로드에 실패했습니다. ({message})",
    en: "Failed to upload {fileName}. ({message})",
  },
  BUILDER_STUDY_ROLE_UPLOAD_CONFIRM_DIALOG_TITLE: {
    ko: "Upload R & P",
    en: "Upload R & P",
  },
  BUILDER_STUDY_ROLE_UPLOAD_CONFIRM_DIALOG_DESC: {
    ko: "업로드하면 기존 R&P를 덮어쓰게 됩니다.",
    en: "Uploading will overwrite your existing R&P.",
  },
  BUILDER_STUDY_ROLE_UPLOAD_OVERWRITE_CONFIRM_DIALOG_TITLE: {
    ko: "Overwrite R & P",
    en: "Overwrite R & P",
  },
  BUILDER_STUDY_ROLE_UPLOAD_OVERWRITE_CONFIRM_DIALOG_DESC: {
    ko: "업로드하면 기존 R&P를 덮어쓰게 됩니다. 계속하시겠습니까?",
    en: "Uploading will overwrite your existing R&P. Are you sure you want to continue?",
  },
  BUILDER_STUDY_ROLE_UPLOAD_FILE_DOWNLOAD_REQUEST_SUCC: {
    ko: "Study Role 파일 다운로드를 요청하였습니다.",
    en: "File download request has been completed successfully.",
  },
  BUILDER_STUDY_ROLE_UPLOAD_FILE_DOWNLOAD_REQUEST_FAIL: {
    ko: "Study Role 파일 다운로드 요청에 실패했습니다. ({message})",
    en: "Failed to request file download. ({message})",
  },
};

MvnCodeInitiate(BUILDER_STUDY_ROLE_UPLOAD_LANG);
