import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const DOCS_PROPOSAL_LANG: {
  PROPOSAL_CREATE_HEADER: MvnCodeFE;
  PROPOSAL_TEMPLATE_LABEL: MvnCodeFE;
  PROPOSAL_TEMPLATE_PLACEHOLDER: MvnCodeFE;
  PROPOSAL_TEMPLATE_PLACEHOLDER_EMPTY: MvnCodeFE;
  PROPOSAL_TITLE_LABEL: MvnCodeFE;
  PROPOSAL_TITLE_PLACEHOLDER: MvnCodeFE;
  PROPOSAL_VERSION_ADD_HEADER: MvnCodeFE;
  PROPOSAL_VERSION_LABEL: MvnCodeFE;
  PROPOSAL_VERSION_PLACEHOLDER: MvnCodeFE;
  PROPOSAL_VERSION_WARNING: MvnCodeFE;
  PROPOSAL_CREATE_SUCC: MvnCodeFE;
  PROPOSAL_CREATE_FAIL: MvnCodeFE;
  PROPOSAL_VERSION_CREATE_SUCC: MvnCodeFE;
  PROPOSAL_VERSION_CREATE_FAIL: MvnCodeFE;
  PROPOSAL_VERSION_EXPORT_SUCC: MvnCodeFE;
  PROPOSAL_VERSION_EXPORT_FAIL: MvnCodeFE;
  PROPOSAL_TITLE_PATCH_FAIL: MvnCodeFE;
  PROPOSAL_CONTENT_PATCH_FAIL: MvnCodeFE;
  PROPOSAL_CONTENT_SEARCH_FAIL: MvnCodeFE;
  PROPOSAL_CONTENT_SEARCH_EMPTY: MvnCodeFE;
  PROPOSAL_CONTENT_RECONNECT_MIRRORING: MvnCodeFE;
  PROPOSAL_CONTENT_RECONNECT_MIRRORING_TITLE: MvnCodeFE;
  PROPOSAL_CONTENT_RECONNECT_MIRRORING_MESSAGE: MvnCodeFE;
  PROPOSAL_REVISION_FETCH_FAIL: MvnCodeFE;
  PROPOSAL_REVISION_EMPTY: MvnCodeFE;
  PROPOSAL_REFERENCE_EMPTY: MvnCodeFE;
  PROPOSAL_REFERENCE_DIALOG_HEADER: MvnCodeFE;
  PROPOSAL_REFERENCE_DIALOG_TITLE: MvnCodeFE;
  PROPOSAL_VARIABLE_TEXT_DIALOG_TITLE: MvnCodeFE;
  PROPOSAL_VARIABLE_TEXT_DIALOG_HEADER: MvnCodeFE;
  PROPOSAL_FOOTNOTE_DIALOG_HEADER: MvnCodeFE;
  PROPOSAL_FOOTNOTE_DIALOG_TITLE: MvnCodeFE;
  PROPOSAL_CROSS_REFERENCE_DIALOG_HEADER: MvnCodeFE;
  PROPOSAL_CROSS_REFERENCE_DIALOG_TITLE: MvnCodeFE;
  PROPOSAL_CROSS_REFERENCE_NOT_FOUND_MESSAGE: MvnCodeFE;
  PROPOSAL_CROSS_REFERENCE_ADD_SUCC: MvnCodeFE;
  PROPOSAL_CROSS_REFERENCE_ADD_FAIL: MvnCodeFE;
  PROPOSAL_SECTION_REMOVE_MESSAGE: MvnCodeFE;
  PROPOSAL_SECTION_REMOVE_DESC: MvnCodeFE;
  PROPOSAL_SECTION_REMOVE_DESC_SUB: MvnCodeFE;
  PROPOSAL_SECTION_REMOVE_SUCC: MvnCodeFE;
  PROPOSAL_SECTION_REMOVE_FAIL: MvnCodeFE;
  PROPOSAL_SECTION_RESTORE_MESSAGE: MvnCodeFE;
  PROPOSAL_SECTION_RESTORE_DESC: MvnCodeFE;
  PROPOSAL_SECTION_RESTORE_SUCC: MvnCodeFE;
  PROPOSAL_SECTION_RESTORE_FAIL: MvnCodeFE;
  PROPOSAL_SECTION_TITLE_LABEL: MvnCodeFE;
  PROPOSAL_SECTION_TITLE_PLACEHOLDER: MvnCodeFE;
  PROPOSAL_SECTION_ADD_SUCC: MvnCodeFE;
  PROPOSAL_SECTION_ADD_FAIL: MvnCodeFE;
  PROPOSAL_SECTION_MOVE_SUCC: MvnCodeFE;
  PROPOSAL_SECTION_MOVE_FAIL: MvnCodeFE;
  PROPOSAL_SECTION_OVER_MAX_DEPTH: MvnCodeFE;
  PROPOSAL_SECTION_CIRCULAR_REFERENCE: MvnCodeFE;
  PROPOSAL_SECTION_MOVE_ROOT: MvnCodeFE;
  PROPOSAL_COMMENT_HEADER: MvnCodeFE;
  PROPOSAL_COMMNET_FORM_PLACEHOLDER: MvnCodeFE;
  PROPOSAL_COMMENT_CREATE_COMMENT_SUCC: MvnCodeFE;
  PROPOSAL_COMMENT_CREATE_COMMENT_FAIL: MvnCodeFE;
  PROPOSAL_COMMENT_DELETED_SECTION_MESSAGE: MvnCodeFE;
  PROPOSAL_COMMENT_DELETED_CONTENT_MESSAGE: MvnCodeFE;
  PROPOSAL_COMMENT_DELETE_THREAD_MESSAGE: MvnCodeFE;
  PROPOSAL_COMMENT_DELETE_THREAD_SUCC: MvnCodeFE;
  PROPOSAL_COMMENT_DELETE_THREAD_FAIL: MvnCodeFE;
  PROPOSAL_COMMENT_DELETE_COMMENT_MESSAGE: MvnCodeFE;
  PROPOSAL_COMMENT_DELETE_COMMENT_SUCC: MvnCodeFE;
  PROPOSAL_COMMENT_DELETE_COMMENT_FAIL: MvnCodeFE;
  PROPOSAL_COMMENT_RESOLVE_COMMENT_SUCC: MvnCodeFE;
  PROPOSAL_COMMENT_RESOLVE_COMMENT_FAIL: MvnCodeFE;
  PROPOSAL_COMMENT_UNRESOLVE_COMMENT_DIALOG_HEADER: MvnCodeFE;
  PROPOSAL_COMMENT_UNRESOLVE_COMMENT_DIALOG_CONFIRM: MvnCodeFE;
  PROPOSAL_COMMENT_UNRESOLVE_COMMENT_MESSAGE: MvnCodeFE;
  PROPOSAL_COMMENT_UNRESOLVE_COMMENT_SUCC: MvnCodeFE;
  PROPOSAL_COMMENT_UNRESOLVE_COMMENT_FAIL: MvnCodeFE;
  PROPOSAL_COMMENT_EDIT_COMMENT_SUCC: MvnCodeFE;
  PROPOSAL_COMMENT_EDIT_COMMENT_FAIL: MvnCodeFE;
  PROPOSAL_COMMENT_CREATE_COMMENT_SELECTION_DELETED: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_ADD_SHADING: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_REMOVE_SHADING: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_ADD_ROW_ABOVE: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_ADD_ROW_BELOW: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_ADD_COLUMN_LEFT: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_ADD_COLUMN_RIGHT: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_DELETE_ROW: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_DELETE_COLUMN: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_DELETE_CONTENT: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_DELETE_TABLE: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_MERGE_CELL: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_UNDO_MERGE_CELL: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_SPLIT_CELL: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_SPLIT_CELL_HORIZONTAL: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_SPLIT_CELL_VERTICAL: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_DISTRIBUTE_COLUMN: MvnCodeFE;
  PROPOSAL_TABLE_CONTEXT_MENU_COPY_TABLE: MvnCodeFE;
} = {
  PROPOSAL_CREATE_HEADER: {
    ko: "Create Proposal",
    en: "Create Proposal",
  },
  PROPOSAL_TEMPLATE_LABEL: {
    ko: "Template",
    en: "Template",
  },
  PROPOSAL_TEMPLATE_PLACEHOLDER: {
    ko: "문서 템플릿을 선택해 주세요.",
    en: "Please choose the document template.",
  },
  PROPOSAL_TEMPLATE_PLACEHOLDER_EMPTY: {
    ko: "등록된 문서 템플릿이 없습니다.",
    en: "There is no available document template.",
  },
  PROPOSAL_TITLE_LABEL: {
    ko: "Title",
    en: "Title",
  },
  PROPOSAL_TITLE_PLACEHOLDER: {
    ko: "문서 제목을 입력해 주세요.",
    en: "Please enter the document title.",
  },
  PROPOSAL_VERSION_ADD_HEADER: {
    ko: "Add Version",
    en: "Add Version",
  },
  PROPOSAL_VERSION_LABEL: {
    ko: "Version",
    en: "Version",
  },
  PROPOSAL_VERSION_PLACEHOLDER: {
    ko: "1.0",
    en: "1.0",
  },
  PROPOSAL_VERSION_WARNING: {
    ko: "신규 버전을 등록할 경우 이전 버전의 편집이 제한됩니다.",
    en: "Editing of previous version will be restricted after registered a new version.",
  },
  PROPOSAL_CREATE_SUCC: {
    ko: "문서 생성에 성공했습니다.",
    en: "You have created a proposal successfully.",
  },
  PROPOSAL_CREATE_FAIL: {
    ko: "문서 생성에 실패했습니다. ({message})",
    en: "Failed to create a document. ({message})",
  },
  PROPOSAL_VERSION_CREATE_SUCC: {
    ko: "문서 버전 생성에 성공했습니다.",
    en: "You have created a new version of the document successfully.",
  },
  PROPOSAL_VERSION_CREATE_FAIL: {
    ko: "문서 버전 생성에 실패했습니다. ({message})",
    en: "Failed to create a new version of document. ({message})",
  },
  PROPOSAL_VERSION_EXPORT_SUCC: {
    ko: "문서 다운로드가 시작되었습니다.",
    en: "Document download has been started.",
  },
  PROPOSAL_VERSION_EXPORT_FAIL: {
    ko: "문서 다운로드에 실패했습니다. ({message})",
    en: "Failed to download a document. ({message})",
  },
  PROPOSAL_TITLE_PATCH_FAIL: {
    ko: "문서 제목 저장에 실패했습니다. ({message})",
    en: "Failed to save a document title. ({message})",
  },
  PROPOSAL_CONTENT_PATCH_FAIL: {
    ko: "문서 저장에 실패했습니다. ({message})",
    en: "Failed to save a document content. ({message})",
  },
  PROPOSAL_CONTENT_SEARCH_FAIL: {
    ko: "문서 검색에 실패했습니다. ({message})",
    en: "Failed to search library. ({message})",
  },
  PROPOSAL_CONTENT_SEARCH_EMPTY: {
    ko: "검색된 관련 문서가 없습니다. ({keyword})",
    en: "No related documents found. ({keyword})",
  },
  PROPOSAL_CONTENT_RECONNECT_MIRRORING: {
    ko: "Reconnect Mirroring",
    en: "Reconnect Mirroring",
  },
  PROPOSAL_CONTENT_RECONNECT_MIRRORING_TITLE: {
    ko: "Mirroring을 다시 연결하시겠습니까?",
    en: "Are you sure you want to reconnect mirroring?",
  },
  PROPOSAL_CONTENT_RECONNECT_MIRRORING_MESSAGE: {
    ko: "※ 연결하면 해당 섹션에 작성하신 내용은 사라지고, mirroring한 내용으로 대체됩니다.",
    en: "※ When connected, the contents written in this section disappear and is replaced with the mirrorred contents.",
  },
  PROPOSAL_REVISION_FETCH_FAIL: {
    ko: "문서 변경 이력 조회에 실패했습니다. ({message})",
    en: "Failed to fetch revision history. ({message})",
  },
  PROPOSAL_REVISION_EMPTY: {
    ko: "변경 이력이 없습니다.",
    en: "No revision history.",
  },
  PROPOSAL_REFERENCE_EMPTY: {
    ko: "문단에 입력된 참조가 자동으로 반영됩니다.",
    en: "References will be automatically reflected here.",
  },
  PROPOSAL_REFERENCE_DIALOG_HEADER: {
    ko: "Reference",
    en: "Reference",
  },
  PROPOSAL_REFERENCE_DIALOG_TITLE: {
    ko: "선택한 단락의 출처를 입력해 주세요.",
    en: "Please enter the source of the selected paragraph.",
  },
  PROPOSAL_VARIABLE_TEXT_DIALOG_HEADER: {
    ko: "Variable Text",
    en: "Variable Text",
  },
  PROPOSAL_VARIABLE_TEXT_DIALOG_TITLE: {
    ko: "Variable Text는 폴더에 포함된 모든 문서에 반영됩니다.",
    en: "Variable text will be reflected to all documents in folder.",
  },
  PROPOSAL_FOOTNOTE_DIALOG_HEADER: {
    ko: "Footnote",
    en: "Footnote",
  },
  PROPOSAL_FOOTNOTE_DIALOG_TITLE: {
    ko: "선택한 단락의 각주를 입력해 주세요.",
    en: "Please enter the footnote of the selected paragraph.",
  },
  // TODO: 기획에 따라 메시지 변경 필요
  PROPOSAL_CROSS_REFERENCE_DIALOG_HEADER: {
    ko: "Cross-reference",
    en: "Cross-reference",
  },
  PROPOSAL_CROSS_REFERENCE_DIALOG_TITLE: {
    ko: "삽입할 섹션을 선택해 주세요.",
    en: "Please select the section you would like to insert.",
  },
  PROPOSAL_CROSS_REFERENCE_NOT_FOUND_MESSAGE: {
    ko: "상호 참조한 섹션이 삭제됐습니다.",
    en: "The cross-reference section has been removed.",
  },
  PROPOSAL_CROSS_REFERENCE_ADD_SUCC: {
    ko: "상호 참조를 삽입했습니다.",
    en: "The cross-reference successfully inserted.",
  },
  PROPOSAL_CROSS_REFERENCE_ADD_FAIL: {
    ko: "상호 참조 삽입을 실패했습니다.",
    en: "Failed to insert the cross-reference.",
  },
  PROPOSAL_SECTION_REMOVE_MESSAGE: {
    ko: "선택한 Section을 삭제하시겠습니까? 하위 섹션이 있는 경우 모두 삭제됩니다.",
    en: "Are you sure you want to remove the selected section? Subsections will be removed together.",
  },
  PROPOSAL_SECTION_REMOVE_DESC: {
    ko: "※ 삭제한 섹션은 휴지통에서 복원할 수 있습니다.",
    en: "※ You can restore the removed section from trash.",
  },
  PROPOSAL_SECTION_REMOVE_DESC_SUB: {
    ko: "※ 상위 섹션을 복원하는 경우 하위 섹션도 모두 복원됩니다.",
    en: "※ If you restore parent section, subsections will be restored together.",
  },
  PROPOSAL_SECTION_REMOVE_SUCC: {
    ko: "Section이 삭제되었습니다.",
    en: "Section has been removed.",
  },
  PROPOSAL_SECTION_REMOVE_FAIL: {
    ko: "Section 삭제에 실패했습니다. ({message})",
    en: "The section removal request has been failed. ({message})",
  },
  PROPOSAL_SECTION_RESTORE_MESSAGE: {
    ko: "선택한 Section을 복구하시겠습니까?",
    en: "Are you sure you want to restore the selected section?",
  },
  PROPOSAL_SECTION_RESTORE_DESC: {
    ko: "복원할 섹션을 선택해 주세요.",
    en: "Please select the section you would like to restore.",
  },
  PROPOSAL_SECTION_RESTORE_SUCC: {
    ko: "섹션을 복구하였습니다.",
    en: "The section successfully restored.",
  },
  PROPOSAL_SECTION_RESTORE_FAIL: {
    ko: "섹션 복원을 실패했습니다.",
    en: "The section restore request has been failed. ({message})",
  },
  PROPOSAL_SECTION_TITLE_LABEL: {
    ko: "Section Title",
    en: "Section Title",
  },
  PROPOSAL_SECTION_TITLE_PLACEHOLDER: {
    ko: "섹션 제목을 입력해 주세요.",
    en: "Please enter the section title.",
  },
  PROPOSAL_SECTION_ADD_SUCC: {
    ko: "Section이 추가되었습니다.",
    en: "Section has been added.",
  },
  PROPOSAL_SECTION_ADD_FAIL: {
    ko: "Section 추가에 실패했습니다. ({message})",
    en: "The section addition request has been failed. ({message})",
  },
  PROPOSAL_SECTION_MOVE_SUCC: {
    ko: "Section을 이동했습니다.",
    en: "You have moved the section successfully.",
  },
  PROPOSAL_SECTION_MOVE_FAIL: {
    ko: "Section 이동에 실패했습니다. ({message})",
    en: "You have failed to move the section. ({message})",
  },
  PROPOSAL_SECTION_OVER_MAX_DEPTH: {
    ko: "최대 4레벨까지 섹션을 추가할 수 있습니다.",
    en: "You can only add a section up to level 4.",
  },
  PROPOSAL_SECTION_CIRCULAR_REFERENCE: {
    ko: "해당 섹션의 하위 레벨로는 이동할 수 없습니다.",
    en: "You cannot move the section to sub level.",
  },
  PROPOSAL_SECTION_MOVE_ROOT: {
    ko: "1레벨 섹션은 이동할 수 없습니다.",
    en: "You cannot move level 1 section.",
  },
  PROPOSAL_COMMENT_HEADER: {
    ko: "댓글",
    en: "Comments",
  },
  PROPOSAL_COMMNET_FORM_PLACEHOLDER: {
    ko: "댓글을 입력해주세요.",
    en: "Add a comment.",
  },
  PROPOSAL_COMMENT_CREATE_COMMENT_SUCC: {
    ko: "댓글 등록에 성공했습니다.",
    en: "You have added a comment successfully.",
  },
  PROPOSAL_COMMENT_CREATE_COMMENT_FAIL: {
    ko: "댓글 등록에 실패했습니다. ({message})",
    en: "Failed to add a comment. ({message})",
  },
  PROPOSAL_COMMENT_DELETED_SECTION_MESSAGE: {
    ko: "삭제됐습니다.",
    en: "This section has been deleted.",
  },
  PROPOSAL_COMMENT_DELETED_CONTENT_MESSAGE: {
    ko: "삭제됐습니다.",
    en: "This paragraph has been deleted.",
  },
  PROPOSAL_COMMENT_DELETE_THREAD_MESSAGE: {
    ko: "댓글 목록을 삭제하시겠습니까?",
    en: "Are you sure you want to delete this comment thread?",
  },
  PROPOSAL_COMMENT_DELETE_THREAD_SUCC: {
    ko: "댓글 목록 삭제에 성공했습니다.",
    en: "You have deleted a comment thread successfully.",
  },
  PROPOSAL_COMMENT_DELETE_THREAD_FAIL: {
    ko: "댓글 목록 삭제에 실패했습니다. ({message})",
    en: "Failed to delete a comment thread. ({message})",
  },
  PROPOSAL_COMMENT_DELETE_COMMENT_MESSAGE: {
    ko: "댓글을 삭제하시겠습니까?",
    en: "Are you sure you want to delete a comment?",
  },
  PROPOSAL_COMMENT_DELETE_COMMENT_SUCC: {
    ko: "댓글 삭제에 성공했습니다.",
    en: "You have deleted a comment successfully.",
  },
  PROPOSAL_COMMENT_DELETE_COMMENT_FAIL: {
    ko: "댓글 삭제에 실패했습니다. ({message})",
    en: "Failed to delete a comment. ({message})",
  },
  PROPOSAL_COMMENT_RESOLVE_COMMENT_SUCC: {
    ko: "댓글 해결 처리에 성공했습니다.",
    en: "You have resolved a comment successfully.",
  },
  PROPOSAL_COMMENT_RESOLVE_COMMENT_FAIL: {
    ko: "댓글 해결 처리에 실패했습니다.({message})",
    en: "Failed to resolve a comment. ({message})",
  },
  PROPOSAL_COMMENT_UNRESOLVE_COMMENT_DIALOG_HEADER: {
    ko: "Re-Open",
    en: "Re-Open",
  },
  PROPOSAL_COMMENT_UNRESOLVE_COMMENT_DIALOG_CONFIRM: {
    ko: "Re-Open",
    en: "Re-Open",
  },
  PROPOSAL_COMMENT_UNRESOLVE_COMMENT_MESSAGE: {
    ko: "해결된 댓글을 미해결 상태로 변경하시겠습니까?",
    en: "Are you sure you want to change a comment’s status from resolved to open?",
  },
  PROPOSAL_COMMENT_UNRESOLVE_COMMENT_SUCC: {
    ko: "댓글 미해결 처리에 성공했습니다.",
    en: "You have re-opened a comment successfully.",
  },
  PROPOSAL_COMMENT_UNRESOLVE_COMMENT_FAIL: {
    ko: "댓글 미해결 처리에 실패했습니다.",
    en: "Failed to re-open a comment. ({message})",
  },
  PROPOSAL_COMMENT_EDIT_COMMENT_SUCC: {
    ko: "댓글 수정에 성공했습니다.",
    en: "You have edited a comment successfully.",
  },
  PROPOSAL_COMMENT_EDIT_COMMENT_FAIL: {
    ko: "댓글 수정에 실패했습니다. ({message})",
    en: "Failed to edit a comment. ({message})",
  },
  PROPOSAL_COMMENT_CREATE_COMMENT_SELECTION_DELETED: {
    ko: "본문에서 선택한 내용이 삭제됐습니다.",
    en: "The selected text has been deleted.",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_ADD_SHADING: {
    ko: "셀 음영",
    en: "Shading",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_REMOVE_SHADING: {
    ko: "셀 음영 제거",
    en: "Remove Shading",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_ADD_ROW_ABOVE: {
    ko: "위에 행 삽입",
    en: "Add a Row Above",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_ADD_ROW_BELOW: {
    ko: "아래에 행 삽입",
    en: "Add a Row Below",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_ADD_COLUMN_LEFT: {
    ko: "왼쪽에 열 삽입",
    en: "Add a Column to the Left",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_ADD_COLUMN_RIGHT: {
    ko: "오른쪽에 열 삽입",
    en: "Add a Column to the Right",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_DELETE_ROW: {
    ko: "행 삭제",
    en: "Delete Entire Row",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_DELETE_COLUMN: {
    ko: "열 삭제",
    en: "Delete Entire Column",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_DELETE_CONTENT: {
    ko: "셀 지우기",
    en: "Delete Contents",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_DELETE_TABLE: {
    ko: "표 삭제",
    en: "Delete Table",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_MERGE_CELL: {
    ko: "셀 병합",
    en: "Merge",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_UNDO_MERGE_CELL: {
    ko: "셀 병합 해제",
    en: "Undo Merge",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_SPLIT_CELL: {
    ko: "셀 나누기",
    en: "Split Cell",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_SPLIT_CELL_HORIZONTAL: {
    ko: "행으로 나누기",
    en: "Split Horizontally",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_SPLIT_CELL_VERTICAL: {
    ko: "열로 나누기",
    en: "Split Vertically",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_DISTRIBUTE_COLUMN: {
    ko: "열 균등배치",
    en: "Distribute Columns",
  },
  PROPOSAL_TABLE_CONTEXT_MENU_COPY_TABLE: {
    ko: "표 복사",
    en: "Copy Table",
  },
};

MvnCodeInitiate(DOCS_PROPOSAL_LANG);
