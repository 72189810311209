import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const VDR_ROOM_SETTING_LANG: {
  LAUNCH_ROOM_SUCCESS: MvnCodeFE;
  CLOSE_ROOM_SUCCESS: MvnCodeFE;
  SAVE_ROOM_SUCCESS: MvnCodeFE;
  SAVE_ROOM_FAILED: MvnCodeFE;
  ROOM_CLOSE_DESCRIPTION_1: MvnCodeFE;
  ROOM_CLOSE_DESCRIPTION_2: MvnCodeFE;
  ROOM_CLOSE_DESCRIPTION_3: MvnCodeFE;
  ROOM_LAUNCH_DESCRIPTION_1: MvnCodeFE;
  ROOM_LAUNCH_DESCRIPTION_2: MvnCodeFE;
  ROOM_LAUNCH_DESCRIPTION_3: MvnCodeFE;
  WATERMARK_CUSTOM_MAX_LENGTH_EXCEEDED: MvnCodeFE;
  WATERMARK_CUSTOM_EMPTY: MvnCodeFE;
  WATERMARK_UPSERT_SUCCESS: MvnCodeFE;
  WATERMARK_UPSERT_FAILED: MvnCodeFE;
  WATERMARK_LEAST_ONE_INCLUDE_REQUIRED: MvnCodeFE;
} = {
  // SnackBar
  LAUNCH_ROOM_SUCCESS: {
    ko: "Room을 launch 하였습니다.",
    en: "Room has been launched.",
  },
  CLOSE_ROOM_SUCCESS: {
    ko: "Room을 close 하였습니다.",
    en: "Room has been closed.",
  },
  SAVE_ROOM_SUCCESS: {
    ko: "룸 정보를 저장하였습니다.",
    en: "The room information has been saved.",
  },
  SAVE_ROOM_FAILED: {
    ko: "룸 정보 저장에 실패하였습니다.",
    en: "Failed to save the room info.",
  },

  ROOM_CLOSE_DESCRIPTION_1: {
    ko: "룸을 닫힌 룸으로 이동됩니다.",
    en: "The room will be moved to the Closed Room Tab.",
  },
  ROOM_CLOSE_DESCRIPTION_2: {
    ko: "변경 후 준비중 또는 개시 상태로 되돌릴 수 없습니다.",
    en: "You will not be able to reverse the status back to Active.",
  },
  ROOM_CLOSE_DESCRIPTION_3: {
    ko: "룸을 닫으시겠습니까?",
    en: "Are you sure you want to close the room?",
  },
  ROOM_LAUNCH_DESCRIPTION_1: {
    ko: "룸을 개시하면 공개 상태로 변경됩니다.",
    en: "Once you launch the room, it is open to the public.",
  },
  ROOM_LAUNCH_DESCRIPTION_2: {
    ko: "변경 후 준비중으로 되돌릴 수 없습니다.",
    en: "You will not be able to reverse the status back to Getting Ready.",
  },
  ROOM_LAUNCH_DESCRIPTION_3: {
    ko: "룸을 개시하겠습니까?",
    en: "Are you sure you want to launch the room?",
  },
  WATERMARK_CUSTOM_MAX_LENGTH_EXCEEDED: {
    ko: "최대 40자까지 입력 가능합니다.",
    en: "Maximum 40 characters are allowed.",
  },
  WATERMARK_CUSTOM_EMPTY: {
    ko: "텍스트를 입력해 주세요",
    en: "Please enter the text.",
  },
  WATERMARK_UPSERT_SUCCESS: {
    ko: "워터마크 설정을 저장하였습니다.",
    en: "Watermark settings have been saved.",
  },
  WATERMARK_UPSERT_FAILED: {
    ko: "워터마크 설정 저장에 실패하였습니다.",
    en: "Failed to save the watermark settings.",
  },
  WATERMARK_LEAST_ONE_INCLUDE_REQUIRED: {
    ko: "최소한 1개의 속성이 필요합니다.",
    en: "At least one property is required.",
  },
};

MvnCodeInitiate(VDR_ROOM_SETTING_LANG);
