import React from "react";
import { useRequest } from "@coralblack/flax";
import { orchestraMsaApi } from "maven-lib/dist/states/apis/orchestra";
import { useInterval } from "../../hooks/useInterval";

interface WakenProps {}

export function Waken(props: WakenProps) {
  const { request } = useRequest({ ...orchestraMsaApi.waken });

  useInterval(() => {
    request();
  }, 60 * 1000);

  return (
    <>
      <div style={{ visibility: "hidden", width: 0, height: 0 }}>Waken: {new Date().toString()}</div>
    </>
  );
}
