import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_LANG: {
  STUDY_LIST_HOME_BREADCRUMB: MvnCodeFE;
  STUDY_CLOSED_LIST_HOME_BREADCRUMB: MvnCodeFE;
  STUDY_ADD_TITLE: MvnCodeFE;
  STUDY_EDIT_TITLE: MvnCodeFE;
  STUDY_TITLE: MvnCodeFE;
  STUDY_TITLE_PLACEHOLDER: MvnCodeFE;
  STUDY_TITLE_EN: MvnCodeFE;
  STUDY_TITLE_EN_PLACEHOLDER: MvnCodeFE;
  STUDY_NAME: MvnCodeFE;
  STUDY_NAME_PLACEHOLDER: MvnCodeFE;
  STUDY_PROTOCOL_NO: MvnCodeFE;
  STUDY_PROTOCOL_NO_PLACEHOLDER: MvnCodeFE;
  STUDY_SPONSOR: MvnCodeFE;
  STUDY_SPONSOR_PLACEHOLDER: MvnCodeFE;
  STUDY_CRO: MvnCodeFE;
  STUDY_CRO_PLACEHOLDER: MvnCodeFE;
  STUDY_PHASE: MvnCodeFE;
  STUDY_PHASE_PLACEHOLDER: MvnCodeFE;
  STUDY_NUMBER_OF_SUBJECTS: MvnCodeFE;
  STUDY_NUMBER_OF_SUBJECTS_PLACEHOLDER: MvnCodeFE;
  STUDY_NUMBER_OF_RANDOM_SUBJECTS: MvnCodeFE;
  STUDY_NUMBER_OF_RANDOM_SUBJECTS_PLACEHOLDER: MvnCodeFE;
  STUDY_CRF_PAYLOAD: MvnCodeFE;
  STUDY_CRF_PAYLOAD_PLACEHOLDER: MvnCodeFE;
  STUDY_ADD_SUCC: MvnCodeFE;
  STUDY_ADD_FAIL: MvnCodeFE;
  STUDY_EDIT_SUCC: MvnCodeFE;
  STUDY_EDIT_FAIL: MvnCodeFE;
} = {
  STUDY_LIST_HOME_BREADCRUMB: {
    ko: "현재 진행중인 스터디 목록을 확인할 수 있습니다. 스터디를 선택하면 상세페이지로 이동할 수 있습니다.",
    en: "Overall study information. Select Protocol no. for details.",
  },
  STUDY_CLOSED_LIST_HOME_BREADCRUMB: {
    ko: "종료된 스터디 목록을 확인할 수 있습니다. 종료된 스터디의 임상시험데이터는 일정 기간 보관 후 삭제됩니다.",
    en: "Closed study information. Closed study data will be deleted after a period of time.",
  },
  STUDY_ADD_TITLE: {
    ko: "Study 추가",
    en: "Add Study",
  },
  STUDY_EDIT_TITLE: {
    ko: "Study 수정",
    en: "Edit Study",
  },
  STUDY_TITLE: {
    ko: "Study Title",
    en: "Study Title",
  },
  STUDY_TITLE_PLACEHOLDER: {
    ko: "Study Title을 입력해 주세요. (ex. ***를 복용 중인 *** 환자를 대상으로 ***의 효과를 평가하기 위한, 제 *상 임상시험)",
    en: "Please Enter the study Title. (ex. Phase * clinical trial to assess the effectiveness of *** in *** patients taking ***)",
  },
  STUDY_TITLE_EN: {
    ko: "Study Title(en)",
    en: "Study Title(en)",
  },
  STUDY_TITLE_EN_PLACEHOLDER: {
    ko: "Study Title(en)",
    en: "Study Title(en)",
  },
  STUDY_NAME: {
    ko: "Study Name",
    en: "Study Name",
  },
  STUDY_NAME_PLACEHOLDER: {
    ko: "Study Name을 입력해주세요.",
    en: "Please enter the study name.",
  },
  STUDY_PROTOCOL_NO: {
    ko: "Protocol No.",
    en: "Protocol No.",
  },
  STUDY_PROTOCOL_NO_PLACEHOLDER: {
    ko: "Protocol No.를 입력해주세요.",
    en: "Please enter the Protocol No.",
  },
  STUDY_SPONSOR: {
    ko: "Sponsor",
    en: "Sponsor",
  },
  STUDY_SPONSOR_PLACEHOLDER: {
    ko: "Sponsor를 선택해주세요.",
    en: "Please select the sponsor.",
  },
  STUDY_CRO: {
    ko: "CRO",
    en: "CRO",
  },
  STUDY_CRO_PLACEHOLDER: {
    ko: "CRO를 선택해주세요.",
    en: "Please select the CRO.",
  },
  STUDY_PHASE: {
    ko: "Study Phase",
    en: "Study Phase",
  },
  STUDY_PHASE_PLACEHOLDER: {
    ko: "Study Phase를 선택해주세요.",
    en: "Please select the Study Phase.",
  },
  STUDY_NUMBER_OF_SUBJECTS: {
    ko: "배정 대상자 수",
    en: "Number of Subjects",
  },
  STUDY_NUMBER_OF_SUBJECTS_PLACEHOLDER: {
    ko: "배정 대상자 수를 입력해 주세요",
    en: "Please enter the number of subjects",
  },
  STUDY_NUMBER_OF_RANDOM_SUBJECTS: {
    ko: "무작위 배정 대상자 수",
    en: "Number of Randomization",
  },
  STUDY_NUMBER_OF_RANDOM_SUBJECTS_PLACEHOLDER: {
    ko: "무작위 배정 대상자 수를 입력해 주세요.",
    en: "Please enter the number of randomization.",
  },
  STUDY_CRF_PAYLOAD: {
    ko: "eCRF Payload",
    en: "eCRF Payload",
  },
  STUDY_CRF_PAYLOAD_PLACEHOLDER: {
    ko: "eCRF Payload를 입력해주세요.",
    en: "Please enter the eCRF payload.",
  },
  STUDY_ADD_SUCC: {
    ko: "신규 Study 등록에 성공하였습니다.",
    en: "A new study({message}) is registered successfully.",
  },
  STUDY_ADD_FAIL: {
    ko: "신규 Study 등록에 실패하였습니다. ({error})",
    en: "New study registration has been failed. ({error})",
  },
  STUDY_EDIT_SUCC: {
    ko: "성공적으로 Study 정보를 수정했습니다.",
    en: "Study information is edited successfully.",
  },
  STUDY_EDIT_FAIL: {
    ko: "Study 정보 수정에 실패했습니다. ({error})",
    en: "Study information edit has been failed.({error})",
  },
};

MvnCodeInitiate(STUDY_LANG);
