import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_SUBJECT_CONTACT_CODE: {
  STUDY_SUBJECT_NOT_SET: MvnCodeBE;
} = {
  STUDY_SUBJECT_NOT_SET: {
    status: 400,
    ko: "대상자 전화번호가 설정되지 않았습니다. 전화번호 설정 후 다시 시도해 주십시오.",
    en: "Subject phone number has not been entered. Please try again after setting the phone number.",
  },
};

MvnCodeInitiate(STUDY_SUBJECT_CONTACT_CODE);
