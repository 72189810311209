import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CUSTOM_DATA_CRF_FILE_CODE: {
  STUDY_CUSTOM_DATA_CRF_FILE_NOT_FOUND: MvnCodeBE;
  STUDY_CUSTOM_DATA_CRF_FILE_NOT_AVAILABLE: MvnCodeBE;
} = {
  STUDY_CUSTOM_DATA_CRF_FILE_NOT_FOUND: {
    status: 404,
    ko: "Study Data Combination File 정보가 존재하지 않습니다.",
    en: "The Study Data Combination File info does not exist.",
  },
  STUDY_CUSTOM_DATA_CRF_FILE_NOT_AVAILABLE: {
    status: 400,
    ko: "요청하신 Study Data Combination File 정보가 올바르지 않습니다.",
    en: "The Study Data combination File info you requested is invalid.",
  },
};

MvnCodeInitiate(STUDY_CUSTOM_DATA_CRF_FILE_CODE);
