import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_REPORT_INFO_LANG: {
  CDMS_SYNC_REQUEST_DIALOG_HEADER: MvnCodeFE;
  CDMS_SYNC_DATA_LIST_DIALOG_HEADER: MvnCodeFE;
  CDMS_SYNC_REQUEST_DIALOG_DESCRIPTION: MvnCodeFE;
  CDMS_SYNC_REQUEST_BUTTON: MvnCodeFE;
} = {
  CDMS_SYNC_REQUEST_DIALOG_HEADER: {
    ko: "Report Sync",
    en: "Report Sync",
  },
  CDMS_SYNC_DATA_LIST_DIALOG_HEADER: {
    ko: "Sync Data",
    en: "Sync Data",
  },
  CDMS_SYNC_REQUEST_DIALOG_DESCRIPTION: {
    ko: "Report 연관 CRF 상 변경 사항이 있습니다. Sync를 진행하시겠습니까?",
    en: "There are changes to the report related CRF. Do you want to sync data?",
  },
  CDMS_SYNC_REQUEST_BUTTON: {
    ko: "Sync",
    en: "Sync",
  },
};

MvnCodeInitiate(CDMS_STUDY_REPORT_INFO_LANG);
