import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const AUTH_INVOICE_LANG: {
  AUTH_INVOICE_SUBSCRIPTION_EMPTY: MvnCodeFE;
  AUTH_INVOICE_SUBSCRIPTION_EMPTY_GO: MvnCodeFE;
} = {
  AUTH_INVOICE_SUBSCRIPTION_EMPTY: {
    ko: "발행된 Invoice가 없습니다.\n먼저 Plan을 구독해 주세요.",
    en: "No invoices have been issued.\nSubscribe a plan.",
  },
  AUTH_INVOICE_SUBSCRIPTION_EMPTY_GO: {
    ko: "Plan 구독하기",
    en: "Subscribe a Plan",
  },
};

MvnCodeInitiate(AUTH_INVOICE_LANG);
